import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    // Sign out

    case ActionTypes.SignOut: {
      return {
        ...state,
        social: false,
      };
    }

    // Reset password

    case ActionTypes.ResetPassword: {
      return {
        ...state,
      };
    }

    case ActionTypes.ResetPasswordSuccess: {
      return {
        ...state,
        uuid: action.payload.response.data,
      };
    }

    case ActionTypes.ResetPasswordFailure: {
      return {
        ...state,
        error: action.payload.response.error,
      };
    }

    case ActionTypes.ConfirmCode: {
      return {
        ...state,
      };
    }

    case ActionTypes.ConfirmCodeSuccess: {
      return {
        ...state,
        confirm: true,
      };
    }

    case ActionTypes.ConfirmCodeFailure: {
      return {
        ...state,
        confirm: false,
      };
    }

    case ActionTypes.UpdatePassword: {
      return {
        ...state,
      };
    }

    case ActionTypes.UpdatePasswordSuccess: {
      return {
        ...state,
        passwordUpdated: true,
      };
    }

    case ActionTypes.UpdatePasswordFailure: {
      return {
        ...state,
        passwordUpdated: false,
      };
    }

    default: {
      return state;
    }
  }
}
