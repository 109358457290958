import { EventTicketsResponse } from '@app/models/client/tickets/event-tickets-response.model';
import { GetFanTicketsResponse } from '@app/models/client/tickets/get-fan-tickets-response.model';
import { CreateTicketRequest } from '@models/client/tickets/create-ticket-request.model';

export interface State {
  loading: boolean;
  loaded: boolean;
  entities: {
    [id: number]: {
      ticket: any;
      count: number;
      left: number;
    };
  };
  organizerEventTicketsResponse: EventTicketsResponse;
  ticketForUpdate: CreateTicketRequest;
  fanBoughtTicketsResponse: GetFanTicketsResponse;
  inviteSuccess: boolean;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  entities: {},
  organizerEventTicketsResponse: null,
  ticketForUpdate: null,
  fanBoughtTicketsResponse: null,
  inviteSuccess: false,
};
