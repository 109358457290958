/* tslint:disable:max-line-length */
import { Action } from '@ngrx/store';

import { Response } from '@app/interfaces/response.interface';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { MessagesOrganizerResponseSuccess } from '@app/models/client/organizer/messages-organizer-response-success.model';
import { FollowersCount } from '@app/models/client/followers-count.model';
import { OrganizerProfile } from '@app/models/client/organizer/organizer-profile.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { CreateOrUpdateOrganizerAccountRequest } from '@app/models/client/organizer/create-or-update-organizer-account-request/create-or-update-organizer-account-request.model';
import { AccountType } from '@app/models/admin/accounts/account-type.enum';
import { EventResponse } from '@models/client/events/event-response.model';
import { GetOrganizerEventsResponse } from '@modules/api/models/get-organizer-events-response';
import { GetGoingFanEventResponseSuccess } from '@modules/api/models/get-going-fan-event-response-success';
import { GetEventsResponse } from '@modules/api/models/get-events-response';
import { GetOrganizerPermissionResponse } from '@modules/api/models/get-organizer-permission';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export enum ActionTypes {
  // Create Profile
  CleanEvents = '[Organizer] Clean Events',
  CleanEventById = '[Organizer] Clean Event By ID',
  CleanProfile = '[Organizer] Clean Profile',

  CreateProfile = '[Organizer] Create Profile',
  CreateProfileSuccess = '[Organizer] Create Profile Success',
  CreateProfileFailure = '[Organizer] Create Profile Failure',

  SetIsUpdate = '[Organizer] Set IsUpdate',

  // Set Profile
  SetProfile = '[Organizer] Set Profile',

  // Get organizer permission
  GetOrganizerPermission = '[Organizer] Get permission',
  GetOrganizerPermissionSuccess = '[Organizer] Get permission Success',
  GetOrganizerPermissionFailure = '[Organizer] Get permission Failure',

  // Send On Review
  SendOnReview = '[Organizer] Send on review',
  SendOnReviewSuccess = '[Organizer] Send on review Success',
  SendOnReviewFailure = '[Organizer] Send on review Failure',

  // Get Profile By ID
  GetProfileByID = '[Organizer] Get Profile By ID',
  GetProfileByIDSuccess = '[Organizer] Get Profile By ID Success',
  GetProfileByIDFailure = '[Organizer] Get Profile By ID Failure',

  // Update Profile
  UpdateProfile = '[Organizer] Update Profile',
  UpdateProfileSuccess = '[Organizer] Update Profile Success',
  UpdateProfileFailure = '[Organizer] Update Profile Failure',

  SetProfilePhoto = '[Organizer] Set Profile Photo',
  SetProfilePhotoSuccess = '[Organizer] Set Profile Photo Success',
  SetProfilePhotoFailure = '[Organizer] Set Profile Photo Failure',

  // Delete
  RemoveProfile = '[Organizer] Remove Profile',

  // Get Organizers Events
  GetOrganizersEvents = '[Organizer] Get Organizers Events',
  GetOrganizersEventsSuccess = '[Organizer] Get Organizers Events Success',
  GetOrganizersEventsFailure = '[Organizer] Get Organizers Events Failure',

  // Get Organizers Past Events
  GetOrganizersPastEvents = '[Organizer] Get Organizers Past Events',
  GetOrganizersPastEventsSuccess = '[Organizer] Get Organizers Past Events Success',
  GetOrganizersPastEventsFailure = '[Organizer] Get Organizers Past Events Failure',

  // Get Organizer Events
  GetOrganizerUpcomingEvents = '[Organizer] Get Organizer Upcoming Events',
  GetOrganizerUpcomingEventsSuccess = '[Organizer] Get Organizer Events Upcoming Success',
  GetOrganizerUpcomingEventsFailure = '[Organizer] Get Organizer Events Upcoming Failure',

  // Get Organizer Events Past
  GetOrganizerEventsPast = '[Organizer] Get Organizer Events Past',
  GetOrganizerEventsPastSuccess = '[Organizer] Get Organizer Events Past Success',
  GetOrganizerEventsPastFailure = '[Organizer] Get Organizer Events Past Failure',

  // Get Organizer Event By ID
  GetOrganizerEventByID = '[Organizer] Get Organizer Event By ID',
  GetOrganizerEventByIDSuccess = '[Organizer] Get Organizer Event By ID Success',
  GetOrganizerEventByIDFailure = '[Organizer] Get Organizer Event By ID Failure',

  // Is Created Organizer Event
  IsCreatedOrganizerEvent = '[Organizer] Is Created Organizer Event',
  IsCreatedOrganizerEventSuccess = '[Organizer] Is Created Organizer Event Success',
  IsCreatedOrganizerEventFailure = '[Organizer] Is Created Organizer Event Failure',

  GetFollowersCount = '[Organizer] Get Followers Count',
  GetFollowersCountSuccess = '[Organizer] Get Followers Count Success',
  GetFollowersCountFailure = '[Organizer] Get Followers Count Failure',

  GetFollowingCount = '[Organizer] Get Following Count',
  GetFollowingCountSuccess = '[Organizer] Get Following Count Success',
  GetFollowingCountFailure = '[Organizer] Get Following Count Failure',

  /***** Messages *****/

  GetProfileMessages = '[Organizer] Get Profile Messages',
  GetProfileMessagesSuccess = '[Organizer] Get Profile Messages Success',
  GetProfileMessagesFailure = '[Organizer] Get Profile Messages Failure',

  /***** Followers&Following *****/

  FollowAccount = '[Organizer] Follow Account',
  FollowAccountSuccess = '[Organizer] Follow Account Success',
  FollowAccountFailure = '[Organizer] Follow Account Failure',

  UnfollowAccount = '[Organizer] Unfollow Account',
  UnfollowAccountSuccess = '[Organizer] Unfollow Account Success',
  UnfollowAccountFailure = '[Organizer] Unfollow Account Failure',

  RemoveMediaFile = '[Organizer] Remove Media',
  RemoveVideoSuccess = '[Organizer] Remove Video Success',
  RemovePhotoSuccess = '[Organizer] Remove Photo Success',
  RemoveMediaFileFailure = '[Organizer] Remove Media File Failure',

  SetUpdatedState = '[Organizer] Set Updated State',

  RemoveArtistFromEvent = '[Organizer] Remove Artist From Event State',

  SetPhotoToMedia = '[Organizer] Set Organizer Event',

  UploadVideo = '[Organizer] Upload Vide',
  UploadVideoSuccess = '[Organizer] Upload Vide Success',
  UploadVideoFailure = '[Organizer] Upload Vide Failure',
}

export class SetPhotoToMedia implements Action {
  public readonly type = ActionTypes.SetPhotoToMedia;

  constructor(
    public payload: {
      url: string;
      title: string;
    },
  ) {}
}

export class SetUpdatedState implements Action {
  public readonly type = ActionTypes.SetUpdatedState;

  constructor(public payload: boolean) {}
}

export class CleanEventById implements Action {
  public readonly type = ActionTypes.CleanEventById;
}

export class CleanProfile implements Action {
  public readonly type = ActionTypes.CleanProfile;
}

export class SetIsUpdate implements Action {
  public readonly type = ActionTypes.SetIsUpdate;

  constructor(public payload: boolean) {}
}

// Create

export class CreateProfile implements Action {
  public readonly type = ActionTypes.CreateProfile;

  constructor(public payload: { model: any }) {}
}

export class CleanEvents implements Action {
  public readonly type = ActionTypes.CleanEvents;
}

export class CreateProfileSuccess implements Action {
  public readonly type = ActionTypes.CreateProfileSuccess;
}

export class CreateProfileFailure implements Action {
  public readonly type = ActionTypes.CreateProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Remove media
export class RemoveMediaFile implements Action {
  public readonly type = ActionTypes.RemoveMediaFile;

  constructor(public payload: { eventID: string; url: string; type: string }) {}
}

export class RemovePhotoSuccess implements Action {
  public readonly type = ActionTypes.RemovePhotoSuccess;

  constructor(public payload: { photos: any[] }) {}
}

export class RemoveVideoSuccess implements Action {
  public readonly type = ActionTypes.RemoveVideoSuccess;

  constructor(public payload: { videos: any[] }) {}
}

export class RemoveMediaFileFailure implements Action {
  public readonly type = ActionTypes.RemoveMediaFileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Upload video
export class UploadVideo implements Action {
  public readonly type = ActionTypes.UploadVideo;

  constructor(public payload: { video: any }) {}
}

export class UploadVideoSuccess implements Action {
  public readonly type = ActionTypes.UploadVideoSuccess;

  constructor(public payload: { videos: any[] }) {}
}

export class UploadVideoFailure implements Action {
  public readonly type = ActionTypes.UploadVideoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Set Profile
export class SetProfile implements Action {
  public readonly type = ActionTypes.SetProfile;

  constructor(public payload: { profile: Profile }) {}
}

// Get Organizer permission

export class GetOrganizerPermission implements Action {
  public readonly type = ActionTypes.GetOrganizerPermission;

  constructor(public payload: { id: string }) {}
}

export class GetOrganizerPermissionSuccess implements Action {
  public readonly type = ActionTypes.GetOrganizerPermissionSuccess;

  constructor(public payload: { response: GetOrganizerPermissionResponse }) {}
}

export class GetOrganizerPermissionFailure implements Action {
  public readonly type = ActionTypes.GetOrganizerPermissionFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Profile By ID

export class GetProfileByID implements Action {
  public readonly type = ActionTypes.GetProfileByID;

  constructor(public payload: { id: string }) {}
}

export class GetProfileByIDSuccess implements Action {
  public readonly type = ActionTypes.GetProfileByIDSuccess;

  constructor(public payload: { profile: OrganizerProfile }) {}
}

export class GetProfileByIDFailure implements Action {
  public readonly type = ActionTypes.GetProfileByIDFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Update

export class UpdateProfile implements Action {
  public readonly type = ActionTypes.UpdateProfile;

  constructor(
    public payload: { model: CreateOrUpdateOrganizerAccountRequest },
  ) {}
}

export class UpdateProfileSuccess implements Action {
  public readonly type = ActionTypes.UpdateProfileSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateProfileFailure implements Action {
  public readonly type = ActionTypes.UpdateProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Set Profile Photo

export class SetProfilePhoto implements Action {
  public readonly type = ActionTypes.SetProfilePhoto;

  constructor(public payload: { photo: FormData }) {}
}

export class SetProfilePhotoSuccess implements Action {
  public readonly type = ActionTypes.SetProfilePhotoSuccess;

  constructor(public payload: { response: Response }) {}
}

export class SetProfilePhotoFailure implements Action {
  public readonly type = ActionTypes.SetProfilePhotoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Set Profile Photo

export class SendOnReview implements Action {
  public readonly type = ActionTypes.SendOnReview;
}

export class SendOnReviewSuccess implements Action {
  public readonly type = ActionTypes.SendOnReviewSuccess;

  constructor(public payload: { response: Response }) {}
}

export class SendOnReviewFailure implements Action {
  public readonly type = ActionTypes.SendOnReviewFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Delete

export class RemoveProfile implements Action {
  public readonly type = ActionTypes.RemoveProfile;
}

// Get Organizers Events

export class GetOrganizersEvents implements Action {
  public readonly type = ActionTypes.GetOrganizersEvents;

  constructor(
    public payload: {
      limit: number;
      page: number;
      dateFrom: string;
      dateTo: string;
    },
  ) {}
}

export class GetOrganizersEventsSuccess implements Action {
  public readonly type = ActionTypes.GetOrganizersEventsSuccess;

  constructor(public payload: { response: GetOrganizerEventsResponse }) {}
}

export class GetOrganizersEventsFailure implements Action {
  public readonly type = ActionTypes.GetOrganizersEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Get Organizers Events Past

export class GetOrganizersPastEvents implements Action {
  public readonly type = ActionTypes.GetOrganizersPastEvents;

  constructor(
    public payload: {
      limit: string;
      page: string;
    },
  ) {}
}

export class GetOrganizersPastEventsSuccess implements Action {
  public readonly type = ActionTypes.GetOrganizersPastEventsSuccess;

  constructor(public payload: { response: GetGoingFanEventResponseSuccess }) {}
}

export class GetOrganizersPastEventsFailure implements Action {
  public readonly type = ActionTypes.GetOrganizersPastEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Get Upcoming Organizer Events

export class GetOrganizerUpcomingEvents implements Action {
  public readonly type = ActionTypes.GetOrganizerUpcomingEvents;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
    },
  ) {}
}

export class GetOrganizerUpcomingEventsSuccess implements Action {
  public readonly type = ActionTypes.GetOrganizerUpcomingEventsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class GetOrganizerUpcomingEventsFailure implements Action {
  public readonly type = ActionTypes.GetOrganizerUpcomingEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Get Organizer Events Past

export class GetOrganizerEventsPast implements Action {
  public readonly type = ActionTypes.GetOrganizerEventsPast;

  constructor(
    public payload?: {
      query: string;
      limit: string;
      page: string;
      filters: any;
    },
  ) {}
}

export class GetOrganizerEventsPastSuccess implements Action {
  public readonly type = ActionTypes.GetOrganizerEventsPastSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class GetOrganizerEventsPastFailure implements Action {
  public readonly type = ActionTypes.GetOrganizerEventsPastFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Get Organizer Event By ID

export class GetOrganizerEventByID implements Action {
  public readonly type = ActionTypes.GetOrganizerEventByID;

  constructor(public payload: { id: string }) {}
}

export class GetOrganizerEventByIDSuccess implements Action {
  public readonly type = ActionTypes.GetOrganizerEventByIDSuccess;

  constructor(public payload: { response: EventResponse }) {}
}

export class GetOrganizerEventByIDFailure implements Action {
  public readonly type = ActionTypes.GetOrganizerEventByIDFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Is Created Organizer Event

export class IsCreatedOrganizerEvent implements Action {
  public readonly type = ActionTypes.IsCreatedOrganizerEvent;

  constructor(public payload: { id: string }) {}
}

export class IsCreatedOrganizerEventSuccess implements Action {
  public readonly type = ActionTypes.IsCreatedOrganizerEventSuccess;

  constructor(public payload: { response: EventResponse }) {}
}

export class IsCreatedOrganizerEventFailure implements Action {
  public readonly type = ActionTypes.IsCreatedOrganizerEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

/***** Follows *****/

export class GetFollowersCount implements Action {
  public readonly type = ActionTypes.GetFollowersCount;

  constructor(public payload: { id: string }) {}
}

export class GetFollowersCountSuccess implements Action {
  public readonly type = ActionTypes.GetFollowersCountSuccess;

  constructor(public payload: { response: FollowersCount }) {}
}

export class GetFollowersCountFailure implements Action {
  public readonly type = ActionTypes.GetFollowersCountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class GetFollowingCount implements Action {
  public readonly type = ActionTypes.GetFollowingCount;

  constructor(public payload: { id: string }) {}
}

export class GetFollowingCountSuccess implements Action {
  public readonly type = ActionTypes.GetFollowingCountSuccess;

  constructor(public payload: { response: FollowersCount }) {}
}

export class GetFollowingCountFailure implements Action {
  public readonly type = ActionTypes.GetFollowingCountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

/***** Messages *****/

export class GetProfileMessages implements Action {
  public readonly type = ActionTypes.GetProfileMessages;
}

export class GetProfileMessagesSuccess implements Action {
  public readonly type = ActionTypes.GetProfileMessagesSuccess;

  constructor(public payload: { response: MessagesOrganizerResponseSuccess }) {}
}

export class GetProfileMessagesFailure implements Action {
  public readonly type = ActionTypes.GetProfileMessagesFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class FollowAccount implements Action {
  public readonly type = ActionTypes.FollowAccount;

  constructor(
    public payload: {
      id: string;
      accountType: AccountType;
    },
  ) {}
}

export class FollowAccountSuccess implements Action {
  public readonly type = ActionTypes.FollowAccountSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class FollowAccountFailure implements Action {
  public readonly type = ActionTypes.FollowAccountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class UnfollowAccount implements Action {
  public readonly type = ActionTypes.UnfollowAccount;

  constructor(
    public payload: {
      id: string;
      accountType: AccountType;
    },
  ) {}
}

export class UnfollowAccountSuccess implements Action {
  public readonly type = ActionTypes.UnfollowAccountSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UnfollowAccountFailure implements Action {
  public readonly type = ActionTypes.UnfollowAccountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class RemoveArtistFromEvent implements Action {
  public readonly type = ActionTypes.RemoveArtistFromEvent;

  constructor(public payload: any) {}
}

export type Actions =
  | CreateProfile
  | CreateProfileSuccess
  | CreateProfileFailure
  | SetIsUpdate
  | CleanEvents
  | CleanEventById
  | SetUpdatedState
  | RemoveArtistFromEvent
  | SetProfile
  | GetOrganizerPermission
  | GetOrganizerPermissionSuccess
  | GetOrganizerPermissionFailure
  | GetProfileByID
  | GetProfileByIDSuccess
  | GetProfileByIDFailure
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFailure
  | SetProfilePhoto
  | SetProfilePhotoSuccess
  | SetProfilePhotoFailure
  | RemoveProfile
  | GetOrganizersPastEvents
  | GetOrganizersPastEventsSuccess
  | GetOrganizersPastEventsFailure
  | SendOnReview
  | SendOnReviewSuccess
  | SendOnReviewFailure
  | GetOrganizerEventsPast
  | GetOrganizerEventsPastSuccess
  | GetOrganizerEventsPastFailure
  | GetOrganizerUpcomingEvents
  | GetOrganizerUpcomingEventsSuccess
  | GetOrganizerUpcomingEventsFailure
  | GetOrganizersEvents
  | GetOrganizersEventsSuccess
  | GetOrganizersEventsFailure
  | GetOrganizerEventByID
  | GetOrganizerEventByIDSuccess
  | GetOrganizerEventByIDFailure
  | IsCreatedOrganizerEvent
  | IsCreatedOrganizerEventSuccess
  | IsCreatedOrganizerEventFailure
  | GetFollowersCount
  | GetFollowersCountSuccess
  | GetFollowersCountFailure
  | GetFollowingCount
  | GetFollowingCountSuccess
  | GetFollowingCountFailure
  | GetProfileMessages
  | GetProfileMessagesSuccess
  | GetProfileMessagesFailure
  | FollowAccount
  | FollowAccountSuccess
  | FollowAccountFailure
  | UnfollowAccount
  | UnfollowAccountSuccess
  | UnfollowAccountFailure
  | RemoveMediaFile
  | RemovePhotoSuccess
  | RemoveVideoSuccess
  | RemoveMediaFileFailure
  | UploadVideo
  | UploadVideoSuccess
  | UploadVideoFailure
  | CleanProfile
  | SetPhotoToMedia;
