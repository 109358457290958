import { createSelector } from '@ngrx/store';

import { RootStoreState } from '@app/store/root';
import { State } from '@store/root/client/media/state';

export const getMediaState = (state: RootStoreState.State) =>
  state.client.media;

export const getState = createSelector(getMediaState, (state: State) => state);

export const getUploadState = createSelector(
  getMediaState,
  (state: State) => state.upload,
);

export const getResponseState = createSelector(
  getMediaState,
  (state: State) => state.response,
);

export const getPercentageState = createSelector(
  getMediaState,
  (state: State) => state.percentage,
);

export const getUrlState = createSelector(
  getMediaState,
  (state: State) => state.url,
);

export const getFanAvatarState = createSelector(
  getMediaState,
  (state: State) => state.fanAvatar,
);

export const getArtistAvatarState = createSelector(
  getMediaState,
  (state: State) => state.artistAvatar,
);

export const getOrganizerAvatarState = createSelector(
  getMediaState,
  (state: State) => state.organizerAvatar,
);

export const getArtistMediaImageState = createSelector(
  getMediaState,
  (state: State) => state.artistMediaImage,
);

export const getEventMediaImageState = createSelector(
  getMediaState,
  (state: State) => state.eventMediaImage,
);

export const getEventVerticalImageState = createSelector(
  getMediaState,
  (state: State) => state.eventVerticalImage,
);

export const getEventHorizontalImageState = createSelector(
  getMediaState,
  (state: State) => state.eventHorizontalImage,
);

export const getErrorState = createSelector(
  getMediaState,
  (state: State) => state.error,
);
