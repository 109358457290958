import { Action } from '@ngrx/store';

import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { Response } from '@app/interfaces/response.interface';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { GetFanTicketsResponseSuccess } from '@app/models/client/tickets/get-fan-tickets-response-success.model';
import { GiveTicketRequest } from '@app/models/client/tickets/give-ticket-request.model';
import { CreateTicketRequest } from '@app/models/client/tickets/create-ticket-request.model';
import { EventTicketsResponse } from '@app/models/client/tickets/event-tickets-response.model';

export enum ActionTypes {
  AddTickets = '[Tickets] Add Ticket',
  AddTicketCount = '[Tickets] Add Ticket Count',

  RemoveForUpdateTicket = '[Tickets] Remove For Update Ticket',

  RemoveTicketCount = '[Tickets] Remove Ticket Count',

  RemoveTickets = '[Tickets] Remove Tickets',

  // Get Organizer Event Tickets
  GetOrganizerEventTickets = '[Tickets] Get Organizer Event Tickets',
  GetOrganizerEventTicketsSuccess = '[Tickets] Get Organizer Event Tickets Success',
  GetOrganizerEventTicketsFailure = '[Tickets] Get Organizer Event Tickets Failure',

  // Create Ticket
  UpdateTicket = '[Tickets] Update Ticket',
  SetForUpdateTicket = '[Tickets] Set For Update Ticket',
  UpdateTicketSuccess = '[Tickets] Update Ticket Success',
  UpdateTicketFailure = '[Tickets] Update Ticket Failure',

  // Create Ticket
  CreateTicket = '[Tickets] Create Ticket',
  CreateTicketSuccess = '[Tickets] Create Ticket Success',
  CreateTicketFailure = '[Tickets] Create Ticket Failure',

  // Delete Ticket
  DeleteTicket = '[Tickets] Delete Ticket',
  DeleteTicketSuccess = '[Tickets] Delete Ticket Success',
  DeleteTicketFailure = '[Tickets] Delete Ticket Failure',

  // Print Ticket As PDF
  PrintTicketAsPDF = '[Tickets] Print Ticket As PDF',
  PrintTicketAsPDFSuccess = '[Tickets] Print Ticket As PDF Success',
  PrintTicketAsPDFFailure = '[Tickets] Print Ticket As PDF Failure',

  // Get Fan Bought Tickets
  GetFanBoughtTickets = '[Tickets] Get Fan Bought Tickets',
  GetFanBoughtTicketsSuccess = '[Tickets] Get Fan Bought Tickets Success',
  GetFanBoughtTicketsFailure = '[Tickets] Get Fan Bought Tickets Failure',

  // Remove Fan Bought Tickets
  RemoveFanBoughtTickets = '[Tickets] Remove Fan Bought Tickets',

  // Give Ticket To Guest
  GiveTicketToGuest = '[Tickets] Give Ticket To Guest',
  GiveTicketToGuestSuccess = '[Tickets] Give Ticket To Guest Success',
  GiveTicketToGuestFailure = '[Tickets] Give Ticket To Guest Failure',

  // Accept Ticket
  AcceptTicket = '[Tickets] Accept Ticket',
  AcceptTicketSuccess = '[Tickets] Accept Ticket Success',
  AcceptTicketFailure = '[Tickets] Accept Ticket Failure',

  // Refuse Ticket
  RefuseTicket = '[Tickets] Refuse Ticket',
  RefuseTicketSuccess = '[Tickets] Refuse Ticket Success',
  RefuseTicketFailure = '[Tickets] Refuse Ticket Failure',

  SetOrganizerEventTickets = '[Tickets] Set Organizer Event Tickets',
}

export class SetOrganizerEventTickets implements Action {
  public readonly type = ActionTypes.SetOrganizerEventTickets;

  constructor(public payload: any) {}
}

export class RemoveTickets implements Action {
  public readonly type = ActionTypes.RemoveTickets;
}

export class DeleteTicket implements Action {
  public readonly type = ActionTypes.DeleteTicket;

  constructor(public payload: { ticketGUID: string }) {}
}

export class DeleteTicketSuccess implements Action {
  public readonly type = ActionTypes.DeleteTicketSuccess;

  constructor(public payload: { ticketGUID: string }) {}
}

export class DeleteTicketFailure implements Action {
  public readonly type = ActionTypes.DeleteTicketFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class RemoveForUpdateTicket implements Action {
  public readonly type = ActionTypes.RemoveForUpdateTicket;
}

export class AddTickets implements Action {
  public readonly type = ActionTypes.AddTickets;

  constructor(public payload: any[]) {}
}

export class AddTicketCount implements Action {
  public readonly type = ActionTypes.AddTicketCount;

  constructor(public payload: number) {}
}

export class RemoveTicketCount implements Action {
  public readonly type = ActionTypes.RemoveTicketCount;

  constructor(public payload: number) {}
}

// Get Organizer Event Tickets

export class GetOrganizerEventTickets implements Action {
  public readonly type = ActionTypes.GetOrganizerEventTickets;

  constructor(
    public payload: {
      eventGUID: string;
      limit: string;
      page: string;
    },
  ) {}
}

export class GetOrganizerEventTicketsSuccess implements Action {
  public readonly type = ActionTypes.GetOrganizerEventTicketsSuccess;

  constructor(public payload: { response: EventTicketsResponse }) {}
}

export class GetOrganizerEventTicketsFailure implements Action {
  public readonly type = ActionTypes.GetOrganizerEventTicketsFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Update Ticket
export class UpdateTicket implements Action {
  public readonly type = ActionTypes.UpdateTicket;

  constructor(
    public payload: { query: CreateTicketRequest; ticketGUID: string },
  ) {}
}

export class SetForUpdateTicket implements Action {
  public readonly type = ActionTypes.SetForUpdateTicket;

  constructor(public payload: CreateTicketRequest) {}
}

export class UpdateTicketSuccess implements Action {
  public readonly type = ActionTypes.UpdateTicketSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateTicketFailure implements Action {
  public readonly type = ActionTypes.UpdateTicketFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Create Ticket

export class CreateTicket implements Action {
  public readonly type = ActionTypes.CreateTicket;

  constructor(public payload: { query: CreateTicketRequest }) {}
}

export class CreateTicketSuccess implements Action {
  public readonly type = ActionTypes.CreateTicketSuccess;

  constructor(public payload: { response: Response }) {}
}

export class CreateTicketFailure implements Action {
  public readonly type = ActionTypes.CreateTicketFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Print Ticket As PDF

export class PrintTicketAsPDF implements Action {
  public readonly type = ActionTypes.PrintTicketAsPDF;

  constructor(public payload: { ticketGUID: string }) {}
}

export class PrintTicketAsPDFSuccess implements Action {
  public readonly type = ActionTypes.PrintTicketAsPDFSuccess;

  constructor(public payload: { response: any }) {}
}

export class PrintTicketAsPDFFailure implements Action {
  public readonly type = ActionTypes.PrintTicketAsPDFFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Remove Fan Bought Tickets

export class RemoveFanBoughtTickets implements Action {
  public readonly type = ActionTypes.RemoveFanBoughtTickets;
}

// Get Fan Bought Tickets

export class GetFanBoughtTickets implements Action {
  public readonly type = ActionTypes.GetFanBoughtTickets;

  constructor(
    public payload: {
      eventId: string;
      limit: string;
      page: string;
    },
  ) {}
}

export class GetFanBoughtTicketsSuccess implements Action {
  public readonly type = ActionTypes.GetFanBoughtTicketsSuccess;

  constructor(public payload: { response: GetFanTicketsResponseSuccess }) {}
}

export class GetFanBoughtTicketsFailure implements Action {
  public readonly type = ActionTypes.GetFanBoughtTicketsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Give Ticket To Guest

export class GiveTicketToGuest implements Action {
  public readonly type = ActionTypes.GiveTicketToGuest;

  constructor(
    public payload: {
      ticketId: string;
      request: GiveTicketRequest;
    },
  ) {}
}

export class GiveTicketToGuestSuccess implements Action {
  public readonly type = ActionTypes.GiveTicketToGuestSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class GiveTicketToGuestFailure implements Action {
  public readonly type = ActionTypes.GiveTicketToGuestFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// AcceptTicket

export class AcceptTicket implements Action {
  public readonly type = ActionTypes.AcceptTicket;

  constructor(
    public payload: {
      ticketId: string;
    },
  ) {}
}

export class AcceptTicketSuccess implements Action {
  public readonly type = ActionTypes.AcceptTicketSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class AcceptTicketFailure implements Action {
  public readonly type = ActionTypes.AcceptTicketFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// RefuseTicket

export class RefuseTicket implements Action {
  public readonly type = ActionTypes.RefuseTicket;

  constructor(
    public payload: {
      ticketId: string;
    },
  ) {}
}

export class RefuseTicketSuccess implements Action {
  public readonly type = ActionTypes.RefuseTicketSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class RefuseTicketFailure implements Action {
  public readonly type = ActionTypes.RefuseTicketFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | AddTickets
  | AddTicketCount
  | RemoveTicketCount
  | GetOrganizerEventTickets
  | GetOrganizerEventTicketsSuccess
  | GetOrganizerEventTicketsFailure
  | CreateTicket
  | CreateTicketSuccess
  | CreateTicketFailure
  | DeleteTicket
  | DeleteTicketSuccess
  | DeleteTicketFailure
  | PrintTicketAsPDF
  | PrintTicketAsPDFSuccess
  | PrintTicketAsPDFFailure
  | GetFanBoughtTickets
  | GetFanBoughtTicketsSuccess
  | GetFanBoughtTicketsFailure
  | RemoveFanBoughtTickets
  | GiveTicketToGuest
  | GiveTicketToGuestSuccess
  | GiveTicketToGuestFailure
  | AcceptTicket
  | AcceptTicketSuccess
  | AcceptTicketFailure
  | UpdateTicket
  | UpdateTicketSuccess
  | UpdateTicketFailure
  | SetForUpdateTicket
  | RefuseTicket
  | RefuseTicketSuccess
  | RefuseTicketFailure
  | RemoveForUpdateTicket
  | RemoveTickets
  | SetOrganizerEventTickets;
