import { Action, createReducer, on } from '@ngrx/store';

import * as UserAction from './actions';
import { initialState, State } from './state';

const r = createReducer(
  initialState,

  /* Create User */
  on(UserAction.SetActiveProfile, (state, { profile }) => ({
    ...state,
    activeAccount: profile,
  })),
  on(UserAction.CreateUser, (state) => ({
    ...state,
    loading: true,
    created: false,
  })),
  on(UserAction.CreateUserSuccess, (state) => ({
    ...state,
    created: true,
    logined: false,
    loading: false,
  })),
  on(UserAction.CreateUserFailure, (state) => ({
    ...state,
    created: false,
    logined: false,
    loading: false,
  })),
  on(UserAction.UpdateUser, (state) => ({ ...state })),
  on(UserAction.UpdateUserSuccess, (state) => ({
    ...state,
    userUpdated: true,
  })),
  on(UserAction.UpdateUserStatus, (state) => ({
    ...state,
    userUpdated: false,
  })),

  /* Set User */
  on(UserAction.SetSocialUser, (state, { user }) => ({
    ...state,
    socialUser: user,
  })),
  on(UserAction.SetUser, (state, { userLogin }) => ({
    ...state,
    userForm: userLogin,
  })),

  /* Get User */
  on(UserAction.GetUser, (state) => ({ ...state, loading: true })),
  on(UserAction.GetUserSuccess, (state, { response }) => ({
    ...state,
    profile: response,
    loading: false,
    logined: true,
  })),
  on(UserAction.GetUserFailure, (state) => ({
    ...state,
    loading: false,
    logined: false,
  })),
  // create Guest Account
  on(UserAction.CreateGuestAccount, (state: State) => ({ ...state })),
  on(UserAction.CreateGuestAccountSuccess, (state: State, { response }) => ({
    ...state,
    guestTokenResponse: response.data,
  })),
  on(UserAction.CreateGuestAccountFailure, (state: State, payload) => ({
    ...state,
    paymentError: payload.response,
  })),

  /* Token */
  on(UserAction.SetToken, (state, { userToken }) => ({
    ...state,
    token: userToken.token,
    expire: userToken.expire,
    tokenResponse: userToken,
  })),
  on(UserAction.RemoveToken, (state) => ({
    ...state,
    accountType: null,
    token: null,
    expire: null,
    guestTokenResponse: null,
    logined: false,
    loading: false,
    loaded: false,
  })),

  /* Token for password update */
  on(UserAction.SetTokenForPasswordUpdate, (state, { userToken }) => ({
    ...state,
    tokenForPasswordUpdate: userToken.token,
    // expire: action.payload.userToken.expire,
    // tokenResponse: action.payload.userToken,
    // logined: true
  })),

  /* Refresh Token */
  on(UserAction.RefreshToken, (state) => ({
    ...state,
  })),
  on(UserAction.RefreshTokenSuccess, (state, { response }) => ({
    ...state,
    tokenResponse: response.data,
    token: response.data.token,
    expire: response.data.expire,
    logined: true,
  })),
  on(UserAction.RefreshTokenFailure, (state) => ({
    ...state,
    logined: false,
  })),

  /* Load User */
  on(UserAction.LoadUser, (state) => ({
    ...state,
    logined: false,
    loaded: false,
    loading: true,
  })),
  on(UserAction.LoadUserSuccess, (state, { userData }) => ({
    ...state,
    logined: true,
    loaded: true,
    loading: false,
    profile: userData,
  })),
  on(UserAction.LoadUserFailure, (state) => ({
    ...state,
    loaded: false,
    loading: false,
  })),
  on(UserAction.LoadUserSuccess, (state, { userData }) => ({
    ...state,
    logined: true,
    profile: userData,
  })),
  on(UserAction.ConfirmEmail, (state) => ({
    ...state,
    confirmEmail: null,
  })),
  on(UserAction.ConfirmEmailByToken, (state) => ({
    ...state,
    confirmEmail: null,
  })),
  on(UserAction.ConfirmEmailByCode, (state) => ({
    ...state,
    confirmEmail: null,
  })),
  on(UserAction.ConfirmFailure, (state, { response }) => ({
    ...state,
    confirmEmail: response,
  })),
  on(UserAction.ConfirmSuccess, (state, { response }) => ({
    ...state,
    confirmEmail: response,
  })),
  on(UserAction.ConfirmEmailSuccess, (state, { response }) => ({
    ...state,
    confirmEmail: response,
  })),
  on(UserAction.ConfirmEmailFailure, (state, { response }) => ({
    ...state,
    confirmEmail: response,
  })),
  on(UserAction.SendMagicLink, (state) => ({
    ...state,
    confirmMagicLink: null,
  })),
  on(UserAction.AuthenticateWithMagicLinkByToken, (state) => ({
    ...state,
    confirmMagicLink: null,
  })),
  on(UserAction.AuthenticateWithMagicLinkByCode, (state) => ({
    ...state,
    confirmMagicLink: null,
  })),
  on(UserAction.SendMagicLinkSuccess, (state, { response }) => ({
    ...state,
    confirmMagicLink: response,
  })),
  on(UserAction.SendMagicLinkFailure, (state, { response }) => ({
    ...state,
    confirmMagicLink: response,
  })),
  on(UserAction.AuthenticateWithMagicLinkSuccess, (state, { response }) => ({
    ...state,
    confirmMagicLink: response,
  })),
  on(UserAction.AuthenticateWithMagicLinkFailure, (state, { response }) => ({
    ...state,
    confirmMagicLink: response,
  })),
  on(UserAction.RemoveUser, (state) => {
    return {
      ...state,
      isRemoveUser: false,
      removeUserError: null,
    };
  }),
  on(UserAction.RemoveUserSuccess, (state) => {
    return {
      ...state,
      profile: null,
      logined: false,
      social: false,
      isRemoveUser: true,
      removeUserError: null,
    };
  }),
  on(UserAction.RemoveUserFailure, (state, { response }) => {
    return {
      ...state,
      isRemoveUser: false,
      removeUserError: response.error,
    };
  }),
  /* Load Profiles */
  on(UserAction.LoadProfiles, (state) => ({
    ...state,
  })),
  on(UserAction.LoadProfilesSuccess, (state) => ({
    ...state,
    haveProfiles: true,
  })),
  on(UserAction.LoadProfilesFailure, (state) => ({
    ...state,
    haveProfiles: false,
  })),

  /* Login */
  on(UserAction.LoginUser, (state) => ({
    ...state,
  })),
  on(UserAction.LoginUserBySocialGoogle, (state) => ({
    ...state,
  })),
  on(UserAction.LoginUserBySocialFacebook, (state) => ({
    ...state,
  })),
  on(UserAction.LoginUserSuccess, (state) => ({
    ...state,
    logined: true,
  })),
  on(UserAction.LoginUserFailure, (state) => ({
    ...state,
    logined: false,
  })),
  on(UserAction.SetSocialStatusUser, (state) => ({
    ...state,
    social: true,
  })),

  /* Switch account */
  on(UserAction.SwitchAccount, (state, { accountType }) => ({
    ...state,
    accountType,
  })),

  /* Application settings */
  on(UserAction.GetApplicationSettings, (state) => ({
    ...state,
  })),
  on(UserAction.GetApplicationSettingsSuccess, (state, { response }) => ({
    ...state,
    appUserSettings: response.data,
  })),
  on(UserAction.GetApplicationSettingsFailure, (state) => ({
    ...state,
  })),
  on(UserAction.SetApplicationSettings, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(UserAction.SetApplicationSettingsSuccess, (state) => ({
    ...state,
    loading: false,
    loaded: true,
  })),
  on(UserAction.SetApplicationSettingsFailure, (state) => ({
    ...state,
    loading: false,
    loaded: true,
  })),
  on(UserAction.SetupIntent, (state) => ({
    ...state,
    clientSecret: null,
    paymentMethodID: null,
    paymentError: null,
    paymentInProgress: true,
    paymentSuccess: null,
  })),
  on(UserAction.SetupIntentSuccess, (state, { response }) => ({
    ...state,
    clientSecret: response.data.clientSecret,
    paymentInProgress: true,
  })),
  on(UserAction.SetupIntentFailure, (state, { response }) => ({
    ...state,
    clientSecret: null,
    paymentError: response,
    paymentInProgress: false,
  })),
  on(UserAction.ConfirmSetupIntent, (state) => ({
    ...state,
    paymentError: null,
    paymentInProgress: true,
  })),
  on(UserAction.ConfirmSetupIntentSuccess, (state, { response }) => ({
    ...state,
    paymentMethodID: response.data.paymentMethodId,
    paymentError: null,
    paymentInProgress: true,
  })),
  on(UserAction.ConfirmSetupIntentFailure, (state, { response }) => ({
    ...state,
    paymentMethodID: null,
    paymentError: response,
    paymentInProgress: false,
  })),
  on(UserAction.GetProfileFollowers, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(UserAction.GetProfileFollowersSuccess, (state, { response }) => ({
    ...state,
    profileFollowers: response.data,
  })),
  on(UserAction.CreatePaymentFailure, (state, { response }) => ({
    ...state,
    paymentError: response,
    paymentInProgress: false,
  })),
  on(UserAction.CreatePaymentSuccess, (state, { response }) => ({
    ...state,
    paymentSuccess: response,
    paymentInProgress: !response?.success,
  })),
  on(UserAction.SetPaymentError, (state, { response }) => ({
    ...state,
    clientSecret: null,
    paymentMethodID: null,
    paymentSuccess: null,
    paymentInProgress: false,
    paymentError: response,
  })),

  on(UserAction.CleanPayment, (state) => ({
    ...state,
    clientSecret: null,
    paymentMethodID: null,
    paymentSuccess: null,
    paymentInProgress: false,
    paymentError: null,
  })),
  on(UserAction.CreatePayment, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    paymentSuccess: null,
    paymentInProgress: true,
  })),
  on(UserAction.CleanPaymentMethodsState, (state) => ({
    ...state,
    paymentMethods: null,
  })),
  on(UserAction.CleanPaymentMethodState, (state, { id }) => ({
    ...state,
    paymentMethods: {
      ...state.paymentMethods,
      card:
        state.paymentMethods.card.length === 1 &&
        state.paymentMethods.card[0].id === id
          ? null
          : state.paymentMethods.card.filter((card) => card.id !== id),
    },
  })),
  on(UserAction.GetPayments, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    paymentSuccess: null,
    paymentInProgress: true,
  })),
  on(UserAction.GetPaymentsSuccess, (state, { response }) => ({
    ...state,
    paymentMethods: response.data,
  })),
  on(UserAction.GetPaymentsFailure, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    paymentMethods: null,
  })),
  on(UserAction.GetProfileFollowersFailure, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    profileFollowers: null,
  })),
  on(UserAction.GetProfileFollowing, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(UserAction.GetProfileFollowingSuccess, (state, { response }) => ({
    ...state,
    loading: false,
    loaded: true,
    profileFollowing: response.data,
  })),
  on(UserAction.GetProfileFollowingFailure, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    profileFollowing: null,
  })),
  on(UserAction.CheckIsProfileFollowing, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(UserAction.CheckIsProfileFollowingSuccess, (state, { response }) => ({
    ...state,
    loading: false,
    loaded: true,
    isFollowing: response.data,
  })),
  on(UserAction.CheckIsProfileFollowingFailure, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    isFollowing: null,
  })),
  on(UserAction.SetTicketsBy, (state, { tickets }) => ({
    ...state,
    ticketsBy: tickets,
  })),
  on(UserAction.CheckEmail, (state) => ({
    ...state,
    checkEmail: null,
  })),
  on(UserAction.CheckEmailSuccess, (state, { response }) => ({
    ...state,
    checkEmail: response,
  })),
  on(UserAction.CheckIsProfileFollowingFailure, (state, { response }) => ({
    ...state,
    checkEmail: response,
  })),
);

export function reducer(state: State, action: Action) {
  return r(state, action);
}
