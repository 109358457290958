import { Action } from '@ngrx/store';
import { Response } from '@app/interfaces/response.interface';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';

export enum ActionTypes {
  // Sign out
  SignOut = '[Sign In] Sign Out',

  // Reset password

  ResetPassword = '[Sign In] Reset Password',
  ResetPasswordSuccess = '[Sign In] Reset Password Success',
  ResetPasswordFailure = '[Sign In] Reset Password Failure',

  ConfirmCode = '[Sign In] Confirm Code',
  ConfirmCodeSuccess = '[Sign In] Confirm Code Success',
  ConfirmCodeFailure = '[Sign In] Confirm Code Failure',

  UpdatePassword = '[Sign In] Update Password',
  UpdatePasswordSuccess = '[Sign In] Update Password Success',
  UpdatePasswordFailure = '[Sign In] Update Password Failure',
}

export class SignOut implements Action {
  public readonly type = ActionTypes.SignOut;
}

// Reset password

export class ResetPassword implements Action {
  public readonly type = ActionTypes.ResetPassword;

  constructor(public payload: { email: string }) {}
}

export class ResetPasswordSuccess implements Action {
  public readonly type = ActionTypes.ResetPasswordSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class ResetPasswordFailure implements Action {
  public readonly type = ActionTypes.ResetPasswordFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class ConfirmCode implements Action {
  public readonly type = ActionTypes.ConfirmCode;

  constructor(public payload: { code: number; uuid: string }) {}
}

export class ConfirmCodeSuccess implements Action {
  public readonly type = ActionTypes.ConfirmCodeSuccess;

  constructor(public payload: { response: Response }) {}
}

export class ConfirmCodeFailure implements Action {
  public readonly type = ActionTypes.ConfirmCodeFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class UpdatePassword implements Action {
  public readonly type = ActionTypes.UpdatePassword;

  constructor(public payload: { password: string }) {}
}

export class UpdatePasswordSuccess implements Action {
  public readonly type = ActionTypes.UpdatePasswordSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdatePasswordFailure implements Action {
  public readonly type = ActionTypes.UpdatePasswordFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export type Actions =
  // Sign out

  | SignOut

  // Reset password
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | ConfirmCode
  | ConfirmCodeSuccess
  | ConfirmCodeFailure
  | UpdatePassword
  | UpdatePasswordSuccess
  | UpdatePasswordFailure;
