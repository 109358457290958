/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrganizerAccountRequest } from '../models/create-organizer-account-request';
import { FollowersCount } from '../models/followers-count';
import { FollowingCount } from '../models/following-count';
import { GetEventsResponse } from '../models/get-events-response';
import { GetOrganizerAccountInfoResponseSuccess } from '../models/get-organizer-account-info-response-success';
import { GetOrganizerMediaResponse } from '../models/get-organizer-media-response';
import { JoinToStripeRequest } from '../models/join-to-stripe-request';
import { OrganizerAccountStatusResponse } from '../models/organizer-account-status-response';
import { ResponseSuccess } from '../models/response-success';
import { UpdateOrganizerAccountRequest } from '../models/update-organizer-account-request';

@Injectable({
  providedIn: 'root',
})
export class OrganizerAccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation followOrganizerArtist
   */
  static readonly FollowOrganizerArtistPath =
    '/fan/follower/organizer/artist/{artistAccountGUID}';

  /**
   * Follow up artist account.
   *
   * Follow the artist(if authenticated as an organizer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followOrganizerArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  followOrganizerArtist$Response(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.FollowOrganizerArtistPath,
      'post',
    );
    if (params) {
      rb.path('artistAccountGUID', params.artistAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Follow up artist account.
   *
   * Follow the artist(if authenticated as an organizer)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followOrganizerArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followOrganizerArtist(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followOrganizerArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowOrganizerArtist
   */
  static readonly UnfollowOrganizerArtistPath =
    '/fan/follower/organizer/artist/{artistAccountGUID}';

  /**
   * Unfollow up artist account.
   *
   * Unfollow up artist account(if authenticated as an organizer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowOrganizerArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowOrganizerArtist$Response(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.UnfollowOrganizerArtistPath,
      'delete',
    );
    if (params) {
      rb.path('artistAccountGUID', params.artistAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Unfollow up artist account.
   *
   * Unfollow up artist account(if authenticated as an organizer)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowOrganizerArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowOrganizerArtist(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.unfollowOrganizerArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followOrganizerFan
   */
  static readonly FollowOrganizerFanPath =
    '/fan/follower/organizer/fan/{fanAccountGUID}';

  /**
   * Follow up fan account.
   *
   * Follow the fan account(if authenticated as an organizer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followOrganizerFan()` instead.
   *
   * This method doesn't expect any request body.
   */
  followOrganizerFan$Response(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.FollowOrganizerFanPath,
      'post',
    );
    if (params) {
      rb.path('fanAccountGUID', params.fanAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Follow up fan account.
   *
   * Follow the fan account(if authenticated as an organizer)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followOrganizerFan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followOrganizerFan(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followOrganizerFan$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowOrganizerFan
   */
  static readonly UnfollowOrganizerFanPath =
    '/fan/follower/organizer/fan/{fanAccountGUID}';

  /**
   * Unfollow up fan account.
   *
   * Unfollows the fan account(if authenticated as an organizer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowOrganizerFan()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowOrganizerFan$Response(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.UnfollowOrganizerFanPath,
      'delete',
    );
    if (params) {
      rb.path('fanAccountGUID', params.fanAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Unfollow up fan account.
   *
   * Unfollows the fan account(if authenticated as an organizer)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowOrganizerFan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowOrganizerFan(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.unfollowOrganizerFan$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followOrganizer
   */
  static readonly FollowOrganizerPath =
    '/fan/follower/organizer/organizer/{organizerAccountGUID}';

  /**
   * Follow up Organizer Account.
   *
   * Follow another organizer(if authenticated as organizer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followOrganizer()` instead.
   *
   * This method doesn't expect any request body.
   */
  followOrganizer$Response(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.FollowOrganizerPath,
      'post',
    );
    if (params) {
      rb.path('organizerAccountGUID', params.organizerAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Follow up Organizer Account.
   *
   * Follow another organizer(if authenticated as organizer)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followOrganizer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followOrganizer(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followOrganizer$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowOrganizer
   */
  static readonly UnfollowOrganizerPath =
    '/fan/follower/organizer/organizer/{organizerAccountGUID}';

  /**
   * Unfollow up another Organizer Account.
   *
   * Unfollow up another Organizer Account(if authenticated as an organizer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowOrganizer()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowOrganizer$Response(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.UnfollowOrganizerPath,
      'delete',
    );
    if (params) {
      rb.path('organizerAccountGUID', params.organizerAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Unfollow up another Organizer Account.
   *
   * Unfollow up another Organizer Account(if authenticated as an organizer)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowOrganizer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowOrganizer(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.unfollowOrganizer$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followOrganizerVenue
   */
  static readonly FollowOrganizerVenuePath =
    '/fan/follower/organizer/venue/{venueAccountGUID}';

  /**
   * Follow up venue account.
   *
   * Follow the venue account(if authenticaed as an organizer)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followOrganizerVenue()` instead.
   *
   * This method doesn't expect any request body.
   */
  followOrganizerVenue$Response(params: {
    /**
     * Venue Acount ID
     */
    venueAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.FollowOrganizerVenuePath,
      'post',
    );
    if (params) {
      rb.path('venueAccountGUID', params.venueAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Follow up venue account.
   *
   * Follow the venue account(if authenticaed as an organizer)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followOrganizerVenue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followOrganizerVenue(params: {
    /**
     * Venue Acount ID
     */
    venueAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followOrganizerVenue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowVenueOrganizer
   */
  static readonly UnfollowVenueOrganizerPath =
    '/fan/follower/organizer/venue/{venueAccountGUID}';

  /**
   * Unfollow up venue account(if authenticated as an organizer).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowVenueOrganizer()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowVenueOrganizer$Response(params: {
    /**
     * Venue Account ID
     */
    venueAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.UnfollowVenueOrganizerPath,
      'delete',
    );
    if (params) {
      rb.path('venueAccountGUID', params.venueAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Unfollow up venue account(if authenticated as an organizer).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowVenueOrganizer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowVenueOrganizer(params: {
    /**
     * Venue Account ID
     */
    venueAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.unfollowVenueOrganizer$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getOrganizerFollowersCount
   */
  static readonly GetOrganizerFollowersCountPath =
    '/fans/{userGUID}/organizer/followers_count';

  /**
   * Followers count for current organizer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizerFollowersCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerFollowersCount$Response(params: {
    /**
     * Account ID
     */
    userGUID: string;
  }): Observable<StrictHttpResponse<FollowersCount>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.GetOrganizerFollowersCountPath,
      'get',
    );
    if (params) {
      rb.path('userGUID', params.userGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FollowersCount>;
        }),
      );
  }

  /**
   * Followers count for current organizer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizerFollowersCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerFollowersCount(params: {
    /**
     * Account ID
     */
    userGUID: string;
  }): Observable<FollowersCount> {
    return this.getOrganizerFollowersCount$Response(params).pipe(
      map((r: StrictHttpResponse<FollowersCount>) => r.body as FollowersCount),
    );
  }

  /**
   * Path part for operation getFollowingCount
   */
  static readonly GetFollowingCountPath =
    '/fans/{userGUID}/organizer/following_count';

  /**
   * Following count for current Organizer Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFollowingCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowingCount$Response(params: {
    /**
     * Account ID
     */
    userGUID: string;
  }): Observable<StrictHttpResponse<FollowingCount>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.GetFollowingCountPath,
      'get',
    );
    if (params) {
      rb.path('userGUID', params.userGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FollowingCount>;
        }),
      );
  }

  /**
   * Following count for current Organizer Account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFollowingCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowingCount(params: {
    /**
     * Account ID
     */
    userGUID: string;
  }): Observable<FollowingCount> {
    return this.getFollowingCount$Response(params).pipe(
      map((r: StrictHttpResponse<FollowingCount>) => r.body as FollowingCount),
    );
  }

  /**
   * Path part for operation updateOrganizerAccount
   */
  static readonly UpdateOrganizerAccountPath = '/organizer';

  /**
   * Update Organizer Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrganizerAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrganizerAccount$Response(params: {
    /**
     * Get
     */
    body: UpdateOrganizerAccountRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.UpdateOrganizerAccountPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update Organizer Account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOrganizerAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrganizerAccount(params: {
    /**
     * Get
     */
    body: UpdateOrganizerAccountRequest;
  }): Observable<ResponseSuccess> {
    return this.updateOrganizerAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation createOrganizerAccount
   */
  static readonly CreateOrganizerAccountPath = '/organizer';

  /**
   * Create Organizer Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrganizerAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrganizerAccount$Response(params: {
    /**
     * Get
     */
    body: CreateOrganizerAccountRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.CreateOrganizerAccountPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create Organizer Account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrganizerAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrganizerAccount(params: {
    /**
     * Get
     */
    body: CreateOrganizerAccountRequest;
  }): Observable<ResponseSuccess> {
    return this.createOrganizerAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeOrganizerAccount
   */
  static readonly RemoveOrganizerAccountPath = '/organizer';

  /**
   * removes Organizer Account of user.
   *
   * removes Organizer Account by user id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeOrganizerAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeOrganizerAccount$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.RemoveOrganizerAccountPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * removes Organizer Account of user.
   *
   * removes Organizer Account by user id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeOrganizerAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeOrganizerAccount(params?: {}): Observable<ResponseSuccess> {
    return this.removeOrganizerAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getOwnOrganizerAccount
   */
  static readonly GetOwnOrganizerAccountPath = '/organizer/profile';

  /**
   * returns all info that can be exposed to client.
   *
   * Only for yourself
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOwnOrganizerAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOwnOrganizerAccount$Response(params?: {}): Observable<
    StrictHttpResponse<GetOrganizerAccountInfoResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.GetOwnOrganizerAccountPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetOrganizerAccountInfoResponseSuccess>;
        }),
      );
  }

  /**
   * returns all info that can be exposed to client.
   *
   * Only for yourself
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOwnOrganizerAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOwnOrganizerAccount(params?: {}): Observable<GetOrganizerAccountInfoResponseSuccess> {
    return this.getOwnOrganizerAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetOrganizerAccountInfoResponseSuccess>) =>
          r.body as GetOrganizerAccountInfoResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadProfilePhotoOrganizerAccount
   */
  static readonly UploadProfilePhotoOrganizerAccountPath =
    '/organizer/profile_photo';

  /**
   * Upload profile photo.
   *
   * Upload profile photo to Organizer Account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadProfilePhotoOrganizerAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadProfilePhotoOrganizerAccount$Response(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.UploadProfilePhotoOrganizerAccountPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Upload profile photo.
   *
   * Upload profile photo to Organizer Account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadProfilePhotoOrganizerAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadProfilePhotoOrganizerAccount(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;
  }): Observable<ResponseSuccess> {
    return this.uploadProfilePhotoOrganizerAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation reviewOrganizerAccount
   */
  static readonly ReviewOrganizerAccountPath = '/organizer/review';

  /**
   * Sends Organizer Account for review and returns the current status.
   *
   * Review Organizer Account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewOrganizerAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewOrganizerAccount$Response(params?: {}): Observable<
    StrictHttpResponse<OrganizerAccountStatusResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.ReviewOrganizerAccountPath,
      'put',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OrganizerAccountStatusResponse>;
        }),
      );
  }

  /**
   * Sends Organizer Account for review and returns the current status.
   *
   * Review Organizer Account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reviewOrganizerAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewOrganizerAccount(params?: {}): Observable<OrganizerAccountStatusResponse> {
    return this.reviewOrganizerAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OrganizerAccountStatusResponse>) =>
          r.body as OrganizerAccountStatusResponse,
      ),
    );
  }

  /**
   * Path part for operation statusOrganizerAccountStatus
   */
  static readonly StatusOrganizerAccountStatusPath = '/organizer/status';

  /**
   * returns Organizer Account status.
   *
   * Organizer Account status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusOrganizerAccountStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusOrganizerAccountStatus$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.StatusOrganizerAccountStatusPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * returns Organizer Account status.
   *
   * Organizer Account status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statusOrganizerAccountStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusOrganizerAccountStatus(params?: {}): Observable<ResponseSuccess> {
    return this.statusOrganizerAccountStatus$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation joinToStripe
   */
  static readonly JoinToStripePath = '/organizer/stripe';

  /**
   * Join to stripe.
   *
   * how to create code look this https://stripe.com/docs/connect/standard-accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `joinToStripe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  joinToStripe$Response(params: {
    /**
     * Get
     */
    body: JoinToStripeRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.JoinToStripePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Join to stripe.
   *
   * how to create code look this https://stripe.com/docs/connect/standard-accounts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `joinToStripe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  joinToStripe(params: {
    /**
     * Get
     */
    body: JoinToStripeRequest;
  }): Observable<ResponseSuccess> {
    return this.joinToStripe$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation mediaOrganizerId
   */
  static readonly MediaOrganizerIdPath = '/organizers/{id}/media';

  /**
   * returns all media for organizer by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaOrganizerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaOrganizerId$Response(params: {
    /**
     * Organizer ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetOrganizerMediaResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.MediaOrganizerIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetOrganizerMediaResponse>;
        }),
      );
  }

  /**
   * returns all media for organizer by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaOrganizerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaOrganizerId(params: {
    /**
     * Organizer ID
     */
    id: string;
  }): Observable<GetOrganizerMediaResponse> {
    return this.mediaOrganizerId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetOrganizerMediaResponse>) =>
          r.body as GetOrganizerMediaResponse,
      ),
    );
  }

  /**
   * Path part for operation pastEventsOrganizerId
   */
  static readonly PastEventsOrganizerIdPath = '/organizers/{id}/past_events';

  /**
   * returns past events for organizer by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pastEventsOrganizerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  pastEventsOrganizerId$Response(params: {
    /**
     * Organizer ID
     */
    id: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetEventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.PastEventsOrganizerIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventsResponse>;
        }),
      );
  }

  /**
   * returns past events for organizer by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pastEventsOrganizerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pastEventsOrganizerId(params: {
    /**
     * Organizer ID
     */
    id: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetEventsResponse> {
    return this.pastEventsOrganizerId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventsResponse>) =>
          r.body as GetEventsResponse,
      ),
    );
  }

  /**
   * Path part for operation statusOrganizerAccountStatusId
   */
  static readonly StatusOrganizerAccountStatusIdPath =
    '/organizers/{id}/status';

  /**
   * returns Organizer Account status.
   *
   * Organizer Account status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusOrganizerAccountStatusId()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusOrganizerAccountStatusId$Response(params: {
    /**
     * Organizer ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetOrganizerMediaResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.StatusOrganizerAccountStatusIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetOrganizerMediaResponse>;
        }),
      );
  }

  /**
   * returns Organizer Account status.
   *
   * Organizer Account status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statusOrganizerAccountStatusId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusOrganizerAccountStatusId(params: {
    /**
     * Organizer ID
     */
    id: string;
  }): Observable<GetOrganizerMediaResponse> {
    return this.statusOrganizerAccountStatusId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetOrganizerMediaResponse>) =>
          r.body as GetOrganizerMediaResponse,
      ),
    );
  }

  /**
   * Path part for operation upcomingEventsOrganizerId
   */
  static readonly UpcomingEventsOrganizerIdPath =
    '/organizers/{id}/upcoming_events';

  /**
   * returns all events for organizer by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upcomingEventsOrganizerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  upcomingEventsOrganizerId$Response(params: {
    /**
     * Organizer ID
     */
    id: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetEventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.UpcomingEventsOrganizerIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventsResponse>;
        }),
      );
  }

  /**
   * returns all events for organizer by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upcomingEventsOrganizerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  upcomingEventsOrganizerId(params: {
    /**
     * Organizer ID
     */
    id: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetEventsResponse> {
    return this.upcomingEventsOrganizerId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventsResponse>) =>
          r.body as GetEventsResponse,
      ),
    );
  }

  /**
   * Path part for operation publicGetOrganizerAccount
   */
  static readonly PublicGetOrganizerAccountPath = '/organizers/{organizerGUID}';

  /**
   * returns all info that can be exposed to the public.
   *
   * For public / No auth
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicGetOrganizerAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetOrganizerAccount$Response(params: {
    /**
     * Organizer ID
     */
    organizerGUID: string;
  }): Observable<StrictHttpResponse<GetOrganizerAccountInfoResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      OrganizerAccountService.PublicGetOrganizerAccountPath,
      'get',
    );
    if (params) {
      rb.path('organizerGUID', params.organizerGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetOrganizerAccountInfoResponseSuccess>;
        }),
      );
  }

  /**
   * returns all info that can be exposed to the public.
   *
   * For public / No auth
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publicGetOrganizerAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetOrganizerAccount(params: {
    /**
     * Organizer ID
     */
    organizerGUID: string;
  }): Observable<GetOrganizerAccountInfoResponseSuccess> {
    return this.publicGetOrganizerAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetOrganizerAccountInfoResponseSuccess>) =>
          r.body as GetOrganizerAccountInfoResponseSuccess,
      ),
    );
  }
}
