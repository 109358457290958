import { RootStoreState } from '@app/store/root';
import { createSelector } from '@ngrx/store';
import { State } from './state';

export const getProfileState = (state: RootStoreState.State) =>
  state.client.profile;

export const getProfilesState = createSelector(
  getProfileState,
  (state: State) => state.profiles,
);

export const getIsUpdate = createSelector(
  getProfileState,
  (state: State) => state.isUpdate,
);
export const getErrorState = createSelector(
  getProfileState,
  (state: State) => state.error,
);
