import { Action } from '@ngrx/store';

export enum ActionTypes {
  Toggle = '[Sidebar] Toggle',
}

export class Toggle implements Action {
  public readonly type = ActionTypes.Toggle;
}

export type Actions = Toggle;
