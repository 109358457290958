import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { OrganizerProfileResponse } from '@app/models/client/organizer/organizer-profile-response';
// tslint:disable-next-line:max-line-length
import { HttpUrlEncodingCodecWithSquareBrackets } from '@app/base/http/HttpUrlEncodingCodecWithSquareBrackets';
import { MessagesOrganizerResponseSuccess } from '@app/models/client/organizer/messages-organizer-response-success.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { APIService } from '@app/interfaces/api-service.interface';
import dayjs from 'dayjs';
import { FollowersCount } from '@app/models/client/followers-count.model';
import { FollowingCount } from '@app/models/client/following-count.model';
// tslint:disable-next-line:max-line-length
import { CreateOrUpdateOrganizerAccountRequest } from '@app/models/client/organizer/create-or-update-organizer-account-request/create-or-update-organizer-account-request.model';
import { GetOrganizerEventsResponse } from '@app/models/client/organizer/get-organizer-events-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { EventResponse } from '@models/client/events/event-response.model';

/**
 * Implementation of organizer account APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class OrganizerService implements APIService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * POST: `/api/v1/organizer`.
   * Create organizer account.
   *
   *
   * @param query - Organizer artist-artist-profile-private-profiles model.
   *
   * @return An `Observable` of the `IResponse`.
   */
  createOrganizerProfile(query: any): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/organizer`,
      query,
    );
  }

  /**
   * PUT: `/api/v1/organizer/review`.
   * Send on review organizer account.
   *
   *
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  sendOnReview(): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/organizer/review`,
      null,
    );
  }

  /**
   * PUT: `/api/v2/organizer/events/media/video`.
   * Upload video.
   *
   *
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  uploadVideo(video: any): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v2/organizer/events/media/video`,
      video,
    );
  }

  /**
   * DELETE: `/api/v2/organizer/events/media/`.
   * Delete event media.
   *
   *
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  deleteEventMedia(
    eventID: string,
    url: string,
    type: string,
  ): Observable<ResponseSuccess<any>> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        eventID,
        url,
      },
    };
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v2/organizer/events/media/${type}`,
      options,
    );
  }

  getProfileByID(organizerGUID: string): Observable<OrganizerProfileResponse> {
    return this.http.get<OrganizerProfileResponse>(
      `${this.host}/api/v1/organizers/${organizerGUID}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  /**
   * POST: `/api/v1/organizer/profile_photo`.
   * Upload artist-artist-profile-private-profiles photo.
   *
   *
   * @param query - multipart/form-data image=file.
   *
   * @return An `Observable` of the `IResponse`.
   */
  setProfilePhoto(query: FormData): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/organizer/profile_photo`,
      query,
    );
  }

  /**
   * PUT: `/api/v1/organizer`.
   * Update organizer artist-artist-profile-private-profiles.
   *
   *
   * @param query - multipart/form-data image=file.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  updateProfile(
    query: CreateOrUpdateOrganizerAccountRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/organizer`,
      query,
    );
  }

  /**
   * GET: `/api/v1/organizers/{id}/upcoming_events`.
   * Returns all upcoming events for a organizers by the ID.
   *
   *
   * @param id - Organizers Profile ID.
   */
  getUpcomingEvents(
    id: string,
  ): Observable<ResponseSuccess<GetEventsResponse>> {
    return this.http.get<ResponseSuccess<GetEventsResponse>>(
      `${this.host}/api/v1/organizers/${id}/upcoming_events`,
    );
  }

  /**
   * GET: `/api/v1/organizers/{id}/past_events`.
   * Returns all past events for a organizers by the ID.
   *
   *
   * @param id - Organizers Profile ID.
   */
  getPastEvents(id: string): Observable<ResponseSuccess<GetEventsResponse>> {
    return this.http.get<ResponseSuccess<GetEventsResponse>>(
      `${this.host}/api/v1/organizers/${id}/past_events`,
    );
  }

  /**
   * GET: `/api/v1/organizer/events`.
   * Returns list of events with revenue and tickets count.
   *
   *
   * @param limit - Limit.
   * @param page - Page.
   * @param dateFrom - Date from - filter[date_from], Format 2006-07-08.
   * @param dateTo - Date to - filter[date_to], Format 2006-07-08.
   *
   * @return An `Observable` of the `IGetOrganizerEventsResponseSuccess`.
   */
  getOrganizersEvents(
    limit: string,
    page: string,
    dateFrom: string,
    dateTo: string,
  ): Observable<ResponseSuccess<GetOrganizerEventsResponse>> {
    return this.http.get<ResponseSuccess<GetOrganizerEventsResponse>>(
      `${this.host}/api/v1/organizer/events`,
      {
        params: new HttpParams({
          encoder: new HttpUrlEncodingCodecWithSquareBrackets(),
        })
          .set('filter[date_from]'.toString(), dateFrom)
          .set('filter[date_to]'.toString(), dateTo),
      },
    );
  }

  /**
   * GET: `/api/v1/organizer/events`.
   * Returns list of events with revenue and tickets count.
   *
   *
   * @param limit - Limit.
   * @param page - Page.
   *
   * @return An `Observable` of the `IGetOrganizerEventsResponseSuccess`.
   */
  getOrganizersPastEvents(
    limit: string,
    page: string,
  ): Observable<ResponseSuccess<GetOrganizerEventsResponse>> {
    // Current Date
    const currentDate = dayjs();
    const currentDateFormatted = currentDate.format('YYYY-MM-DD');
    // Past Date
    const pastDate = currentDate.subtract(20, 'year');
    const pastDateFormatted = pastDate.format('YYYY-MM-DD');

    return this.http.get<ResponseSuccess<GetOrganizerEventsResponse>>(
      `${this.host}/api/v1/organizer/events`,
      {
        params: new HttpParams({
          encoder: new HttpUrlEncodingCodecWithSquareBrackets(),
        })
          .set('filter[date_from]'.toString(), pastDateFormatted)
          .set('filter[date_to]'.toString(), currentDateFormatted),
      },
    );
  }

  /**
   * GET: `/api/v2/organizer/events/{eventGUID}`.
   * Returns list of events with revenue and tickets count.
   *
   * @param eventGUID - Event ID.
   *
   * @return An `Observable` of the `GetOrganizerEventResponseSuccess`.
   */
  getOrganizerEventByID(eventGUID: string): Observable<EventResponse> {
    return this.http.get<EventResponse>(
      `${this.host}/api/v2/organizer/events/${eventGUID}`,
    );
  }

  /***** Follows *****/

  /**
   * GET: `/api/v1/fans/{userGUID}/organizer/followers_count`.
   * Followers count for the current organizer.
   *
   * @param userGUID - User ID.
   *
   * @return An `Observable` of the `FollowersCount`.
   */
  getFollowersCount(userGUID: string): Observable<FollowersCount> {
    return this.http.get<FollowersCount>(
      `${this.host}/api/v1/fans/${userGUID}/organizer/followers_count`,
    );
  }

  /**
   * GET: `/api/v1/fans/{userGUID}/organizer/following_count`.
   * Following count for the current organizer.
   *
   * @param userGUID - User ID.
   *
   * @return An `Observable` of the `FollowingCount`.
   */
  getFollowingCount(userGUID: string): Observable<FollowingCount> {
    return this.http.get<FollowingCount>(
      `${this.host}/api/v1/fans/${userGUID}/organizer/following_count`,
    );
  }

  /***** Messages *****/

  /**
   * GET: `/api/v1/organizer/messages`.
   * Get organizer messages.
   *
   *
   * @return An `Observable` of the `MessagesOrganizerResponseSuccess`
   */

  getMessages(): Observable<MessagesOrganizerResponseSuccess> {
    return this.http.get<MessagesOrganizerResponseSuccess>(
      `${this.host}/api/v1/organizer/messages`,
    );
  }

  /***** Followers&Following. *****/

  /**
   * POST: `/api/v1/fan/follower/organizer/artist/{artistAccountGUID}`.
   * Follow up artist account.
   *
   *
   * @param artistAccountGUID - Artist Account ID.
   */
  followUpArtistAccount(
    artistAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/organizer/artist/${artistAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/organizer/artist/{artistAccountGUID}`.
   * Unfollow up artist account.
   *
   *
   * @param artistAccountGUID - Artist Account ID.
   */
  unfollowUpArtistAccount(
    artistAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/organizer/artist/${artistAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/organizer/fan/{fanAccountGUID}`.
   * Follow up fan account.
   *
   *
   * @param fanAccountGUID - Fan Account ID.
   */
  followUpFanAccount(fanAccountGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/organizer/fan/${fanAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/organizer/fan/{fanAccountGUID}`.
   * Unfollow up fan account.
   *
   *
   * @param fanAccountGUID - Fan Account ID.
   */
  unfollowUpFanAccount(
    fanAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/organizer/fan/${fanAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/organizer/organizer/{organizerAccountGUID}`.
   * Follow up organizer account.
   *
   *
   * @param organizerAccountGUID - Organizer Account ID.
   */
  followUpOrganizerAccount(
    organizerAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/organizer/organizer/${organizerAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/organizer/organizer/{organizerAccountGUID}`.
   * Unfollow up organizer account.
   *
   *
   * @param organizerAccountGUID - Organizer Account ID.
   */
  unfollowUpOrganizerAccount(
    organizerAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/organizer/organizer/${organizerAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/organizer/venue/{venueAccountGUID}`.
   * Follow up venue account.
   *
   *
   * @param venueAccountGUID - Venue Account ID.
   */
  followUpVenueAccount(
    venueAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/organizer/venue/${venueAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/organizer/venue/{venueAccountGUID}`.
   * Unfollow up venue account.
   *
   *
   * @param venueAccountGUID - Venue Account ID.
   */
  unfollowUpVenueAccount(
    venueAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/organizer/venue/${venueAccountGUID}`,
    );
  }

  getEventPermission(guidOrCustomURL: string): Observable<any> {
    return this.http.get(
      `${this.host}/api/v2/events/${guidOrCustomURL}/permission`,
    );
  }
}
