import { Injectable } from '@angular/core';
import { APIService } from '@app/interfaces/api-service.interface';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';

@Injectable({
  providedIn: 'root',
})
export class VideoService implements APIService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * POST: `/api/v1/videos`.
   * Create video link or upload video.
   *
   * @param file - multipart/form-data file=[file].
   * @param link - youtube link.
   */
  uploadVideo(file: FormData, link: string): Observable<ResponseSuccess<any>> {
    if (file) {
      return this.http.post<ResponseSuccess<any>>(
        `${this.host}/api/v1/videos`,
        file,
      );
    } else {
      return this.http.post<ResponseSuccess<any>>(
        `${this.host}/api/v1/videos`,
        { link },
      );
    }
  }

  /**
   * DELETE: `/api/v1/videos/{videoGUID}`.
   * Removes video by ID.
   *
   * @param id - Video ID.
   */
  removeVideo(id: string): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/videos/${id}`,
    );
  }
}
