import { saveAs } from 'file-saver';

export class FileUtils {
  public static downloadFilePDF(data: ArrayBuffer) {
    const blob = new Blob([data], {
      type: 'application/pdf',
    });
    // const url = window.URL.createObjectURL(blob);
    saveAs(blob, 'ticket.pdf');
  }
}
