import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { FAQQuestion } from '@app/models/client/faq/FAQQuestion.model';

/**
 * Implementation of support APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class SupportService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  /**
   * GET: `/support/faq`.
   *
   * Get FAQ.
   *
   *
   *
   * @return An `Observable` of the `GetFAQResponseSuccess`.
   */
  getFAQ(): Observable<ResponseSuccess<FAQQuestion[]>> {
    return this.http.get<ResponseSuccess<FAQQuestion[]>>(
      `${this.baseUrl}/support/faq`,
    );
  }
}
