import { Action } from '@ngrx/store';
import { SearchResponseSuccess } from '@app/models/client/search/search-response-success.interface';
import { FanAccountsResponseSuccess } from '@app/models/client/fan/fan-accounts-response-success.model';
import { GetArtistAccountsResponseSuccess } from '@app/models/client/artist/get-artist-accounts-response-success.model';
import { GetVenueAccountsResponseSuccess } from '@app/models/client/venue/get-venue-accounts-response-success.model';
import { GetOrganizerAccountsResponseSuccess } from '@app/models/client/organizer/get-organizer-accounts-response-success.model';
import { GetTagsResponseSuccess } from '@app/models/client/tags/get-tags-response-success.model';
import { GetSuggestionsResponseSuccess } from '@app/models/client/suggest/get-suggestions-response-success.model';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { SearchArtistsForEventsParams } from '@app/models/client/search/search-artists-for-events-params.model';
import { SearchVenuesForEventsParams } from '@app/models/client/search/search-venues-for-events-params.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { EventArtistAccountsResponse } from '@app/models/client/events/event-artist-accounts-response.model';
import { EventVenueAccountsResponse } from '@app/models/client/events/event-venue-accounts-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { SearchResponse } from '@models/client/search/search-response.model';

export enum ActionTypes {
  GetSearch = '[Search] Get Search',
  GetSearchSuccess = '[Search] Get Search Success',
  GetSearchFailure = '[Search] Get Search Failure',

  GetSearchRange = '[Search] Get Search Range',
  GetSearchRangeSuccess = '[Search] Get Search Range Success',
  GetSearchRangeFailure = '[Search] Get Search Range Failure',

  GetSearchFan = '[Search] Get Search Fan',
  GetSearchFanSuccess = '[Search] Get Search Fan Success',
  GetSearchFanFailure = '[Search] Get Search Fan Failure',

  GetSearchArtist = '[Search] Get Search Artist',
  GetSearchArtistSuccess = '[Search] Get Search Artist Success',
  GetSearchArtistFailure = '[Search] Get Search Artist Failure',

  GetSearchVenue = '[Search] Get Search Venue',
  GetSearchVenueSuccess = '[Search] Get Search Venue Success',
  GetSearchVenueFailure = '[Search] Get Search Venue Failure',

  RemoveSearchOrganizer = '[Search] Remove Search Organizer',
  GetSearchOrganizer = '[Search] Get Search Organizer',
  GetSearchOrganizerSuccess = '[Search] Get Search Organizer Success',
  GetSearchOrganizerFailure = '[Search] Get Search Organizer Failure',

  GetSearchEvent = '[Search] Get Search Event',
  GetSearchEventSuccess = '[Search] Get Search Event Success',
  GetSearchEventFailure = '[Search] Get Search Event Failure',

  GetSearchRangeEvent = '[Search] Get Search Range Event',
  GetSearchRangeEventSuccess = '[Search] Get Search Range Event Success',
  GetSearchRangeEventFailure = '[Search] Get Search Range Event Failure',

  GetSearchTags = '[Search] Get Search Tags',
  GetSearchTagsSuccess = '[Search] Get Search Tags Success',
  GetSearchTagsFailure = '[Search] Get Search Tags Failure',

  GetSearchSuggest = '[Search] Get Search Suggest',
  GetSearchSuggestSuccess = '[Search] Get Search Suggest Success',
  GetSearchSuggestFailure = '[Search] Get Search Suggest Failure',

  SetSearchQuery = '[Search] Set Search Query',

  SearchArtistsForEvent = '[Search] Search Artists For Event',
  SearchArtistsForEventSuccess = '[Search] Search Artists For Event Success',
  SearchArtistsForEventFailure = '[Search] Search Artists For Event Failure',

  SearchVenuesForEvent = '[Search] Search Venues For Event',
  SearchVenuesForEventSuccess = '[Search] Search Venues For Event Success',
  SearchVenuesForEventFailure = '[Search] Search Venues For Event Failure',

  CleanSearch = '[Search] Clean Search',
  CleanSearchTypes = '[Search] Clean Search Types',

  SetStatus = '[Search] Set Status',
}

export class CleanSearch implements Action {
  public readonly type = ActionTypes.CleanSearch;
}

export class CleanSearchTypes implements Action {
  public readonly type = ActionTypes.CleanSearchTypes;
}

// Get Search

export class GetSearch implements Action {
  public readonly type = ActionTypes.GetSearch;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
      sort?: any;
    },
  ) {}
}

export class GetSearchSuccess implements Action {
  public readonly type = ActionTypes.GetSearchSuccess;

  constructor(public payload: { response: SearchResponse }) {}
}

export class GetSearchFailure implements Action {
  public readonly type = ActionTypes.GetSearchFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Range
export class GetSearchRange implements Action {
  public readonly type = ActionTypes.GetSearchRange;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
      price: string;
      sort?: any;
    },
  ) {}
}

export class GetSearchRangeSuccess implements Action {
  public readonly type = ActionTypes.GetSearchRangeSuccess;

  constructor(public payload: { response: SearchResponseSuccess }) {}
}

export class GetSearchRangeFailure implements Action {
  public readonly type = ActionTypes.GetSearchRangeFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Fan

export class GetSearchFan implements Action {
  public readonly type = ActionTypes.GetSearchFan;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
      sort?: any;
    },
  ) {}
}

export class GetSearchFanSuccess implements Action {
  public readonly type = ActionTypes.GetSearchFanSuccess;

  constructor(public payload: { response: FanAccountsResponseSuccess }) {}
}

export class GetSearchFanFailure implements Action {
  public readonly type = ActionTypes.GetSearchFanFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Artist

export class GetSearchArtist implements Action {
  public readonly type = ActionTypes.GetSearchArtist;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
      sort?: any;
    },
  ) {}
}

export class GetSearchArtistSuccess implements Action {
  public readonly type = ActionTypes.GetSearchArtistSuccess;

  constructor(public payload: { response: GetArtistAccountsResponseSuccess }) {}
}

export class GetSearchArtistFailure implements Action {
  public readonly type = ActionTypes.GetSearchArtistFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Venue

export class GetSearchVenue implements Action {
  public readonly type = ActionTypes.GetSearchVenue;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
      sort?: any;
    },
  ) {}
}

export class GetSearchVenueSuccess implements Action {
  public readonly type = ActionTypes.GetSearchVenueSuccess;

  constructor(public payload: { response: GetVenueAccountsResponseSuccess }) {}
}

export class GetSearchVenueFailure implements Action {
  public readonly type = ActionTypes.GetSearchVenueFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Organizer

export class GetSearchOrganizer implements Action {
  public readonly type = ActionTypes.GetSearchOrganizer;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
      sort?: any;
    },
  ) {}
}

export class RemoveSearchOrganizer implements Action {
  public readonly type = ActionTypes.RemoveSearchOrganizer;

  constructor() {}
}

export class GetSearchOrganizerSuccess implements Action {
  public readonly type = ActionTypes.GetSearchOrganizerSuccess;

  constructor(
    public payload: { response: GetOrganizerAccountsResponseSuccess },
  ) {}
}

export class GetSearchOrganizerFailure implements Action {
  public readonly type = ActionTypes.GetSearchOrganizerFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Event

export class GetSearchEvent implements Action {
  public readonly type = ActionTypes.GetSearchEvent;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
      price?: string;
      sort?: any;
    },
  ) {}
}

export class GetSearchEventSuccess implements Action {
  public readonly type = ActionTypes.GetSearchEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class GetSearchEventFailure implements Action {
  public readonly type = ActionTypes.GetSearchEventFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Event Range

export class GetSearchRangeEvent implements Action {
  public readonly type = ActionTypes.GetSearchRangeEvent;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filters: any;
      price: string;
      sort?: any;
    },
  ) {}
}

export class GetSearchRangeEventSuccess implements Action {
  public readonly type = ActionTypes.GetSearchRangeEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class GetSearchRangeEventFailure implements Action {
  public readonly type = ActionTypes.GetSearchRangeEventFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Tags

export class GetSearchTags implements Action {
  public readonly type = ActionTypes.GetSearchTags;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
    },
  ) {}
}

export class GetSearchTagsSuccess implements Action {
  public readonly type = ActionTypes.GetSearchTagsSuccess;

  constructor(public payload: { response: GetTagsResponseSuccess }) {}
}

export class GetSearchTagsFailure implements Action {
  public readonly type = ActionTypes.GetSearchTagsFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Search Suggest

export class GetSearchSuggest implements Action {
  public readonly type = ActionTypes.GetSearchSuggest;

  constructor(
    public payload: {
      query: string;
      limit: string;
      page: string;
      filtersParams: any;
      sort: [
        {
          geoDistance: {
            coordinates: {
              lat: number;
              lon: number;
            };
          };
        },
      ];
    },
  ) {}
}

export class GetSearchSuggestSuccess implements Action {
  public readonly type = ActionTypes.GetSearchSuggestSuccess;

  constructor(public payload: { response: GetSuggestionsResponseSuccess }) {}
}

export class GetSearchSuggestFailure implements Action {
  public readonly type = ActionTypes.GetSearchSuggestFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Set Search Query

export class SetSearchQuery implements Action {
  public readonly type = ActionTypes.SetSearchQuery;

  constructor(public payload: { searchQuery: string }) {}
}

// Search Artists For Event

export class SearchArtistsForEvent implements Action {
  public readonly type = ActionTypes.SearchArtistsForEvent;

  constructor(
    public payload: {
      searchParams: SearchArtistsForEventsParams;
    },
  ) {}
}

export class SearchArtistsForEventSuccess implements Action {
  public readonly type = ActionTypes.SearchArtistsForEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<EventArtistAccountsResponse> },
  ) {}
}

export class SearchArtistsForEventFailure implements Action {
  public readonly type = ActionTypes.SearchArtistsForEventFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Search Venues For Event

export class SearchVenuesForEvent implements Action {
  public readonly type = ActionTypes.SearchVenuesForEvent;

  constructor(
    public payload: {
      searchParams: SearchVenuesForEventsParams;
    },
  ) {}
}

export class SearchVenuesForEventSuccess implements Action {
  public readonly type = ActionTypes.SearchVenuesForEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<EventVenueAccountsResponse> },
  ) {}
}

export class SearchVenuesForEventFailure implements Action {
  public readonly type = ActionTypes.SearchVenuesForEventFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class SetStatus implements Action {
  public readonly type = ActionTypes.SetStatus;

  constructor(public payload: { status: boolean }) {}
}

export type Actions =
  | GetSearch
  | GetSearchSuccess
  | GetSearchFailure
  | GetSearchRange
  | GetSearchRangeSuccess
  | GetSearchRangeFailure
  | GetSearchFan
  | GetSearchFanSuccess
  | GetSearchFanFailure
  | GetSearchArtist
  | GetSearchArtistSuccess
  | GetSearchArtistFailure
  | GetSearchVenue
  | GetSearchVenueSuccess
  | GetSearchVenueFailure
  | RemoveSearchOrganizer
  | GetSearchOrganizer
  | GetSearchOrganizerSuccess
  | GetSearchOrganizerFailure
  | GetSearchEvent
  | GetSearchEventSuccess
  | GetSearchEventFailure
  | GetSearchRangeEvent
  | GetSearchRangeEventSuccess
  | GetSearchRangeEventFailure
  | GetSearchTags
  | GetSearchTagsSuccess
  | GetSearchTagsFailure
  | GetSearchSuggest
  | GetSearchSuggestSuccess
  | GetSearchSuggestFailure
  | SetSearchQuery
  | SearchArtistsForEvent
  | SearchArtistsForEventSuccess
  | SearchArtistsForEventFailure
  | SearchVenuesForEvent
  | SearchVenuesForEventSuccess
  | SearchVenuesForEventFailure
  | CleanSearch
  | CleanSearchTypes
  | SetStatus;
