import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as ArtistStoreReducer from '@app/store/root/client/artist/artist.reducer';
import * as ClientStoreState from '@app/store/root/client/state';
import * as CommentsStoreReducer from '@app/store/root/client/comments/reducer';
import * as EventsStoreReducer from '@app/store/root/client/events/reducer';
import * as FanStoreReducer from '@app/store/root/client/fan/reducer';
import * as GenresStoreReducer from '@app/store/root/client/genres/reducer';
import * as GoingFansStoreReducer from '@app/store/root/client/going-fans/reducer';
import * as MessagesStoreReducer from '@app/store/root/client/messages/reducer';
import * as OrganizerStoreReducer from '@app/store/root/client/organizer/reducer';
import * as PaymentStoreReducer from '@app/store/root/client/payment/reducer';
import * as SearchStoreReducer from '@app/store/root/client/search/reducer';
import * as SidebarStoreReducer from '@app/store/root/client/sidebar/reducer';
import * as SignInStoreReducer from '@app/store/root/client/sign-in/reducer';
import * as SignUpStoreReducer from '@app/store/root/client/sign-up/reducer';
import * as SupportStoreReducer from '@app/store/root/client/support/reducer';
import * as TicketsStoreReducer from '@app/store/root/client/tickets/reducer';
import * as UserStoreReducer from '@app/store/root/client/user/reducer';
import * as VenueStoreReducer from '@app/store/root/client/venue/reducer';
import * as NavbarStoreReducer from '@app/store/root/client/navbar/navbar.reducer';
import { CommentsEffects } from '@app/store/root/client/comments/effects';
import { EventsEffects } from '@app/store/root/client/events/effects';
import { GenresEffects } from '@app/store/root/client/genres/effects';
import { GoingFansEffects } from '@app/store/root/client/going-fans/effects';
import { OrganizerEffects } from '@app/store/root/client/organizer/effects';
import { PaymentEffects } from '@app/store/root/client/payment/effects';
import { SearchEffects } from '@app/store/root/client/search/effects';
import { SignInEffects } from '@app/store/root/client/sign-in/effects';
import { SignUpEffects } from '@app/store/root/client/sign-up/effects';
import { SupportEffects } from '@app/store/root/client/support/effects';
import { TicketsEffects } from '@app/store/root/client/tickets/effects';
import { UserEffects } from '@app/store/root/client/user/effects';
import { VideoStoreReducer } from '@app/store/root/client/video';
import { VideoEffects } from '@app/store/root/client/video/video.effects';
import { ArtistEffects } from '@app/store/root/client/artist/artist.effects';
import { VenueEffects } from '@app/store/root/client/venue/effects';
import { metaReducers } from '@store/root/client/meta';
import { FanEffects } from '@store/root/client/fan/effects';
import { CartStoreReducer } from '@store/root/client/cart';
import { CartEffects } from '@store/root/client/cart/effects';
import { MediaStoreReducer } from '@store/root/client/media';
import { MediaEffects } from '@store/root/client/media/effects';
import { EventStoreReducer } from '@store/root/client/event';
import { EventEffects } from '@store/root/client/event/effects';
import { DashboardStoreReducer } from '@store/root/client/dashboard';
import { DashboardEffects } from '@store/root/client/dashboard/effects';
import { DonorsStoreReducer } from '@store/root/client/donors';
import { DonorsEffects } from '@store/root/client/donors/effects';
import { ProfileStoreReducer } from '@store/root/client/profile';
import { ProfileEffects } from '@store/root/client/profile/effects';

export const FEATURE_REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<ClientStoreState.State>
>('Client Feature Reducers');

export function getReducers(): ActionReducerMap<ClientStoreState.State> {
  // map of reducers
  return {
    donors: DonorsStoreReducer.reducer,
    artist: ArtistStoreReducer.reducer,
    comments: CommentsStoreReducer.reducer,
    events: EventsStoreReducer.reducer,
    event: EventStoreReducer.reducer,
    fan: FanStoreReducer.reducer,
    genres: GenresStoreReducer.reducer,
    goingFans: GoingFansStoreReducer.reducer,
    messages: MessagesStoreReducer.reducer,
    organizer: OrganizerStoreReducer.reducer,
    payment: PaymentStoreReducer.reducer,
    profile: ProfileStoreReducer.reducer,
    search: SearchStoreReducer.reducer,
    sidebar: SidebarStoreReducer.reducer,
    signIn: SignInStoreReducer.reducer,
    signUp: SignUpStoreReducer.reducer,
    support: SupportStoreReducer.reducer,
    tickets: TicketsStoreReducer.reducer,
    user: UserStoreReducer.reducer,
    venue: VenueStoreReducer.reducer,
    video: VideoStoreReducer.reducer,
    navbar: NavbarStoreReducer.reducer,
    cart: CartStoreReducer.reducer,
    media: MediaStoreReducer.reducer,
    dashboard: DashboardStoreReducer.reducer,
  };
}

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      CommentsEffects,
      EventsEffects,
      EventEffects,
      GenresEffects,
      GoingFansEffects,
      OrganizerEffects,
      PaymentEffects,
      ProfileEffects,
      SearchEffects,
      SignInEffects,
      SignUpEffects,
      SupportEffects,
      TicketsEffects,
      UserEffects,
      FanEffects,
      ArtistEffects,
      VenueEffects,
      VideoEffects,
      CartEffects,
      MediaEffects,
      DashboardEffects,
      DonorsEffects,
    ]),
    StoreModule.forFeature('client', FEATURE_REDUCER_TOKEN, { metaReducers }),
  ],
  providers: [
    {
      provide: FEATURE_REDUCER_TOKEN,
      useFactory: getReducers,
    },
  ],
})
export class ClientStoreModule {}
