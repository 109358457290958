import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import {
  ActionTypes,
  AddComment,
  AddCommentFailure,
  AddCommentSuccess,
  LoadComments,
  LoadCommentsSuccess,
} from './actions';
import { EventsService } from '@app/services/client/events/events.service';
import { CommentService } from '@app/services/client/comment/comment.service';

@Injectable()
export class CommentsEffects {
  public loadComments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadComments>(ActionTypes.LoadComments),
      switchMap((action) => {
        const limit = action.payload.limit;
        const page = action.payload.page;
        const eventId = action.payload.eventId;

        return this.eventsService.getEventComments(limit, page, eventId).pipe(
          map(
            (response: any) => new LoadCommentsSuccess({ data: response.data }),
          ),
          catchError((response) => of(new LoadCommentsSuccess(response.data))),
        );
      }),
    ),
  );

  public addComment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AddComment>(ActionTypes.AddComment),
      switchMap((action) => {
        const comment = action.payload.comment;
        const eventId = action.payload.eventId;
        const userType = action.payload.usertype;

        return this.commentService
          .createComment(userType, comment, eventId)
          .pipe(
            map((response: any) => new AddCommentSuccess({ response })),
            catchError((response) => of(new AddCommentFailure())),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private eventsService: EventsService,
    private commentService: CommentService,
  ) {}
}
