import { MessagesOrganizerResponse } from '@app/models/client/organizer/messages-organizer-response.model';
import { OrganizerProfile } from '@app/models/client/organizer/organizer-profile.model';
import { EventResponse } from '@models/client/events/event-response.model';
import { GetOrganizerEventsResponse } from '@modules/api/models/get-organizer-events-response';
import { GetEventsResponse } from '@modules/api/models/get-events-response';
import { GetGoingFanEventResponseSuccess } from '@modules/api/models/get-going-fan-event-response-success';
import { GetOrganizerPermissionResponse } from '@modules/api/models/get-organizer-permission';
import { GetOrganizerPermissionSuccess } from '@models/client/events/get-organizer-permission';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export interface State {
  profile: Profile;
  profileByID: OrganizerProfile;
  loading: boolean;
  logined: boolean;
  loaded: boolean;
  organizerEventsResponse: GetEventsResponse;
  organizerEventsResponsePast: GetEventsResponse;
  organizersEventsResponse: GetOrganizerEventsResponse;
  organizersEventsResponsePast: GetGoingFanEventResponseSuccess;
  organizerEventByIDResponse: EventResponse;
  organizerEventTicketsDisplayOrderUpdated: boolean;
  organizerEventIsCreated: boolean;
  organizerPermission: GetOrganizerPermissionResponse;
  messagesResponse: MessagesOrganizerResponse[];
  followersCount: number;
  followingCount: number;
  updated: boolean;
  updateEvent: boolean;
  sendOnReview: boolean;
  removeMedia: boolean;
  uploadingVideo: boolean;
  error: any;
}

export const initialState: State = {
  profile: null,
  profileByID: null,
  loading: true,
  logined: false,
  loaded: false,
  organizerEventsResponse: null,
  organizerEventsResponsePast: null,
  organizersEventsResponse: null,
  organizersEventsResponsePast: null,
  organizerEventByIDResponse: new EventResponse(),
  organizerPermission: new GetOrganizerPermissionSuccess(),
  organizerEventIsCreated: false,
  organizerEventTicketsDisplayOrderUpdated: false,
  messagesResponse: [],
  followersCount: 0,
  followingCount: 0,
  updated: false,
  updateEvent: true,
  sendOnReview: false,
  removeMedia: false,
  uploadingVideo: false,
  error: null,
};
