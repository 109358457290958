import { Action } from '@ngrx/store';
import { SignUpForm } from '@app/models/client/sign-up/sign-up-form.model';
import { UserCreate } from '@app/models/client/user/user-create.model';
import { ArtistProfileResponse } from '@app/models/client/artist/artist-profile-response.model';
import { SignUpSocialForm } from '@app/models/client/sign-up/sign-up-social-form.model';
import { OrganizerProfile } from '@app/models/client/organizer/organizer-profile.model';
import { UpdateUserRequest } from '@app/models/client/user/update-user-request.model';
import { CreateVenueAccountRequest } from '@app/models/client/venue/create-venue-account-request.model';
import { ResponseError } from '@models/shared/response/response-error.model';

export enum ActionTypes {
  SetSignUpForm = '[Sign Up] Set Sign Up Form',
  SetSignUpSocialForm = '[Sign Up] Set Sign Up Social Form',

  SetType = '[Sign Up] Set Type',
  SetCompanyStatus = '[Sign Up] Set Company Status',

  CreateUser = '[Sign Up] Create User',
  CreateUserSocial = '[Sign Up] Create User Social',
  CreateUserSuccess = '[Sign Up] Create User Success',
  CreateUserFailure = '[Sign Up] Create User Failure',

  CreateProfileFan = '[Sign Up] Create Profile Fan',
  CreateProfileFanSuccess = '[Sign Up] Create Profile Fan Success',
  CreateProfileFanFailure = '[Sign Up] Create Profile Fan Failure',

  CreateProfileArtist = '[Sign Up] Create Profile Artist',
  CreateProfileArtistSuccess = '[Sign Up] Create Profile Artist Success',
  CreateProfileArtistFailure = '[Sign Up] Create Profile Artist Failure',

  CreateProfileVenue = '[Sign Up] Create Profile Venue',
  CreateProfileVenueSuccess = '[Sign Up] Create Profile Venue Success',
  CreateProfileVenueFailure = '[Sign Up] Create Profile Venue Failure',

  CreateProfileOrganizer = '[Sign Up] Create Profile Organizer',
  CreateProfileOrganizerSuccess = '[Sign Up] Create Profile Organizer Success',
  CreateProfileOrganizerFailure = '[Sign Up] Create Profile Organizer Failure',

  SetFormInfo = '[Sign Up] Set Form Info',

  LoadFollows = '[Sign Up] Load Follows',
  LoadFollowsSuccess = '[Sign Up] Load Follows Success',
  LoadFollowsSearch = '[Sign Up] Load Follows Search',
  LoadFollowsSearchSuccess = '[Sign Up] Load Follows Search Success',

  RemoveErrorsCreateUserState = '[Sign Up] Remove Errors from Create User State',
}

export class SetSignUpForm implements Action {
  public readonly type = ActionTypes.SetSignUpForm;

  constructor(
    public payload: {
      passed: boolean;
      form: SignUpForm;
    },
  ) {}
}

export class SetSignUpSocialForm implements Action {
  public readonly type = ActionTypes.SetSignUpSocialForm;

  constructor(
    public payload: {
      passed: boolean;
      form: SignUpSocialForm;
    },
  ) {}
}

export class SetType implements Action {
  public readonly type = ActionTypes.SetType;

  constructor(public payload: string) {}
}

export class SetCompanyStatus implements Action {
  public readonly type = ActionTypes.SetCompanyStatus;

  constructor(public payload: string) {}
}

// Create User

export class CreateUser implements Action {
  public readonly type = ActionTypes.CreateUser;

  constructor(public payload: { user: UserCreate }) {}
}

export class CreateUserSocial implements Action {
  public readonly type = ActionTypes.CreateUserSocial;

  constructor(public payload: { userType: string; user: UpdateUserRequest }) {}
}

export class CreateUserSuccess implements Action {
  public readonly type = ActionTypes.CreateUserSuccess;

  constructor(
    public token: any,
    public status: string,
  ) {}
}

export class CreateUserFailure implements Action {
  public readonly type = ActionTypes.CreateUserFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Create Profile Fan

export class CreateProfileFan implements Action {
  public readonly type = ActionTypes.CreateProfileFan;

  constructor(public payload: { mouseliveId: string }) {}
}

export class CreateProfileFanSuccess implements Action {
  public readonly type = ActionTypes.CreateProfileFanSuccess;
}

export class CreateProfileFanFailure implements Action {
  public readonly type = ActionTypes.CreateProfileFanFailure;
}

// Create Profile Artist

export class CreateProfileArtist implements Action {
  public readonly type = ActionTypes.CreateProfileArtist;

  constructor(public payload: { profile: ArtistProfileResponse }) {}
}

export class CreateProfileArtistSuccess implements Action {
  public readonly type = ActionTypes.CreateProfileArtistSuccess;
}

export class CreateProfileArtistFailure implements Action {
  public readonly type = ActionTypes.CreateProfileArtistFailure;
}

// Create Profile Venue

export class CreateProfileVenue implements Action {
  public readonly type = ActionTypes.CreateProfileVenue;

  constructor(public payload: { profile: CreateVenueAccountRequest }) {}
}

export class CreateProfileVenueSuccess implements Action {
  public readonly type = ActionTypes.CreateProfileVenueSuccess;
}

export class CreateProfileVenueFailure implements Action {
  public readonly type = ActionTypes.CreateProfileVenueFailure;
}

// Create Profile Organizer

export class CreateProfileOrganizer implements Action {
  public readonly type = ActionTypes.CreateProfileOrganizer;

  constructor(public payload: { profile: OrganizerProfile }) {}
}

export class CreateProfileOrganizerSuccess implements Action {
  public readonly type = ActionTypes.CreateProfileOrganizerSuccess;
}

export class CreateProfileOrganizerFailure implements Action {
  public readonly type = ActionTypes.CreateProfileOrganizerFailure;
}

export class SetFormInfo implements Action {
  public readonly type = ActionTypes.SetFormInfo;

  constructor(public payload: any) {}
}

export class LoadFollows implements Action {
  public readonly type = ActionTypes.LoadFollows;

  constructor(public payload: any) {}
}

export class LoadFollowsSuccess implements Action {
  public readonly type = ActionTypes.LoadFollowsSuccess;

  constructor(public payload: any) {}
}

export class LoadFollowsSearch implements Action {
  public readonly type = ActionTypes.LoadFollowsSearch;

  constructor(public payload: any) {}
}

export class LoadFollowsSearchSuccess implements Action {
  public readonly type = ActionTypes.LoadFollowsSearchSuccess;

  constructor(public payload: any) {}
}

export class RemoveErrorsCreateUserState implements Action {
  public readonly type = ActionTypes.RemoveErrorsCreateUserState;
}

export type Actions =
  | SetSignUpForm
  | SetSignUpSocialForm
  | SetType
  | SetCompanyStatus
  | CreateUser
  | CreateUserSocial
  | CreateUserSuccess
  | CreateUserFailure
  | CreateProfileFan
  | CreateProfileFanSuccess
  | CreateProfileFanFailure
  | CreateProfileArtist
  | CreateProfileArtistSuccess
  | CreateProfileArtistFailure
  | CreateProfileVenue
  | CreateProfileVenueSuccess
  | CreateProfileVenueFailure
  | CreateProfileOrganizer
  | CreateProfileOrganizerSuccess
  | CreateProfileOrganizerFailure
  | SetFormInfo
  | LoadFollows
  | LoadFollowsSuccess
  | LoadFollowsSearch
  | LoadFollowsSearchSuccess
  | RemoveErrorsCreateUserState;
