import { Action } from '@ngrx/store';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import { ResponseError } from '@models/shared/response/response-error.model';
import {
  DashboardOverviewInterface,
  RecentOrdersResponse,
} from '@models/client/dashboard/dashboard.model';

export enum ActionTypes {
  DashboardOverview = '[Dashboard] Overview',
  DashboardOverviewSuccess = '[Dashboard] Overview Success',
  DashboardOverviewFailure = '[Dashboard] Overview Failure',

  DashboardOverviewByEvent = '[Dashboard] Overview By Event',
  DashboardOverviewByEventSuccess = '[Dashboard] Overview By Event Success',
  DashboardOverviewByEventFailure = '[Dashboard] Overview By Event Failure',

  RemoveDashboardOverview = '[Dashboard] Remove Overview',
  RemoveDashboardOverviewByEvent = '[Dashboard] Remove Overview By Event',

  RecentOrder = '[Dashboard] Recent Order',
  RecentOrderSuccess = '[Dashboard] Recent Order Success',
  RecentOrderFailure = '[Dashboard] Recent Order Failure',

  RecentOrderByEvent = '[Dashboard] Recent Order By Event',
  RecentOrderByEventSuccess = '[Dashboard] Recent Order By Event Success',
  RecentOrderByEventFailure = '[Dashboard] Recent Order By Event Failure',
}

export class DashboardOverview implements Action {
  public readonly type = ActionTypes.DashboardOverview;
}

export class DashboardOverviewSuccess implements Action {
  public readonly type = ActionTypes.DashboardOverviewSuccess;

  constructor(
    public payload: { response: ResponseSuccess<DashboardOverviewInterface> },
  ) {}
}

export class DashboardOverviewFailure implements Action {
  public readonly type = ActionTypes.DashboardOverviewFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class DashboardOverviewByEvent implements Action {
  public readonly type = ActionTypes.DashboardOverviewByEvent;

  constructor(public payload: { eventId: string }) {}
}

export class DashboardOverviewByEventSuccess implements Action {
  public readonly type = ActionTypes.DashboardOverviewByEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<DashboardOverviewInterface> },
  ) {}
}

export class DashboardOverviewByEventFailure implements Action {
  public readonly type = ActionTypes.DashboardOverviewByEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class RemoveDashboardOverview implements Action {
  public readonly type = ActionTypes.RemoveDashboardOverview;
}

export class RemoveDashboardOverviewByEvent implements Action {
  public readonly type = ActionTypes.RemoveDashboardOverviewByEvent;
}

export class RecentOrder implements Action {
  public readonly type = ActionTypes.RecentOrder;
}

export class RecentOrderSuccess implements Action {
  public readonly type = ActionTypes.RecentOrderSuccess;

  constructor(
    public payload: { response: ResponseSuccess<RecentOrdersResponse> },
  ) {}
}

export class RecentOrderFailure implements Action {
  public readonly type = ActionTypes.RecentOrderFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class RecentOrderByEvent implements Action {
  public readonly type = ActionTypes.RecentOrderByEvent;

  constructor(public payload: { eventId: string }) {}
}

export class RecentOrderByEventSuccess implements Action {
  public readonly type = ActionTypes.RecentOrderByEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<RecentOrdersResponse> },
  ) {}
}

export class RecentOrderByEventFailure implements Action {
  public readonly type = ActionTypes.RecentOrderByEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | DashboardOverview
  | DashboardOverviewSuccess
  | DashboardOverviewFailure
  | DashboardOverviewByEvent
  | DashboardOverviewByEventSuccess
  | DashboardOverviewByEventFailure
  | RemoveDashboardOverview
  | RecentOrder
  | RecentOrderSuccess
  | RecentOrderFailure
  | RecentOrderByEvent
  | RecentOrderByEventSuccess
  | RecentOrderByEventFailure
  | RemoveDashboardOverviewByEvent;
