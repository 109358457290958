export interface State {
  entities: IComment[];
  count: number;
  loading: boolean;
  loaded: boolean;
  send: boolean;
}

export interface IAccount {
  id?: string;
  userId?: string;
  mouseliveId?: string;
  firstName?: string;
  lastName?: string;
  profilePhotoUrl?: string;
}

export interface IComment {
  organizerAccount?: IAccount;
  fanAccount?: IAccount;
  artistAccount?: IAccount;
  createdAt: Date;
  comment: string;
  accountType: 'Fan' | 'Organizer' | 'Artist';
}

export const initialState: State = {
  entities: null,
  count: 0,
  loading: false,
  loaded: false,
  send: false,
};
