import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { UserLogin } from '@app/models/client/user/user-login.model';
import { AuthResponseSuccess } from '@app/models/client/auth/auth-response-success';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';

/**
 * TODO: move to user.service.ts
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  /**
   * POST: `/user/login`
   * Login with one time password.
   *
   * @param user - Model `
   *  {
   *   "email": "string",
   *   "password": "string"
   *  }
   * `
   */
  signIn(user: UserLogin): Observable<AuthResponseSuccess> {
    return this.http.post<AuthResponseSuccess>(
      `${this.baseUrl}/user/login`,
      user,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  /**
   * POST: `/user/reset_password`
   * Reset password.
   *
   * @param email - email.
   */
  resetPassword(email: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.baseUrl}/user/reset_password`,
      { email },
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      },
    );
  }

  /**
   * POST: `/user/confirmation`
   * Reset password.
   *
   * @param code - confirmation code.
   * @param uuid - response string in resetPassword.
   */
  confirm(code: number, uuid: string): Observable<AuthResponseSuccess> {
    return this.http.post<AuthResponseSuccess>(
      `${this.baseUrl}/user/confirmation`,
      { code, uuid },
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      },
    );
  }

  /**
   * PUT: `/user/password`
   *
   * Update user password.
   */
  updatePassword(password: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/user/password`, { password });
  }

  refreshToken(): any {
    return this.http.post<any>(`${this.baseUrl}/user/refreshToken`, null);
  }
}
