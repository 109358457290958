import { EVenuePageStatus } from '@app/enums/venue/venue-page-status.enum';
import { VenueAccountCalendarItem } from '@app/models/client/venue/calendar/venue-account-calendar-item.model';
import { VenueAccountCalendarSettings } from '@app/models/client/venue/calendar/venue-account-calendar-settings.model';
import { EVenueProfileViewMode } from '@app/enums/venue/venue-profile-view-mode.enum';
import { VenueProfileResponse } from '@app/models/client/venue/venue-profile-response.model';
import { MessagesVenueResponse } from '@app/models/client/venue/messages-venue-response.model';
import { GetArtistMediaResponse } from '@app/models/client/artist/get-artist-media-response.model';
import { CreateVenueSteps } from '@app/models/client/venue/create-venue-steps.model';
import { VenueAccountResponse } from '@app/models/client/venue/venue-account-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';

export interface State {
  profile: VenueAccountResponse;
  profileById: VenueProfileResponse;
  loading: boolean;
  logined: boolean;
  updated: boolean;
  loaded: boolean;
  createVenueSteps: CreateVenueSteps;
  pageStatus: EVenuePageStatus;
  mediaResponse: GetArtistMediaResponse;
  calendarItems: VenueAccountCalendarItem[];
  calendarSettings: VenueAccountCalendarSettings;
  profileViewMode: EVenueProfileViewMode;
  messagesResponse: MessagesVenueResponse[];
  upcomingEventsResponse: GetEventsResponse;
  pastEventsResponse: GetEventsResponse;
}

export const initialState: State = {
  profile: null,
  profileById: null,
  loading: true,
  logined: false,
  updated: false,
  loaded: false,
  createVenueSteps: null,
  pageStatus: EVenuePageStatus.Profile,
  mediaResponse: null,
  calendarItems: [],
  calendarSettings: null,
  profileViewMode: EVenueProfileViewMode.Fan,
  messagesResponse: [],
  upcomingEventsResponse: null,
  pastEventsResponse: null,
};
