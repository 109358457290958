import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { GetArtistAccountBookingSettingsResponseSuccess } from '@app/models/client/artist/booking/get-artist-account-booking-settings-response-success.model';

import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { Response } from '@app/interfaces/response.interface';
import { ArtistService } from '@app/services/client/artist/artist.service';
import { SearchService } from '@app/services/client/search/search.service';
import { getUserTokenState } from '../user/selectors';
import {
  ActionTypes,
  AddCalendarItem,
  AddCalendarItemFailure,
  AddCalendarItemSuccess,
  CreateProfile,
  CreateProfileFailure,
  CreateProfileSuccess,
  DownloadPDF,
  DownloadPDFFailure,
  DownloadPDFSuccess,
  DownloadRiderBackstage,
  DownloadRiderBackstageFailure,
  DownloadRiderBackstageSuccess,
  DownloadRiderHospitality,
  DownloadRiderHospitalityFailure,
  DownloadRiderHospitalitySuccess,
  DownloadRiderStage,
  DownloadRiderStageFailure,
  DownloadRiderStageSuccess,
  DownloadRiderTechnical,
  DownloadRiderTechnicalFailure,
  DownloadRiderTechnicalSuccess,
  FollowAccount,
  FollowAccountFailure,
  FollowAccountSuccess,
  GetBookingSettings,
  GetBookingSettingsFailure,
  GetBookingSettingsSuccess,
  GetCalendar,
  GetCalendarFailure,
  GetCalendarSuccess,
  GetMedia,
  GetMediaFailure,
  GetMediaSuccess,
  GetPastEvents,
  GetPastEventsFailure,
  GetPastEventsSuccess,
  GetProfileByID,
  GetProfileByIDFailure,
  GetProfileByIDSuccess,
  GetProfileMessages,
  GetProfileMessagesFailure,
  GetProfileMessagesSuccess,
  GetRiders,
  GetRidersFailure,
  GetRidersSuccess,
  GetUpcomingEvents,
  GetUpcomingEventsFailure,
  GetUpcomingEventsSuccess,
  RemoveArtistPhoto,
  RemoveArtistPhotoFailure,
  RemoveArtistPhotoSuccess,
  RemoveCalendarItem,
  RemoveCalendarItemFailure,
  RemoveCalendarItemSuccess,
  RemoveMediaFile,
  RemoveMediaFileFailure,
  RemovePhotoSuccess,
  RemoveRiderBackstage,
  RemoveRiderBackstageFailure,
  RemoveRiderBackstageSuccess,
  RemoveRiderHospitality,
  RemoveRiderHospitalityFailure,
  RemoveRiderHospitalitySuccess,
  RemoveRiderStage,
  RemoveRiderStageFailure,
  RemoveRiderStageSuccess,
  RemoveRiderTechnical,
  RemoveRiderTechnicalFailure,
  RemoveRiderTechnicalSuccess,
  RemoveVideoSuccess,
  ReviewProfile,
  ReviewProfileFailure,
  ReviewProfileSuccess,
  SetProfilePhoto,
  SetProfilePhotoFailure,
  SetProfilePhotoSuccess,
  UnfollowAccount,
  UnfollowAccountFailure,
  UnfollowAccountSuccess,
  UpdateCalendarSettings,
  UpdateCalendarSettingsFailure,
  UpdateCalendarSettingsSuccess,
  UpdateProfile,
  UpdateProfileFailure,
  UpdateProfileSuccess,
  UpdateRiderInfo,
  UpdateRiderInfoFailure,
  UpdateRiderInfoSuccess,
  UploadArtistPhoto,
  UploadArtistPhotoFailure,
  UploadArtistPhotoSuccess,
  UploadBookingSettings,
  UploadBookingSettingsFailure,
  UploadBookingSettingsSuccess,
  UploadRiderBackstage,
  UploadRiderBackstageFailure,
  UploadRiderBackstageSuccess,
  UploadRiderHospitality,
  UploadRiderHospitalityFailure,
  UploadRiderHospitalitySuccess,
  UploadRiderStage,
  UploadRiderStageFailure,
  UploadRiderStageSuccess,
  UploadRiderTechnical,
  UploadRiderTechnicalFailure,
  UploadRiderTechnicalSuccess,
  UploadVideo,
  UploadVideoFailure,
  UploadVideoSuccess,
} from './artist.actions';
import { getProfileState } from './artist.selectors';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { MessagesArtistResponseSuccess } from '@app/models/client/artist/messages-artist-response-success.model';
import { RootStoreState } from '@app/store/root';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { getProfileIDState } from '@app/store/root/client/artist/artist.selectors';
import { GetArtistMediaResponse } from '@app/models/client/artist/get-artist-media-response.model';
import { GetArtistAccountCalendarResponse } from '@app/models/client/artist/calendar/get-artist-account-calendar-response.model';
import { ArtistAccountCalendarItem } from '@app/models/client/artist/calendar/artist-account-calendar-item.model';
import { AccountType } from '@app/models/admin/accounts/account-type.enum';
import { AppStoreActions } from '@store/root/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ProfileStoreActions } from '@store/root/client/profile';

@Injectable()
export class ArtistEffects {
  createProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProfile>(ActionTypes.CreateProfile),
      switchMap((action) =>
        this.artistService.createArtistProfile(action.payload.model).pipe(
          tap(async () => {
            const user = await this.auth.user.toPromise();
            await user.getIdToken(true);
          }),
          map((response: Response) => new CreateProfileSuccess()),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new CreateProfileFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  uploadVideo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadVideo>(ActionTypes.UploadVideo),
      switchMap((action) =>
        this.artistService
          .uploadVideo(action.payload.link, action.payload.title)
          .pipe(
            map(
              (response: any) =>
                new UploadVideoSuccess({ videos: response['videos'] }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new UploadVideoFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  removeMediaFile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveMediaFile>(ActionTypes.RemoveMediaFile),
      switchMap((action) =>
        this.artistService
          .deleteEventMedia(action.payload.url, action.payload.type)
          .pipe(
            tap(console.log),
            map((response) => {
              if (response?.photos) {
                return new RemovePhotoSuccess({ photos: response.photos });
              } else if (response?.videos) {
                return new RemoveVideoSuccess({ videos: response.videos });
              }
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new RemoveMediaFileFailure({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getProfileByID$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfileByID>(ActionTypes.GetProfileByID),
      switchMap((action) =>
        this.artistService.getProfileByID(action.payload.id).pipe(
          map(
            (response) => new GetProfileByIDSuccess({ profile: response.data }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetProfileByIDFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadPDF$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadPDF>(ActionTypes.DownloadPDF),
      switchMap((action) =>
        this.artistService
          .getPDF(action.payload.url, action.payload.fileName)
          .pipe(
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new DownloadPDFFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  reviewProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ReviewProfile>(ActionTypes.ReviewProfile),
      switchMap(() =>
        this.artistService.sendOnReview().pipe(
          map((response: Response) => new ReviewProfileSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new ReviewProfileFailure({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfile>(ActionTypes.UpdateProfile),
      switchMap((action) =>
        this.artistService.updateArtistProfile(action.payload.model).pipe(
          map((response: Response) => new UpdateProfileSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateProfileFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  setProfilePhoto$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SetProfilePhoto>(ActionTypes.SetProfilePhoto),
      switchMap((action) =>
        this.artistService.setProfilePhoto(action.payload.image).pipe(
          switchMap((response: Response) => [
            new SetProfilePhotoSuccess({ response }),
            new ProfileStoreActions.GetProfiles(),
          ]),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new SetProfilePhotoFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  uploadArtistPhoto$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadArtistPhoto>(ActionTypes.UploadArtistPhoto),
      switchMap((action) =>
        this.artistService
          .uploadArtistPhoto(
            action.payload.image,
            action.payload.description,
            action.payload.title,
          )
          .pipe(
            map(
              (response: Response) =>
                new UploadArtistPhotoSuccess({ response }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new UploadArtistPhotoFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  removeArtistPhoto$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveArtistPhoto>(ActionTypes.RemoveArtistPhoto),
      switchMap((action) =>
        this.artistService.removeArtistPhoto(action.payload.photoId).pipe(
          switchMap((response: Response) => [
            new RemoveArtistPhotoSuccess({ response }),
            new ProfileStoreActions.GetProfiles(),
          ]),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new RemoveArtistPhotoFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles get media action.
   * @action - [Artist] GetMedia
   * @return:
   *   Success: [Artist] GetMediaSuccess
   *   Failure: [Artist] GetMediaFailure & [Errors] AddErrorResponse
   */
  getMedia$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetMedia>(ActionTypes.GetMedia),
      withLatestFrom(
        this.store.pipe(select(getUserTokenState)),
        this.store.pipe(select(getProfileIDState)),
      ),
      switchMap(([action, token, id]) => {
        let artistID = '';
        if (action.payload) {
          artistID = action.payload.artistID;
        } else {
          artistID = id;
        }
        return this.artistService.getMedia(artistID).pipe(
          map(
            (response: ResponseSuccess<GetArtistMediaResponse>) =>
              new GetMediaSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetMediaFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        );
      }),
    ),
  );

  /*** Calendar ***/

  getCalendar$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCalendar>(ActionTypes.GetCalendar),
      withLatestFrom(
        this.store.select(getUserTokenState),
        this.store.select(getProfileState),
      ),
      switchMap(([action, token, profile]) =>
        this.artistService
          .getCalendar(profile.id, action.payload.from, action.payload.to)
          .pipe(
            map(
              (response: ResponseSuccess<GetArtistAccountCalendarResponse>) =>
                new GetCalendarSuccess({ response }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetCalendarFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  addCalendarItem$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AddCalendarItem>(ActionTypes.AddCalendarItem),
      switchMap((action) =>
        this.artistService.addCalendarItem({ day: action.payload.day }).pipe(
          map(
            (response: ResponseSuccess<ArtistAccountCalendarItem>) =>
              new AddCalendarItemSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new AddCalendarItemFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  removeCalendarItem$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveCalendarItem>(ActionTypes.RemoveCalendarItem),
      switchMap((action) =>
        this.artistService.removeCalendarItem(action.payload.id).pipe(
          map(
            (response: Response) => new RemoveCalendarItemSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new RemoveCalendarItemFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateCalendarSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCalendarSettings>(ActionTypes.UpdateCalendarSettings),
      switchMap((action) =>
        this.artistService.updateCalendarSettings(action.payload).pipe(
          map(
            (response: Response) =>
              new UpdateCalendarSettingsSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateCalendarSettingsFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /*** Booking ***/

  uploadBookingSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadBookingSettings>(ActionTypes.UploadBookingSettings),
      switchMap((action) =>
        this.artistService.updateBookingSettings(action.payload.settings).pipe(
          map(
            (response: Response) =>
              new UploadBookingSettingsSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UploadBookingSettingsFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getBookingSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetBookingSettings>(ActionTypes.GetBookingSettings),
      switchMap((action) =>
        this.artistService.getBookingSettings().pipe(
          map(
            (response: GetArtistAccountBookingSettingsResponseSuccess) =>
              new GetBookingSettingsSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetBookingSettingsFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /*** Riders ***/

  getRiders$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetRiders>(ActionTypes.GetRiders),
      switchMap((action) =>
        this.artistService.getRiders().pipe(
          map((response: Response) => new GetRidersSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetRidersFailure({ response }),
                // // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  // Backstage

  uploadRiderBackstage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadRiderBackstage>(ActionTypes.UploadRiderBackstage),
      switchMap((action) =>
        this.artistService.uploadRiderBackstage(action.payload.file).pipe(
          map(
            (response: Response) =>
              new UploadRiderBackstageSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UploadRiderBackstageFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  removeRiderBackstage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveRiderBackstage>(ActionTypes.RemoveRiderBackstage),
      switchMap((action) =>
        this.artistService.removeRiderBackstage().pipe(
          map(
            (response: Response) =>
              new RemoveRiderBackstageSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new RemoveRiderBackstageFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadRiderBackstage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadRiderBackstage>(ActionTypes.DownloadRiderBackstage),
      switchMap((action) =>
        this.artistService.downloadRiderBackstage().pipe(
          map(
            (response: Response) =>
              new DownloadRiderBackstageSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new DownloadRiderBackstageFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadRiderBackstageSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadRiderBackstageSuccess>(
        ActionTypes.DownloadRiderBackstageSuccess,
      ),
      switchMap((action) =>
        this.artistService
          .getPDF(
            action.payload.response.data.File.URL,
            action.payload.response.data.File.filename,
          )
          .pipe(
            map((response: Response) => new DownloadPDFSuccess({ response })),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new DownloadPDFFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  // Hospitality

  uploadRiderHospitality$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadRiderHospitality>(ActionTypes.UploadRiderHospitality),
      switchMap((action) =>
        this.artistService.uploadRiderHospitality(action.payload.file).pipe(
          map(
            (response: Response) =>
              new UploadRiderHospitalitySuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UploadRiderHospitalityFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  removeRiderHospitality$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveRiderHospitality>(ActionTypes.RemoveRiderHospitality),
      switchMap((action) =>
        this.artistService.removeRiderHospitality().pipe(
          map(
            (response: Response) =>
              new RemoveRiderHospitalitySuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new RemoveRiderHospitalityFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadRiderHospitality$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadRiderHospitality>(ActionTypes.DownloadRiderHospitality),
      switchMap((action) =>
        this.artistService.downloadRiderHospitality().pipe(
          map(
            (response: Response) =>
              new DownloadRiderHospitalitySuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new DownloadRiderHospitalityFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadRiderHospitalitySuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadRiderHospitalitySuccess>(
        ActionTypes.DownloadRiderHospitalitySuccess,
      ),
      switchMap((action) =>
        this.artistService
          .getPDF(
            action.payload.response.data.File.URL,
            action.payload.response.data.File.filename,
          )
          .pipe(
            map((response: Response) => new DownloadPDFSuccess({ response })),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new DownloadPDFFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  // Stage

  uploadRiderStage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadRiderStage>(ActionTypes.UploadRiderStage),
      switchMap((action) =>
        this.artistService.uploadRiderStage(action.payload.file).pipe(
          map(
            (response: Response) => new UploadRiderStageSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UploadRiderStageFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  removeRiderStage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveRiderStage>(ActionTypes.RemoveRiderStage),
      switchMap((action) =>
        this.artistService.removeRiderStage().pipe(
          map(
            (response: Response) => new RemoveRiderStageSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new RemoveRiderStageFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadRiderStage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadRiderStage>(ActionTypes.DownloadRiderStage),
      switchMap((action) =>
        this.artistService.downloadRiderStage().pipe(
          map(
            (response: Response) => new DownloadRiderStageSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new DownloadRiderStageFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadRiderStageSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadRiderStageSuccess>(ActionTypes.DownloadRiderStageSuccess),
      switchMap((action) =>
        this.artistService
          .getPDF(
            action.payload.response.data.File.URL,
            action.payload.response.data.File.filename,
          )
          .pipe(
            map((response: Response) => new DownloadPDFSuccess({ response })),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new DownloadPDFFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  updateRiderInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateRiderInfo>(ActionTypes.UpdateRiderInfo),
      switchMap((action) =>
        this.artistService.updateRiderInfo(action.payload.rider).pipe(
          map((response: Response) => new UpdateRiderInfoSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateRiderInfoFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  // Technical

  uploadRiderTechnical$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadRiderTechnical>(ActionTypes.UploadRiderTechnical),
      switchMap((action) =>
        this.artistService.uploadRiderTechnical(action.payload.file).pipe(
          map(
            (response: Response) =>
              new UploadRiderTechnicalSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UploadRiderTechnicalFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  removeRiderTechnical$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveRiderTechnical>(ActionTypes.RemoveRiderTechnical),
      switchMap((action) =>
        this.artistService.removeRiderTechnical().pipe(
          map(
            (response: Response) =>
              new RemoveRiderTechnicalSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new RemoveRiderTechnicalFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadRiderTechnical$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadRiderTechnical>(ActionTypes.DownloadRiderTechnical),
      switchMap((action) =>
        this.artistService.downloadRiderTechnical().pipe(
          map(
            (response: Response) =>
              new DownloadRiderTechnicalSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new DownloadRiderTechnicalFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  downloadRiderTechnicalSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadRiderTechnicalSuccess>(
        ActionTypes.DownloadRiderTechnicalSuccess,
      ),
      switchMap((action) =>
        this.artistService
          .getPDF(
            action.payload.response.data.File.URL,
            action.payload.response.data.File.filename,
          )
          .pipe(
            map((response: Response) => new DownloadPDFSuccess({ response })),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new DownloadPDFFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  /*** Upcoming events ***/

  getUpcomingEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetUpcomingEvents>(ActionTypes.GetUpcomingEvents),
      withLatestFrom(
        this.store.select(getUserTokenState),
        this.store.select(getProfileState),
      ),
      switchMap(([action]) => {
        return this.searchService
          .searchEvent(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
          )
          .pipe(
            map(
              (response: ResponseSuccess<GetEventsResponse>) =>
                new GetUpcomingEventsSuccess({ response }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetUpcomingEventsFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          );
      }),
    ),
  );

  getPastEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPastEvents>(ActionTypes.GetPastEvents),
      withLatestFrom(
        this.store.select(getUserTokenState),
        this.store.select(getProfileState),
      ),
      switchMap(([action]) => {
        return this.searchService
          .searchEvent(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
          )
          .pipe(
            map(
              (response: ResponseSuccess<GetEventsResponse>) =>
                new GetPastEventsSuccess({ response }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetPastEventsFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          );
      }),
    ),
  );

  /***** Messages *****/

  getMessages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfileMessages>(ActionTypes.GetProfileMessages),
      withLatestFrom(this.store.select(getUserTokenState)),
      switchMap(() =>
        this.artistService.getMessages().pipe(
          map(
            (response: MessagesArtistResponseSuccess) =>
              new GetProfileMessagesSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetProfileMessagesFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  followAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FollowAccount>(ActionTypes.FollowAccount),
      switchMap((action) => {
        switch (action.payload.accountType) {
          case AccountType.FAN:
            return this.artistService
              .followUpFanAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      // new AppStoreActions.AddErrorResponse({response})
                    ]),
                  ),
                ),
              );
          case AccountType.ARTIST:
            return this.artistService
              .followUpArtistAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      // new AppStoreActions.AddErrorResponse({response})
                    ]),
                  ),
                ),
              );
          case AccountType.VENUE:
            return this.artistService
              .followUpVenueAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      // new AppStoreActions.AddErrorResponse({response})
                    ]),
                  ),
                ),
              );
          case AccountType.ORGANIZER:
            return this.artistService
              .followUpOrganizerAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      // new AppStoreActions.AddErrorResponse({response})
                    ]),
                  ),
                ),
              );
        }
      }),
    ),
  );

  unfollowAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UnfollowAccount>(ActionTypes.UnfollowAccount),
      switchMap((action) => {
        switch (action.payload.accountType) {
          case AccountType.FAN:
            return this.artistService
              .unfollowUpFanAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      // new AppStoreActions.AddErrorResponse({response})
                    ]),
                  ),
                ),
              );
          case AccountType.ARTIST:
            return this.artistService
              .unfollowUpArtistAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      // new AppStoreActions.AddErrorResponse({response})
                    ]),
                  ),
                ),
              );
          case AccountType.VENUE:
            return this.artistService
              .unfollowUpVenueAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      // new AppStoreActions.AddErrorResponse({response})
                    ]),
                  ),
                ),
              );
          case AccountType.ORGANIZER:
            return this.artistService
              .unfollowUpOrganizerAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      // new AppStoreActions.AddErrorResponse({response})
                    ]),
                  ),
                ),
              );
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private artistService: ArtistService,
    private searchService: SearchService,
    private auth: AngularFireAuth,
  ) {}
}
