import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as UserStoreSelectors from '../user/selectors';
import { getUserTokenState } from '../user/selectors';
import * as VenueStoreSelectors from './selectors';
import {
  ActionTypes,
  CreateProfile,
  CreateProfileFailure,
  CreateProfileSuccess,
  DeletePhoto,
  DeletePhotoFailure,
  DeletePhotoSuccess,
  GetPastEventsFailure,
  GetPastEventsSuccess,
  GetProfile,
  GetProfileByID,
  GetProfileByIDFailure,
  GetProfileByIDSuccess,
  GetProfileFailure,
  GetProfileSuccess,
  ReviewProfile,
  ReviewProfileFailure,
  ReviewProfileSuccess,
  SetProfilePhoto,
  SetProfilePhotoFailure,
  SetProfilePhotoSuccess,
  UpdateAbout,
  UpdateAboutFailure,
  UpdateAboutSuccess,
  UpdateHours,
  UpdateHoursFailure,
  UpdateHoursSuccess,
  UpdateListingDetails,
  UpdateListingDetailsFailure,
  UpdateListingDetailsSuccess,
  UpdateMedia,
  UpdateMediaFailure,
  UpdateMediaSuccess,
  UploadPhoto,
  UploadPhotoFailure,
  UploadPhotoSuccess,
} from './actions';
import { VenueService } from '@app/services/client/venue/venue.service';
import { Response } from '@app/interfaces/response.interface';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import {
  AddCalendarItem,
  AddCalendarItemFailure,
  AddCalendarItemSuccess,
  GetCalendar,
  GetCalendarFailure,
  GetCalendarSuccess,
  GetMedia,
  GetMediaFailure,
  GetMediaSuccess,
  GetProfileMessages,
  GetProfileMessagesFailure,
  GetProfileMessagesSuccess,
  GetUpcomingEvents,
  GetUpcomingEventsFailure,
  GetUpcomingEventsSuccess,
  RemoveCalendarItem,
  RemoveCalendarItemFailure,
  RemoveCalendarItemSuccess,
  UpdateCalendarSettings,
  UpdateCalendarSettingsFailure,
  UpdateCalendarSettingsSuccess,
} from '@app/store/root/client/venue/actions';
import { VenueProfileResponse } from '@app/models/client/venue/venue-profile-response.model';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { MessagesVenueResponseSuccess } from '@app/models/client/venue/messages-venue-response-success.model';
import { RootStoreState } from '@app/store/root';
import {
  getProfileIDState,
  getProfileState,
} from '@app/store/root/client/venue/selectors';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { GetArtistMediaResponse } from '@app/models/client/artist/get-artist-media-response.model';
import { GetVenueAccountInfoResponseSuccess } from '@app/models/client/venue/get-venue-account-info-response-success.model';
import { VenueAccountCalendarItem } from '@app/models/client/venue/calendar/venue-account-calendar-item.model';
import { GetVenueAccountCalendarResponse } from '@app/models/client/venue/calendar/get-venue-account-calendar-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';

@Injectable()
export class VenueEffects {
  createProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProfile>(ActionTypes.CreateProfile),
      switchMap((action) =>
        this.venueService.createVenueProfile(action.payload.model).pipe(
          map((response: Response) => new CreateProfileSuccess()),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new CreateProfileFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  reviewProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ReviewProfile>(ActionTypes.ReviewProfile),
      switchMap(() =>
        this.venueService.sendOnReview().pipe(
          map((response: Response) => new ReviewProfileSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new ReviewProfileFailure({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfile>(ActionTypes.GetProfile),
      switchMap((action) =>
        this.venueService.getVenueProfile().pipe(
          switchMap((response: GetVenueAccountInfoResponseSuccess) => [
            new GetProfileSuccess({ response }),
          ]),
          catchError((response: IErrorResponse) =>
            of(new GetProfileFailure({ response })),
          ),
        ),
      ),
    ),
  );

  getProfileById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfileByID>(ActionTypes.GetProfileByID),
      switchMap((action) =>
        this.venueService.getVenueProfileById(action.payload.id).pipe(
          map(
            (response: ResponseSuccess<VenueProfileResponse>) =>
              new GetProfileByIDSuccess({ response }),
          ),
          catchError((response: ResponseError) =>
            of(new GetProfileByIDFailure({ response })),
          ),
        ),
      ),
    ),
  );

  /*** Upcoming events ***/

  getUpcomingEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetUpcomingEvents>(ActionTypes.GetUpcomingEvents),
      withLatestFrom(
        this.store.select(getUserTokenState),
        this.store.select(getProfileState),
      ),
      switchMap(([action, token, profileState]) => {
        let id = '';
        if (action.payload) {
          id = action.payload.id;
        } else {
          id = profileState.mouseliveID;
        }

        return this.venueService.getUpcomingEvents(id).pipe(
          map(
            (response: ResponseSuccess<GetEventsResponse>) =>
              new GetUpcomingEventsSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetUpcomingEventsFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        );
      }),
    ),
  );

  /*** Past events ***/

  getPastEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetUpcomingEvents>(ActionTypes.GetPastEvents),
      withLatestFrom(
        this.store.select(getUserTokenState),
        this.store.select(getProfileState),
      ),
      switchMap(([action, token, profileState]) => {
        let id = '';
        if (action.payload) {
          id = action.payload.id;
        } else {
          id = profileState.mouseliveID;
        }

        return this.venueService.getPastEvents(id).pipe(
          map(
            (response: ResponseSuccess<GetEventsResponse>) =>
              new GetPastEventsSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetPastEventsFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        );
      }),
    ),
  );

  updateAbout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAbout>(ActionTypes.UpdateAbout),
      switchMap((action) =>
        this.venueService.updateAbout(action.payload.model).pipe(
          map((response: Response) => {
            return new UpdateAboutSuccess({ response });
          }),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateAboutFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateHours$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateHours>(ActionTypes.UpdateHours),
      switchMap((action) =>
        this.venueService.updateHours(action.payload.model).pipe(
          map((response: Response) => new UpdateHoursSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateHoursFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateListingDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateListingDetails>(ActionTypes.UpdateListingDetails),
      switchMap((action) =>
        this.venueService.updateListingDetails(action.payload.model).pipe(
          map(
            (response: Response) =>
              new UpdateListingDetailsSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateListingDetailsFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles get media action.
   *
   * @action - [Venue] GetMedia
   *
   * @return:
   *   Success: [Venue] GetMediaSuccess
   *   Failure: [Venue] GetMediaFailure & [Errors] AddErrorResponse
   */
  getMedia$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetMedia>(ActionTypes.GetMedia),
      withLatestFrom(
        this.store.pipe(select(getUserTokenState)),
        this.store.pipe(select(getProfileIDState)),
      ),
      switchMap(([action, token, id]) => {
        let venueID = '';
        if (action.payload) {
          venueID = action.payload.venueID;
        } else {
          venueID = id;
        }
        return this.venueService.getMedia(venueID).pipe(
          map(
            (response: ResponseSuccess<GetArtistMediaResponse>) =>
              new GetMediaSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetMediaFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        );
      }),
    ),
  );

  updateMedia$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateMedia>(ActionTypes.UpdateMedia),
      switchMap((action) =>
        this.venueService.updateMedia(action.payload.model).pipe(
          map((response: Response) => new UpdateMediaSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateMediaFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  uploadPhoto$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadPhoto>(ActionTypes.UploadPhoto),
      switchMap((action) =>
        this.venueService.uploadPhoto(action.payload.model).pipe(
          map((response: Response) => new UploadPhotoSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UploadPhotoFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  deletePhoto: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeletePhoto>(ActionTypes.DeletePhoto),
      switchMap((action) =>
        this.venueService.deletePhoto(action.payload.id).pipe(
          map((response: Response) => new DeletePhotoSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new DeletePhotoFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /*** Calendar ***/

  getCalendar$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCalendar>(ActionTypes.GetCalendar),
      withLatestFrom(
        this.store.select(UserStoreSelectors.getUserTokenState),
        this.store.select(VenueStoreSelectors.getProfileState),
      ),
      switchMap(([action, token, profile]) =>
        this.venueService
          .getCalendar(profile.id, action.payload.from, action.payload.to)
          .pipe(
            map(
              (response: ResponseSuccess<GetVenueAccountCalendarResponse>) =>
                new GetCalendarSuccess({ response }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetCalendarFailure({ response }),
                  // new AppStoreActions.AddErrorResponse({response})
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  addCalendarItem$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AddCalendarItem>(ActionTypes.AddCalendarItem),
      switchMap((action) =>
        this.venueService.addCalendarItem({ day: action.payload.day }).pipe(
          map(
            (response: ResponseSuccess<VenueAccountCalendarItem>) =>
              new AddCalendarItemSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new AddCalendarItemFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  removeCalendarItem$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveCalendarItem>(ActionTypes.RemoveCalendarItem),
      switchMap((action) =>
        this.venueService.removeCalendarItem(action.payload.id).pipe(
          map(
            (response: Response) => new RemoveCalendarItemSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new RemoveCalendarItemFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateCalendarSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCalendarSettings>(ActionTypes.UpdateCalendarSettings),
      switchMap((action) =>
        this.venueService.updateDatesSettings(action.payload.model).pipe(
          map(
            (response: Response) =>
              new UpdateCalendarSettingsSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateCalendarSettingsFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  setProfilePhoto$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<SetProfilePhoto>(ActionTypes.SetProfilePhoto),
      switchMap((action) =>
        this.venueService.uploadProfilePhoto(action.payload.image).pipe(
          switchMap((response: Response) => [
            new SetProfilePhotoSuccess({ response }),
            new GetProfile(),
          ]),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new SetProfilePhotoFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /***** Messages *****/

  getMessages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfileMessages>(ActionTypes.GetProfileMessages),
      withLatestFrom(this.store.select(getUserTokenState)),
      switchMap(() =>
        this.venueService.getMessages().pipe(
          map(
            (response: MessagesVenueResponseSuccess) =>
              new GetProfileMessagesSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetProfileMessagesFailure({ response }),
                // new AppStoreActions.AddErrorResponse({response})
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private venueService: VenueService,
  ) {}
}
