import { initialState, State } from '@store/root/client/event/state';
import { Actions, ActionTypes } from '@store/root/client/event/actions';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    // Create Event
    case ActionTypes.CreateEvent: {
      return {
        ...state,
        loading: true,
        created: false,
        error: null,
      };
    }
    case ActionTypes.CreateEventSuccess: {
      return {
        ...state,
        event: action.payload.response.data,
        created: true,
        loading: false,
        error: null,
      };
    }
    case ActionTypes.RemoveEventFromState: {
      return {
        ...state,
        event: null,
        eventID: '',
        timeZoneId: '',
        donations: null,
        error: null,
      };
    }
    case ActionTypes.CreateEventFailure: {
      return {
        ...state,
        created: false,
        loading: false,
        error: action.payload.response,
      };
    }
    case ActionTypes.GetTimeZone: {
      return {
        ...state,
        timeZone: null,
        timeZoneId: null,
      };
    }
    case ActionTypes.GetTimeZoneSuccess: {
      return {
        ...state,
        timeZoneId: action.payload.response.timeZoneId,
        timeZone: action.payload.response,
      };
    }
    case ActionTypes.GetTimeZoneFailure: {
      return {
        ...state,
        timeZoneId: null,
        timeZone: null,
      };
    }
    // Update Event
    case ActionTypes.UpdateEvents: {
      return {
        ...state,
        loading: true,
        updated: false,
        error: null,
      };
    }
    case ActionTypes.UpdateEventsSuccess: {
      return {
        ...state,
        event: action.payload.response.data,
        updated: true,
        loading: false,
        error: null,
      };
    }
    case ActionTypes.UpdateEventsFailure: {
      return {
        ...state,
        updated: false,
        loading: false,
        error: action.payload.response,
      };
    }

    // Update Current Value
    case ActionTypes.UpdateCurrentValue: {
      return {
        ...state,
        loading: true,
        updated: false,
        error: null,
      };
    }
    case ActionTypes.UpdateCurrentValueSuccess: {
      return {
        ...state,
        event: action.payload.response.data,
        updated: true,
        loading: false,
        error: null,
      };
    }
    case ActionTypes.UpdateCurrentValueFailure: {
      return {
        ...state,
        updated: false,
        loading: false,
        error: action.payload.response,
      };
    }

    // Get Event By ID
    case ActionTypes.GetEventById: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ActionTypes.GetEventByIdSuccess: {
      return {
        ...state,
        event: action.payload.response.data,
        loading: false,
        error: null,
      };
    }
    case ActionTypes.GetEventByIdFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload.response,
      };
    }

    // Get Donations By Event ID
    case ActionTypes.GetDonationsByEventId: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ActionTypes.GetDonationsByEventIdSuccess: {
      return {
        ...state,
        donations: action.payload.response.data,
        loading: false,
        error: null,
      };
    }
    case ActionTypes.GetDonationsByEventIdFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload.response,
      };
    }

    // Remove Event By ID
    case ActionTypes.RemoveEventByID: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ActionTypes.RemoveEventByIDSuccess: {
      return {
        ...state,
        event: null,
        loading: false,
        error: null,
      };
    }
    case ActionTypes.RemoveEventByIDFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload.response,
      };
    }

    default: {
      return state;
    }
  }
}
