import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import { Observable } from 'rxjs';
import {
  DashboardOverviewInterface,
  RecentOrdersResponse,
} from '@models/client/dashboard/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * GET: `/api/organizer/dashboard-overview`
   * Get dashboard overview analytics.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  dashboardOverview(): Observable<ResponseSuccess<DashboardOverviewInterface>> {
    return this.http.get<ResponseSuccess<DashboardOverviewInterface>>(
      `${this.host}/api/v1/organizer/dashboard-overview`,
    );
  }

  /**
   * GET: `/api/organizer/dashboard-overview?event_id=${id}`
   * Get dashboard overview by event analytics.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  dashboardOverviewByEvent(
    id: string,
  ): Observable<ResponseSuccess<DashboardOverviewInterface>> {
    return this.http.get<ResponseSuccess<DashboardOverviewInterface>>(
      `${this.host}/api/v1/organizer/dashboard-overview?eventId=${id}`,
    );
  }

  /**
   * GET: `/api/organizer/recent-orders`
   * Get Recent Orders.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  getRecentOrders(): Observable<ResponseSuccess<RecentOrdersResponse>> {
    return this.http.get<ResponseSuccess<RecentOrdersResponse>>(
      `${this.host}/api/v1/organizer/recent-orders`,
    );
  }

  /**
   * GET: `/api/organizer/recent-orders`
   * Get Recent Orders.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  getRecentOrdersByEvent(
    id: string,
  ): Observable<ResponseSuccess<RecentOrdersResponse>> {
    return this.http.get<ResponseSuccess<RecentOrdersResponse>>(
      `${this.host}/api/v1/organizer/recent-orders?eventId=${id}`,
    );
  }
}
