/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateEventRequest } from '../models/create-or-update-event-request';
import { EventAboutResponseSuccess } from '../models/event-about-response-success';
import { EventAnalyticsResponse } from '../models/event-analytics-response';
import { EventPhotoResponse } from '../models/event-photo-response';
import { EventResponse } from '../models/event-response';
import { EventResponseSuccess } from '../models/event-response-success';
import { GetEventsResponse } from '../models/get-events-response';
import { GetGoingFanEventResponseSuccess } from '../models/get-going-fan-event-response-success';
import { GetGoingFansResponseSuccess } from '../models/get-going-fans-response-success';
import { GetOrganizerEventResponseSuccess } from '../models/get-organizer-event-response-success';
import { GetOrganizerEventsResponse } from '../models/get-organizer-events-response';
import { Photo } from '../models/photo';
import { ResponseSuccess } from '../models/response-success';
import { UpdateEventAboutRequest } from '../models/update-event-about-request';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getEvents
   */
  static readonly GetEventsPath = '/events';

  /**
   * Information about events.
   *
   * returns list of events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents$Response(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Format 2006-07-08
     */
    date_from?: string;

    /**
     * Format 2006-07-08
     */
    date_to?: string;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * Country
     */
    country?: string;

    /**
     * ZipCode
     */
    zipCode?: string;

    /**
     * Latitude
     */
    latitude?: number;

    /**
     * Longitude
     */
    longitude?: number;

    /**
     * Genres array. Example: &#x27;Rap,Rock&#x27;
     */
    genres?: Array<string>;

    /**
     * Ticket types array.  Example: &#x27;Bar,Theatre&#x27;
     */
    ticket_types?: Array<string>;

    /**
     * Venue types array.  Example: &#x27;VR,General&#x27;
     */
    venue_types?: Array<string>;

    /**
     * Distance in miles
     */
    distance?: number;

    /**
     * Order by a specific column
     */
    order?: string;
  }): Observable<StrictHttpResponse<EventResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetEventsPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.query('date_from', params.date_from, {});
      rb.query('date_to', params.date_to, {});
      rb.query('city', params.city, {});
      rb.query('state', params.state, {});
      rb.query('country', params.country, {});
      rb.query('zipCode', params.zipCode, {});
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('genres', params.genres, {});
      rb.query('ticket_types', params.ticket_types, {});
      rb.query('venue_types', params.venue_types, {});
      rb.query('distance', params.distance, {});
      rb.query('order', params.order, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventResponse>;
        }),
      );
  }

  /**
   * Information about events.
   *
   * returns list of events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Format 2006-07-08
     */
    date_from?: string;

    /**
     * Format 2006-07-08
     */
    date_to?: string;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * Country
     */
    country?: string;

    /**
     * ZipCode
     */
    zipCode?: string;

    /**
     * Latitude
     */
    latitude?: number;

    /**
     * Longitude
     */
    longitude?: number;

    /**
     * Genres array. Example: &#x27;Rap,Rock&#x27;
     */
    genres?: Array<string>;

    /**
     * Ticket types array.  Example: &#x27;Bar,Theatre&#x27;
     */
    ticket_types?: Array<string>;

    /**
     * Venue types array.  Example: &#x27;VR,General&#x27;
     */
    venue_types?: Array<string>;

    /**
     * Distance in miles
     */
    distance?: number;

    /**
     * Order by a specific column
     */
    order?: string;
  }): Observable<EventResponse> {
    return this.getEvents$Response(params).pipe(
      map((r: StrictHttpResponse<EventResponse>) => r.body as EventResponse),
    );
  }

  /**
   * Path part for operation createEvent
   */
  static readonly CreateEventPath = '/events';

  /**
   * Create event.
   *
   * Create event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEvent$Response(params: {
    /**
     * Get
     */
    body: CreateOrUpdateEventRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.CreateEventPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create event.
   *
   * Create event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEvent(params: {
    /**
     * Get
     */
    body: CreateOrUpdateEventRequest;
  }): Observable<ResponseSuccess> {
    return this.createEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getEventDetails
   */
  static readonly GetEventDetailsPath = '/events/{eventGUID}';

  /**
   * Get event by id.
   *
   * returns information about event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventDetails$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<EventResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetEventDetailsPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventResponseSuccess>;
        }),
      );
  }

  /**
   * Get event by id.
   *
   * returns information about event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventDetails(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<EventResponseSuccess> {
    return this.getEventDetails$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventResponseSuccess>) =>
          r.body as EventResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateEvent
   */
  static readonly UpdateEventPath = '/events/{eventGUID}';

  /**
   * Update event.
   *
   * Update event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEvent$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Get
     */
    body: CreateOrUpdateEventRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.UpdateEventPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update event.
   *
   * Update event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEvent(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Get
     */
    body: CreateOrUpdateEventRequest;
  }): Observable<ResponseSuccess> {
    return this.updateEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeEventById
   */
  static readonly RemoveEventByIdPath = '/events/{eventGUID}';

  /**
   * removes event.
   *
   * removes event by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeEventById()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventById$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.RemoveEventByIdPath,
      'delete',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * removes event.
   *
   * removes event by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeEventById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventById(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<ResponseSuccess> {
    return this.removeEventById$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getEventDetailsAbout
   */
  static readonly GetEventDetailsAboutPath = '/events/{eventGUID}/about';

  /**
   * Get event about by id.
   *
   * returns information about event about section
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventDetailsAbout()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventDetailsAbout$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<EventAboutResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetEventDetailsAboutPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventAboutResponseSuccess>;
        }),
      );
  }

  /**
   * Get event about by id.
   *
   * returns information about event about section
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventDetailsAbout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventDetailsAbout(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<EventAboutResponseSuccess> {
    return this.getEventDetailsAbout$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventAboutResponseSuccess>) =>
          r.body as EventAboutResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateEventAbout
   */
  static readonly UpdateEventAboutPath = '/events/{eventGUID}/about';

  /**
   * Update event about.
   *
   * Update event about
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEventAbout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEventAbout$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Get
     */
    body: UpdateEventAboutRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.UpdateEventAboutPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update event about.
   *
   * Update event about
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEventAbout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEventAbout(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Get
     */
    body: UpdateEventAboutRequest;
  }): Observable<ResponseSuccess> {
    return this.updateEventAbout$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getAnalyticsOfEvent
   */
  static readonly GetAnalyticsOfEventPath = '/events/{eventGUID}/analytics';

  /**
   * Get analytics of the event.
   *
   * Get analytics of the event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnalyticsOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnalyticsOfEvent$Response(params: {
    /**
     * eventGUID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<EventAnalyticsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetAnalyticsOfEventPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventAnalyticsResponse>;
        }),
      );
  }

  /**
   * Get analytics of the event.
   *
   * Get analytics of the event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAnalyticsOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnalyticsOfEvent(params: {
    /**
     * eventGUID
     */
    eventGUID: string;
  }): Observable<EventAnalyticsResponse> {
    return this.getAnalyticsOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventAnalyticsResponse>) =>
          r.body as EventAnalyticsResponse,
      ),
    );
  }

  /**
   * Path part for operation getGoingFans
   */
  static readonly GetGoingFansPath = '/events/{eventGUID}/going_fans';

  /**
   * Get fans who going to event.
   *
   * returns list of fan account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGoingFans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoingFans$Response(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<GetGoingFansResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetGoingFansPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetGoingFansResponseSuccess>;
        }),
      );
  }

  /**
   * Get fans who going to event.
   *
   * returns list of fan account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGoingFans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGoingFans(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<GetGoingFansResponseSuccess> {
    return this.getGoingFans$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetGoingFansResponseSuccess>) =>
          r.body as GetGoingFansResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getEventPhotos
   */
  static readonly GetEventPhotosPath = '/events/{eventGUID}/photos';

  /**
   * Get event photos.
   *
   * Get event photos
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventPhotos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventPhotos$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<Array<EventPhotoResponse>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetEventPhotosPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EventPhotoResponse>>;
        }),
      );
  }

  /**
   * Get event photos.
   *
   * Get event photos
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventPhotos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventPhotos(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<Array<EventPhotoResponse>> {
    return this.getEventPhotos$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<EventPhotoResponse>>) =>
          r.body as Array<EventPhotoResponse>,
      ),
    );
  }

  /**
   * Path part for operation uploadEventPhotoEvents
   */
  static readonly UploadEventPhotoEventsPath = '/events/{eventGUID}/photos';

  /**
   * Upload event photo.
   *
   * Upload event photo to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadEventPhotoEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadEventPhotoEvents$Response(params: {
    /**
     * multipart/form-data image&#x3D;file description&#x3D;text
     */
    query: string;

    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<Photo>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.UploadEventPhotoEventsPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Photo>;
        }),
      );
  }

  /**
   * Upload event photo.
   *
   * Upload event photo to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadEventPhotoEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadEventPhotoEvents(params: {
    /**
     * multipart/form-data image&#x3D;file description&#x3D;text
     */
    query: string;

    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<Photo> {
    return this.uploadEventPhotoEvents$Response(params).pipe(
      map((r: StrictHttpResponse<Photo>) => r.body as Photo),
    );
  }

  /**
   * Path part for operation removeEventPhoto
   */
  static readonly RemoveEventPhotoPath =
    '/events/{eventGUID}/photos/{photoGUID}';

  /**
   * Remove photo of event.
   *
   * Remove photo of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeEventPhoto()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventPhoto$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Photo ID
     */
    photoGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.RemoveEventPhotoPath,
      'delete',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('photoGUID', params.photoGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove photo of event.
   *
   * Remove photo of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeEventPhoto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventPhoto(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Photo ID
     */
    photoGUID: string;
  }): Observable<ResponseSuccess> {
    return this.removeEventPhoto$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadPosterPhotoEvent
   */
  static readonly UploadPosterPhotoEventPath =
    '/events/{eventGUID}/poster_photo';

  /**
   * Upload poster photo.
   *
   * Upload poster photo to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadPosterPhotoEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadPosterPhotoEvent$Response(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;

    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.UploadPosterPhotoEventPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Upload poster photo.
   *
   * Upload poster photo to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadPosterPhotoEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadPosterPhotoEvent(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;

    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<ResponseSuccess> {
    return this.uploadPosterPhotoEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeEventPhotoEvents
   */
  static readonly RemoveEventPhotoEventsPath =
    '/events/{eventGUID}/poster_photo';

  /**
   * Remove uploded poster photo.
   *
   * Remove uploded poster photo to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeEventPhotoEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventPhotoEvents$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.RemoveEventPhotoEventsPath,
      'delete',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove uploded poster photo.
   *
   * Remove uploded poster photo to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeEventPhotoEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeEventPhotoEvents(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<ResponseSuccess> {
    return this.removeEventPhotoEvents$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getOrganizerEvents
   */
  static readonly GetOrganizerEventsPath = '/organizer/events';

  /**
   * Information about organizer events.
   *
   * returns list of events with revenue and tickets count
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizerEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerEvents$Response(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Format 2006-07-08
     */
    'filter[date_from]'?: string;

    /**
     * Format 2006-07-08
     */
    'filter[date_to]'?: string;
  }): Observable<StrictHttpResponse<GetOrganizerEventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetOrganizerEventsPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.query('filter[date_from]', params['filter[date_from]'], {});
      rb.query('filter[date_to]', params['filter[date_to]'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetOrganizerEventsResponse>;
        }),
      );
  }

  /**
   * Information about organizer events.
   *
   * returns list of events with revenue and tickets count
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizerEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerEvents(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Format 2006-07-08
     */
    'filter[date_from]'?: string;

    /**
     * Format 2006-07-08
     */
    'filter[date_to]'?: string;
  }): Observable<GetOrganizerEventsResponse> {
    return this.getOrganizerEvents$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetOrganizerEventsResponse>) =>
          r.body as GetOrganizerEventsResponse,
      ),
    );
  }

  /**
   * Path part for operation getOrganizerEventDetails
   */
  static readonly GetOrganizerEventDetailsPath =
    '/organizer/events/{eventGUID}';

  /**
   * Get organizer event by id.
   *
   * returns information about event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizerEventDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerEventDetails$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<GetOrganizerEventResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetOrganizerEventDetailsPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetOrganizerEventResponseSuccess>;
        }),
      );
  }

  /**
   * Get organizer event by id.
   *
   * returns information about event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizerEventDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerEventDetails(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<GetOrganizerEventResponseSuccess> {
    return this.getOrganizerEventDetails$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetOrganizerEventResponseSuccess>) =>
          r.body as GetOrganizerEventResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation approveCrowdfunding
   */
  static readonly ApproveCrowdfundingPath =
    '/organizer/events/{eventGUID}/approve_crowdfunding';

  /**
   * Approve crowdfunding.
   *
   * Approve crowdfunding
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveCrowdfunding()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveCrowdfunding$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.ApproveCrowdfundingPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Approve crowdfunding.
   *
   * Approve crowdfunding
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approveCrowdfunding$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveCrowdfunding(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<ResponseSuccess> {
    return this.approveCrowdfunding$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getPastEvents
   */
  static readonly GetPastEventsPath = '/past_events';

  /**
   * Information about fan past events.
   *
   * returns list of events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPastEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPastEvents$Response(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Format 2006-07-08
     */
    date_from?: string;

    /**
     * Format 2006-07-08
     */
    date_to?: string;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * ZipCode
     */
    zipCode?: string;

    /**
     * Genres array. Example: &#x27;Rap,Rock&#x27;
     */
    genres?: string;

    /**
     * Ticket types array.  Example: &#x27;Bar,Theatre&#x27;
     */
    ticket_types?: string;

    /**
     * Venue types array.  Example: &#x27;VR,General&#x27;
     */
    venue_types?: string;
  }): Observable<StrictHttpResponse<GetEventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetPastEventsPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.query('date_from', params.date_from, {});
      rb.query('date_to', params.date_to, {});
      rb.query('city', params.city, {});
      rb.query('state', params.state, {});
      rb.query('zipCode', params.zipCode, {});
      rb.query('genres', params.genres, {});
      rb.query('ticket_types', params.ticket_types, {});
      rb.query('venue_types', params.venue_types, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventsResponse>;
        }),
      );
  }

  /**
   * Information about fan past events.
   *
   * returns list of events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPastEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPastEvents(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Format 2006-07-08
     */
    date_from?: string;

    /**
     * Format 2006-07-08
     */
    date_to?: string;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * ZipCode
     */
    zipCode?: string;

    /**
     * Genres array. Example: &#x27;Rap,Rock&#x27;
     */
    genres?: string;

    /**
     * Ticket types array.  Example: &#x27;Bar,Theatre&#x27;
     */
    ticket_types?: string;

    /**
     * Venue types array.  Example: &#x27;VR,General&#x27;
     */
    venue_types?: string;
  }): Observable<GetEventsResponse> {
    return this.getPastEvents$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventsResponse>) =>
          r.body as GetEventsResponse,
      ),
    );
  }

  /**
   * Path part for operation getEventByShortLink
   */
  static readonly GetEventByShortLinkPath = '/shows/{shortLink}';

  /**
   * Get event by short link.
   *
   * returns information about event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventByShortLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventByShortLink$Response(params: {
    /**
     * Short Link
     */
    shortLink: string;
  }): Observable<StrictHttpResponse<EventResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetEventByShortLinkPath,
      'get',
    );
    if (params) {
      rb.path('shortLink', params.shortLink, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventResponseSuccess>;
        }),
      );
  }

  /**
   * Get event by short link.
   *
   * returns information about event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventByShortLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventByShortLink(params: {
    /**
     * Short Link
     */
    shortLink: string;
  }): Observable<EventResponseSuccess> {
    return this.getEventByShortLink$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventResponseSuccess>) =>
          r.body as EventResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getRelevantEventsByTag
   */
  static readonly GetRelevantEventsByTagPath = '/tags/{tagGUID}/events';

  /**
   * Get events by tag id.
   *
   * returns information about events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRelevantEventsByTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRelevantEventsByTag$Response(params: {
    /**
     * Tag ID
     */
    tagGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<EventResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetRelevantEventsByTagPath,
      'get',
    );
    if (params) {
      rb.path('tagGUID', params.tagGUID, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventResponseSuccess>;
        }),
      );
  }

  /**
   * Get events by tag id.
   *
   * returns information about events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRelevantEventsByTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRelevantEventsByTag(params: {
    /**
     * Tag ID
     */
    tagGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<EventResponseSuccess> {
    return this.getRelevantEventsByTag$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventResponseSuccess>) =>
          r.body as EventResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getUpcomingEvents
   */
  static readonly GetUpcomingEventsPath = '/upcoming_events';

  /**
   * Information about fan upcoming events.
   *
   * returns list of events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUpcomingEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpcomingEvents$Response(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Format 2006-07-08
     */
    date_from?: string;

    /**
     * Format 2006-07-08
     */
    date_to?: string;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * ZipCode
     */
    zipCode?: string;

    /**
     * Genres array. Example: &#x27;Rap,Rock&#x27;
     */
    genres?: string;

    /**
     * Ticket types array.  Example: &#x27;Bar,Theatre&#x27;
     */
    ticket_types?: string;

    /**
     * Venue types array.  Example: &#x27;VR,General&#x27;
     */
    venue_types?: string;

    /**
     * Latitude
     */
    latitude?: string;

    /**
     * Longitude
     */
    longitude?: string;

    /**
     * Distance
     */
    distance?: string;
  }): Observable<StrictHttpResponse<GetGoingFanEventResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetUpcomingEventsPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.query('date_from', params.date_from, {});
      rb.query('date_to', params.date_to, {});
      rb.query('city', params.city, {});
      rb.query('state', params.state, {});
      rb.query('zipCode', params.zipCode, {});
      rb.query('genres', params.genres, {});
      rb.query('ticket_types', params.ticket_types, {});
      rb.query('venue_types', params.venue_types, {});
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('distance', params.distance, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetGoingFanEventResponseSuccess>;
        }),
      );
  }

  /**
   * Information about fan upcoming events.
   *
   * returns list of events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUpcomingEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpcomingEvents(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Format 2006-07-08
     */
    date_from?: string;

    /**
     * Format 2006-07-08
     */
    date_to?: string;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * ZipCode
     */
    zipCode?: string;

    /**
     * Genres array. Example: &#x27;Rap,Rock&#x27;
     */
    genres?: string;

    /**
     * Ticket types array.  Example: &#x27;Bar,Theatre&#x27;
     */
    ticket_types?: string;

    /**
     * Venue types array.  Example: &#x27;VR,General&#x27;
     */
    venue_types?: string;

    /**
     * Latitude
     */
    latitude?: string;

    /**
     * Longitude
     */
    longitude?: string;

    /**
     * Distance
     */
    distance?: string;
  }): Observable<GetGoingFanEventResponseSuccess> {
    return this.getUpcomingEvents$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetGoingFanEventResponseSuccess>) =>
          r.body as GetGoingFanEventResponseSuccess,
      ),
    );
  }
}
