import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { EventResponse } from '@app/models/client/events/event-response.model';
import { EventPageStatus } from '@app/enums/events/event-page-status.enum';
import { CreateEventSteps } from '@app/models/client/events/create-event-steps.model';
import { EventArtistsResponse } from '@app/models/client/events/event-artists-response.model';
import { IEventVenuesResponse } from '@app/models/client/events/event-venues-response.model';
import { EventAnalyticsResponse } from '@app/models/client/events/analytics/event-analytics-response.model';
import { VenuePrivateInfoOfEvent } from '@app/models/client/events/get-venue-private-info-of-event.model';
import { ArtistPrivateInfoOfEvent } from '@app/models/client/events/get-artist-private-info-of-event.model';
import { EventArtistResponse } from '@app/models/client/events/event-artist-response.model';

export interface State {
  eventsDataResponse: GetEventsResponse;
  event: EventResponse;
  photos: any;
  videos: any;
  poster: any;
  loading: boolean;
  loaded: boolean;
  fanUpcomingEvents: any;
  fanPastEvents: any;
  toReview: boolean;
  saveEvent: boolean;
  eventID: string;
  createEventSteps: CreateEventSteps;
  pageStatus: EventPageStatus;
  artistsToEventResp: EventArtistsResponse;
  allowedArtistsResp: EventArtistResponse[];
  venuesToEventResp: IEventVenuesResponse;
  eventAnalyticsResponse: EventAnalyticsResponse;
  venuePrivateInfoOfEvent: VenuePrivateInfoOfEvent;
  artistPrivateInfoOfEvent: ArtistPrivateInfoOfEvent;
  timeZone: string;
  updated: boolean;
  created: boolean;
}

export const initialState: State = {
  eventsDataResponse: null,
  event: null,
  photos: [],
  videos: [],
  toReview: false,
  poster: null,
  saveEvent: false,
  loading: false,
  loaded: false,
  fanUpcomingEvents: null,
  fanPastEvents: null,
  eventID: '',
  createEventSteps: null,
  pageStatus: EventPageStatus.Create,
  artistsToEventResp: null,
  allowedArtistsResp: null,
  venuesToEventResp: null,
  eventAnalyticsResponse: null,
  venuePrivateInfoOfEvent: null,
  artistPrivateInfoOfEvent: null,
  timeZone: '',
  updated: false,
  created: false,
};
