import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getSidebarState = (state: RootStoreState.State) =>
  state.client.sidebar;

export const getSidebarOpenState = createSelector(
  getSidebarState,
  (state: State) => state.open,
);
