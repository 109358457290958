import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { GetFanTicketsResponseSuccess } from '@app/models/client/tickets/get-fan-tickets-response-success.model';
import { APIService } from '@app/interfaces/api-service.interface';
import { GiveTicketRequest } from '@app/models/client/tickets/give-ticket-request.model';
import { CreateTicketRequest } from '@app/models/client/tickets/create-ticket-request.model';
import { EventTicketsResponse } from '@app/models/client/tickets/event-tickets-response.model';

/**
 * Implementation of tickets APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class TicketsService implements APIService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * [POST]: `/api/v1/tickets`.
   * Create a new tickets.
   */
  createTicket(
    query: CreateTicketRequest,
  ): Observable<ResponseSuccess<EventTicketsResponse>> {
    return this.http.post<ResponseSuccess<EventTicketsResponse>>(
      `${this.host}/api/v2/tickets`,
      query,
    );
  }

  /**
   * [PUT]: `/api/v1/tickets`.
   * Update a new tickets.
   */
  updateTicket(
    query: CreateTicketRequest,
    ticketGUID: string,
  ): Observable<ResponseSuccess<EventTicketsResponse>> {
    return this.http.put<ResponseSuccess<EventTicketsResponse>>(
      `${this.host}/api/v2/tickets/${ticketGUID}`,
      query,
    );
  }

  /**
   * [GET]: `/api/v1/organizer/events/{eventGUID}/tickets`.
   * Returns information about Organizer event tickets.
   */
  getOrganizerEventTickets(
    eventGUID: string,
  ): Observable<EventTicketsResponse> {
    return this.http.get<EventTicketsResponse>(
      `${this.host}/api/v2/events/${eventGUID}/tickets`,
    );
  }

  /**
   * [GET]: `/api/v1/tickets/{ticketGUID}/print`.
   * Prints ticket as pdf version.
   *
   *
   * @param ticketGUID - Ticket ID.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  printTicketAsPDF(ticketGUID: string): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(
      `${this.host}/api/v1/tickets/${ticketGUID}/print`,
      {
        // @ts-ignore
        responseType: 'blob',
      },
    );
  }

  /**
   * [GET]: `/api/v1/events/{eventId}/bought_tickets`.
   * Information about bought tickets.
   *
   *
   * @param eventId - Event ID.
   * @param limit - Limit
   * @param page - Page
   *
   * @return An `Observable` of the `GetFanTicketsResponseSuccess`.
   */
  getFanBoughtTickets(
    eventId: string,
    limit: string,
    page: string,
  ): Observable<GetFanTicketsResponseSuccess> {
    const params = new HttpParams();
    limit && params.set('limit', limit);
    page && params.set('page', page);

    return this.http.get<GetFanTicketsResponseSuccess>(
      `${this.host}/api/v1/events/${eventId}/bought_tickets`,
      {
        params,
      },
    );
  }

  /**
   * [POST]: `/api/v1/tickets/gift/{paymentTicketGUID}/give`.
   * Give ticket to guest, will change ticket status to waiting_to_present.
   *
   *
   * @param paymentTicketGUID - Ticket ID.
   * @param body - Request query.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  giveTicketToGuest(
    paymentTicketGUID: string,
    body: GiveTicketRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/tickets/gift/${paymentTicketGUID}/give`,
      body,
    );
  }

  /**
   * [POST]: `/api/v1/tickets/give/{paymentTicketGUID}/accept`.
   * Fan accept ticket, will change ticket status to presented.
   *
   *
   * @param paymentTicketGUID - Ticket ID.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  acceptTicket(paymentTicketGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/tickets/give/${paymentTicketGUID}/accept`,
      null,
    );
  }

  /**
   * [POST]: `/api/v1/tickets/give/{paymentTicketGUID}/refuse`.
   * Fan refuse ticket, will change ticket status to own.
   *
   *
   * @param paymentTicketGUID - Ticket ID.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  refuseTicket(paymentTicketGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/tickets/give/${paymentTicketGUID}/refuse`,
      null,
    );
  }

  deleteTicket(ticketGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v2/tickets/${ticketGUID}`,
    );
  }
}
