import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { SearchArtistsForEventsParams } from '@app/models/client/search/search-artists-for-events-params.model';
import { SearchVenuesForEventsParams } from '@app/models/client/search/search-venues-for-events-params.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { APIService } from '@app/interfaces/api-service.interface';
import { EventArtistAccountsResponse } from '@app/models/client/events/event-artist-accounts-response.model';
import { EventVenueAccountsResponse } from '@app/models/client/events/event-venue-accounts-response.model';

/**
 * Implementation of search APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class SearchService implements APIService {
  host = environment.host;
  searchHost = environment.searchHost;
  constructor(private http: HttpClient) {}

  /**
   * [GET]: `/search`.
   * Returns search.
   */
  search(
    query: string,
    limit: string,
    page: string,
    filters: any,
    sort?: any,
  ): Observable<ResponseSuccess<any>> {
    const body: any = {
      filters,
      query,
      limit,
      page,
    };
    if (sort) {
      body.sort = sort;
    }
    return this.http.post<ResponseSuccess<any>>(
      `${this.searchHost}/api/v2/search/all`,
      body,
    );
  }

  /**
   * [GET]: `/search/fan`.
   * Returns search.
   */
  searchFan(
    query: string,
    limit: string,
    page: string,
    filters: any,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.searchHost}/api/v2/search/fan`,
      {
        filters,
        query,
        limit,
        page,
      },
    );
  }

  /**
   * [GET]: `/search/artist`.
   * Returns search.
   */

  searchArtist(
    query: string,
    limit: string,
    page: string,
    filters: any,
    sort?: any,
  ): Observable<ResponseSuccess<any>> {
    const body: any = {
      filters,
      query,
      limit,
      page,
    };
    if (sort) {
      body.sort = sort;
    }
    return this.http.post<ResponseSuccess<any>>(
      `${this.searchHost}/api/v2/search/artist`,
      body,
    );
  }

  /**
   * [GET]: `/search/venue`.
   * Returns search.
   */

  searchVenue(
    query: string,
    limit: string,
    page: string,
    filters: any,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.searchHost}/api/v2/search/venue`,
      {
        filters,
        query,
        limit,
        page,
      },
    );
  }

  /**
   * [GET]: `/search/organizer`.
   * Returns search.
   */
  searchOrganizer(
    query: string,
    limit: string,
    page: string,
    filters: any,
    sort?: any,
  ): Observable<ResponseSuccess<any>> {
    const body: any = {
      filters,
      query,
      limit,
      page,
    };
    if (sort) {
      body.sort = sort;
    }
    return this.http.post<ResponseSuccess<any>>(
      `${this.searchHost}/api/v2/search/organizer`,
      body,
    );
  }

  /**
   * [GET]: `/search/event`.
   * Returns search.
   */

  searchEvent(
    query: string,
    limit: string,
    page: string,
    filters: any,
    sort?: any,
  ): Observable<ResponseSuccess<any>> {
    const body: any = {
      filters,
      query,
      limit,
      page,
    };

    if (sort) {
      body.sort = sort;
    }
    return this.http.post<ResponseSuccess<any>>(
      `${this.searchHost}/api/v2/search/event`,
      body,
    );
  }

  /**
   * [GET]: `/search/tags`.
   * Returns search.
   */
  searchTags(
    query: string,
    limit: string,
    page: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.searchHost}/api/v2/search/tags`,
      {
        query,
        limit,
        page,
      },
    );
  }

  /**
   * [GET]: `/search/suggest`.
   * Returns search.
   */
  searchSuggest(
    query: string,
    filterParams: any,
    sort: any,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.searchHost}/api/v2/search/suggest`,
      {
        query,
        filters: filterParams,
        sort,
      },
    );
  }

  // Search for events.

  /**
   * [GET]: `/api/v1/search/artist/event`.
   * Returns list of artist all-accounts with private info for an event.
   */
  searchArtistsForEvent(
    searchParams: SearchArtistsForEventsParams,
  ): Observable<ResponseSuccess<EventArtistAccountsResponse>> {
    let params = new HttpParams()
      .set('search', searchParams.search)
      .set('limit', searchParams.limit)
      .set('page', searchParams.page)
      .set('city', searchParams.city)
      .set('state', searchParams.state)
      .set('country', searchParams.country)
      .set('price', searchParams.price ? searchParams.price.join() : null)
      .set('timeFor', searchParams.timeFor)
      .set('genres', searchParams.genres ? searchParams.genres.join() : null)
      .set('latitude', searchParams.latitude)
      .set('longitude', searchParams.longitude)
      .set('distance', searchParams.distance);

    if (searchParams.price) {
      if (searchParams.price[0] === '' || searchParams.price[1] === '') {
        params = params.delete('price');
      }
    }

    for (const param of params.keys()) {
      if (!params.get(param)) {
        params = params.delete(param.toString());
      }
    }

    return this.http.get<ResponseSuccess<EventArtistAccountsResponse>>(
      `${this.host}/api/v1/search/artist/event`,
      {
        params,
      },
    );
  }

  /**
   * [GET]: `/api/v1/search/venue/event`.
   * Returns list of venue all-accounts with private info for the event.
   */
  searchVenuesForEvent(
    searchParams: SearchVenuesForEventsParams,
  ): Observable<ResponseSuccess<EventVenueAccountsResponse>> {
    let params = new HttpParams()
      .set('search', searchParams.search)
      .set('limit', searchParams.limit)
      .set('page', searchParams.page)
      .set('city', searchParams.city)
      .set('state', searchParams.state)
      .set('zipCode', searchParams.zipCode)
      .set('price', searchParams.price ? searchParams.price.join() : null)
      .set(
        'capacity',
        searchParams.capacity ? searchParams.capacity.join() : null,
      )
      .set('types', searchParams.types ? searchParams.types.join() : null)
      .set('latitude', searchParams.latitude)
      .set('longitude', searchParams.longitude)
      .set('distance', searchParams.distance);

    for (const param of params.keys()) {
      if (!params.get(param)) {
        params = params.delete(param.toString());
      }
    }

    return this.http.get<ResponseSuccess<EventVenueAccountsResponse>>(
      `${this.host}/api/v1/search/venue/event`,
      {
        params,
      },
    );
  }
}
