import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as UserStoreActions from '../user/actions';
import * as UserStoreSelectors from '../user/selectors';
import * as ArtistStoreActions from '../artist/artist.actions';
import * as VenueStoreActions from '../venue/actions';
import * as OrganizerStoreActions from '../organizer/actions';

import {
  ActionTypes,
  CreateProfileArtist,
  CreateProfileOrganizer,
  CreateProfileVenue,
  CreateUser,
  CreateUserFailure,
  CreateUserSocial,
  SetSignUpForm,
  SetSignUpSocialForm,
} from './actions';
import { getSignUpFormState, getSignUpSocialFormState } from './selectors';

import { UserCreate } from '@app/models/client/user/user-create.model';
import { SignUpForm } from '@app/models/client/sign-up/sign-up-form.model';
import { ArtistProfileResponse } from '@app/models/client/artist/artist-profile-response.model';
import * as FanStoreActions from '@app/store/root/client/fan/actions';
import { OrganizerProfile } from '@app/models/client/organizer/organizer-profile.model';
import { RootStoreState } from '@app/store/root';
import { CreateVenueAccountRequest } from '@app/models/client/venue/create-venue-account-request.model';
import { ResponseError } from '@models/shared/response/response-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponseHelper } from '@base/helpers/error-response.helper';

@Injectable()
export class SignUpEffects {
  /* User */

  /* Create User */

  public createUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateUser>(ActionTypes.CreateUser),
      map((action: CreateUser) => action.payload.user),
      map((user: UserCreate) => UserStoreActions.CreateUser({ user })),
    ),
  );

  public createUserSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStoreActions.CreateUserSuccess),
      map((action) => {
        return new SetSignUpForm({
          passed: false,
          form: action.user as SignUpForm,
        });
      }),
    ),
  );

  public createUserFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStoreActions.CreateUserFailure),
      map((_) => new SetSignUpForm({ passed: false, form: null })),
      catchError((errResponse: ResponseError | HttpErrorResponse) =>
        ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
          switchMap((response: ResponseError) => [
            new CreateUserFailure({ response }),
          ]),
        ),
      ),
    ),
  );

  /* Fan */

  /* Create Profile */

  createProfileFanSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FanStoreActions.CreateFanSuccess>(
        FanStoreActions.ActionTypes.CreateFanSuccess,
      ),
      tap((_) => {
        this.store.dispatch(UserStoreActions.LoadUser());
      }),
      withLatestFrom(this.store.select(getSignUpFormState)),
      switchMap(([_, signUpFormState]) => {
        return [
          new SetSignUpForm({ passed: true, form: signUpFormState.form }),
        ];
      }),
    ),
  );

  createProfileFanFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FanStoreActions.CreateFanFailure>(
        FanStoreActions.ActionTypes.CreateFanFailure,
      ),
      withLatestFrom(this.store.select(getSignUpFormState)),
      map(
        ([_, signUpFormState]) =>
          new SetSignUpForm({ passed: false, form: signUpFormState.form }),
      ),
    ),
  );

  /* Artist */

  /* Create Profile */

  createProfileArtist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProfileArtist>(ActionTypes.CreateProfileArtist),
      map((action: CreateProfileArtist) => action.payload.profile),
      map(
        (model: ArtistProfileResponse) =>
          new ArtistStoreActions.CreateProfile({ model }),
      ),
    ),
  );

  createProfileArtistSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ArtistStoreActions.CreateProfileSuccess>(
        ArtistStoreActions.ActionTypes.CreateProfileSuccess,
      ),
      tap((v) => {
        this.store.dispatch(UserStoreActions.LoadProfiles());
      }),
      withLatestFrom(this.store.select(getSignUpFormState)),
      switchMap(([_, signUpFormState]) => {
        return [
          new SetSignUpForm({ passed: true, form: signUpFormState.form }),
        ];
      }),
    ),
  );

  createProfileArtistFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ArtistStoreActions.CreateProfileFailure>(
        ArtistStoreActions.ActionTypes.CreateProfileFailure,
      ),
      withLatestFrom(this.store.select(getSignUpFormState)),
      map(
        ([_, signUpFormState]) =>
          new SetSignUpForm({ passed: false, form: signUpFormState.form }),
      ),
    ),
  );

  /* Venue */

  /* Create Profile */

  createProfileVenue$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProfileVenue>(ActionTypes.CreateProfileVenue),
      map((action: CreateProfileVenue) => action.payload.profile),
      map(
        (profile: CreateVenueAccountRequest) =>
          new VenueStoreActions.CreateProfile({ model: profile }),
      ),
    ),
  );

  createProfileVenueSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<VenueStoreActions.CreateProfileSuccess>(
        VenueStoreActions.ActionTypes.CreateProfileSuccess,
      ),
      withLatestFrom(this.store.select(getSignUpFormState)),
      switchMap(([_, signUpFormState]) => {
        return [
          new SetSignUpForm({ passed: true, form: signUpFormState.form }),
        ];
      }),
    ),
  );

  createProfileVenueFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<VenueStoreActions.CreateProfileFailure>(
        VenueStoreActions.ActionTypes.CreateProfileFailure,
      ),
      withLatestFrom(this.store.select(getSignUpFormState)),
      map(
        ([_, signUpFormState]) =>
          new SetSignUpForm({ passed: false, form: signUpFormState.form }),
      ),
    ),
  );

  /* Organizer */

  /* Create Profile */

  createProfileOrganizer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProfileOrganizer>(ActionTypes.CreateProfileOrganizer),
      map((action: CreateProfileOrganizer) => action.payload.profile),
      map(
        (profile: OrganizerProfile) =>
          new OrganizerStoreActions.CreateProfile({ model: profile }),
      ),
    ),
  );

  createProfileOrganizerSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<OrganizerStoreActions.CreateProfileSuccess>(
        OrganizerStoreActions.ActionTypes.CreateProfileSuccess,
      ),
      tap((_) => {
        this.store.dispatch(UserStoreActions.LoadUser());
      }),
      withLatestFrom(this.store.select(getSignUpFormState)),
      switchMap(([_, signUpFormState]) => {
        return [
          new SetSignUpForm({ passed: true, form: signUpFormState.form }),
        ];
      }),
    ),
  );

  createProfileOrganizerFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<OrganizerStoreActions.CreateProfileFailure>(
        OrganizerStoreActions.ActionTypes.CreateProfileFailure,
      ),
      withLatestFrom(this.store.select(getSignUpFormState)),
      map(
        ([_, signUpFormState]) =>
          new SetSignUpForm({ passed: false, form: signUpFormState.form }),
      ),
    ),
  );

  /* Social User */

  /* Create User */

  public createUserSocial$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateUserSocial>(ActionTypes.CreateUserSocial),
      withLatestFrom(
        this.store.pipe(select(UserStoreSelectors.getUserTokenState)),
        this.store.pipe(select(getSignUpSocialFormState)),
        this.store.pipe(select(UserStoreSelectors.getSocialUserProfileState)),
      ),
      switchMap(([action, _, form]) => {
        const userType = action.payload.userType;
        const mouseliveId = form.form.username;

        this.store.dispatch(
          UserStoreActions.UpdateUser({ user: action.payload.user }),
        );
        if (userType === 'fan') {
          this.ngZone.run(() => this.router.navigate(['/sign-up/info']));
          return [
            UserStoreActions.UpdateUser({ user: action.payload.user }),
            new SetSignUpSocialForm({ passed: true, form: form.form }),
          ];
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private ngZone: NgZone,
    private router: Router,
  ) {}
}
