import { ActionReducer, INIT, UPDATE } from '@ngrx/store';

import * as ClientStoreState from '@store/root/client/state';
import { LocalStorageService } from '@services/core/local-storage/local-storage.service';

export function initStateFromLocalStorage(
  reducer: ActionReducer<ClientStoreState.State>,
): ActionReducer<ClientStoreState.State> {
  return (state, action) => {
    const newState = reducer(state, action);
    if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
      return { ...newState, ...LocalStorageService.loadInitialState() };
    }
    return newState;
  };
}
