export interface EventAboutSectionPermission {
  address: boolean;
  comments: boolean;
  crowdfunding: {
    event: boolean;
    fundingDate: boolean;
    fundingGoal: boolean;
    fundingTime: boolean;
  };
  date: boolean;
  description: boolean;
  eventDuration: boolean;
  eventName: boolean;
  genre: boolean;
  hashtags: boolean;
  posters: boolean;
  time: boolean;
  timezone: boolean;
  venueName: boolean;
  customUrl: boolean;
}

export interface EventArtistSectionPermission {
  addNewArtist: boolean;
  closeArtistCard: boolean;
  selectArtist: boolean;
}

export interface EventMediaSectionPermission {
  addNewPhoto: boolean;
  addNewVideo: boolean;
  closePhoto: boolean;
  closeVideo: boolean;
}

export interface EventPreviewSectionPermission {
  backButton: boolean;
  comment: boolean;
  saleTickets: boolean;
  tickets: {
    left: boolean;
    right: boolean;
  };
}

export interface EventTicketsSectionPermission {
  addTickets: boolean;
  createTickets: {
    cancel: boolean;
    create: boolean;
    description: boolean;
    numbers: boolean;
    price: boolean;
    title: boolean;
  };
  editTicket: {
    cancel: boolean;
    update: boolean;
    description: boolean;
    numbers: boolean;
    price: boolean;
    title: boolean;
    edit: boolean;
    ticketId: string;
  }[];
  editTickets: boolean;
}

export class GetOrganizerPermissionSuccess {
  EventAboutSectionPermission: EventAboutSectionPermission;
  EventArtistSectionPermission: EventArtistSectionPermission;
  EventMediaSectionPermission: EventMediaSectionPermission;
  EventPreviewSectionPermission: EventPreviewSectionPermission;
  EventTicketsSectionPermission: EventTicketsSectionPermission;

  constructor() {
    this.EventAboutSectionPermission = {
      address: true,
      comments: true,
      crowdfunding: {
        event: true,
        fundingDate: true,
        fundingGoal: true,
        fundingTime: true,
      },
      date: true,
      description: true,
      eventDuration: true,
      eventName: true,
      genre: true,
      hashtags: true,
      posters: true,
      time: true,
      timezone: true,
      venueName: true,
      customUrl: true,
    };
    this.EventArtistSectionPermission = {
      addNewArtist: true,
      closeArtistCard: true,
      selectArtist: true,
    };
    this.EventMediaSectionPermission = {
      addNewPhoto: true,
      addNewVideo: true,
      closePhoto: true,
      closeVideo: true,
    };
    this.EventPreviewSectionPermission = {
      backButton: true,
      comment: true,
      saleTickets: true,
      tickets: {
        left: true,
        right: true,
      },
    };
    this.EventTicketsSectionPermission = {
      addTickets: true,
      createTickets: {
        cancel: true,
        create: true,
        description: true,
        numbers: true,
        price: true,
        title: true,
      },
      editTicket: [],
      editTickets: true,
    };
  }
}
