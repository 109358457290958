import { Action } from '@ngrx/store';

import { CartItemModel } from '@models/client/cart/cart-item.model';

export enum ActionTypes {
  CleanCart = '[Cart] Clean Cart',
  RemoveEventFromCart = '[Cart] Remove Event From Cart',
  SetCurrentEventTickets = '[Cart] Set Current Event Tickets',
  CleanCurrentEventTickets = '[Cart] Clean Current EventTickets',
  RemoveTicketsFromCart = '[Cart] Remove Tickets FromCart',
  SetCart = '[Cart] Set Cart',
  CleanCurrentTickets = '[Cart] Clean Current Tickets',
  SetDonation = '[Cart] Set Donation',
}

export class SetCart implements Action {
  public readonly type = ActionTypes.SetCart;

  constructor(public payload: { currentTickets: CartItemModel[] }) {}
}

export class SetDonation implements Action {
  public readonly type = ActionTypes.SetDonation;

  constructor(
    public donatInfo: {
      eventId: string;
      donationAmount: number;
      isDonationAnonymous: boolean;
    },
  ) {}
}

export class SetCurrentEventTickets implements Action {
  public readonly type = ActionTypes.SetCurrentEventTickets;

  constructor(
    public payload: {
      eventId: string;
      eventInfo: any;
      ticket: { id: string; count: number; ticketInfo: any };
    },
  ) {}
}

export class CleanCurrentEventTickets implements Action {
  public readonly type = ActionTypes.CleanCurrentEventTickets;

  constructor(public payload: { eventId: string }) {}
}

export class CleanCurrentTickets implements Action {
  public readonly type = ActionTypes.CleanCurrentTickets;

  constructor(public payload: { eventId: string; ticketId: string }) {}
}

export class CleanCart implements Action {
  public readonly type = ActionTypes.CleanCart;
}

export class RemoveEventFromCart implements Action {
  public readonly type = ActionTypes.RemoveEventFromCart;

  constructor(public payload: { eventId: string }) {}
}

export class RemoveTicketsFromCart implements Action {
  public readonly type = ActionTypes.RemoveEventFromCart;

  constructor(public payload: { eventId: string }) {}
}

export type Actions =
  | SetCurrentEventTickets
  | RemoveEventFromCart
  | CleanCurrentEventTickets
  | RemoveTicketsFromCart
  | SetDonation
  | SetCart
  | CleanCurrentTickets
  | CleanCart;
