export interface State {
  entities: [];
  count: number;
  loading: boolean;
}

export const initialState: State = {
  entities: [],
  count: 0,
  loading: false,
};
