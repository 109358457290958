import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { APIService } from '@app/interfaces/api-service.interface';

/**
 * Implementation of search APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class PhotoService implements APIService {
  host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * [POST]: `/get media`.
   */
  media(
    fileName: string,
    contentType: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(`${this.host}/api/v2/media`, {
      fileName,
      contentType,
    });
  }

  /**
   * [POST]: `/upload file`.
   */
  uploadFile(
    data: any,
    file: any,
    url: string,
  ): Observable<ResponseSuccess<any>> {
    const formData: FormData = new FormData();
    formData.append('Content-Type', data['Content-Type']);
    formData.append('bucket', data.bucket);
    formData.append('key', data.key);
    formData.append('policy', data.policy);
    formData.append('x-amz-algorithm', data['x-amz-algorithm']);
    formData.append('x-amz-credential', data['x-amz-credential']);
    formData.append('x-amz-date', data['x-amz-date']);
    formData.append('x-amz-signature', data['x-amz-signature']);
    formData.append('file', file);
    return this.http.post<ResponseSuccess<any>>(url, formData);
  }
}
