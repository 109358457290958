import { Action } from '@ngrx/store';
import { Response } from '@app/interfaces/response.interface';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { MessagesFanResponseSuccess } from '@app/models/client/fan/messages-fan-response-success.model';
import { FollowersCount } from '@app/models/client/followers-count.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { AccountType } from '@app/models/admin/accounts/account-type.enum';
import { FanModel } from '@models/client/fan/fan.model';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export enum ActionTypes {
  CreateFan = '[Fan] Create Fan',
  CreateFanSuccess = '[Fan] Create Fan Success',
  CreateFanFailure = '[Fan] Create Fan Failure',

  SetIsUpdate = '[Fan] Set Is Update',

  SetProfile = '[Fan] Set Profile',

  GetProfileByID = '[Fan] Get Profile By ID',
  GetProfileByIDSuccess = '[Fan] Get Profile By ID Success',
  GetProfileByIDFailure = '[Fan] Get Profile By ID Failure',

  UpdateProfile = '[Fan] Update Fan',
  UpdateProfileSuccess = '[Fan] Update Fan Success',
  UpdateProfileFailure = '[Fan] Update Fan Failure',

  RemoveFan = '[Fan] Remove Fan',

  SetProfilePhoto = '[Fan] Set Profile Photo',

  FollowArtist = '[Fan] Follow Artist',
  FollowArtistSuccess = '[Fan] Follow Artist Success',
  FollowArtistFailure = '[Fan] Follow Artist Failure',

  UnfollowArtist = '[Fan] Unfollow Artist',
  UnfollowArtistSuccess = '[Fan] Unfollow Artist Success',
  UnfollowArtistFailure = '[Fan] Unfollow Artist Failure',

  GetFollowingArtists = '[Fan] Get Following Artists',
  GetFollowingArtistsSuccess = '[Fan] Get Following Artists Success',
  GetFollowingArtistsFailure = '[Fan] Get Following Artists Failure',

  GetFollowersCount = '[Fan] Get Followers Count',
  GetFollowersCountSuccess = '[Fan] Get Followers Count Success',
  GetFollowersCountFailure = '[Fan] Get Followers Count Failure',

  GetFollowingCount = '[Fan] Get Following Count',
  GetFollowingCountSuccess = '[Fan] Get Following Count Success',
  GetFollowingCountFailure = '[Fan] Get Following Count Failure',

  /***** Messages *****/

  GetProfileMessages = '[Fan] Get Profile Messages',
  GetProfileMessagesSuccess = '[Fan] Get Profile Messages Success',
  GetProfileMessagesFailure = '[Fan] Get Profile Messages Failure',

  /***** Followers&Following *****/

  FollowAccount = '[Fan] Follow Account',
  FollowAccountSuccess = '[Fan] Follow Account Success',
  FollowAccountFailure = '[Fan] Follow Account Failure',

  UnfollowAccount = '[Fan] Unfollow Account',
  UnfollowAccountSuccess = '[Fan] Unfollow Account Success',
  UnfollowAccountFailure = '[Fan] Unfollow Account Failure',
}

// Create Fan

export class CreateFan implements Action {
  public readonly type = ActionTypes.CreateFan;

  constructor(public payload: { fan: FanModel }) {}
}

export class SetIsUpdate implements Action {
  public readonly type = ActionTypes.SetIsUpdate;

  constructor(public payload: boolean) {}
}

export class CreateFanSuccess implements Action {
  public readonly type = ActionTypes.CreateFanSuccess;
}

export class CreateFanFailure implements Action {
  public readonly type = ActionTypes.CreateFanFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Set Fan Profile

export class SetProfile implements Action {
  public readonly type = ActionTypes.SetProfile;

  constructor(public payload: { profile: Profile }) {}
}

// Get Profile By ID

export class GetProfileByID implements Action {
  public readonly type = ActionTypes.GetProfileByID;

  constructor(public payload: { id: string }) {}
}

export class GetProfileByIDSuccess implements Action {
  public readonly type = ActionTypes.GetProfileByIDSuccess;

  constructor(public payload: { response: ResponseSuccess<FanModel> }) {}
}

export class GetProfileByIDFailure implements Action {
  public readonly type = ActionTypes.GetProfileByIDFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Update Profile

export class UpdateProfile implements Action {
  public readonly type = ActionTypes.UpdateProfile;

  constructor(public payload: { profile: FanModel }) {}
}

export class UpdateProfileSuccess implements Action {
  public readonly type = ActionTypes.UpdateProfileSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateProfileFailure implements Action {
  public readonly type = ActionTypes.UpdateProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Remove Fan

export class RemoveFan implements Action {
  public readonly type = ActionTypes.RemoveFan;
}

// Set Profile Photo

export class SetProfilePhoto implements Action {
  public readonly type = ActionTypes.SetProfilePhoto;

  constructor(public payload: { image: FormData }) {}
}

// Follow Artist

export class FollowArtist implements Action {
  public readonly type = ActionTypes.FollowArtist;

  constructor(public payload: { artistGUID: string }) {}
}

export class FollowArtistSuccess implements Action {
  public readonly type = ActionTypes.FollowArtistSuccess;

  constructor(public payload: { response: Response }) {}
}

export class FollowArtistFailure implements Action {
  public readonly type = ActionTypes.FollowArtistFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Unfollow Artist

export class UnfollowArtist implements Action {
  public readonly type = ActionTypes.UnfollowArtist;

  constructor(public payload: { artistGUID: string }) {}
}

export class UnfollowArtistSuccess implements Action {
  public readonly type = ActionTypes.UnfollowArtistSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UnfollowArtistFailure implements Action {
  public readonly type = ActionTypes.UnfollowArtistFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Following Artists

export class GetFollowingArtists implements Action {
  public readonly type = ActionTypes.GetFollowingArtists;

  constructor(
    public payload: {
      fanGUID: string;
      limit: string;
      page: string;
    },
  ) {}
}

export class GetFollowingArtistsSuccess implements Action {
  public readonly type = ActionTypes.GetFollowingArtistsSuccess;

  constructor(public payload: { response: Response }) {}
}

export class GetFollowingArtistsFailure implements Action {
  public readonly type = ActionTypes.GetFollowingArtistsFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

/***** Follows *****/

export class GetFollowersCount implements Action {
  public readonly type = ActionTypes.GetFollowersCount;
}

export class GetFollowersCountSuccess implements Action {
  public readonly type = ActionTypes.GetFollowersCountSuccess;

  constructor(public payload: { response: FollowersCount }) {}
}

export class GetFollowersCountFailure implements Action {
  public readonly type = ActionTypes.GetFollowersCountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class GetFollowingCount implements Action {
  public readonly type = ActionTypes.GetFollowingCount;
}

export class GetFollowingCountSuccess implements Action {
  public readonly type = ActionTypes.GetFollowingCountSuccess;

  constructor(public payload: { response: FollowersCount }) {}
}

export class GetFollowingCountFailure implements Action {
  public readonly type = ActionTypes.GetFollowingCountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

/***** Messages *****/

export class GetProfileMessages implements Action {
  public readonly type = ActionTypes.GetProfileMessages;
}

export class GetProfileMessagesSuccess implements Action {
  public readonly type = ActionTypes.GetProfileMessagesSuccess;

  constructor(public payload: { response: MessagesFanResponseSuccess }) {}
}

export class GetProfileMessagesFailure implements Action {
  public readonly type = ActionTypes.GetProfileMessagesFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class FollowAccount implements Action {
  public readonly type = ActionTypes.FollowAccount;

  constructor(
    public payload: {
      id: string;
      accountType: AccountType;
    },
  ) {}
}

export class FollowAccountSuccess implements Action {
  public readonly type = ActionTypes.FollowAccountSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class FollowAccountFailure implements Action {
  public readonly type = ActionTypes.FollowAccountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class UnfollowAccount implements Action {
  public readonly type = ActionTypes.UnfollowAccount;

  constructor(
    public payload: {
      id: string;
      accountType: AccountType;
    },
  ) {}
}

export class UnfollowAccountSuccess implements Action {
  public readonly type = ActionTypes.UnfollowAccountSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UnfollowAccountFailure implements Action {
  public readonly type = ActionTypes.UnfollowAccountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | CreateFan
  | CreateFanSuccess
  | CreateFanFailure
  | SetProfile
  | GetProfileByID
  | GetProfileByIDSuccess
  | GetProfileByIDFailure
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFailure
  | RemoveFan
  | SetProfilePhoto
  | FollowArtist
  | FollowArtistSuccess
  | FollowArtistFailure
  | UnfollowArtist
  | UnfollowArtistSuccess
  | UnfollowArtistFailure
  | GetFollowingArtists
  | GetFollowingArtistsSuccess
  | GetFollowingArtistsFailure
  | GetFollowersCount
  | GetFollowersCountSuccess
  | GetFollowersCountFailure
  | GetFollowingCount
  | GetFollowingCountSuccess
  | GetFollowingCountFailure
  | GetProfileMessages
  | GetProfileMessagesSuccess
  | GetProfileMessagesFailure
  | FollowAccount
  | FollowAccountSuccess
  | FollowAccountFailure
  | SetIsUpdate
  | UnfollowAccount
  | UnfollowAccountSuccess
  | UnfollowAccountFailure;
