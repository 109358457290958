import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SetPhotoToMedia: {
      return {
        ...state,
        photos: [action.payload.photo, ...state.photos],
      };
    }

    case ActionTypes.LoadEvents: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.LoadEventsSuccess: {
      const data = action.payload.response.data;

      let eventsDataResponse = state.eventsDataResponse;
      if (state.eventsDataResponse === null) {
        eventsDataResponse = data;
      } else {
        // Extends events array on next load.
        eventsDataResponse.events = [
          ...state.eventsDataResponse.events,
          ...data.events,
        ];
      }

      return {
        ...state,
        eventsDataResponse,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.LoadEventsFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.LaunchEvent: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.LaunchEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.RemoveEvents: {
      return {
        ...state,
        eventsDataResponse: null,
      };
    }
    case ActionTypes.LoadEventById: {
      return {
        ...state,
        event: null,
      };
    }
    case ActionTypes.LoadEventByIdSuccess: {
      return {
        ...state,
        event: action.payload.response,
        photos: action.payload.response.photos,
      };
    }
    case ActionTypes.GetTimeZoneSuccess: {
      return {
        ...state,
        timeZone: action.payload.response.data,
      };
    }
    case ActionTypes.LoadEventByIdFailure: {
      return {
        ...state,
        event: null,
      };
    }
    case ActionTypes.RemoveEvent: {
      return {
        ...state,
        event: null,
      };
    }
    case ActionTypes.LoadEventImage: {
      return {
        ...state,
      };
    }
    case ActionTypes.CreateEventSuccess: {
      return {
        ...state,
        eventID: action.payload.response.id,
        created: true,
      };
    }
    case ActionTypes.CreateEventFailure: {
      return {
        ...state,
        created: false,
      };
    }

    case ActionTypes.SetCreated: {
      return {
        ...state,
        created: action.payload,
      };
    }

    case ActionTypes.GetFanUpcomingEvents: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetFanUpcomingEventsSuccess: {
      return {
        ...state,
        fanUpcomingEvents: action.payload.response.data,
      };
    }
    case ActionTypes.GetFanUpcomingEventsFailure: {
      return {
        ...state,
        fanUpcomingEvents: null,
      };
    }

    case ActionTypes.GetFanPastEvents: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetFanPastEventsSuccess: {
      return {
        ...state,
        fanPastEvents: action.payload.response.data,
      };
    }
    case ActionTypes.GetFanPastEventsFailure: {
      return {
        ...state,
        fanPastEvents: null,
      };
    }

    // Event Allowed Artists

    case ActionTypes.GetAllowedArtistsOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetAllowedArtistsOfEventSuccess: {
      return {
        ...state,
        allowedArtistsResp: action.payload.response.data,
      };
    }
    case ActionTypes.GetAllowedArtistOfEventFailure: {
      return {
        ...state,
        allowedArtistsResp: null,
      };
    }

    // Event Artist

    case ActionTypes.GetArtistsToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetArtistsToEventSuccess: {
      return {
        ...state,
        artistsToEventResp: action.payload.response.data,
      };
    }
    case ActionTypes.GetArtistsToEventFailure: {
      return {
        ...state,
        artistsToEventResp: null,
      };
    }

    case ActionTypes.AddArtistsToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.AddArtistsToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.AddArtistsToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.RemoveArtistFromEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.RemoveArtistFromEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.RemoveArtistFromEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.AcceptArtistRequestToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.AcceptArtistRequestToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.AcceptArtistRequestToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.ApproveArtistRequestToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.ApproveArtistRequestToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.ApproveArtistRequestToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.DeclineArtistRequestToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.DeclineArtistRequestToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.DeclineArtistRequestToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetArtistsRequestToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetArtistsRequestToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetArtistsRequestToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.UpdateArtistRequestToEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.UpdateArtistRequestToEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.UpdateArtistRequestToEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.SendRequestToArtistOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.SendRequestToArtistOfEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.SendRequestToArtistOfEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.ResendRequestToArtistOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.ResendRequestToArtistOfEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.ResendRequestToArtistOfEventFailure: {
      return {
        ...state,
      };
    }

    // Event Venue

    case ActionTypes.GetVenuesToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetVenuesToEventSuccess: {
      return {
        ...state,
        venuesToEventResp: action.payload.response.data,
      };
    }
    case ActionTypes.GetVenuesToEventFailure: {
      return {
        ...state,
        venuesToEventResp: null,
      };
    }
    case ActionTypes.UpdateEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
        updated: false,
      };
    }
    case ActionTypes.UpdateEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        updated: true,
      };
    }
    case ActionTypes.UpdateEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        updated: false,
      };
    }

    case ActionTypes.AddVenuesToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.AddVenuesToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.AddVenuesToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.RemoveVenueFromEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.RemoveVenueFromEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.RemoveVenueFromEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.AcceptVenueRequestToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.AcceptVenueRequestToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.AcceptVenueRequestToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.ApproveVenueRequestToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.ApproveVenueRequestToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.ApproveVenueRequestToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.DeclineVenueRequestToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.DeclineVenueRequestToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.DeclineVenueRequestToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetVenuesRequestToEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetVenuesRequestToEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetVenuesRequestToEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.UpdateVenueRequestToEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.UpdateVenueRequestToEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.UpdateVenueRequestToEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.SendRequestToVenueOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.SendRequestToVenueOfEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.SendRequestToVenueOfEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.ResendRequestToVenueOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.ResendRequestToVenueOfEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.ResendRequestToVenueOfEventFailure: {
      return {
        ...state,
      };
    }

    // Funding

    case ActionTypes.PayForArtistOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.PayForArtistOfEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.PayForArtistOfEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.PayForVenueOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.PayForVenueOfEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.PayForVenueOfEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetFundingDetailsOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetFundingDetailsOfEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetFundingDetailsOfEventFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.UpdateFundingPartOfEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.UpdateFundingPartOfEventSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.UpdateFundingPartOfEventFailure: {
      return {
        ...state,
      };
    }

    // Analytics

    case ActionTypes.GetEventAnalytics: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetEventAnalyticsSuccess: {
      return {
        ...state,
        eventAnalyticsResponse: action.payload.response.data,
      };
    }
    case ActionTypes.GetEventAnalyticsFailure: {
      return {
        ...state,
        eventAnalyticsResponse: null,
      };
    }

    case ActionTypes.GetEventPhotos: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.GetEventPhotosSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.GetEventPhotosFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.SetEventID: {
      return {
        ...state,
        eventID: action.payload.id,
      };
    }
    case ActionTypes.SetPageStatus: {
      return {
        ...state,
        pageStatus: action.payload.status,
      };
    }

    case ActionTypes.SetCreateEventSteps: {
      return {
        ...state,
        createEventSteps: action.payload.steps,
      };
    }

    // Remove Event By ID
    case ActionTypes.RemoveEventByID: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.RemoveEventByIDSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.RemoveEventByIDFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    // Put Event To Review
    case ActionTypes.PutEventToReview: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.PutEventToReviewSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.PutEventToReviewFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    // Get Venue Private Info Of Event
    case ActionTypes.GetVenuePrivateInfoOfEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.GetVenuePrivateInfoOfEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        venuePrivateInfoOfEvent: action.payload.response.data,
      };
    }
    case ActionTypes.GetVenuePrivateInfoOfEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    // Get Artist Private Info Of Event
    case ActionTypes.GetArtistPrivateInfoOfEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.GetArtistPrivateInfoOfEventSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        artistPrivateInfoOfEvent: action.payload.response.data,
      };
    }
    case ActionTypes.GetArtistPrivateInfoOfEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.SetUpdated: {
      return {
        ...state,
        updated: false,
      };
    }

    default: {
      return state;
    }
  }
}
