import { initialState, State } from '@store/root/client/dashboard/state';
import { Actions, ActionTypes } from '@store/root/client/dashboard/actions';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.DashboardOverview: {
      return {
        ...state,
        error: null,
      };
    }
    case ActionTypes.DashboardOverviewSuccess: {
      return {
        ...state,
        dashboardOverview: action.payload.response.data,
        error: null,
      };
    }
    case ActionTypes.DashboardOverviewFailure: {
      return {
        ...state,
        dashboardOverview: null,
        error: action.payload.response,
      };
    }
    case ActionTypes.RecentOrder: {
      return {
        ...state,
        error: null,
      };
    }
    case ActionTypes.RecentOrderSuccess: {
      return {
        ...state,
        recentOrders: action.payload.response.data,
        error: null,
      };
    }
    case ActionTypes.RecentOrderFailure: {
      return {
        ...state,
        recentOrders: null,
        error: action.payload.response,
      };
    }
    case ActionTypes.RemoveDashboardOverview: {
      return {
        ...state,
        dashboardOverview: null,
        recentOrders: null,
        error: null,
      };
    }
    case ActionTypes.DashboardOverviewByEvent: {
      return {
        ...state,
        error: null,
      };
    }
    case ActionTypes.DashboardOverviewByEventSuccess: {
      return {
        ...state,
        dashboardOverviewByEvent: action.payload.response.data,
        error: null,
      };
    }
    case ActionTypes.DashboardOverviewByEventFailure: {
      return {
        ...state,
        dashboardOverviewByEvent: null,
        error: action.payload.response,
      };
    }
    case ActionTypes.RecentOrderByEvent: {
      return {
        ...state,
        error: null,
      };
    }
    case ActionTypes.RecentOrderByEventSuccess: {
      return {
        ...state,
        recentOrdersByEvent: action.payload.response.data,
        error: null,
      };
    }
    case ActionTypes.RecentOrderByEventFailure: {
      return {
        ...state,
        recentOrdersByEvent: null,
        error: action.payload.response,
      };
    }
    case ActionTypes.RemoveDashboardOverviewByEvent: {
      return {
        ...state,
        dashboardOverviewByEvent: null,
        recentOrdersByEvent: null,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
}
