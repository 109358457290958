import { FAQQuestion } from '@app/models/client/faq/FAQQuestion.model';

export interface State {
  FAQ: FAQQuestion[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  FAQ: [],
  loading: false,
  loaded: false,
};
