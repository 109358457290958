import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PhotoService } from '@services/client/photo/photo.service';
import { RootStoreState } from '@store/root';

@Injectable()
export class CartEffects {
  //
  // public getMedia$: Observable<Action> = this.actions$.pipe(
  //   ofType<GetMedia>(ActionTypes.GetMedia),
  //   exhaustMap((action) => this.photoService
  //     .media(
  //       action.payload.fileName,
  //       action.payload.fileType
  //     )
  //     .pipe(
  //       map((response: ResponseSuccess<any>) => (
  //         new GetMediaSuccess({response, mediaType: action.payload.mediaType})
  //       )),
  //       catchError((errResponse: ResponseError | HttpErrorResponse) => (
  //           ErrorResponseHelper
  //             .getErrorResponseErrorObject(errResponse)
  //             .pipe(
  //               switchMap((response: ResponseError) => [
  //                 new GetMediaFailure({response}),
  //                 new AppStoreActions.AddErrorResponse({response})
  //               ])
  //             )
  //         )
  //       )
  //     )
  //   )
  // );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private photoService: PhotoService,
  ) {}
}
