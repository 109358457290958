import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getUserState = (state: RootStoreState.State) => state.client.user;

export const getLoginedState = createSelector(
  getUserState,
  (state: State) => state.logined,
);

export const getActiveAccount = createSelector(
  getUserState,
  (state: State) => state.activeAccount,
);

export const getUserTokenState = createSelector(
  getUserState,
  (state: State) => state.token,
);

export const getUserUpdatedState = createSelector(
  getUserState,
  (state: State) => state.userUpdated,
);

export const getUserTokenForPasswordUpdateState = createSelector(
  getUserState,
  (state: State) => state.tokenForPasswordUpdate,
);

export const getUserTokenResponseState = createSelector(
  getUserState,
  (state: State) => state.tokenResponse,
);

export const getUserDataState = createSelector(
  getUserState,
  (state: State) => state.profile,
);

export const getUserIDState = createSelector(getUserState, (state: State) => {
  if (state.profile) {
    if (state.profile.userData) {
      return state.profile.userData.id;
    }
  }
});

export const getUserStatusMessageState = createSelector(
  getUserState,
  (state: State) => state.error,
);

export const getUserSocialState = createSelector(
  getUserState,
  (state: State) => state.social,
);

export const getSocialUserProfileState = createSelector(
  getUserState,
  (state: State) => state.socialUser,
);

export const getRemoveUserInfo = createSelector(
  getUserState,
  (state: State) => ({
    isRemoveUser: state.isRemoveUser,
    error: state.removeUserError,
  }),
);

export const getUserPhotoState = createSelector(
  getUserState,
  (state: State) => state.profile.profilePhotoUrl,
);

export const getUserAuthStatus = createSelector(
  getUserState,
  (state: State) => ({
    logined: state.logined,
    loading: state.loading,
    created: state.created,
  }),
);

export const getAccountTypeState = createSelector(
  getUserState,
  (state: State) => state.accountType,
);

export const getUserApplicationSettingsState = createSelector(
  getUserState,
  (state: State) => state.appUserSettings,
);

export const getLoadedState = createSelector(
  getUserState,
  (state: State) => state.loaded,
);

export const getClientSecretState = createSelector(
  getUserState,
  (state: State) => state.clientSecret,
);

export const getPaymentMethodIDState = createSelector(
  getUserState,
  (state: State) => state.paymentMethodID,
);

export const getPaymentState = createSelector(getUserState, (state: State) => {
  return {
    success: state.paymentSuccess,
    error: state.paymentError,
  };
});

export const getPayments = createSelector(getUserState, (state: State) => {
  return {
    data: state.paymentMethods,
  };
});

export const getPaymentSuccessState = createSelector(
  getUserState,
  (state: State) => state.paymentSuccess,
);
export const getSavedSuccessState = createSelector(
  getUserState,
  (state: State) => state.saveSuccess,
);
export const getInProgressState = createSelector(
  getUserState,
  (state: State) => state.paymentInProgress,
);

export const getPaymentErrorState = createSelector(
  getUserState,
  (state: State) => state.paymentError,
);

export const getProfileFollowersState = createSelector(
  getUserState,
  (state: State) => state.profileFollowers,
);

export const getProfileFollowingState = createSelector(
  getUserState,
  (state: State) => state.profileFollowing,
);

export const getConfirmEmailState = createSelector(
  getUserState,
  (state: State) => state.confirmEmail,
);

export const getConfirmMagicLinkState = createSelector(
  getUserState,
  (state: State) => state.confirmMagicLink,
);
export const getProfileIsFollowingState = createSelector(
  getUserState,
  (state: State) => state.isFollowing,
);

export const getTicketsByState = createSelector(
  getUserState,
  (state: State) => state.ticketsBy,
);
export const getGuestTokenResponse = createSelector(
  getUserState,
  (state: State) => state.guestTokenResponse,
);

export const getPaymentMethodsResponseState = createSelector(
  getUserState,
  (state: State) => state.paymentMethods,
);

export const getCheckEmailState = createSelector(
  getUserState,
  (state: State) => state.checkEmail,
);
