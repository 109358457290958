import { Action } from '@ngrx/store';

export enum ActionTypes {
  InitUploadPhoto = '[Media] Init Upload Photo',
  UploadPhotoSuccess = '[Media] Upload Photo Success',
  UploadPhotoFailure = '[Media] Upload Photo Failure',
  UploadPhotoInProgress = '[Media] Upload Photo In Progress',
  CleanMediaState = '[Media] Clean Media State',
  CleanErrorState = '[Media] Clean Error State',
}

export class InitUploadPhoto implements Action {
  public readonly type = ActionTypes.InitUploadPhoto;
}

export class UploadPhotoInProgress implements Action {
  public readonly type = ActionTypes.UploadPhotoInProgress;

  constructor(public payload: { percentage: any }) {}
}

export class UploadPhotoSuccess implements Action {
  public readonly type = ActionTypes.UploadPhotoSuccess;

  constructor(
    public payload: { response: { type: string; url: string; status: string } },
  ) {}
}

export class UploadPhotoFailure implements Action {
  public readonly type = ActionTypes.UploadPhotoFailure;

  constructor(public payload: { response: any }) {}
}

export class CleanMediaState implements Action {
  public readonly type = ActionTypes.CleanMediaState;
}

export class CleanErrorState implements Action {
  public readonly type = ActionTypes.CleanErrorState;
}

export type Actions =
  | InitUploadPhoto
  | UploadPhotoSuccess
  | UploadPhotoInProgress
  | UploadPhotoFailure
  | CleanMediaState
  | CleanErrorState;
