import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

import { OrganizerProfile } from '@app/models/client/organizer/organizer-profile.model';
import { EventResponse } from '@models/client/events/event-response.model';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.UploadVideo: {
      return {
        ...state,
        uploadingVideo: true,
      };
    }
    case ActionTypes.RemoveMediaFile: {
      return {
        ...state,
        removeMedia: true,
      };
    }
    case ActionTypes.RemovePhotoSuccess: {
      return {
        ...state,
        organizerEventByIDResponse: {
          ...state.organizerEventByIDResponse,
          photos: action.payload.photos,
        },
        removeMedia: false,
      };
    }
    case ActionTypes.RemoveVideoSuccess: {
      return {
        ...state,
        organizerEventByIDResponse: {
          ...state.organizerEventByIDResponse,
          videoLinks: action.payload.videos,
        },
        removeMedia: false,
      };
    }

    case ActionTypes.RemoveMediaFileFailure: {
      return {
        ...state,
        removeMedia: false,
      };
    }

    case ActionTypes.UploadVideoSuccess: {
      return {
        ...state,
        organizerEventByIDResponse: {
          ...state.organizerEventByIDResponse,
          videoLinks: action.payload.videos,
        },
        uploadingVideo: false,
      };
    }

    case ActionTypes.UploadVideoFailure: {
      return {
        ...state,
        uploadingVideo: false,
      };
    }
    case ActionTypes.SetPhotoToMedia: {
      let eventById;
      if (state.organizerEventByIDResponse) {
        eventById = state.organizerEventByIDResponse;
      } else {
        eventById = new EventResponse();
      }
      return {
        ...state,
        organizerEventByIDResponse: {
          ...eventById,
          photos: [...state.organizerEventByIDResponse?.photos, action.payload],
        },
      };
    }
    case ActionTypes.CleanEventById: {
      return {
        ...state,
        organizerEventByIDResponse: new EventResponse(),
      };
    }
    case ActionTypes.CleanProfile: {
      return {
        ...state,
        profileByID: null,
      };
    }

    case ActionTypes.CleanEvents: {
      return {
        ...state,
        organizerEventsResponse: null,
        organizerEventsResponsePast: null,
        organizersEventsResponse: null,
        organizersEventsResponsePast: null,
      };
    }
    case ActionTypes.CreateProfile: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.CreateProfileSuccess: {
      return {
        ...state,
        logined: true,
        loading: false,
        loaded: true,
        updated: true,
      };
    }
    case ActionTypes.SendOnReview: {
      return {
        ...state,
        sendOnReview: false,
      };
    }
    case ActionTypes.SetIsUpdate: {
      return {
        ...state,
        updated: action.payload,
      };
    }
    case ActionTypes.SendOnReviewSuccess: {
      return {
        ...state,
        sendOnReview: true,
      };
    }
    case ActionTypes.CreateProfileFailure: {
      return {
        ...state,
        logined: false,
        loading: false,
        loaded: true,
        updated: false,
      };
    }
    case ActionTypes.SetProfile: {
      const profile: Profile = action.payload.profile;

      return {
        ...state,
        profile,
        logined: true,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.GetProfileByID: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetProfileByIDSuccess: {
      const profileByID: OrganizerProfile = action.payload.profile;

      return {
        ...state,
        profileByID,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.GetProfileByIDFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.UpdateProfile: {
      return {
        ...state,
      };
    }

    case ActionTypes.UpdateProfileSuccess: {
      return {
        ...state,
        profile: action.payload.response.data,
        updated: true,
      };
    }

    case ActionTypes.UpdateProfileFailure: {
      return {
        ...state,
        updated: false,
      };
    }

    case ActionTypes.SetProfilePhoto: {
      return {
        ...state,
      };
    }

    case ActionTypes.SetProfilePhotoSuccess: {
      return {
        ...state,
      };
    }

    case ActionTypes.SetProfilePhotoFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.RemoveProfile: {
      return {
        ...state,
        profile: null,
        loading: false,
        logined: false,
        loaded: false,
      };
    }

    case ActionTypes.GetOrganizersEvents: {
      return {
        ...state,
        error: null,
      };
    }

    case ActionTypes.GetOrganizersEventsSuccess: {
      return {
        ...state,
        organizersEventsResponse: action.payload.response,
        error: null,
      };
    }

    case ActionTypes.GetOrganizerUpcomingEventsFailure: {
      return {
        ...state,
        organizersEventsResponse: null,
      };
    }

    case ActionTypes.GetOrganizerPermission: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetOrganizerPermissionSuccess: {
      const permission = action.payload.response;
      return {
        ...state,
        organizerPermission: permission,
      };
    }

    case ActionTypes.GetOrganizerPermissionFailure: {
      return {
        ...state,
        organizerPermission: null,
      };
    }

    case ActionTypes.GetOrganizerUpcomingEvents: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetOrganizerUpcomingEventsSuccess: {
      let events;
      if (state.organizerEventsResponse?.events.length > 0) {
        events = [
          ...state.organizerEventsResponse?.events,
          ...action.payload.response?.data?.events,
        ];
      } else {
        events = [...action.payload.response?.data?.events];
      }

      return {
        ...state,
        organizerEventsResponse: {
          count: action.payload.response.data?.count,
          events,
        },
      };
    }

    case ActionTypes.GetOrganizersEventsFailure: {
      return {
        ...state,
        organizerEventsResponse: null,
        error: action.payload.response,
      };
    }

    case ActionTypes.GetOrganizersPastEvents: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetOrganizersPastEventsSuccess: {
      return {
        ...state,
        organizersEventsResponsePast: action.payload.response,
      };
    }
    case ActionTypes.GetOrganizersPastEventsFailure: {
      return {
        ...state,
        organizersEventsResponsePast: null,
      };
    }

    case ActionTypes.GetOrganizerEventsPast: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetOrganizerEventsPastSuccess: {
      let events;
      if (state.organizerEventsResponsePast?.events.length > 0) {
        events = [
          ...state.organizerEventsResponsePast?.events,
          ...action.payload.response?.data?.events,
        ];
      } else {
        events = [...action.payload.response?.data?.events];
      }
      return {
        ...state,
        organizerEventsResponsePast: {
          count: action.payload?.response?.data.count,
          events,
        },
      };
    }
    case ActionTypes.GetOrganizerEventsPastFailure: {
      return {
        ...state,
        organizerEventsResponsePast: null,
      };
    }

    // Get Organizer Event By ID
    case ActionTypes.GetOrganizerEventByID: {
      return {
        ...state,
        updateEvent: false,
      };
    }
    case ActionTypes.GetOrganizerEventByIDSuccess: {
      const event = action.payload.response;
      return {
        ...state,
        organizerEventByIDResponse: event,
      };
    }
    case ActionTypes.GetOrganizerEventByIDFailure: {
      return {
        ...state,
        organizerEventByIDResponse: null,
      };
    }

    // Is Created Organizer Event
    case ActionTypes.IsCreatedOrganizerEvent: {
      return {
        ...state,
      };
    }
    case ActionTypes.IsCreatedOrganizerEventSuccess: {
      return {
        ...state,
        organizerEventIsCreated: true,
        organizerEventByIDResponse: action.payload.response,
      };
    }
    case ActionTypes.IsCreatedOrganizerEventFailure: {
      return {
        ...state,
        organizerEventIsCreated: false,
        organizerEventByIDResponse: null,
      };
    }

    case ActionTypes.GetFollowersCount: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetFollowersCountSuccess: {
      return {
        ...state,
        followersCount: action.payload.response.count,
      };
    }

    case ActionTypes.GetFollowersCountFailure: {
      return {
        ...state,
        followersCount: 0,
      };
    }

    case ActionTypes.GetFollowingCount: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetFollowingCountSuccess: {
      return {
        ...state,
        followingCount: action.payload.response.count,
      };
    }

    case ActionTypes.GetFollowingCountFailure: {
      return {
        ...state,
        followingCount: 0,
      };
    }

    /***** Messages *****/

    case ActionTypes.GetProfileMessages: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case ActionTypes.GetProfileMessagesSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        messagesResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetProfileMessagesFailure: {
      return {
        ...state,
        loaded: false,
        loading: false,
        messagesResponse: null,
      };
    }

    case ActionTypes.FollowAccount: {
      return {
        ...state,
      };
    }
    case ActionTypes.FollowAccountSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.FollowAccountFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.UnfollowAccount: {
      return {
        ...state,
      };
    }
    case ActionTypes.UnfollowAccountSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.UnfollowAccountFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.SetUpdatedState: {
      return {
        ...state,
        updated: action.payload,
      };
    }

    case ActionTypes.RemoveArtistFromEvent: {
      return {
        ...state,
        updateEvent: true,
        organizerEventByIDResponse: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
