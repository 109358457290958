import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { map, switchMap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { GenresService } from '@app/services/client/genres/genres.service';
import { ActionTypes, LoadGenres, SetGenres } from './actions';
import { Response } from '@app/interfaces/response.interface';
import { RootStoreState } from '@app/store/root';

@Injectable()
export class GenresEffects {
  public loadGenres$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadGenres>(ActionTypes.LoadGenres),
      switchMap((action) => {
        return this.genresService.getGenres().pipe(
          map((response: Response) => {
            return new SetGenres(response.data);
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private genresService: GenresService,
  ) {}
}
