import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class SocialAuthService {
  googleAuth: gapi.auth2.GoogleAuth;
  facebookAuth: any;

  constructor(private afAuth: AngularFireAuth) {}

  signOut() {
    this.afAuth
      .signOut()
      .then((resp) => {})
      .catch((err: any) => {
        console.log('err', err);
      });
  }

  loadScript(id: string, src: string, onload: any, async = true): void {
    if (document.getElementById(id)) {
      return;
    }

    const signInJS = document.createElement('script');
    signInJS.async = async;
    signInJS.src = src;
    signInJS.onload = onload;
    document.head.appendChild(signInJS);
  }
}
