import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { FanProfileResponse } from '@app/models/client/fan/fan-profile-response.model';
import { FanProfilesSearchResponse } from '@app/models/client/fan/fan-profiles-search-response.model';
import { MessagesFanResponseSuccess } from '@app/models/client/fan/messages-fan-response-success.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { APIService } from '@app/interfaces/api-service.interface';
import { FollowersCount } from '@app/models/client/followers-count.model';
import { FollowingCount } from '@app/models/client/following-count.model';
import { FanModel } from '@models/client/fan/fan.model';

/**
 * Implementation of fan account APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class FanService implements APIService {
  host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * PUT: `/fan`.
   * Update fan.
   *
   *
   * @param fan - Fan.
   *
   * @return An `Observable` of the `IResponse`.
   */
  updateFanProfile(fan: FanModel): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(`${this.host}/api/v1/fan`, fan);
  }

  /**
   * POST: `/fan`.
   * Create fan.
   *
   * @param fan - Fan Profile.
   *
   * @return An `Observable` of the `Response`.
   */
  createFanProfile(fan: FanModel): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(`${this.host}/api/v1/fan`, fan);
  }

  /**
   * POST: `/fan/follower`.
   * Follows up fan.
   *
   * @param mouseliveId - User mouselive ID.
   *
   * @return An `Observable` of the `Response`.
   */
  followFanProfile(mouseliveId: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower`,
      { mouseliveId },
    );
  }

  /**
   * GET: `/fan/followers`.
   * Retrieves list of followers
   *
   *
   *
   * @return An `Observable` of the `Response`.
   */
  getFanFollowers(): Observable<ResponseSuccess<any>> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/followers`,
    );
  }

  /**
   * GET: `/fan/following`.
   * Retrieves list of users which are followed by the user.
   *
   *
   *
   * @return An `Observable` of the `Response`.
   */
  getFanFollowing(): Observable<ResponseSuccess<any>> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/following`,
    );
  }

  /**
   * GET: `/fan/artist-artist-profile-private-profiles`.
   * Returns info about fan artist-artist-profile-private-profiles.
   *
   *
   *
   * @return An `Observable` of the `FanProfileResponse`.
   */
  getFanProfile(): Observable<FanProfileResponse> {
    return this.http.get<FanProfileResponse>(`${this.host}/api/v1/fan/profile`);
  }

  /**
   * GET: `/api/v1/fans/{id}`.
   * Returns info about fan by ID.
   *
   * @param id - Profile ID.
   */
  getProfileByID(id: string): Observable<ResponseSuccess<FanModel>> {
    return this.http.get<ResponseSuccess<FanModel>>(
      `${this.host}/api/v1/fans/${id}`,
    );
  }

  /**
   * GET: `/fans`.
   * Search for fans profiles by name or mouseliveId.
   *
   *
   * @param limit - Limit count.
   * @param page - Page count.
   * @param searchString - Search string.
   *
   * @return An `Observable` of the `FanProfilesSearchResponse`.
   */
  getFanProfilesBySearch(
    limit: string,
    page: string,
    searchString: string,
  ): Observable<FanProfilesSearchResponse> {
    return this.http.get<FanProfilesSearchResponse>(
      `${this.host}/api/v1/fans`,
      {
        params: new HttpParams()
          .set('limit', limit)
          .set('page', page)
          .set('searchString', searchString),
      },
    );
  }

  /**
   * POST: `/fan/profile_photo`.
   * Upload photo.
   *
   * @param image - multipart/form-data image=file.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  setProfilePhoto(image: FormData): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/profile_photo`,
      image,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/fan/artist/artistAccountGUID`.
   * Fan follows an artist account.
   *
   * @param artistGUID - artistGUID.
   *
   * @return An `Observable` of the `Response`.
   */
  followArtistProfile(artistGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/artist/${artistGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/fan/artist/artistAccountGUID`.
   * Fan unfollows an artist account.
   *
   * @param artistGUID - artistGUID.
   *
   * @return An `Observable` of the `Response`.
   */
  unfollowArtistProfile(artistGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/artist/${artistGUID}`,
    );
  }

  /**
   * GET: `/api/v1/fans/fanAccountGUID/following/artists`.
   * Retrieves list of artists which are followed by the fan.
   *
   * @param fanGUID - Fan account GUID.
   * @param limit - Limit count.
   * @param page - Page count.
   *
   * @return An `Observable` of the `Response`.
   */
  getFollowingArtists(
    fanGUID: string,
    limit: string,
    page: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/fans/${fanGUID}/following/artists`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams().set('limit', limit).set('page', page),
      },
    );
  }

  /***** Follows *****/

  /**
   * GET: `/api/v1/fans/{userGUID}/fan/followers_count`.
   * Followers count for the current fan.
   *
   * @param userGUID - User ID.
   *
   * @return An `Observable` of the `FollowersCount`.
   */
  getFollowersCount(userGUID: string): Observable<FollowersCount> {
    return this.http.get<FollowersCount>(
      `${this.host}/api/v1/fans/${userGUID}/fan/followers_count`,
    );
  }

  /**
   * GET: `/api/v1/fans/{userGUID}/fan/following_count`.
   * Following count for the current fan.
   *
   * @param userGUID - User ID.
   *
   * @return An `Observable` of the `FollowingCount`.
   */
  getFollowingCount(userGUID: string): Observable<FollowingCount> {
    return this.http.get<FollowingCount>(
      `${this.host}/api/v1/fans/${userGUID}/fan/following_count`,
    );
  }

  /***** Messages *****/

  /**
   * GET: `/api/v1/fan/messages`.
   * Get fan messages.
   *
   *
   * @return An `Observable` of the `MessagesFanResponseSuccess`
   */
  getMessages(): Observable<MessagesFanResponseSuccess> {
    return this.http.get<MessagesFanResponseSuccess>(
      `${this.host}/api/v1/fan/messages`,
    );
  }

  /***** Followers&Following. *****/

  /**
   * POST: `/api/v1/fan/follower/fan/artist/{artistAccountGUID}`.
   * Follow up artist account(if authenticated as an artist).
   *
   *
   * @param artistAccountGUID - Artist Account ID.
   */
  followUpArtistAccount(
    artistAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/artist/${artistAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/fan/artist/{artistAccountGUID}`.
   * Unfollow up artist account(if authenticated as an artist).
   *
   *
   * @param artistAccountGUID - Artist Account ID.
   */
  unfollowUpArtistAccount(
    artistAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/artist/${artistAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/fan/fan/{fanAccountGUID}`.
   * Follow up fan account(if authenticated as an artist).
   *
   *
   * @param fanAccountGUID - Fan Account ID.
   */
  followUpFanAccount(fanAccountGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/fan/${fanAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/fan/fan/{fanAccountGUID}`.
   * Unfollow up fan account(if authenticated as an artist).
   *
   *
   * @param fanAccountGUID - Fan Account ID.
   */
  unfollowUpFanAccount(
    fanAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/fan/${fanAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/fan/organizer/{organizerAccountGUID}`.
   * Follow up organizer account(if authenticated as an artist).
   *
   *
   * @param organizerAccountGUID - Organizer Account ID.
   */
  followUpOrganizerAccount(
    organizerAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/organizer/${organizerAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/fan/organizer/{organizerAccountGUID}`.
   * Unfollow up organizer account(if authenticated as an artist).
   *
   *
   * @param organizerAccountGUID - Organizer Account ID.
   */
  unfollowUpOrganizerAccount(
    organizerAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/organizer/${organizerAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/fan/venue/{venueAccountGUID}`.
   * Follow up venue account(if authenticated as an artist).
   *
   *
   * @param venueAccountGUID - Venue Account ID.
   */
  followUpVenueAccount(
    venueAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/venue/${venueAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/fan/venue/{venueAccountGUID}`.
   * Unfollow up venue account(if authenticated as an artist).
   *
   *
   * @param venueAccountGUID - Venue Account ID.
   */
  unfollowUpVenueAccount(
    venueAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/fan/venue/${venueAccountGUID}`,
    );
  }
}
