import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  ActionTypes,
  GetSearch,
  GetSearchArtist,
  GetSearchArtistFailure,
  GetSearchArtistSuccess,
  GetSearchEvent,
  GetSearchEventFailure,
  GetSearchEventSuccess,
  GetSearchFailure,
  GetSearchFan,
  GetSearchFanFailure,
  GetSearchFanSuccess,
  GetSearchOrganizer,
  GetSearchOrganizerFailure,
  GetSearchOrganizerSuccess,
  GetSearchRange,
  GetSearchRangeEventFailure,
  GetSearchRangeEventSuccess,
  GetSearchRangeSuccess,
  GetSearchSuccess,
  GetSearchSuggest,
  GetSearchSuggestFailure,
  GetSearchSuggestSuccess,
  GetSearchTags,
  GetSearchTagsFailure,
  GetSearchTagsSuccess,
  GetSearchVenue,
  GetSearchVenueFailure,
  GetSearchVenueSuccess,
  SearchArtistsForEvent,
  SearchArtistsForEventFailure,
  SearchArtistsForEventSuccess,
  SearchVenuesForEvent,
  SearchVenuesForEventFailure,
  SearchVenuesForEventSuccess,
} from './actions';

import { SearchService } from '@app/services/client/search/search.service';
import { SearchResponseSuccess } from '@app/models/client/search/search-response-success.interface';
import { FanAccountsResponseSuccess } from '@app/models/client/fan/fan-accounts-response-success.model';
import { GetArtistAccountsResponseSuccess } from '@app/models/client/artist/get-artist-accounts-response-success.model';
import { GetVenueAccountsResponseSuccess } from '@app/models/client/venue/get-venue-accounts-response-success.model';
import { GetOrganizerAccountsResponseSuccess } from '@app/models/client/organizer/get-organizer-accounts-response-success.model';
import { GetTagsResponseSuccess } from '@app/models/client/tags/get-tags-response-success.model';
import { GetSuggestionsResponseSuccess } from '@app/models/client/suggest/get-suggestions-response-success.model';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { AppStoreActions } from '@app/store/root/app';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { EventArtistAccountsResponse } from '@app/models/client/events/event-artist-accounts-response.model';
import { EventVenueAccountsResponse } from '@app/models/client/events/event-venue-accounts-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';

@Injectable()
export class SearchEffects {
  public getSearch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearch>(ActionTypes.GetSearch),
      exhaustMap((action: GetSearch) =>
        this.searchService
          .search(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
            action.payload?.sort,
          )
          .pipe(
            map((response: SearchResponseSuccess) => {
              return new GetSearchSuccess({ response: response.data });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchRange$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchRange>(ActionTypes.GetSearchRange),
      exhaustMap((action: GetSearchRange) =>
        this.searchService
          .search(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
            action.payload?.sort,
          )
          .pipe(
            map(
              (response: SearchResponseSuccess) =>
                response.data.resultEvents.events,
            ),
            map((events) =>
              events.filter(
                (event) =>
                  +event.ticketPriceRange.min <= +action.payload.price &&
                  +action.payload.price <= +event.ticketPriceRange.max,
              ),
            ),
            map((filteredEvents) => {
              const response = new SearchResponseSuccess();
              response.data = {
                resultEvents: {
                  count: filteredEvents.length,
                  events: filteredEvents,
                },
              };
              response.error = '';
              response.success = true;
              return new GetSearchRangeSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchFan$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchFan>(ActionTypes.GetSearchFan),
      exhaustMap((action: GetSearchFan) =>
        this.searchService
          .searchFan(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
          )
          .pipe(
            map((response: FanAccountsResponseSuccess) => {
              return new GetSearchFanSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchFanFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchArtist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchArtist>(ActionTypes.GetSearchArtist),
      exhaustMap((action: GetSearchArtist) =>
        this.searchService
          .searchArtist(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
            action.payload?.sort,
          )
          .pipe(
            map((response: GetArtistAccountsResponseSuccess) => {
              return new GetSearchArtistSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchArtistFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchVenue$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchVenue>(ActionTypes.GetSearchVenue),
      exhaustMap((action: GetSearchVenue) =>
        this.searchService
          .searchVenue(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
          )
          .pipe(
            map((response: GetVenueAccountsResponseSuccess) => {
              return new GetSearchVenueSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchVenueFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchOrganizer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchOrganizer>(ActionTypes.GetSearchOrganizer),
      exhaustMap((action: GetSearchOrganizer) =>
        this.searchService
          .searchOrganizer(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
            action.payload?.sort,
          )
          .pipe(
            map((response: GetOrganizerAccountsResponseSuccess) => {
              return new GetSearchOrganizerSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchOrganizerFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchEvent>(ActionTypes.GetSearchEvent),
      exhaustMap((action: GetSearchEvent) =>
        this.searchService
          .searchEvent(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
            action.payload?.sort,
          )
          .pipe(
            map((response: ResponseSuccess<GetEventsResponse>) => {
              return new GetSearchEventSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchRangeEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchRange>(ActionTypes.GetSearchRangeEvent),
      exhaustMap((action: GetSearchRange) =>
        this.searchService
          .searchEvent(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
            action.payload?.sort,
          )
          .pipe(
            map(
              (response: ResponseSuccess<GetEventsResponse>) =>
                response.data.events,
            ),
            map((events) =>
              events.filter(
                (event) =>
                  +event.ticketPriceRange.min <= +action.payload.price &&
                  +action.payload.price <= +event.ticketPriceRange.max,
              ),
            ),
            map((filteredEvents) => {
              return new GetSearchRangeEventSuccess({
                response: {
                  data: {
                    count: filteredEvents.length,
                    events: filteredEvents,
                  },
                  error: '',
                  success: true,
                },
              });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchRangeEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchTags>(ActionTypes.GetSearchTags),
      exhaustMap((action: GetSearchTags) =>
        this.searchService
          .searchTags(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
          )
          .pipe(
            map((response: GetTagsResponseSuccess) => {
              return new GetSearchTagsSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchTagsFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public getSearchSuggest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSearchSuggest>(ActionTypes.GetSearchSuggest),
      exhaustMap((action: GetSearchSuggest) =>
        this.searchService
          .searchSuggest(
            action.payload.query,
            action.payload.filtersParams,
            action.payload.sort,
          )
          .pipe(
            map((response: GetSuggestionsResponseSuccess) => {
              return new GetSearchSuggestSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetSearchSuggestFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public searchArtistsForEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SearchArtistsForEvent>(ActionTypes.SearchArtistsForEvent),
      switchMap((action) =>
        this.searchService
          .searchArtistsForEvent(action.payload.searchParams)
          .pipe(
            map((response: ResponseSuccess<EventArtistAccountsResponse>) => {
              return new SearchArtistsForEventSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new SearchArtistsForEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  public searchVenuesForEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SearchVenuesForEvent>(ActionTypes.SearchVenuesForEvent),
      switchMap((action) =>
        this.searchService
          .searchVenuesForEvent(action.payload.searchParams)
          .pipe(
            map((response: ResponseSuccess<EventVenueAccountsResponse>) => {
              return new SearchVenuesForEventSuccess({ response });
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new SearchVenuesForEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
  ) {}
}
