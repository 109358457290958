import { Actions, ActionTypes } from './video.actions';
import { initialState, State } from './video.state';

import { StoreData } from '@app/models/shared/store-data.model';
import { VideoLink } from '@app/models/client/video/video-link.model';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.UploadVideoSuccess: {
      const storeData = new StoreData<VideoLink>();
      storeData.data = action.payload.response.data;
      storeData.loading = false;
      storeData.loaded = true;

      return {
        videoResponse: storeData,
      };
    }

    case ActionTypes.UploadVideo: {
      const storeData = new StoreData<VideoLink>();
      storeData.data = null;
      storeData.loading = true;
      storeData.loaded = false;

      return {
        videoResponse: storeData,
      };
    }

    case ActionTypes.RemoveVideo: {
      return {
        ...state,
      };
    }
    case ActionTypes.RemoveVideoSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.RemoveVideoFailure: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
