import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import {
  Profile,
  UpdateProfileRequest,
} from '@models/client/profiles/create-profile-response.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * PUT: `/api/v1/profile`.
   * Update user profile.
   */
  updateProfile(
    body: Profile,
  ): Observable<ResponseSuccess<UpdateProfileRequest>> {
    return this.http.put<ResponseSuccess<UpdateProfileRequest>>(
      `${this.host}/api/v1/profile`,
      body,
    );
  }

  /**
   * GET: `/api/v1/profile`.
   * Update user profile.
   */
  getProfile(): Observable<ResponseSuccess<UpdateProfileRequest>> {
    return this.http.get<ResponseSuccess<UpdateProfileRequest>>(
      `${this.host}/api/v1/profile`,
    );
  }

  addFanProfile(profileData: any): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/create-profiles-from-existing`,
      profileData,
    );
  }
}
