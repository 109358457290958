export interface State {
  loading: boolean;
  loaded: boolean;
  activeMessageID: string;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  activeMessageID: '',
};
