import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SetActiveMessageID: {
      return {
        ...state,
        activeMessageID: action.payload.id,
      };
    }

    default: {
      return state;
    }
  }
}
