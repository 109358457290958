import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserCreate } from '@app/models/client/user/user-create.model';
import { CreateUserResponse } from '@app/models/client/user/create-user-response.model';
import { Response } from '@app/interfaces/response.interface';
import { APIService } from '@app/interfaces/api-service.interface';
import { CreatePaymentMethodResponseSuccess } from '@app/models/client/payment/create-payment-method-response-success.model';
import { SetupIntentResponseSuccess } from '@app/models/client/payment/setup-intent-response-success.model';
import { UserFollowByAccountTypesResponseSuccess } from '@app/models/client/user/user-follow-by-account-types-response-success.model';
import { SetApplicationSettingsRequest } from '@app/models/client/user/set-application-settings-request.model';
import { UpdateUserRequest } from '@app/models/client/user/update-user-request.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { UserToken } from '@app/models/shared/user-token.model';
import { GuestModel } from '@models/client/fan/guest.model';

/**
 * Implementation of user APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class UserService implements APIService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * [GET]: /api/v1/user/me
   * Get user info.
   */
  getUser(): Observable<any> {
    return this.http.get<any>(`${this.host}/api/v1/user/me`);
  }

  /**
   * [DELETE]: /api/v1/user/me
   * Delete user from firebase.
   */
  removeUserFromFirebase(): Observable<any> {
    return this.http.delete<any>(`${this.host}/api/v1/user/firebase`);
  }

  /**
   * [DELETE]: /api/v1/user
   * Delete user.
   */
  removeUser(): Observable<any> {
    return this.http.delete<any>(`${this.host}/api/v1/user`);
  }

  /**
   * [POST]: `/api/v1/user`
   * Create user.
   *
   * @param user - User create model.
   */
  createUser(user: UserCreate): Observable<CreateUserResponse> {
    const userCopy = { ...user };
    if (!user.password) {
      delete userCopy.password;
    }
    return this.http.post<CreateUserResponse>(
      `${this.host}/api/v1/user`,
      userCopy,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  /**
   * [PUT]: `/api/v1/user`
   * Update user.
   *
   *
   * @param user - Update user request model.
   */
  updateUser(user: UpdateUserRequest): Observable<Response> {
    return this.http.put<Response>(`${this.host}/api/v1/user`, user);
  }

  /**
   * [GET]: /api/v1/user/application_settings
   * Get user application settings.
   */
  getUserApplicationSettings(): Observable<Response> {
    return this.http.get<any>(`${this.host}/api/v1/user/application_settings`);
  }

  /**
   * [PUT]: /api/v1/user/application_settings
   * Set user application settings.
   */
  setUserApplicationSettings(
    settings: SetApplicationSettingsRequest,
  ): Observable<Response> {
    return this.http.put<Response>(
      `${this.host}/api/v1/user/application_settings`,
      settings,
    );
  }

  /**
   * [POST]: /api/v1/user/refreshToken
   * Refresh jwt token if time is over.
   */
  refreshToken(): Observable<ResponseSuccess<UserToken>> {
    return this.http.post<ResponseSuccess<UserToken>>(
      `${this.host}/api/v1/user/refreshToken`,
      null,
    );
  }

  /**
   * POST: /api/v1/user/setup_intent
   * Create setup intent
   *
   * @see https://stripe.com/docs/payments/save-and-reuse.
   */
  setupIntent(data: any): Observable<CreatePaymentMethodResponseSuccess> {
    return this.http.post<CreatePaymentMethodResponseSuccess>(
      `${this.host}/api/v1/user/setup_intent`,
      data,
    );
  }

  /**
   * POST: /api/v1/user/setup_intent/{setupIntentID}/confirm
   * Confirms setup intent after confirm in Stripe.
   *
   * @see https://stripe.com/docs/payments/save-and-reuse.
   */
  confirmSetupIntent(
    setupIntentID: string,
  ): Observable<SetupIntentResponseSuccess> {
    return this.http.put<SetupIntentResponseSuccess>(
      `${this.host}/api/v1/user/setup_intent/${setupIntentID}/confirm`,
      null,
    );
  }

  /**
   * POST http://${this.host}/api/v1/user/confirm-email?token={token}
   * Confirms email with token.
   */
  confirmEmailByToken(token: string): Observable<any> {
    return this.http.post<any>(
      `${this.host}/api/v1/user/confirm-email?token=${token}`,
      null,
    );
  }

  /**
   * POST http://${this.host}/api/v1/user/confirm-email?code={code}
   * Confirms email with code.
   */

  confirmEmailByСode(code: string): Observable<any> {
    return this.http.post<any>(
      `${this.host}/api/v1/user/confirm-email?code=${code}`,
      null,
    );
  }

  /**
   * POST:  /api/v1/user/send-confirmation-email
   * Confirms email.
   */

  confirmEmail(email: string): Observable<any> {
    return this.http.post<any>(
      `${this.host}/api/v1/user/send-confirmation-email`,
      { email },
    );
  }

  /**
   * POST:  /api/v1/user/send-magic-link
   * Sends a magic link to the user's email address.
   */

  sendMagicLink(email: string): Observable<any> {
    return this.http.post<any>(`${this.host}/api/v1/user/send-magic-link`, {
      email,
    });
  }

  /**
   * POST http://${this.host}/api/v1/user/confirm-email?token={token}
   * Authenticates the user with the magic link with token.
   */

  authenticateWithMagicLinkByToken(token: string): Observable<any> {
    return this.http.post<any>(
      `${this.host}/api/v1/user/authenticate-magic-link?token=${token}`,
      null,
    );
  }

  /**
   * POST http://${this.host}/api/v1/user/confirm-email?code={code}
   * Authenticates the user with the magic link with code.
   */

  authenticateWithMagicLinkByCode(
    email: string,
    code: string,
  ): Observable<any> {
    return this.http.post<any>(
      `${this.host}/api/v1/user/authenticate-magic-link?email=${email}&code=${code}`,
      null,
    );
  }

  /***** Follows *****/

  /**
   * GET: `/api/v1/user/profiles/{profileID}/followers`.
   * Retrieves list of followers for profile.
   *
   * @param profileID - Profile ID.
   * @param limit - Limit.
   * @param page - Page.
   * @param accountType - Account Type (Example: artist_account /
   *   organizer_account / venue_account / fan_account).
   *
   * @return An `Observable` of the `UserFollowByAccountTypesResponse`.
   */
  getProfileFollowers(
    profileID: string,
    limit: string,
    page: string,
    accountType: string,
  ): Observable<UserFollowByAccountTypesResponseSuccess> {
    return this.http.get<UserFollowByAccountTypesResponseSuccess>(
      `${this.host}/api/v1/user/profiles/${profileID}/followers`,
      {
        params: new HttpParams()
          .set('limit', limit || '0')
          .set('page', page || '0')
          .set('accountType', accountType || 'fan_account'),
      },
    );
  }

  /**
   * GET: `/api/v1/user/profiles/{profileID}/following`.
   * Retrieves list of following for profile.
   *
   * @param profileID - Profile ID.
   * @param limit - Limit.
   * @param page - Page.
   * @param accountType - Account Type (Example: artist_account /
   *   organizer_account / venue_account / fan_account).
   *
   * @return An `Observable` of the `UserFollowByAccountTypesResponse`.
   */
  getProfileFollowing(
    profileID: string,
    limit: string,
    page: string,
    accountType: string,
  ): Observable<UserFollowByAccountTypesResponseSuccess> {
    return this.http.get<UserFollowByAccountTypesResponseSuccess>(
      `${this.host}/api/v1/user/profiles/${profileID}/following`,
      {
        params: new HttpParams()
          .set('limit', limit || '0')
          .set('page', page || '0')
          .set('accountType', accountType || 'fan_account'),
      },
    );
  }

  /**
   * GET: `/api/v1/profiles/{followerID}/following/{followedID}`.
   * Check if profile following other profile.
   *
   * @param followerID- Follower ID (Example: ID of follower).
   * @param followedID - Followed ID (Example: ID of followed).
   */
  checkIsProfileFollowing(
    followerID: string,
    followedID: string,
  ): Observable<ResponseSuccess<boolean>> {
    return this.http.get<ResponseSuccess<boolean>>(
      `${this.host}/api/v1/profiles/${followerID}/following/${followedID}`,
    );
  }

  createGuestAccount(guest: any): Observable<ResponseSuccess<GuestModel>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/user/create-guest-account`,
      guest,
    );
  }

  checkEmail(email: string) {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/user/check-email`,
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
