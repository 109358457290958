import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';
import { FAQQuestion } from '@app/models/client/faq/FAQQuestion.model';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LoadFAQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.LoadFAQSuccess: {
      const data: FAQQuestion[] = action.payload.response.data;

      return {
        ...state,
        FAQ: data,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.LoadFAQFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    default: {
      return state;
    }
  }
}
