import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UpdateVenueAccountRequest } from '@app/models/client/venue/update-venue-account-request.model';
import { CreateCalendarItemRequest } from '@app/models/client/create-calendar-item-request.model';
import { UpdateVenueAccountHoursRequest } from '@app/models/client/venue/update-venue-account-hours-request.model';
import { HttpUrlEncodingCodecWithSquareBrackets } from '@app/base/http/HttpUrlEncodingCodecWithSquareBrackets';
// tslint:disable-next-line:max-line-length
import { VenueProfileResponse } from '@app/models/client/venue/venue-profile-response.model';
import { MessagesVenueResponseSuccess } from '@app/models/client/venue/messages-venue-response-success.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { APIService } from '@app/interfaces/api-service.interface';
import { GetArtistMediaResponse } from '@app/models/client/artist/get-artist-media-response.model';
import { CreateVenueAccountRequest } from '@app/models/client/venue/create-venue-account-request.model';
import { GetVenueAccountInfoResponseSuccess } from '@app/models/client/venue/get-venue-account-info-response-success.model';
import { UpdateVenueAccountListingDetailsRequest } from '@app/models/client/venue/update-venue-account-listing-details-request.model';
import { UpdateVenueAccountMediaRequest } from '@app/models/client/venue/update-venue-account-media-request.model';
import { GetVenueAccountCalendarResponse } from '@app/models/client/venue/calendar/get-venue-account-calendar-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { Photo } from '@app/models/client/photo/photo.model';
import { VenueAccountDatesSettings } from '@app/models/client/venue/venue-account-dates-settings.model';

/**
 * Implementation of venue account APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class VenueService implements APIService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * POST: `/api/v1/venue`.
   * Create venue account.
   *
   *
   * @param venue - Venue create artist-artist-profile-private-profiles model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  createVenueProfile(
    venue: CreateVenueAccountRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue`,
      venue,
    );
  }

  /**
   * PUT: `/api/v1/venue/review`.
   * Send on review venue account.
   *
   *
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  sendOnReview(): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/review`,
      {},
    );
  }

  /**
   * DELETE: `/api/v1/venue`.
   * Remove venue account.
   *
   *
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  removeProfile(): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(`${this.host}/api/v1/venue`);
  }

  /**
   * PUT: `/api/v1/venue/about`.
   * Update venue account.
   *
   *
   * @param model - Update artist-artist-profile-private-profiles model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  updateAbout(
    model: UpdateVenueAccountRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/about`,
      model,
    );
  }

  /**
   * POST: `/api/v1/venue/dates`.
   * Add calendar-artist item.
   *
   *
   * @param model - Item create model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  addCalendarItem(
    model: CreateCalendarItemRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/dates`,
      model,
    );
  }

  /**
   * DELETE: `/api/v1/venue/dates/{id}`.
   * Remove calendar-artist item.
   *
   *
   * @param id - Date ID.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  removeCalendarItem(id: string): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/dates/${id}`,
    );
  }

  /**
   * PUT: `/api/v1/venue/dates_settings`.
   * Update venue account dates settings.
   *
   *
   * @param model - Update model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  updateDatesSettings(
    model: VenueAccountDatesSettings,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/dates_settings`,
      { datesSettings: model },
    );
  }

  /**
   * PUT: `/api/v1/venue/hours`.
   * Update venue account hours.
   *
   *
   * @param model - Update model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  updateHours(
    model: UpdateVenueAccountHoursRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/hours`,
      model,
    );
  }

  /**
   * PUT: `/api/v1/venue/listing_details`.
   * Update venue listing details.
   *
   *
   * @param model - Update model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  updateListingDetails(
    model: UpdateVenueAccountListingDetailsRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/listing_details`,
      model,
    );
  }

  /**
   * PUT: `/api/v1/venue/media`.
   * Update venue listing details.
   *
   *
   * @param model - Update model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  updateMedia(
    model: UpdateVenueAccountMediaRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/media`,
      model,
    );
  }

  /**
   * PUT: `/api/v1/venue/venue_photos`.
   * Upload venue photo.
   *
   *
   * @param model - Upload model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  uploadPhoto(model: Photo): Observable<ResponseSuccess<any>> {
    const payload = new FormData();

    payload.append('image', model.image);
    payload.append('title', JSON.stringify(model.title));
    payload.append('other_title', model.other_title);
    payload.append('description', model.description);

    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/venue_photos`,
      payload,
    );
  }

  /**
   * DELETE: `/api/v1/venue/venue_photos/{id}`.
   * Delete venue photo.
   *
   *
   *
   * @param id - Photo ID
   * @return An `Observable` of the `ResponseSuccess`.
   */
  deletePhoto(id: string): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/venue_photos/${id}`,
    );
  }

  /**
   * GET: `/api/v1/venue/artist-artist-profile-private-profiles`.
   * Returns info about venue artist-artist-profile-private-profiles.
   *
   *
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  getVenueProfile(): Observable<GetVenueAccountInfoResponseSuccess> {
    return this.http.get<GetVenueAccountInfoResponseSuccess>(
      `${this.host}/api/v1/venue/profile`,
    );
  }

  /**
   * GET: `/api/v1/venue/artist-artist-profile-private-profiles/{id}`.
   * Returns info about venue artist-artist-profile-private-profiles by id that can be exposed
   * to client for this venue.
   *
   *
   * @param id - Venue Profile ID.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  getVenueProfileById(
    id: string,
  ): Observable<ResponseSuccess<VenueProfileResponse>> {
    return this.http.get<ResponseSuccess<VenueProfileResponse>>(
      `${this.host}/api/v1/venue/profile/${id}`,
    );
  }

  /**
   * POST: `/api/v1/venue/profile_photo`.
   * Upload artist-artist-profile-private-profiles photo.
   *
   *
   * @param image - multipart/form-data file=file.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  uploadProfilePhoto(image: FormData): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/profile_photo`,
      image,
    );
  }

  /**
   * POST: `/api/v1/venue/profile_photo`.
   * Upload artist-artist-profile-private-profiles photo.
   *
   *
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  removeProfilePhoto(): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/venue/profile_photo`,
    );
  }

  /**
   * GET: `/api/v1/venues/{id}/media`.
   * Returns all media for a venue by the ID.
   *
   *
   * @param id - Profile ID.
   *
   * @return An `Observable` of the `ResponseSuccess<GetArtistMediaResponse>`.
   */
  getMedia(id: string): Observable<ResponseSuccess<GetArtistMediaResponse>> {
    return this.http.get<ResponseSuccess<GetArtistMediaResponse>>(
      `${this.host}/api/v1/venues/${id}/media`,
    );
  }

  /**
   * GET: `/api/v1/venues/{id}/upcoming_events`.
   * Returns all upcoming events for a venue by the ID.
   *
   *
   * @param id - Venue Profile ID.
   */
  getUpcomingEvents(
    id: string,
  ): Observable<ResponseSuccess<GetEventsResponse>> {
    return this.http.get<ResponseSuccess<GetEventsResponse>>(
      `${this.host}/api/v1/venues/${id}/upcoming_events`,
    );
  }

  /**
   * GET: `/api/v1/venues/{id}/past_events`.
   * Returns all upcoming events for a venue by the ID.
   *
   *
   * @param id - Venue Profile ID.
   */
  getPastEvents(id: string): Observable<ResponseSuccess<GetEventsResponse>> {
    return this.http.get<ResponseSuccess<GetEventsResponse>>(
      `${this.host}/api/v1/venues/${id}/past_events`,
    );
  }

  /**
   * GET: `/api/v1/venues/{venueAccountGUID}/calendar`.
   * Returns venue dates.
   *
   *
   * @param venueGUID - Venue Profile ID.
   * @param from - From date.
   * @param to - To date.
   *
   * @return An `Observable` of the `IGetVenueAccountCalendarResponseSuccess`.
   */
  getCalendar(
    venueGUID: string,
    from: string,
    to: string,
  ): Observable<ResponseSuccess<GetVenueAccountCalendarResponse>> {
    return this.http.get<ResponseSuccess<GetVenueAccountCalendarResponse>>(
      `${this.host}/api/v1/venues/${venueGUID}/calendar`,
      {
        params: new HttpParams({
          encoder: new HttpUrlEncodingCodecWithSquareBrackets(),
        })
          .set('filter[from]'.toString(), from)
          .set('filter[to]'.toString(), to),
      },
    );
  }

  /***** Messages *****/

  /**
   * GET: `/api/v1/venue/messages`.
   * Get venue messages.
   *
   *
   * @return An `Observable` of the `MessagesVenueResponseSuccess`
   */
  getMessages(): Observable<MessagesVenueResponseSuccess> {
    return this.http.get<MessagesVenueResponseSuccess>(
      `${this.host}/api/v1/venue/messages`,
    );
  }
}
