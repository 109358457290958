import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@app/interfaces/response.interface';
import { APIService } from '@app/interfaces/api-service.interface';

/**
 * Implementation of event-comments APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class CommentService implements APIService {
  host = environment.baseUrl;

  constructor(private http: HttpClient) {}

  /**
   * [POST]: `/api/v1/event-comments`.
   * Create comment.
   */
  createComment(
    userType: string,
    comment: string,
    eventId: string,
  ): Observable<Response> {
    return this.http.post<Response>(
      `${this.host}/comments`,
      { comment, eventId },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'User-Account-Type': `${userType}`,
        }),
      },
    );
  }
}
