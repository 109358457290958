import { Action } from '@ngrx/store';
import { CreatePaymentRequest } from '@app/models/client/payment/create-payment-request.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { CreatePaymentResponse } from '@app/models/client/payment/create-payment-response.model';

export enum ActionTypes {
  CreatePayment = '[Payment] Create Payment',
  CreatePaymentSuccess = '[Payment] Create Payment Success',
  CreatePaymentFailure = '[Payment] Create Payment Failure',
}

// Create Payment

export class CreatePayment implements Action {
  public readonly type = ActionTypes.CreatePayment;

  constructor(
    public payload: {
      request: CreatePaymentRequest;
    },
  ) {}
}

export class CreatePaymentSuccess implements Action {
  public readonly type = ActionTypes.CreatePaymentSuccess;

  constructor(
    public payload: { response: ResponseSuccess<CreatePaymentResponse> },
  ) {}
}

export class CreatePaymentFailure implements Action {
  public readonly type = ActionTypes.CreatePaymentFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | CreatePayment
  | CreatePaymentSuccess
  | CreatePaymentFailure;
