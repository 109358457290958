import { Action } from '@ngrx/store';
import {
  Profile,
  UpdateProfileRequest,
} from '@models/client/profiles/create-profile-response.model';
import { ResponseError } from '@models/shared/response/response-error.model';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import { AddFanProfileData } from '@models/client/user/add-fan-profile.model';

export enum ActionTypes {
  GetProfiles = '[Profile] Get Profiles',
  GetProfilesSuccess = '[Profile] Get Profiles Success',
  GetProfilesFailure = '[Profile] Get Profiles Failure',

  UpdateProfile = '[Profile] Update Profile',
  UpdateProfileSuccess = '[Profile] Update Profile Success',
  UpdateProfileFailure = '[Profile] Update Profile Failure',

  // Add Fan Profile

  AddFanProfile = '[Fan] Add Fan Profile',
  AddFanProfileSuccess = '[Fan] Add Fan Profile Success',
  AddFanProfileFailure = '[Fan] Add Fan Profile Failure',

  // Delete Active Profile
  RemoveActiveProfile = '[Profile] Remove Profile',
}

export class GetProfiles implements Action {
  public readonly type = ActionTypes.GetProfiles;
}

export class GetProfilesSuccess implements Action {
  public readonly type = ActionTypes.GetProfilesSuccess;

  constructor(
    public payload: { profiles: ResponseSuccess<UpdateProfileRequest> },
  ) {}
}

export class GetProfilesFailure implements Action {
  public readonly type = ActionTypes.GetProfilesFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class UpdateProfile implements Action {
  public readonly type = ActionTypes.UpdateProfile;

  constructor(public payload: { profile: Profile }) {}
}

export class UpdateProfileSuccess implements Action {
  public readonly type = ActionTypes.UpdateProfileSuccess;

  constructor(
    public payload: { response: ResponseSuccess<UpdateProfileRequest> },
  ) {}
}

export class UpdateProfileFailure implements Action {
  public readonly type = ActionTypes.UpdateProfileFailure;

  constructor(public payload: { response: ResponseError }) {}
}

/* Add Fan Profile */

export class AddFanProfile implements Action {
  public readonly type = ActionTypes.AddFanProfile;
  constructor(public payload: { profileData: AddFanProfileData }) {}
}

export class AddFanProfileSuccess implements Action {
  public readonly type = ActionTypes.AddFanProfileSuccess;
  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class AddFanProfileFailure implements Action {
  public readonly type = ActionTypes.AddFanProfileFailure;
  constructor(public payload: { response: ResponseError }) {}
}

// Delete

export class RemoveActiveProfile implements Action {
  public readonly type = ActionTypes.RemoveActiveProfile;
}

export type Actions =
  | GetProfiles
  | GetProfilesSuccess
  | GetProfilesFailure
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFailure
  | AddFanProfile
  | AddFanProfileSuccess
  | AddFanProfileFailure
  | RemoveActiveProfile;
