import {
  DonationRequest,
  EventRequest,
  TimeZoneResponse,
} from '@models/client/events/event-request.model';
import { ErrorResponse } from '@models/client/error/error.response.model';

export interface State {
  event: EventRequest | null;
  donations: DonationRequest[] | null;
  timeZone: TimeZoneResponse | null;
  timeZoneId: string;
  loading: boolean;
  loaded: boolean;
  eventID: string;
  updated: boolean;
  created: boolean;
  error: ErrorResponse | null;
}

export const initialState: State = {
  event: null,
  donations: null,
  loading: false,
  loaded: false,
  eventID: '',
  timeZoneId: '',
  updated: false,
  created: false,
  timeZone: null,
  error: null,
};
