import { Actions, ActionTypes } from './artist.actions';
import { initialState, State } from './artist.state';

import { ArtistProfileResponse } from '@app/models/client/artist/artist-profile-response.model';
import { StoreData } from '@app/models/shared/store-data.model';
import { VideoLink } from '@app/models/client/video/video-link.model';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.DownloadPDF:
      break;
    case ActionTypes.DownloadPDFSuccess:
      break;
    case ActionTypes.DownloadPDFFailure:
      break;
    case ActionTypes.DownloadRiderBackstage:
      break;
    case ActionTypes.DownloadRiderBackstageSuccess:
      break;
    case ActionTypes.DownloadRiderBackstageFailure:
      break;
    case ActionTypes.DownloadRiderTechnical:
      break;
    case ActionTypes.DownloadRiderTechnicalSuccess:
      break;
    case ActionTypes.DownloadRiderTechnicalFailure:
      break;
    case ActionTypes.DownloadRiderHospitality:
      break;
    case ActionTypes.DownloadRiderHospitalitySuccess:
      break;
    case ActionTypes.DownloadRiderHospitalityFailure:
      break;
    case ActionTypes.DownloadRiderStage:
      break;
    case ActionTypes.DownloadRiderStageSuccess:
      break;
    case ActionTypes.DownloadRiderStageFailure:
      break;
    case ActionTypes.UpdateRiderInfo:
      break;
    case ActionTypes.UpdateRiderInfoSuccess:
      break;
    case ActionTypes.UpdateRiderInfoFailure:
      break;
    case ActionTypes.ReviewProfileFailure:
      break;

    case ActionTypes.RemoveMediaFile: {
      return {
        ...state,
        removingMedia: true,
      };
    }
    case ActionTypes.RemovePhotoSuccess: {
      return {
        ...state,
        mediaResponse: {
          ...state.mediaResponse,
          photos: action.payload.photos,
        },
        removingMedia: false,
      };
    }
    case ActionTypes.RemoveVideoSuccess: {
      return {
        ...state,
        mediaResponse: {
          ...state.mediaResponse,
          video: action.payload.videos,
        },
        removingMedia: false,
      };
    }

    case ActionTypes.RemoveMediaFileFailure: {
      return {
        ...state,
        removingMedia: false,
      };
    }

    case ActionTypes.UploadVideo: {
      return {
        ...state,
        uploadingVideo: true,
      };
    }
    case ActionTypes.UploadVideoSuccess: {
      return {
        ...state,
        mediaResponse: {
          ...state.mediaResponse,
          video: action.payload.videos,
        },
        uploadingVideo: false,
      };
    }
    case ActionTypes.UploadVideoFailure: {
      return {
        ...state,
        uploadingVideo: false,
      };
    }
    case ActionTypes.SetPhotoToMedia: {
      return {
        ...state,
        mediaResponse: {
          ...state.mediaResponse,
          photos: [...state.mediaResponse.photos, action.payload],
        },
      };
    }

    case ActionTypes.CleanEvents: {
      return {
        ...state,
        pastEventsResponse: { events: [], count: 0 },
        upcomingEventsResponse: { events: [], count: 0 },
      };
    }

    case ActionTypes.CleanAccount: {
      return {
        ...state,
        profileByID: null,
      };
    }

    case ActionTypes.CreateProfile: {
      return {
        ...state,
        loaded: false,
        hasError: false,
        error: null,
        updated: false,
      };
    }

    case ActionTypes.CreateProfileSuccess: {
      return {
        ...state,
        logined: true,
        loaded: true,
        loading: false,
        hasError: false,
        updated: true,
        error: null,
      };
    }

    case ActionTypes.ReviewProfile: {
      return {
        ...state,
        onReview: false,
      };
    }

    case ActionTypes.ReviewProfileSuccess: {
      return {
        ...state,
        onReview: true,
      };
    }

    case ActionTypes.UpdateOnReviewState: {
      return {
        ...state,
        onReview: action.payload,
      };
    }

    case ActionTypes.CreateProfileFailure: {
      return {
        ...state,
        logined: false,
        loaded: true,
        loading: false,
        hasError: true,
        error: action.payload.response,
      };
    }

    case ActionTypes.SetProfile: {
      const profile: Profile = action.payload.profile;

      return {
        ...state,
        profile,
        logined: true,
        loading: false,
        loaded: true,
        updated: false,
      };
    }

    case ActionTypes.GetProfileByID: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.GetProfileByIDSuccess: {
      const profileByID: ArtistProfileResponse = action.payload.profile;

      return {
        ...state,
        profileByID,
        logined: true,
        loading: false,
        loaded: true,
      };
    }

    case ActionTypes.GetProfileByIDFailure: {
      const profileByID: ArtistProfileResponse = null;

      return {
        ...state,
        profileByID,
        logined: false,
        loading: false,
        loaded: true,
      };
    }

    case ActionTypes.UpdateProfile: {
      const storeData = new StoreData<VideoLink>();
      storeData.loading = true;

      return {
        ...state,
        profileUpdateResultData: storeData,
        updated: false,
      };
    }

    case ActionTypes.UpdateProfileSuccess: {
      const storeData = new StoreData<VideoLink>();
      storeData.data = action.payload.response.data;
      storeData.loading = false;
      storeData.loaded = true;

      return {
        ...state,
        profileUpdateResultData: storeData,
        updated: true,
      };
    }

    case ActionTypes.UpdateProfileFailure: {
      const storeData = new StoreData<VideoLink>();

      return {
        ...state,
        profileUpdateResultData: storeData,
      };
    }

    case ActionTypes.SetProfilePhoto: {
      return {
        ...state,
      };
    }

    case ActionTypes.SetIsUpdate: {
      return {
        ...state,
        updated: action.payload,
      };
    }

    case ActionTypes.SetProfilePhotoSuccess: {
      return {
        ...state,
      };
    }

    case ActionTypes.SetProfilePhotoFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.UploadArtistPhoto: {
      return {
        ...state,
        updated: false,
      };
    }

    case ActionTypes.UploadArtistPhotoSuccess: {
      return {
        ...state,
        updated: true,
      };
    }

    case ActionTypes.UploadArtistPhotoFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.RemoveArtistPhoto: {
      return {
        ...state,
      };
    }

    case ActionTypes.RemoveArtistPhotoSuccess: {
      return {
        ...state,
      };
    }

    case ActionTypes.RemoveArtistPhotoFailure: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetMedia: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }
    case ActionTypes.GetMediaSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        mediaResponse: {
          photos: action.payload.response.data.photos || [],
          video: action.payload.response.data.video || [],
        },
      };
    }
    case ActionTypes.GetMediaFailure: {
      return {
        ...state,
        loaded: false,
        loading: false,
        mediaResponse: null,
      };
    }

    /*** Calendar ***/

    case ActionTypes.GetCalendar: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.GetCalendarSuccess: {
      return {
        ...state,
        loaded: true,
        calendarItems: action.payload.response.data.items,
        calendarSettings: action.payload.response.data.settings,
      };
    }

    case ActionTypes.GetCalendarFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.AddCalendarItem: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.AddCalendarItemSuccess: {
      state.calendarItems.push(action.payload.response.data);

      return {
        ...state,
        loaded: true,
        calendarItems: state.calendarItems,
      };
    }

    case ActionTypes.AddCalendarItemFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveCalendarItem: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.RemoveCalendarItemSuccess: {
      const index = state.calendarItems.indexOf(
        action.payload.response.data.id,
      );

      return {
        ...state,
        loaded: true,
        calendarItems:
          index > -1
            ? state.calendarItems.splice(index, 1)
            : state.calendarItems,
      };
    }

    case ActionTypes.RemoveCalendarItemFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UpdateCalendarSettings: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.UpdateCalendarSettingsSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UpdateCalendarSettingsFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    /*** Booking ***/

    case ActionTypes.UploadBookingSettings: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.UploadBookingSettingsSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UploadBookingSettingsFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.GetBookingSettings: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.GetBookingSettingsSuccess: {
      return {
        ...state,
        loaded: true,
        bookingSettings: action.payload.response.data,
      };
    }

    case ActionTypes.GetBookingSettingsFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    /*** Riders ***/

    case ActionTypes.GetRiders: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.GetRidersSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.GetRidersFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    // Backstage

    case ActionTypes.UploadRiderBackstage: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.UploadRiderBackstageSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UploadRiderBackstageFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveRiderBackstage: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.RemoveRiderBackstageSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveRiderBackstageFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    // Hospitality

    case ActionTypes.UploadRiderHospitality: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.UploadRiderHospitalitySuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UploadRiderHospitalityFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveRiderHospitality: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.RemoveRiderHospitalitySuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveRiderHospitalityFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    // Stage

    case ActionTypes.UploadRiderStage: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.UploadRiderStageSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UploadRiderStageFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveRiderStage: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.RemoveRiderStageSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveRiderStageFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    // Technical

    case ActionTypes.UploadRiderTechnical: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.UploadRiderTechnicalSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UploadRiderTechnicalFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveRiderTechnical: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.RemoveRiderTechnicalSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveRiderTechnicalFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    // Upcoming events

    case ActionTypes.GetUpcomingEvents: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.GetUpcomingEventsSuccess: {
      return {
        ...state,
        loaded: true,
        upcomingEventsResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetUpcomingEventsFailure: {
      return {
        ...state,
        loaded: true,
        upcomingEventsResponse: null,
      };
    }

    // Past events

    case ActionTypes.GetPastEvents: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.GetPastEventsSuccess: {
      return {
        ...state,
        loaded: true,
        pastEventsResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetPastEventsFailure: {
      return {
        ...state,
        loaded: true,
        pastEventsResponse: null,
      };
    }

    case ActionTypes.RemoveProfile: {
      return {
        ...state,
        profile: null,
        loaded: false,
        logined: false,
        loading: false,
      };
    }

    case ActionTypes.SetPageStatus: {
      return {
        ...state,
        pageStatus: action.payload.status,
      };
    }

    case ActionTypes.SetCreateArtistSteps: {
      return {
        ...state,
        createArtistSteps: action.payload.steps,
      };
    }

    /***** Messages *****/

    case ActionTypes.GetProfileMessages: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case ActionTypes.GetProfileMessagesSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        messagesResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetProfileMessagesFailure: {
      return {
        ...state,
        loaded: false,
        loading: false,
        messagesResponse: null,
      };
    }

    case ActionTypes.FollowAccount: {
      return {
        ...state,
      };
    }
    case ActionTypes.FollowAccountSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.FollowAccountFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.UnfollowAccount: {
      return {
        ...state,
      };
    }
    case ActionTypes.UnfollowAccountSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.UnfollowAccountFailure: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
