export interface State {
  percentage: string;
  response: any;
  url: string;
  fanAvatar: string;
  artistAvatar: string;
  organizerAvatar: string;
  eventHorizontalImage: string;
  artistMediaImage: string;
  eventVerticalImage: string;
  eventMediaImage: string;
  error: any;
  upload: boolean;
}

export const initialState: State = {
  percentage: '',
  response: null,
  url: '',
  fanAvatar: '',
  artistAvatar: '',
  organizerAvatar: '',
  eventHorizontalImage: '',
  eventVerticalImage: '',
  artistMediaImage: '',
  eventMediaImage: '',
  error: null,
  upload: false,
};
