import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.InitUploadPhoto: {
      return {
        ...state,
        upload: true,
      };
    }

    case ActionTypes.UploadPhotoSuccess: {
      const response = action.payload.response;
      let updatedState = { ...state };

      switch (response?.type) {
        case 'FanAvatar':
          updatedState = {
            ...updatedState,
            fanAvatar: response.url,
          };
          break;
        case 'ArtistAvatar':
          updatedState = {
            ...updatedState,
            artistAvatar: response.url,
          };
          break;
        case 'OrganizerAvatar':
          updatedState = {
            ...updatedState,
            organizerAvatar: response.url,
          };
          break;
        case 'ArtistMediaImage':
          updatedState = {
            ...updatedState,
            artistMediaImage: response.url,
          };
          break;
        case 'EventMediaImage':
          updatedState = {
            ...updatedState,
            eventMediaImage: response.url,
          };
          break;
        case 'EventVerticalImage':
          updatedState = {
            ...updatedState,
            eventVerticalImage: response.url,
          };
          break;
        case 'EventHorizontalImage':
          updatedState = {
            ...updatedState,
            eventHorizontalImage: response.url,
          };
          break;
        default:
          updatedState = {
            ...updatedState,
            url: response.url,
          };
      }

      return {
        ...updatedState,
        response,
        upload: false,
      };
    }

    case ActionTypes.UploadPhotoFailure: {
      return {
        ...state,
        response: action.payload.response,
        error: action.payload.response?.error,
        upload: false,
      };
    }

    case ActionTypes.UploadPhotoInProgress: {
      return {
        ...state,
        percentage: action.payload.percentage,
      };
    }

    case ActionTypes.CleanMediaState: {
      return {
        ...state,
        percentage: '',
        response: null,
        url: '',
        fanAvatar: '',
        artistAvatar: '',
        organizerAvatar: '',
        eventHorizontalImage: '',
        eventMediaImage: '',
      };
    }

    case ActionTypes.CleanErrorState: {
      return {
        ...state,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}
