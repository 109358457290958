import { Injectable } from '@angular/core';
import { IsPlatformService } from '@services/client/is-platform/is-platform.service';

const APP_PREFIX = 'MOUSE-LIVE-';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private isPlatformService: IsPlatformService) {}

  static isLocalStorageAvailable(): boolean {
    try {
      const testKey = '__test__';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  static loadInitialState() {
    if (!LocalStorageService.isLocalStorageAvailable()) {
      return {};
    }

    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0
                  ? token
                  : token.charAt(0).toUpperCase() + token.slice(1),
              )
              .join(''),
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  setItem(key: string, value: any) {
    if (
      this.isPlatformService.isBrowser() &&
      LocalStorageService.isLocalStorageAvailable()
    ) {
      localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
    }
  }

  getItem(key: string) {
    if (
      this.isPlatformService.isBrowser() &&
      LocalStorageService.isLocalStorageAvailable()
    ) {
      return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
    }
    return null;
  }

  removeItem(key: string) {
    if (
      this.isPlatformService.isBrowser() &&
      LocalStorageService.isLocalStorageAvailable()
    ) {
      localStorage.removeItem(`${APP_PREFIX}${key}`);
    }
  }

  /** Tests that localStorage exists, can be written to, and read from. */
  testLocalStorage() {
    const testValue = 'testValue';
    const testKey = 'testKey';
    let retrievedValue: string;
    const errorMessage = 'localStorage did not return expected value';

    this.setItem(testKey, testValue);
    // eslint-disable-next-line prefer-const
    retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }
}
