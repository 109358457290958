import { ErrorResponse } from '@models/client/error/error.response.model';
import {
  DashboardOverviewInterface,
  RecentOrdersResponse,
} from '@models/client/dashboard/dashboard.model';

export interface State {
  error: ErrorResponse | null;
  dashboardOverview: DashboardOverviewInterface;
  recentOrders: RecentOrdersResponse;
  dashboardOverviewByEvent: DashboardOverviewInterface;
  recentOrdersByEvent: RecentOrdersResponse;
}

export const initialState: State = {
  error: null,
  recentOrders: null,
  dashboardOverview: null,
  dashboardOverviewByEvent: null,
  recentOrdersByEvent: null,
};
