import { createSelector } from '@ngrx/store';
import { State } from './state';
import { StateStatus } from '@app/models/client/state/state-status.model';
import { RootStoreState } from '@app/store/root';

export const getSupportState = (state: RootStoreState.State) =>
  state.client.support;

export const getFAQState = createSelector(
  getSupportState,
  (state: State) => state.FAQ,
);

export const getFAQStateStatus = createSelector(
  getSupportState,
  (state: State): StateStatus => {
    return {
      loading: state.loading,
      loaded: state.loaded,
    };
  },
);
