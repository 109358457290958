import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getGenresState = (state: RootStoreState.State) =>
  state.client.genres;

export const getGenresEntities = createSelector(
  getGenresState,
  (state: State) => state.entities,
);
