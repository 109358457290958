import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getGoingFansState = (state: RootStoreState.State) =>
  state.client.goingFans;

export const getGoingFansEntities = createSelector(
  getGoingFansState,
  (state: State) => state.entities,
);

export const getGoingFansCount = createSelector(
  getGoingFansState,
  (state: State) => state.count,
);
