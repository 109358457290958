import { createSelector } from '@ngrx/store';
import { State } from './video.state';
import { RootStoreState } from '@app/store/root';

export const getVideoState = (state: RootStoreState.State) =>
  state.client.video;

export const getVideoResponseState = createSelector(
  getVideoState,
  (state: State) => state.videoResponse,
);
