import { EArtistPageStatus } from '@app/enums/artist/artist-page-status.enum';
import { ArtistAccountCalendarItem } from '@app/models/client/artist/calendar/artist-account-calendar-item.model';
import { ArtistAccountCalendarSettings } from '@app/models/client/artist/calendar/artist-account-calendar-settings.model';
import { MessagesArtistResponse } from '@app/models/client/artist/messages-artist-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { GetArtistMediaResponse } from '@app/models/client/artist/get-artist-media-response.model';
import { ArtistAccountBookingSettings } from '@app/models/client/artist/artist-booking-settings.model';
import { CreateArtistSteps } from '@app/models/client/artist/create-artist-steps.model';
import { ArtistProfileResponse } from '@app/models/client/artist/artist-profile-response.model';
import { StoreData } from '@app/models/shared/store-data.model';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export interface State {
  profile: Profile;
  profileByID: ArtistProfileResponse;
  loading: boolean;
  loaded: boolean;
  logined: boolean;
  hasError: boolean;
  updated: boolean;
  error: any;
  createArtistSteps: CreateArtistSteps;
  pageStatus: EArtistPageStatus;
  mediaResponse: GetArtistMediaResponse;
  calendarItems: ArtistAccountCalendarItem[];
  calendarSettings: ArtistAccountCalendarSettings;
  bookingSettings: ArtistAccountBookingSettings;
  messagesResponse: MessagesArtistResponse[];
  upcomingEventsResponse: GetEventsResponse;
  pastEventsResponse: GetEventsResponse;
  profileUpdateResultData: StoreData<any>;
  onReview: boolean;
  uploadingVideo: boolean;
  removingMedia: boolean;
}

export const initialState: State = {
  profile: null,
  profileByID: null,
  loading: true,
  loaded: false,
  updated: false,
  logined: false,
  hasError: false,
  error: null,
  createArtistSteps: null,
  pageStatus: EArtistPageStatus.Profile,
  mediaResponse: null,
  calendarItems: [],
  calendarSettings: null,
  bookingSettings: null,
  messagesResponse: [],
  upcomingEventsResponse: null,
  pastEventsResponse: null,
  profileUpdateResultData: new StoreData<any>(),
  onReview: false,
  uploadingVideo: false,
  removingMedia: false,
};
