import { UserLogin } from '@app/models/client/user/user-login.model';
import { SocialUser } from '@app/models/client/social/social-user.model';
import { EAccountTypes } from '@app/enums/account-types.enum';
import { UserToken } from '@app/models/shared/user-token.model';
import { UserFollowByAccountTypesResponse } from '@app/models/client/user/user-follow-by-account-types-response.model';
import { GuestModel } from '@models/client/fan/guest.model';
import { GetPaymentMethodsResponse } from '@modules/api/models/get-payment-methods-response';

export interface State {
  userForm: UserLogin;
  token: string;
  tokenForPasswordUpdate: string;
  expire: string;
  tokenResponse: UserToken;
  guestTokenResponse: GuestModel;
  logined: boolean;
  loading: boolean;
  loaded: boolean;
  profile: any;
  type: string;
  error: string;
  social: boolean;
  socialUser: SocialUser;
  haveProfiles: boolean;
  accountType: EAccountTypes; // Fan, Artist, Venue, Organizer
  appUserSettings: any;
  clientSecret: string;
  paymentMethodID: string;
  profileFollowers: UserFollowByAccountTypesResponse;
  profileFollowing: UserFollowByAccountTypesResponse;
  isFollowing: boolean;
  created: boolean;
  userUpdated: boolean;
  ticketsBy: any;
  paymentSuccess: any;
  saveSuccess: any;
  paymentError: any;
  paymentMethods: GetPaymentMethodsResponse | null;
  paymentInProgress: boolean;
  activeAccount: any;
  confirmEmail: any;
  confirmMagicLink: any;
  checkEmail: any;
  isRemoveUser: boolean;
  removeUserError: any;
}

export interface IConfirmMagicLink {
  accountTypes?: ('Fan' | 'Organizer' | 'Artist')[];
  expire?: string;
  token?: string;
}

export const initialState: State = {
  confirmEmail: null,
  removeUserError: null,
  isRemoveUser: false,
  confirmMagicLink: null,
  userForm: null,
  token: '',
  tokenForPasswordUpdate: '',
  expire: '',
  tokenResponse: null,
  guestTokenResponse: null,
  logined: false,
  loading: false,
  loaded: false,
  profile: null,
  type: '',
  error: '',
  social: false,
  socialUser: null,
  haveProfiles: false,
  accountType: null,
  paymentMethods: null,
  appUserSettings: {},
  clientSecret: null,
  paymentMethodID: null,
  profileFollowers: null,
  profileFollowing: null,
  isFollowing: false,
  created: false,
  userUpdated: false,
  ticketsBy: [],
  paymentSuccess: null,
  saveSuccess: null,
  paymentError: null,
  paymentInProgress: false,
  activeAccount: null,
  checkEmail: null,
};
