import { Action } from '@ngrx/store';

import { ArtistProfileResponse } from '@app/models/client/artist/artist-profile-response.model';
import { Response } from '@app/interfaces/response.interface';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { EArtistPageStatus } from '@app/enums/artist/artist-page-status.enum';
// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
import { GetArtistAccountBookingSettingsResponseSuccess } from '@app/models/client/artist/booking/get-artist-account-booking-settings-response-success.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { MessagesArtistResponseSuccess } from '@app/models/client/artist/messages-artist-response-success.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { GetArtistMediaResponse } from '@app/models/client/artist/get-artist-media-response.model';
import { ArtistAccountBookingSettings } from '@app/models/client/artist/artist-booking-settings.model';
import { CreateArtistSteps } from '@app/models/client/artist/create-artist-steps.model';
import { GetArtistAccountCalendarResponse } from '@app/models/client/artist/calendar/get-artist-account-calendar-response.model';
import { ArtistAccountCalendarItem } from '@app/models/client/artist/calendar/artist-account-calendar-item.model';
import { AccountType } from '@app/models/admin/accounts/account-type.enum';
import { UpdateArtistAccountRequest } from '@app/models/client/artist/update-artist-account-request.model';
import { ArtistRiderInterface } from '@app/interfaces/artist/artist-rider.interface';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export enum ActionTypes {
  CleanEvents = '[Artist] Clean Events',
  CleanAccount = '[Artist] Clean Account',
  UpdateOnReviewState = '[Artist] Update On ReviewState',

  SetIsUpdate = '[Artist] Set IsUpdate',

  CreateProfile = '[Artist] Create Profile',
  CreateProfileSuccess = '[Artist] Create Profile Success',
  CreateProfileFailure = '[Artist] Create Profile Failure',

  SetProfile = '[Artist] Set Profile',

  ReviewProfile = '[Artist] Send Profile To Review',
  ReviewProfileSuccess = '[Artist] Send Profile To Review Success',
  ReviewProfileFailure = '[Artist] Send Profile To Review Failure',

  GetProfileByID = '[Artist] Get Profile By ID',
  GetProfileByIDSuccess = '[Artist] Get Profile By ID Success',
  GetProfileByIDFailure = '[Artist] Get Profile By ID Failure',

  UpdateProfile = '[Artist] Update Profile',
  UpdateProfileSuccess = '[Artist] Update Profile Success',
  UpdateProfileFailure = '[Artist] Update Profile Failure',

  SetProfilePhoto = '[Artist] Set Profile Photo',
  SetProfilePhotoSuccess = '[Artist] Set Profile Photo Success',
  SetProfilePhotoFailure = '[Artist] Set Profile Photo Failure',

  UploadArtistPhoto = '[Artist] Upload Artist Photo',
  UploadArtistPhotoSuccess = '[Artist] Upload Artist Photo Success',
  UploadArtistPhotoFailure = '[Artist] Upload Artist Photo Failure',

  RemoveArtistPhoto = '[Artist] Remove Artist Photo',
  RemoveArtistPhotoSuccess = '[Artist] Remove Artist Photo Success',
  RemoveArtistPhotoFailure = '[Artist] Remove Artist Photo Failure',

  GetMedia = '[Artist] Get Media',
  GetMediaSuccess = '[Artist] Get Media Success',
  GetMediaFailure = '[Artist] Get Media Failure',
  SetPhotoToMedia = '[Artist] Set Photo To Media',

  // Calendar

  GetCalendar = '[Artist] Get Calendar',
  GetCalendarSuccess = '[Artist] Get Calendar Success',
  GetCalendarFailure = '[Artist] Get Calendar Failure',

  AddCalendarItem = '[Artist] Add Calendar Item',
  AddCalendarItemSuccess = '[Artist] Add Calendar Item Success',
  AddCalendarItemFailure = '[Artist] Add Calendar Item Failure',

  RemoveCalendarItem = '[Artist] Remove Calendar Item',
  RemoveCalendarItemSuccess = '[Artist] Remove Calendar Item Success',
  RemoveCalendarItemFailure = '[Artist] Remove Calendar Item Failure',

  UpdateCalendarSettings = '[Artist] Update Calendar Settings',
  UpdateCalendarSettingsSuccess = '[Artist] Update Calendar Settings Success',
  UpdateCalendarSettingsFailure = '[Artist] Update Calendar Settings Failure',

  // Booking

  UploadBookingSettings = '[Artist] Upload Booking Settings',
  UploadBookingSettingsSuccess = '[Artist] Upload Booking Settings Success',
  UploadBookingSettingsFailure = '[Artist] Upload Booking Settings Failure',

  GetBookingSettings = '[Artist] Get Booking Settings',
  GetBookingSettingsSuccess = '[Artist] Get Booking Settings Success',
  GetBookingSettingsFailure = '[Artist] Get Booking Settings Failure',

  // Riders

  GetRiders = '[Artist] Get Riders',
  GetRidersSuccess = '[Artist] Get Riders Success',
  GetRidersFailure = '[Artist] Get Riders Failure',

  UpdateRiderInfo = '[Artist] Update Rider Information',
  UpdateRiderInfoSuccess = '[Artist] Update Rider Information Success',
  UpdateRiderInfoFailure = '[Artist] Update Rider Information Failure',

  // Backstage

  UploadRiderBackstage = '[Artist] Upload Rider Backstage',
  UploadRiderBackstageSuccess = '[Artist] Upload Rider Backstage Success',
  UploadRiderBackstageFailure = '[Artist] Upload Rider Backstage Failure',

  RemoveRiderBackstage = '[Artist] Remove Rider Backstage',
  RemoveRiderBackstageSuccess = '[Artist] Remove Rider Backstage Success',
  RemoveRiderBackstageFailure = '[Artist] Remove Rider Backstage Failure',

  DownloadRiderBackstage = '[Artist] Download Rider Backstage',
  DownloadRiderBackstageSuccess = '[Artist] Download Rider Backstage Success',
  DownloadRiderBackstageFailure = '[Artist] Download Rider Backstage Failure',

  // Hospitality

  UploadRiderHospitality = '[Artist] Upload Rider Hospitality',
  UploadRiderHospitalitySuccess = '[Artist] Upload Rider Hospitality Success',
  UploadRiderHospitalityFailure = '[Artist] Upload Rider Hospitality Failure',

  RemoveRiderHospitality = '[Artist] Remove Rider Hospitality',
  RemoveRiderHospitalitySuccess = '[Artist] Remove Rider Hospitality Success',
  RemoveRiderHospitalityFailure = '[Artist] Remove Rider Hospitality Failure',

  DownloadRiderHospitality = '[Artist] Download Rider Hospitality',
  DownloadRiderHospitalitySuccess = '[Artist] Download Rider Hospitality Success',
  DownloadRiderHospitalityFailure = '[Artist] Download Rider Hospitality Failure',

  // Stage

  UploadRiderStage = '[Artist] Upload Rider Stage',
  UploadRiderStageSuccess = '[Artist] Upload Rider Stage Success',
  UploadRiderStageFailure = '[Artist] Upload Rider Stage Failure',

  RemoveRiderStage = '[Artist] Remove Rider Stage',
  RemoveRiderStageSuccess = '[Artist] Remove Rider Stage Success',
  RemoveRiderStageFailure = '[Artist] Remove Rider Stage Failure',

  DownloadRiderStage = '[Artist] Download Rider Stage',
  DownloadRiderStageSuccess = '[Artist] Download Rider Stage Success',
  DownloadRiderStageFailure = '[Artist] Download Rider Stage Failure',

  // Technical

  UploadRiderTechnical = '[Artist] Upload Rider Technical',
  UploadRiderTechnicalSuccess = '[Artist] Upload Rider Technical Success',
  UploadRiderTechnicalFailure = '[Artist] Upload Rider Technical Failure',

  RemoveRiderTechnical = '[Artist] Remove Rider Technical',
  RemoveRiderTechnicalSuccess = '[Artist] Remove Rider Technical Success',
  RemoveRiderTechnicalFailure = '[Artist] Remove Rider Technical Failure',

  DownloadRiderTechnical = '[Artist] Download Rider Technical',
  DownloadRiderTechnicalSuccess = '[Artist] Download Rider Technical Success',
  DownloadRiderTechnicalFailure = '[Artist] Download Rider Technical Failure',

  // Upcoming events

  GetUpcomingEvents = '[Artist] Get Upcoming Events',
  GetUpcomingEventsSuccess = '[Artist] Get Upcoming Events Success',
  GetUpcomingEventsFailure = '[Artist] Get Upcoming Events Failure',

  // PDF
  DownloadPDF = '[Artist] Download PDF',
  DownloadPDFSuccess = '[Artist] Download PDF Success',
  DownloadPDFFailure = '[Artist] Download PDF Failure',

  // Past events

  GetPastEvents = '[Artist] Get Past Events',
  GetPastEventsSuccess = '[Artist] Get Past Events Success',
  GetPastEventsFailure = '[Artist] Get Past Events Failure',

  RemoveProfile = '[Artist] Remove Profile',

  // Set Page Status

  SetPageStatus = '[Artist] Set Page Status',

  // Set Create Artist Steps

  SetCreateArtistSteps = '[Artist] Set Create Artist Steps',

  /***** Messages *****/

  GetProfileMessages = '[Artist] Get Profile Messages',
  GetProfileMessagesSuccess = '[Artist] Get Profile Messages Success',
  GetProfileMessagesFailure = '[Artist] Get Profile Messages Failure',

  /***** Followers&Following *****/

  FollowAccount = '[Artist] Follow Account',
  FollowAccountSuccess = '[Artist] Follow Account Success',
  FollowAccountFailure = '[Artist] Follow Account Failure',

  UnfollowAccount = '[Artist] Unfollow Account',
  UnfollowAccountSuccess = '[Artist] Unfollow Account Success',
  UnfollowAccountFailure = '[Artist] Unfollow Account Failure',

  RemoveMediaFile = '[Artist] Remove Media File',
  RemovePhotoSuccess = '[Artist] Remove Photo Success',
  RemoveVideoSuccess = '[Artist] Remove Video Success',
  RemoveMediaFileFailure = '[Artist] Remove Media File Failure',

  UploadVideo = '[Artist] Upload Video',
  UploadVideoSuccess = '[Artist] Upload Video Success',
  UploadVideoFailure = '[Artist] Upload Video Failure',
}

// Upload video
export class UploadVideo implements Action {
  public readonly type = ActionTypes.UploadVideo;

  constructor(public payload: { link: string; title: string }) {}
}

export class UploadVideoSuccess implements Action {
  public readonly type = ActionTypes.UploadVideoSuccess;

  constructor(public payload: { videos: any[] }) {}
}

export class UploadVideoFailure implements Action {
  public readonly type = ActionTypes.UploadVideoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Remove media
export class RemoveMediaFile implements Action {
  public readonly type = ActionTypes.RemoveMediaFile;

  constructor(public payload: { url: string; type: string }) {}
}

export class RemovePhotoSuccess implements Action {
  public readonly type = ActionTypes.RemovePhotoSuccess;

  constructor(public payload: { photos: any[] }) {}
}

export class RemoveVideoSuccess implements Action {
  public readonly type = ActionTypes.RemoveVideoSuccess;

  constructor(public payload: { videos: any[] }) {}
}

export class RemoveMediaFileFailure implements Action {
  public readonly type = ActionTypes.RemoveMediaFileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class CleanEvents implements Action {
  public readonly type = ActionTypes.CleanEvents;
}

export class CleanAccount implements Action {
  public readonly type = ActionTypes.CleanAccount;
}

// Create Profile

export class CreateProfile implements Action {
  public readonly type = ActionTypes.CreateProfile;

  constructor(public payload: { model: any }) {}
}

export class SetIsUpdate implements Action {
  public readonly type = ActionTypes.SetIsUpdate;

  constructor(public payload: boolean) {}
}

export class CreateProfileSuccess implements Action {
  public readonly type = ActionTypes.CreateProfileSuccess;
}

export class CreateProfileFailure implements Action {
  public readonly type = ActionTypes.CreateProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Set Profile

export class SetProfile implements Action {
  public readonly type = ActionTypes.SetProfile;

  constructor(public payload: { profile: Profile }) {}
}

// Get Profile By ID

export class GetProfileByID implements Action {
  public readonly type = ActionTypes.GetProfileByID;

  constructor(public payload: { id: string }) {}
}

export class GetProfileByIDSuccess implements Action {
  public readonly type = ActionTypes.GetProfileByIDSuccess;

  constructor(public payload: { profile: ArtistProfileResponse }) {}
}

export class GetProfileByIDFailure implements Action {
  public readonly type = ActionTypes.GetProfileByIDFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Send Profile To Review

export class UpdateOnReviewState implements Action {
  public readonly type = ActionTypes.UpdateOnReviewState;

  constructor(public payload: boolean) {}
}

export class ReviewProfile implements Action {
  public readonly type = ActionTypes.ReviewProfile;

  constructor(public payload: { profile: any }) {}
}

export class ReviewProfileSuccess implements Action {
  public readonly type = ActionTypes.ReviewProfileSuccess;

  constructor(public payload: { response: Response }) {}
}

export class ReviewProfileFailure implements Action {
  public readonly type = ActionTypes.ReviewProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Update Profile

export class UpdateProfile implements Action {
  public readonly type = ActionTypes.UpdateProfile;

  constructor(public payload: { model: UpdateArtistAccountRequest }) {}
}

export class UpdateProfileSuccess implements Action {
  public readonly type = ActionTypes.UpdateProfileSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateProfileFailure implements Action {
  public readonly type = ActionTypes.UpdateProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Set Profile Photo

export class SetProfilePhoto implements Action {
  public readonly type = ActionTypes.SetProfilePhoto;

  constructor(public payload: { image: FormData }) {}
}

export class SetProfilePhotoSuccess implements Action {
  public readonly type = ActionTypes.SetProfilePhotoSuccess;

  constructor(public payload: { response: Response }) {}
}

export class SetProfilePhotoFailure implements Action {
  public readonly type = ActionTypes.SetProfilePhotoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Upload Artist Photo

export class UploadArtistPhoto implements Action {
  public readonly type = ActionTypes.UploadArtistPhoto;

  constructor(
    public payload: { image: FormData; description?: string; title?: string },
  ) {}
}

export class UploadArtistPhotoSuccess implements Action {
  public readonly type = ActionTypes.UploadArtistPhotoSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UploadArtistPhotoFailure implements Action {
  public readonly type = ActionTypes.UploadArtistPhotoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Remove Artist Photo

export class RemoveArtistPhoto implements Action {
  public readonly type = ActionTypes.RemoveArtistPhoto;

  constructor(public payload: { photoId: string }) {}
}

export class RemoveArtistPhotoSuccess implements Action {
  public readonly type = ActionTypes.RemoveArtistPhotoSuccess;

  constructor(public payload: { response: Response }) {}
}

export class RemoveArtistPhotoFailure implements Action {
  public readonly type = ActionTypes.RemoveArtistPhotoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Media

export class GetMedia implements Action {
  public readonly type = ActionTypes.GetMedia;

  constructor(public payload?: { artistID: string }) {}
}

export class GetMediaSuccess implements Action {
  public readonly type = ActionTypes.GetMediaSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetArtistMediaResponse> },
  ) {}
}

export class GetMediaFailure implements Action {
  public readonly type = ActionTypes.GetMediaFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class SetPhotoToMedia implements Action {
  public readonly type = ActionTypes.SetPhotoToMedia;

  constructor(
    public payload: {
      url: string;
      title: string;
    },
  ) {}
}

/*** Calendar ***/

// Get Calendar

export class GetCalendar implements Action {
  public readonly type = ActionTypes.GetCalendar;

  constructor(public payload: { from: string; to: string }) {}
}

export class GetCalendarSuccess implements Action {
  public readonly type = ActionTypes.GetCalendarSuccess;

  constructor(
    public payload: {
      response: ResponseSuccess<GetArtistAccountCalendarResponse>;
    },
  ) {}
}

export class GetCalendarFailure implements Action {
  public readonly type = ActionTypes.GetCalendarFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Add Calendar Item

export class AddCalendarItem implements Action {
  public readonly type = ActionTypes.AddCalendarItem;

  constructor(public payload: { day: string }) {}
}

export class AddCalendarItemSuccess implements Action {
  public readonly type = ActionTypes.AddCalendarItemSuccess;

  constructor(
    public payload: { response: ResponseSuccess<ArtistAccountCalendarItem> },
  ) {}
}

export class AddCalendarItemFailure implements Action {
  public readonly type = ActionTypes.AddCalendarItemFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Remove Calendar

export class RemoveCalendarItem implements Action {
  public readonly type = ActionTypes.RemoveCalendarItem;

  constructor(public payload: { id: any }) {}
}

export class RemoveCalendarItemSuccess implements Action {
  public readonly type = ActionTypes.RemoveCalendarItemSuccess;

  constructor(public payload: { response: Response }) {}
}

export class RemoveCalendarItemFailure implements Action {
  public readonly type = ActionTypes.RemoveCalendarItemFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Update Calendar Settings

export class UpdateCalendarSettings implements Action {
  public readonly type = ActionTypes.UpdateCalendarSettings;

  constructor(
    public payload: {
      flexible: boolean;
      minAdvanceNotice: number;
      minAdvanceNoticeDays: number;
      minAdvanceNoticeWeeks: number;
      minAdvanceNoticeMonths: number;
    },
  ) {}
}

export class UpdateCalendarSettingsSuccess implements Action {
  public readonly type = ActionTypes.UpdateCalendarSettingsSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateCalendarSettingsFailure implements Action {
  public readonly type = ActionTypes.UpdateCalendarSettingsFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

/*** Booking ***/

// Upload Booking Settings

export class UploadBookingSettings implements Action {
  public readonly type = ActionTypes.UploadBookingSettings;

  constructor(public payload: { settings: ArtistAccountBookingSettings }) {}
}

export class UploadBookingSettingsSuccess implements Action {
  public readonly type = ActionTypes.UploadBookingSettingsSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UploadBookingSettingsFailure implements Action {
  public readonly type = ActionTypes.UploadBookingSettingsFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Booking Settings

export class GetBookingSettings implements Action {
  public readonly type = ActionTypes.GetBookingSettings;
}

export class GetBookingSettingsSuccess implements Action {
  public readonly type = ActionTypes.GetBookingSettingsSuccess;

  constructor(
    public payload: {
      response: GetArtistAccountBookingSettingsResponseSuccess;
    },
  ) {}
}

export class GetBookingSettingsFailure implements Action {
  public readonly type = ActionTypes.GetBookingSettingsFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Booking Settings

export class DownloadPDF implements Action {
  public readonly type = ActionTypes.DownloadPDF;

  constructor(public payload: { url: string; fileName: string }) {}
}

export class DownloadPDFSuccess implements Action {
  public readonly type = ActionTypes.DownloadPDFSuccess;

  constructor(public payload: { response: any }) {}
}

export class DownloadPDFFailure implements Action {
  public readonly type = ActionTypes.DownloadPDFFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

/*** Riders ***/

export class GetRiders implements Action {
  public readonly type = ActionTypes.GetRiders;
}

export class GetRidersSuccess implements Action {
  public readonly type = ActionTypes.GetRidersSuccess;

  constructor(public payload: { response: Response }) {}
}

export class GetRidersFailure implements Action {
  public readonly type = ActionTypes.GetRidersFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class UpdateRiderInfo implements Action {
  public readonly type = ActionTypes.UpdateRiderInfo;

  constructor(public payload: { rider: ArtistRiderInterface }) {}
}

export class UpdateRiderInfoSuccess implements Action {
  public readonly type = ActionTypes.UpdateRiderInfoSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateRiderInfoFailure implements Action {
  public readonly type = ActionTypes.UpdateRiderInfoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Backstage

export class UploadRiderBackstage implements Action {
  public readonly type = ActionTypes.UploadRiderBackstage;

  constructor(public payload: { file: FormData }) {}
}

export class UploadRiderBackstageSuccess implements Action {
  public readonly type = ActionTypes.UploadRiderBackstageSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UploadRiderBackstageFailure implements Action {
  public readonly type = ActionTypes.UploadRiderBackstageFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class RemoveRiderBackstage implements Action {
  public readonly type = ActionTypes.RemoveRiderBackstage;
}

export class RemoveRiderBackstageSuccess implements Action {
  public readonly type = ActionTypes.RemoveRiderBackstageSuccess;

  constructor(public payload: { response: Response }) {}
}

export class RemoveRiderBackstageFailure implements Action {
  public readonly type = ActionTypes.RemoveRiderBackstageFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class DownloadRiderBackstage implements Action {
  public readonly type = ActionTypes.DownloadRiderBackstage;
}

export class DownloadRiderBackstageSuccess implements Action {
  public readonly type = ActionTypes.DownloadRiderBackstageSuccess;

  constructor(public payload: { response: Response }) {}
}

export class DownloadRiderBackstageFailure implements Action {
  public readonly type = ActionTypes.DownloadRiderBackstageFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Hospitality

export class UploadRiderHospitality implements Action {
  public readonly type = ActionTypes.UploadRiderHospitality;

  constructor(public payload: { file: FormData }) {}
}

export class UploadRiderHospitalitySuccess implements Action {
  public readonly type = ActionTypes.UploadRiderHospitalitySuccess;

  constructor(public payload: { response: Response }) {}
}

export class UploadRiderHospitalityFailure implements Action {
  public readonly type = ActionTypes.UploadRiderHospitalityFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class RemoveRiderHospitality implements Action {
  public readonly type = ActionTypes.RemoveRiderHospitality;
}

export class RemoveRiderHospitalitySuccess implements Action {
  public readonly type = ActionTypes.RemoveRiderHospitalitySuccess;

  constructor(public payload: { response: Response }) {}
}

export class RemoveRiderHospitalityFailure implements Action {
  public readonly type = ActionTypes.RemoveRiderHospitalityFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class DownloadRiderHospitality implements Action {
  public readonly type = ActionTypes.DownloadRiderHospitality;
}

export class DownloadRiderHospitalitySuccess implements Action {
  public readonly type = ActionTypes.DownloadRiderHospitalitySuccess;

  constructor(public payload: { response: Response }) {}
}

export class DownloadRiderHospitalityFailure implements Action {
  public readonly type = ActionTypes.DownloadRiderHospitalityFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Stage

export class UploadRiderStage implements Action {
  public readonly type = ActionTypes.UploadRiderStage;

  constructor(public payload: { file: FormData }) {}
}

export class UploadRiderStageSuccess implements Action {
  public readonly type = ActionTypes.UploadRiderStageSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UploadRiderStageFailure implements Action {
  public readonly type = ActionTypes.UploadRiderStageFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class RemoveRiderStage implements Action {
  public readonly type = ActionTypes.RemoveRiderStage;
}

export class RemoveRiderStageSuccess implements Action {
  public readonly type = ActionTypes.RemoveRiderStageSuccess;

  constructor(public payload: { response: Response }) {}
}

export class RemoveRiderStageFailure implements Action {
  public readonly type = ActionTypes.RemoveRiderStageFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class DownloadRiderStage implements Action {
  public readonly type = ActionTypes.DownloadRiderStage;
}

export class DownloadRiderStageSuccess implements Action {
  public readonly type = ActionTypes.DownloadRiderStageSuccess;

  constructor(public payload: { response: Response }) {}
}

export class DownloadRiderStageFailure implements Action {
  public readonly type = ActionTypes.DownloadRiderStageFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Technical

export class UploadRiderTechnical implements Action {
  public readonly type = ActionTypes.UploadRiderTechnical;

  constructor(public payload: { file: FormData }) {}
}

export class UploadRiderTechnicalSuccess implements Action {
  public readonly type = ActionTypes.UploadRiderTechnicalSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UploadRiderTechnicalFailure implements Action {
  public readonly type = ActionTypes.UploadRiderTechnicalFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class RemoveRiderTechnical implements Action {
  public readonly type = ActionTypes.RemoveRiderTechnical;
}

export class RemoveRiderTechnicalSuccess implements Action {
  public readonly type = ActionTypes.RemoveRiderTechnicalSuccess;

  constructor(public payload: { response: Response }) {}
}

export class RemoveRiderTechnicalFailure implements Action {
  public readonly type = ActionTypes.RemoveRiderTechnicalFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class DownloadRiderTechnical implements Action {
  public readonly type = ActionTypes.DownloadRiderTechnical;
}

export class DownloadRiderTechnicalSuccess implements Action {
  public readonly type = ActionTypes.DownloadRiderTechnicalSuccess;

  constructor(public payload: { response: Response }) {}
}

export class DownloadRiderTechnicalFailure implements Action {
  public readonly type = ActionTypes.DownloadRiderTechnicalFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Upcoming Events

export class GetUpcomingEvents implements Action {
  public readonly type = ActionTypes.GetUpcomingEvents;

  constructor(
    public payload?: {
      query: string;
      page: string;
      limit: string;
      filters: any;
    },
  ) {}
}

export class GetUpcomingEventsSuccess implements Action {
  public readonly type = ActionTypes.GetUpcomingEventsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class GetUpcomingEventsFailure implements Action {
  public readonly type = ActionTypes.GetUpcomingEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Past Events

export class GetPastEvents implements Action {
  public readonly type = ActionTypes.GetPastEvents;

  constructor(
    public payload?: {
      query: string;
      page: string;
      limit: string;
      filters: any;
    },
  ) {}
}

export class GetPastEventsSuccess implements Action {
  public readonly type = ActionTypes.GetPastEventsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class GetPastEventsFailure implements Action {
  public readonly type = ActionTypes.GetPastEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Remove Profile

export class RemoveProfile implements Action {
  public readonly type = ActionTypes.RemoveProfile;
}

// Set Page Status

export class SetPageStatus implements Action {
  public readonly type = ActionTypes.SetPageStatus;

  constructor(public payload: { status: EArtistPageStatus }) {}
}

// Set Create Artist Steps

export class SetCreateArtistSteps implements Action {
  public readonly type = ActionTypes.SetCreateArtistSteps;

  constructor(public payload: { steps: CreateArtistSteps }) {}
}

/***** Messages *****/

export class GetProfileMessages implements Action {
  public readonly type = ActionTypes.GetProfileMessages;
}

export class GetProfileMessagesSuccess implements Action {
  public readonly type = ActionTypes.GetProfileMessagesSuccess;

  constructor(public payload: { response: MessagesArtistResponseSuccess }) {}
}

export class GetProfileMessagesFailure implements Action {
  public readonly type = ActionTypes.GetProfileMessagesFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class FollowAccount implements Action {
  public readonly type = ActionTypes.FollowAccount;

  constructor(
    public payload: {
      id: string;
      accountType: AccountType;
    },
  ) {}
}

export class FollowAccountSuccess implements Action {
  public readonly type = ActionTypes.FollowAccountSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class FollowAccountFailure implements Action {
  public readonly type = ActionTypes.FollowAccountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class UnfollowAccount implements Action {
  public readonly type = ActionTypes.UnfollowAccount;

  constructor(
    public payload: {
      id: string;
      accountType: AccountType;
    },
  ) {}
}

export class UnfollowAccountSuccess implements Action {
  public readonly type = ActionTypes.UnfollowAccountSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UnfollowAccountFailure implements Action {
  public readonly type = ActionTypes.UnfollowAccountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | CreateProfile
  | CreateProfileSuccess
  | CreateProfileFailure
  | CleanEvents
  | SetProfile
  | GetProfileByID
  | GetProfileByIDSuccess
  | GetProfileByIDFailure
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFailure
  | SetProfilePhoto
  | SetProfilePhotoSuccess
  | SetProfilePhotoFailure
  | UploadArtistPhoto
  | UploadArtistPhotoSuccess
  | UploadArtistPhotoFailure
  | RemoveArtistPhoto
  | RemoveArtistPhotoSuccess
  | RemoveArtistPhotoFailure
  | GetMedia
  | GetMediaSuccess
  | GetMediaFailure

  // Calendar
  | GetCalendar
  | GetCalendarSuccess
  | GetCalendarFailure
  | AddCalendarItem
  | AddCalendarItemSuccess
  | AddCalendarItemFailure
  | RemoveCalendarItem
  | RemoveCalendarItemSuccess
  | RemoveCalendarItemFailure
  | UpdateCalendarSettings
  | UpdateCalendarSettingsSuccess
  | UpdateCalendarSettingsFailure

  // Booking
  | UploadBookingSettings
  | UploadBookingSettingsSuccess
  | UploadBookingSettingsFailure
  | GetBookingSettings
  | GetBookingSettingsSuccess
  | GetBookingSettingsFailure
  | DownloadPDF
  | DownloadPDFSuccess
  | DownloadPDFFailure

  // Riders
  | GetRiders
  | GetRidersSuccess
  | GetRidersFailure
  | UploadRiderBackstage
  | UploadRiderBackstageSuccess
  | UploadRiderBackstageFailure
  | RemoveRiderBackstage
  | RemoveRiderBackstageSuccess
  | RemoveRiderBackstageFailure
  | DownloadRiderBackstage
  | DownloadRiderBackstageSuccess
  | DownloadRiderBackstageFailure
  | RemoveRiderTechnical
  | RemoveRiderTechnicalSuccess
  | RemoveRiderTechnicalFailure
  | DownloadRiderTechnical
  | DownloadRiderTechnicalSuccess
  | DownloadRiderTechnicalFailure
  | UploadRiderHospitality
  | UploadRiderHospitalitySuccess
  | UploadRiderHospitalityFailure
  | RemoveRiderHospitality
  | RemoveRiderHospitalitySuccess
  | RemoveRiderHospitalityFailure
  | DownloadRiderHospitality
  | DownloadRiderHospitalitySuccess
  | DownloadRiderHospitalityFailure
  | UploadRiderStage
  | UploadRiderStageSuccess
  | UploadRiderStageFailure
  | RemoveRiderStage
  | RemoveRiderStageSuccess
  | RemoveRiderStageFailure
  | DownloadRiderStage
  | DownloadRiderStageSuccess
  | DownloadRiderStageFailure
  | UploadRiderTechnical
  | UploadRiderTechnicalSuccess
  | UploadRiderTechnicalFailure
  | UpdateRiderInfo
  | UpdateRiderInfoSuccess
  | UpdateRiderInfoFailure

  // Upcoming events
  | GetUpcomingEvents
  | GetUpcomingEventsSuccess
  | GetUpcomingEventsFailure

  // Past events
  | GetPastEvents
  | GetPastEventsSuccess
  | GetPastEventsFailure
  | RemoveProfile
  | SetIsUpdate
  | SetPageStatus
  | UpdateOnReviewState
  | CleanAccount
  | SetCreateArtistSteps
  | GetProfileMessages
  | GetProfileMessagesSuccess
  | GetProfileMessagesFailure
  | FollowAccount
  | FollowAccountSuccess
  | FollowAccountFailure
  | ReviewProfile
  | ReviewProfileSuccess
  | ReviewProfileFailure
  | UnfollowAccount
  | UnfollowAccountSuccess
  | UnfollowAccountFailure
  | SetPhotoToMedia
  | RemoveMediaFile
  | RemovePhotoSuccess
  | RemoveVideoSuccess
  | RemoveMediaFileFailure
  | UploadVideo
  | UploadVideoSuccess
  | UploadVideoFailure;
