import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LoadComments: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.LoadCommentsSuccess: {
      return {
        ...state,
        entities: action.payload?.data.comments,
        count: action.payload?.data.count,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.LoadCommentsFailure: {
      return {
        ...state,
        count: 0,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.AddComment: {
      return {
        ...state,
        send: false,
      };
    }
    case ActionTypes.AddCommentSuccess: {
      return {
        ...state,
        send: true,
      };
    }
    case ActionTypes.AddCommentFailure: {
      return {
        ...state,
        send: false,
      };
    }

    default: {
      return state;
    }
  }
}
