import { TicketResponse } from '@app/models/client/events/ticket-response.model';
import {
  ArtistInfo,
  CrowdfundingInfo,
  Donation,
  EventLocation,
  OrganizerInfo,
  OrganizersInfo,
  SubCampaign,
  TicketInfo,
} from '@models/client/events/event-request.model';

export class EventResponse {
  address: string;
  donation?: Donation[];
  city: string;
  country: string;
  serviceFeeIncluded: boolean;
  fundingGoal: number;
  eventDate?: string | Date;
  eventEndDate?: string | Date;
  eventName: string;
  photos: any[];
  videoLinks?: any[];
  eventStatus: any;
  squarePosterPhotoURL?: string;
  crowdfundingEvent: any;
  posterPhotoUrl: any;
  postalCode?: any;
  eventLocation?: any;
  ticketsCount?: number;
  organizerAccount?: {
    firstName: string;
    lastName: string;
    mouseliveId: string;
    orgId: string;
    profilePhotoUrl: string;
  };
  eventTime: string;
  ticketPriceRange?: { min: number; max: number };
  genres: string[];
  hashtags: string[];
  id: string;
  isCrowdfunding: boolean;
  isPublic?: boolean;
  latitude: number;
  longitude: number;
  posterPhotoURL: string;
  spotifyLink: string;
  shortDescription: string;
  shortLink: string;
  state: string;
  artists: any[];
  tickets: TicketResponse[] | TicketInfo[];
  organizerId?: string;
  crowdfunding: {
    fundingEnd: string;
    fundingStart: string;
    crowdfundingGoal: number;
    amountFunded: any;
    percentageFunded: number;
    numberOfBackers: number;
    status: string;
  };
  agePolicy: number;
  refundPolicy: string;
  venueName?: string;
  video: string;
  zipCode: string;
  alternativeEventDates: string[];
  proposedEventDate: string;
  timezone: string;
  street: string;
  plusCode?: string;
  subregion?: string;
  region?: string;
  county?: string;
  cityDistrict?: string;
  ward?: string;
  neighborhood?: string;

  createdAt?: Date;
  updatedAt?: Date;

  description?: string;
  coverImageUrl?: string;
  status?: string;
  isShowDonationLeaderboard?: boolean;

  eventType?: 'paid' | 'crowdfunding';
  crowdfundingInfo?: CrowdfundingInfo;
  timeZone?: string;

  showComments?: boolean;
  subOrganizers?: OrganizersInfo[];
  youtubeUrls?: string[];
  photoGalleryUrls?: string[];
  eventCategories?: string[];
  parentEventName?: string;
  parentEventId?: string;
  tags?: string[];
  artistLineup?: ArtistInfo[];
  ageRestriction?: number;
  donations?: Donation[];
  subCampaigns?: SubCampaign[];

  location?: EventLocation;
  customUrl?: string;

  organizer?: OrganizerInfo;
}
