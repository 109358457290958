import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ProfileService } from '@services/client/profile/profile.service';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import { ResponseError } from '@models/shared/response/response-error.model';
import { ErrorResponseHelper } from '@base/helpers/error-response.helper';
import { AppStoreActions } from '@store/root/app';
import {
  ActionTypes,
  AddFanProfile,
  AddFanProfileFailure,
  AddFanProfileSuccess,
  GetProfiles,
  GetProfilesFailure,
  GetProfilesSuccess,
  UpdateProfile,
  UpdateProfileFailure,
  UpdateProfileSuccess,
} from '@store/root/client/profile/actions';
import { UpdateProfileRequest } from '@models/client/profiles/create-profile-response.model';
import {
  ArtistStoreActions,
  FanStoreActions,
  OrganizerStoreActions,
} from '@store/root/client';
import { Router } from '@angular/router';
import * as UserStoreActions from '@store/root/client/user/actions';
import { UserTokenService } from '@services/client/user-token/user-token.service';

@Injectable()
export class ProfileEffects {
  /**
   * Get Profiles.
   * @action - [Profile] Get Profiles
   * @return:
   *   Success: [Profile] GetProfilesSuccess
   *   Failure: [Profile] GetProfilesFailure && [Errors] AddErrorResponse
   */
  getProfiles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfiles>(ActionTypes.GetProfiles),
      switchMap(() =>
        this.profileService.getProfile().pipe(
          map((profiles) => {
            if (profiles.data) {
              const artistProfile = profiles.data.profiles.find(
                (profile) => profile.profileType === 'artist',
              );
              const fanProfile = profiles.data.profiles.find(
                (profile) => profile.profileType === 'fan',
              );
              const organizerProfile = profiles.data.profiles.find(
                (profile) => profile.profileType === 'organizer',
              );

              return [
                new GetProfilesSuccess({ profiles }),
                new ArtistStoreActions.SetProfile({ profile: artistProfile }),
                new FanStoreActions.SetProfile({ profile: fanProfile }),
                new OrganizerStoreActions.SetProfile({
                  profile: organizerProfile,
                }),
              ];
            } else {
              const errorResponse = new ResponseError();
              errorResponse.error = 'No profiles data available.';
              errorResponse.success = false;
              this.router.navigate(['/']);
              return [
                new GetProfilesFailure({ response: errorResponse }),
                new AppStoreActions.AddErrorResponse({
                  response: errorResponse,
                }),
              ];
            }
          }),
          mergeMap((actions) => actions),
          catchError((errResponse: any) => {
            const errorCode = errResponse?.status;
            if (errorCode === 500) {
              this.userTokenService.removeTokenAndSignOutAccount();
            }

            return ErrorResponseHelper.getErrorResponseErrorObject(
              errResponse,
            ).pipe(
              switchMap((response: ResponseError) => [
                new GetProfilesFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            );
          }),
        ),
      ),
    ),
  );

  /**
   * Update Profile.
   * @action - [Profile] Get Profiles
   * @return:
   *   Success: [Profile] GetProfilesSuccess
   *   Failure: [Profile] GetProfilesFailure && [Errors] AddErrorResponse
   */
  updateProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfile>(ActionTypes.UpdateProfile),
      switchMap((actions) =>
        this.profileService.updateProfile(actions.payload.profile).pipe(
          switchMap((response: ResponseSuccess<UpdateProfileRequest>) => [
            new UpdateProfileSuccess({ response }),
            UserStoreActions.LoadProfiles(),
          ]),
          tap(() => {
            this.userTokenService.updateToken();
          }),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new UpdateProfileFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  addFanProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddFanProfile>(ActionTypes.AddFanProfile),
      switchMap((action) =>
        this.profileService.addFanProfile(action.payload.profileData).pipe(
          switchMap((response) => [
            new AddFanProfileSuccess({ response }),
            UserStoreActions.LoadProfiles(),
          ]),
          tap(() => {
            this.userTokenService.updateToken();
          }),
          catchError((errResponse) =>
            of(new AddFanProfileFailure({ response: errResponse })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private router: Router,
    private userTokenService: UserTokenService,
  ) {}
}
