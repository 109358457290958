import { initialState, State } from '@store/root/client/donors/state';
import { Actions, ActionTypes } from '@store/root/client/donors/actions';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetDonorsByOrgAndSubId: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ActionTypes.GetDonorsByOrgAndSubIdSuccess: {
      return {
        ...state,
        donors: action.payload.response.data,
        loading: false,
        error: null,
        success: true,
      };
    }
    case ActionTypes.GetDonorsByOrgAndSubIdFailure: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response,
      };
    }

    case ActionTypes.RemoveDonorsState: {
      return {
        ...state,
        loading: false,
        success: false,
        error: null,
        loaded: false,
        updated: false,
        donors: null,
      };
    }

    default: {
      return state;
    }
  }
}
