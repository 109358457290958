import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SetCart: {
      return {
        ...state,
        currentTickets: action.payload.currentTickets,
      };
    }

    case ActionTypes.CleanCurrentTickets: {
      return {
        ...state,
        currentTickets: [
          ...state.currentTickets
            .map((event) => {
              if (event && event.eventId === action.payload.eventId) {
                event = {
                  ...event,
                  tickets: event.tickets.filter(
                    (t) => t.id !== action.payload.ticketId,
                  ),
                };
                if (event.tickets.length === 0) {
                  return null;
                }
                return event;
              }
              return event;
            })
            .filter((event) => event !== null),
        ],
      };
    }
    case ActionTypes.SetDonation: {
      return {
        ...state,
        currentTickets: state.currentTickets.map((event) =>
          event.eventId === action.donatInfo.eventId
            ? {
                ...event,
                donationAmount: action.donatInfo.donationAmount,
                isDonationAnonymous: action.donatInfo.isDonationAnonymous,
              }
            : event,
        ),
      };
    }
    case ActionTypes.SetCurrentEventTickets: {
      return {
        ...state,
        currentTickets: [
          ...state.currentTickets.filter((event) => {
            if (event.eventId) {
              return event.eventId !== action.payload.eventId;
            }
          }),
          {
            eventId: action.payload.eventId,
            eventInfo: action.payload.eventInfo,
            donationAmount: action.payload.ticket.ticketInfo.donationAmount,
            isDonationAnonymous:
              action.payload.ticket.ticketInfo.isDonationAnonymous,
            tickets: state.currentTickets?.filter(
              (event) => event.eventId === action.payload.eventId,
            )[0]?.tickets
              ? [
                  action.payload.ticket,
                  ...state.currentTickets
                    ?.find((event) => event.eventId === action.payload.eventId)
                    .tickets.filter((t) => t.id !== action.payload.ticket.id),
                ]
              : [action.payload.ticket],
          },
        ],
      };
    }

    case ActionTypes.CleanCurrentEventTickets: {
      return {
        ...state,
        currentTickets: [
          ...state.currentTickets.filter((event) => {
            if (event.eventId) {
              return event.eventId !== action.payload.eventId;
            }
          }),
        ],
      };
    }
    case ActionTypes.CleanCart: {
      return {
        ...state,
        currentTickets: [
          {
            eventId: '',
            eventInfo: null,
            donationAmount: 0,
            isDonationAnonymous: false,
            tickets: [
              {
                id: '',
                count: 0,
                ticketInfo: null,
              },
            ],
          },
        ],
      };
    }
    default: {
      return state;
    }
  }
}
