import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SetSignUpForm: {
      const signUpForm = action.payload;

      return {
        ...state,
        signUpForm,
      };
    }
    case ActionTypes.SetSignUpSocialForm: {
      const signUpSocialForm = action.payload;

      return {
        ...state,
        signUpSocialForm,
      };
    }
    case ActionTypes.SetType: {
      return {
        ...state,
        type: action.payload,
      };
    }
    case ActionTypes.SetCompanyStatus: {
      return {
        ...state,
        haveCompany: action.payload,
      };
    }
    case ActionTypes.CreateUser: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateUserSocial: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateUserSuccess: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateUserFailure: {
      return {
        ...state,
        errors: action.payload.response,
      };
    }
    case ActionTypes.CreateProfileFan: {
      return {
        ...state,
      };
    }
    case ActionTypes.CreateProfileFanSuccess: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateProfileFanFailure: {
      return {
        ...state,
      };
    }
    case ActionTypes.CreateProfileArtist: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateProfileArtistSuccess: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateProfileArtistFailure: {
      return {
        ...state,
      };
    }
    case ActionTypes.CreateProfileVenue: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateProfileVenueSuccess: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateProfileVenueFailure: {
      return {
        ...state,
      };
    }
    case ActionTypes.CreateProfileOrganizer: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateProfileOrganizerSuccess: {
      return {
        ...state,
        errors: null,
      };
    }
    case ActionTypes.CreateProfileOrganizerFailure: {
      return {
        ...state,
      };
    }
    case ActionTypes.SetFormInfo: {
      return {
        ...state,
        signUpInfoForm: action.payload,
      };
    }
    case ActionTypes.LoadFollows: {
      return {
        ...state,
      };
    }
    case ActionTypes.LoadFollowsSuccess: {
      return {
        ...state,
        follows:
          state.follows === null
            ? action.payload
            : [...state.follows, ...action.payload],
      };
    }
    case ActionTypes.LoadFollowsSearch: {
      return {
        ...state,
      };
    }
    case ActionTypes.LoadFollowsSearchSuccess: {
      return {
        ...state,
        follows: action.payload,
      };
    }

    case ActionTypes.RemoveErrorsCreateUserState: {
      return {
        ...state,
        errors: null,
      };
    }

    default: {
      return state;
    }
  }
}
