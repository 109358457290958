import { Action } from '@ngrx/store';
import { Response } from '@app/interfaces/response.interface';

export enum ActionTypes {
  LoadGoingFans = '[GoingFans] Load GoingFans',
  LoadGoingFansSuccess = '[GoingFans] Load GoingFans Success',
  LoadGoingFansFailure = '[GoingFans] Load GoingFans Failure',
}

/* Load going fans. */

export class LoadGoingFans implements Action {
  public readonly type = ActionTypes.LoadGoingFans;

  constructor(
    public payload: { limit: string; page: string; eventId: string },
  ) {}
}

export class LoadGoingFansSuccess implements Action {
  public readonly type = ActionTypes.LoadGoingFansSuccess;

  constructor(public payload: { data: any }) {}
}

export class LoadGoingFansFailure implements Action {
  public readonly type = ActionTypes.LoadGoingFansFailure;

  constructor(public payload: { response: Response }) {}
}

export type Actions =
  | LoadGoingFans
  | LoadGoingFansSuccess
  | LoadGoingFansFailure;
