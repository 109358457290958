export interface State {
  social: boolean;
  uuid: string;
  confirm: boolean;
  passwordUpdated: boolean;
  error: string;
}

export const initialState: State = {
  social: false,
  uuid: null,
  confirm: false,
  passwordUpdated: false,
  error: null,
};
