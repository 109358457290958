import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { SetTokenForPasswordUpdate } from '../user/actions';

import {
  ActionTypes,
  ConfirmCode,
  ConfirmCodeFailure,
  ConfirmCodeSuccess,
  ResetPassword,
  ResetPasswordFailure,
  ResetPasswordSuccess,
  SignOut,
  UpdatePassword,
  UpdatePasswordFailure,
  UpdatePasswordSuccess,
} from './actions';

import { AuthService } from '@app/services/client/auth/auth.service';
import { AuthResponseSuccess } from '@app/models/client/auth/auth-response-success';
import { Response } from '@app/interfaces/response.interface';
import { UserToken } from '@app/models/shared/user-token.model';
import { LocalStorageService } from '@app/services/client/local-storage/local-storage.service';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { AppStoreActions } from '@app/store/root/app';
import { RootStoreState } from '@app/store/root';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';

@Injectable()
export class SignInEffects {
  public resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetPassword>(ActionTypes.ResetPassword),
      switchMap((action: ResetPassword) =>
        this.authService.resetPassword(action.payload.email).pipe(
          map(
            (response: ResponseSuccess<any>) =>
              new ResetPasswordSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new ResetPasswordFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  public confirmCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ConfirmCode>(ActionTypes.ConfirmCode),
      switchMap((action: ConfirmCode) =>
        this.authService.confirm(action.payload.code, action.payload.uuid).pipe(
          switchMap((response: AuthResponseSuccess) => {
            const token = response.data.token;
            const expire = response.data.expire;

            const userToken: UserToken = {
              token,
              expire,
            };

            return [
              SetTokenForPasswordUpdate({ userToken }),
              new ConfirmCodeSuccess({ response }),
            ];
          }),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new ConfirmCodeFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  public updatePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdatePassword>(ActionTypes.UpdatePassword),
      switchMap((action) =>
        this.authService.updatePassword(action.payload.password).pipe(
          map((response: Response) => {
            return new UpdatePasswordSuccess({ response });
          }),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdatePasswordFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /* Sign out */

  signOut$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SignOut>(ActionTypes.SignOut),
        tap((_) => {
          this.localStorage.removeItem('userData');
          this.localStorage.removeAllItems();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private localStorage: LocalStorageService,
  ) {}
}
