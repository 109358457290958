import { createAction, props } from '@ngrx/store';

import { UserLogin } from '@app/models/client/user/user-login.model';
import { SocialUser } from '@app/models/client/social/social-user.model';
import { UserToken } from '@app/models/shared/user-token.model';
import { UserCreate } from '@app/models/client/user/user-create.model';
import { Response } from '@app/interfaces/response.interface';
import { GetUserInfoResponseSuccess } from '@app/models/client/user/get-user-info-response-success.model';
import { EAccountTypes } from '@app/enums/account-types.enum';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { CreatePaymentMethodResponseSuccess } from '@app/models/client/payment/create-payment-method-response-success.model';
import { SetupIntentResponseSuccess } from '@app/models/client/payment/setup-intent-response-success.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { UserFollowByAccountTypesResponseSuccess } from '@app/models/client/user/user-follow-by-account-types-response-success.model';
import { SetApplicationSettingsRequest } from '@app/models/client/user/set-application-settings-request.model';
import { UpdateUserRequest } from '@app/models/client/user/update-user-request.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { CreatePaymentRequest } from '@models/client/payment/create-payment-request.model';
import { CreatePaymentResponse } from '@models/client/payment/create-payment-response.model';
import { UserPaymentsData } from '@models/client/user/user-payments-data.model';
import { GuestModel } from '@models/client/fan/guest.model';
import { SaveCardToCustomerRequest } from '@modules/api/models/save-card-to-customer-request';
import { GetPaymentMethodsResponse } from '@modules/api/models/get-payment-methods-response';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export enum ActionTypes {
  SetActiveAccount = '[User] Set Active Account',
  RemoveFromFirebase = '[User] Remove From Firebase',
  RemoveFromFirebaseFailure = '[User] Remove From Firebase Failure',
  RemoveFromFirebaseSuccess = '[User] Remove From Firebase Success',
  // Create User

  CreateUser = '[User] Create User',
  CreateUserSuccess = '[User] Create User Success',
  CreateUserFailure = '[User] Create User Failure',

  // Update User

  UpdateUser = '[User] Update User',
  UpdateUserSuccess = '[User] Update User Success',
  UpdateUserFailure = '[User] Update User Failure',
  UpdateUserStatus = '[User] Update User Status',

  // Set User

  SetUser = '[User] Set User',
  SetSocialUser = '[User] Set Social User',

  // Get User

  GetUser = '[User] Get User',
  GetUserSuccess = '[User] Get User Success',
  GetUserFailure = '[User] Get User Failure',

  // Create Guest Account

  CreateGuestAccount = '[Guest] Create Guest',
  CreateGuestSuccess = '[Guest] Create Guest Success',
  CreateGuestFailure = '[Guest] Create Guest Failure',

  // Token

  SetToken = '[User] Set Token',
  RemoveToken = '[User] Remove Token',

  // Token For Password Update
  SetTokenForPasswordUpdate = '[User] Set Token For Password Update',

  // Refresh Token
  RefreshToken = '[User] Refresh Token',
  RefreshTokenSuccess = '[User] Refresh Token Success',
  RefreshTokenFailure = '[User] Refresh Token Failure',

  // Load User

  LoadUser = '[User] Load User',
  LoadUserSuccess = '[User] Load User Success',
  LoadUserFailure = '[User] Load User Failure',

  // Remove User

  RemoveUser = '[User] Remove User',
  RemoveUserFailure = '[User] Remove User Failure',
  RemoveUserSuccess = '[User] Remove User Success',

  // Load User's Profiles

  LoadProfiles = '[User] Load Profiles',
  LoadProfilesSuccess = '[User] Load Profiles Success',
  LoadProfilesFailure = '[User] Load Profiles Failure',

  // Login User

  LoginUser = '[User] Login User',
  LoginUserBySocialGoogle = '[User] Login User By Social Google',
  LoginUserBySocialFacebook = '[User] Login User By Social Facebook',
  LoginUserFailure = '[User] Login User Failure',
  LoginUserSuccess = '[User] Login User Success',

  SetSocialStatusUser = '[User] Set Social Status User',

  // SwitchAccount
  SwitchAccount = '[User] SwitchAccount',

  // Application settings
  GetApplicationSettings = '[User] Get Application Settings',
  GetApplicationSettingsSuccess = '[User] Get Application Settings Success',
  GetApplicationSettingsFailure = '[User] Get Application Settings Failure',

  SetApplicationSettings = '[User] Set Application Settings',
  SetApplicationSettingsSuccess = '[User] Set Application Settings Success',
  SetApplicationSettingsFailure = '[User] Set Application Settings Failure',

  // Setup Intent
  SetupIntent = '[User] Setup Intent',
  SetupIntentSuccess = '[User] Setup Intent Success',
  SetupIntentFailure = '[User] Setup Intent Failure',

  // Confirm Setup Intent
  ConfirmSetupIntent = '[User] Confirm Setup Intent',
  ConfirmSetupIntentSuccess = '[User] Confirm Setup Intent Success',
  ConfirmSetupIntentFailure = '[User] Confirm Setup Intent Failure',

  // Confirm Email
  ConfirmEmail = '[User] Confirm Email',
  ConfirmSuccess = '[User] Confirm Email Success',
  ConfirmFailure = '[User] Confirm Email Failure',

  ConfirmEmailByToken = '[User] Confirm Email By Token',
  ConfirmEmailByCode = '[User] Confirm Email By Code',

  ConfirmEmailSuccess = '[User] Confirm Email By Token Success',
  ConfirmEmailFailure = '[User] Confirm Email By Token Failure',

  // MagicLink

  SendMagicLink = '[User] Send Magic Link',
  SendMagicLinkSuccess = '[User] Send Magic Link Success',
  SendMagicLinkFailure = '[User] Send Magic Link Failure',

  AuthenticateWithMagicLinkByToken = '[User] Authenticate With Magic Link By Token',
  AuthenticateWithMagicLinkByCode = '[User] Authenticate With Magic Link By Code',

  AuthenticateWithMagicLinkSuccess = '[User] Authenticate With Magic Link By Token Success',
  AuthenticateWithMagicLinkFailure = '[User] Authenticate With Magic Link By Token Failure',

  // Login User By Token

  LoginUserByToken = '[User] Login User By Token',
  LoginUserByTokenSuccess = '[User] Login User By Token Success',
  LoginUserByTokenFailure = '[User] Login User By Token Failure',

  // Follows
  GetProfileFollowers = '[User] Get Profile Followers',
  GetProfileFollowersSuccess = '[User] Get Profile Followers Success',
  GetProfileFollowersFailure = '[User] Get Profile Followers Failure',

  GetProfileFollowing = '[User] Get Profile Following',
  GetProfileFollowingSuccess = '[User] Get Profile Following Success',
  GetProfileFollowingFailure = '[User] Get Profile Following Failure',

  CheckIsProfileFollowing = '[User] Check Is Profile Following',
  CheckIsProfileFollowingSuccess = '[User] Check Is Profile Following Success',
  CheckIsProfileFollowingFailure = '[User] Check Is Profile Following Failure',

  SetTicketsBy = '[User] Set Tickets For By',

  CreatePayment = '[User] Create Payment',
  CreatePaymentSuccess = '[User] Create Payment Success',
  CreatePaymentFailure = '[User] Create Payment Failure',

  SavePayment = '[User] Save Payment',
  SavePaymentSuccess = '[User] Save Payment Success',
  SavePaymentFailure = '[User] Save Payment Failure',

  RemovePayment = '[User] Remove Payment',
  RemovePaymentSuccess = '[User] Remove Payment Success',
  RemovePaymentFailure = '[User] Remove Payment Failure',

  GetPayments = '[User] Get Payments',
  GetPaymentsSuccess = '[User] Get Payments Success',
  GetPaymentsFailure = '[User] Get Payments Failure',

  SetPaymentError = '[User] Set Payment Error',
  CleanPayment = '[User] Clean Payment',

  CleanPaymentMethodsState = '[User] Clean Payment Methods',

  CleanPaymentMethodState = '[User] Clean Payment Method',

  CheckEmail = '[User] Check email',
  CheckEmailSuccess = '[User] Check email Success',
  CheckEmailFailure = '[User] Check email Failure',
}

export const SetActiveProfile = createAction(
  ActionTypes.SetActiveAccount,
  props<{ profile: Profile }>(),
);

export const RemoveFromFirebase = createAction(ActionTypes.RemoveFromFirebase);

export const RemoveFromFirebaseFailure = createAction(
  ActionTypes.RemoveFromFirebaseFailure,
  props<{ response: IErrorResponse }>(),
);

export const RemoveFromFirebaseSuccess = createAction(
  ActionTypes.RemoveFromFirebaseSuccess,
  props<{ response: any }>(),
);
/* Create User */

export const CreateUser = createAction(
  ActionTypes.CreateUser,
  props<{ user: UserCreate }>(),
);

export const CreateUserSuccess = createAction(
  ActionTypes.CreateUserSuccess,
  props<{ user: UserCreate }>(),
);

export const CreateUserFailure = createAction(
  ActionTypes.CreateUserFailure,
  props<{ response: IErrorResponse }>(),
);

/* Update User */

export const UpdateUser = createAction(
  ActionTypes.UpdateUser,
  props<{ user: UpdateUserRequest }>(),
);

export const UpdateUserSuccess = createAction(ActionTypes.UpdateUserSuccess);

export const UpdateUserStatus = createAction(ActionTypes.UpdateUserStatus);

export const UpdateUserFailure = createAction(
  ActionTypes.UpdateUserFailure,
  props<{ response: IErrorResponse }>(),
);

/* Set User */

export const SetUser = createAction(
  ActionTypes.SetUser,
  props<{ userLogin: UserLogin }>(),
);

export const SetSocialUser = createAction(
  ActionTypes.SetSocialUser,
  props<{ user: SocialUser }>(),
);

/* Get User */

export const GetUser = createAction(ActionTypes.GetUser);

export const GetUserSuccess = createAction(
  ActionTypes.GetUserSuccess,
  props<{ response: GetUserInfoResponseSuccess }>(),
);

export const GetUserFailure = createAction(
  ActionTypes.GetUserFailure,
  props<{ response: IErrorResponse }>(),
);

/* Create Guest Account */

export const CreateGuestAccount = createAction(
  ActionTypes.CreateGuestAccount,
  props<{ guest: UserPaymentsData }>(),
);

export const CreateGuestAccountSuccess = createAction(
  ActionTypes.CreateGuestSuccess,
  props<{ response: ResponseSuccess<GuestModel> }>(),
);

export const CreateGuestAccountFailure = createAction(
  ActionTypes.CreateGuestFailure,
  props<{ response: ResponseError }>(),
);

/* Token */

export const SetToken = createAction(
  ActionTypes.SetToken,
  props<{ userToken: UserToken }>(),
);

/* Token For Password Update */

export const SetTokenForPasswordUpdate = createAction(
  ActionTypes.SetTokenForPasswordUpdate,
  props<{ userToken: UserToken }>(),
);

export const RemoveToken = createAction(ActionTypes.RemoveToken);

// Refresh Token

export const RefreshToken = createAction(ActionTypes.RefreshToken);

export const RefreshTokenSuccess = createAction(
  ActionTypes.RefreshTokenSuccess,
  props<{ response: ResponseSuccess<UserToken> }>(),
);

export const RefreshTokenFailure = createAction(
  ActionTypes.RefreshTokenFailure,
  props<{ response: ResponseError }>(),
);

/* Load User */

export const LoadUser = createAction(ActionTypes.LoadUser);

export const LoadUserSuccess = createAction(
  ActionTypes.LoadUserSuccess,
  props<{ userData: any }>(),
);

export const LoadUserFailure = createAction(ActionTypes.LoadUserFailure);

/* Remove User */

export const RemoveUser = createAction(ActionTypes.RemoveUser);

export const RemoveUserFailure = createAction(
  ActionTypes.RemoveUserFailure,
  props<{ response: IErrorResponse }>(),
);

export const RemoveUserSuccess = createAction(
  ActionTypes.RemoveUserSuccess,
  props<{ response: any }>(),
);

/* Load User's Profiles */

export const LoadProfiles = createAction(ActionTypes.LoadProfiles);

export const LoadProfilesSuccess = createAction(
  ActionTypes.LoadProfilesSuccess,
);

export const LoadProfilesFailure = createAction(
  ActionTypes.LoadProfilesFailure,
);

/* Login User */

export const LoginUser = createAction(
  ActionTypes.LoginUser,
  props<{ email: string; password: string }>(),
);

export const LoginUserBySocialGoogle = createAction(
  ActionTypes.LoginUserBySocialGoogle,
);

export const LoginUserBySocialFacebook = createAction(
  ActionTypes.LoginUserBySocialFacebook,
);

export const LoginUserFailure = createAction(
  ActionTypes.LoginUserFailure,
  props<{ response: IErrorResponse }>(),
);

export const LoginUserSuccess = createAction(ActionTypes.LoginUserSuccess);

export const SetSocialStatusUser = createAction(
  ActionTypes.SetSocialStatusUser,
);

// Switch account

export const SwitchAccount = createAction(
  ActionTypes.SwitchAccount,
  props<{ accountType: EAccountTypes }>(),
);

/* Application settings */

export const GetApplicationSettings = createAction(
  ActionTypes.GetApplicationSettings,
);

export const GetApplicationSettingsSuccess = createAction(
  ActionTypes.GetApplicationSettingsSuccess,
  props<{ response: Response }>(),
);

export const GetApplicationSettingsFailure = createAction(
  ActionTypes.GetApplicationSettingsFailure,
  props<{ response: IErrorResponse }>(),
);

export const SetApplicationSettings = createAction(
  ActionTypes.SetApplicationSettings,
  props<{ settings: SetApplicationSettingsRequest }>(),
);

export const SetApplicationSettingsSuccess = createAction(
  ActionTypes.SetApplicationSettingsSuccess,
  props<{ response: Response }>(),
);

export const SetApplicationSettingsFailure = createAction(
  ActionTypes.SetApplicationSettingsFailure,
  props<{ response: IErrorResponse }>(),
);

// SetupIntent

export const SetupIntent = createAction(
  ActionTypes.SetupIntent,
  props<{ data: any }>(),
);

export const SetupIntentSuccess = createAction(
  ActionTypes.SetupIntentSuccess,
  props<{ response: CreatePaymentMethodResponseSuccess }>(),
);

export const SetupIntentFailure = createAction(
  ActionTypes.SetupIntentFailure,
  props<{ response: IErrorResponse }>(),
);

export const CleanPayment = createAction(ActionTypes.CleanPayment);

export const CleanPaymentMethodsState = createAction(
  ActionTypes.CleanPaymentMethodsState,
);

export const CleanPaymentMethodState = createAction(
  ActionTypes.CleanPaymentMethodState,
  props<{ id: string }>(),
);

export const CreatePaymentFailure = createAction(
  ActionTypes.CreatePaymentFailure,
  props<{ response: ResponseError }>(),
);

export const CreatePaymentSuccess = createAction(
  ActionTypes.CreatePaymentSuccess,
  props<{ response: ResponseSuccess<CreatePaymentResponse> }>(),
);

export const SetPaymentError = createAction(
  ActionTypes.SetPaymentError,
  props<{ response: any }>(),
);

export const CreatePayment = createAction(
  ActionTypes.CreatePayment,
  props<{ request: CreatePaymentRequest }>(),
);

// Save Payment
export const SavePayment = createAction(
  ActionTypes.SavePayment,
  props<{ request: SaveCardToCustomerRequest }>(),
);

export const SavePaymentSuccess = createAction(
  ActionTypes.SavePaymentSuccess,
  props<{ response: ResponseSuccess<null> }>(),
);

export const SavePaymentFailure = createAction(
  ActionTypes.SavePaymentFailure,
  props<{ response: ResponseError }>(),
);

// Remove Payment
export const RemovePayment = createAction(
  ActionTypes.RemovePayment,
  props<{ id: string }>(),
);

export const RemovePaymentSuccess = createAction(
  ActionTypes.RemovePaymentSuccess,
  props<{ response: ResponseSuccess<null> }>(),
);

export const RemovePaymentFailure = createAction(
  ActionTypes.RemovePaymentFailure,
  props<{ response: ResponseError }>(),
);

// Get Payments
export const GetPayments = createAction(ActionTypes.GetPayments);

export const GetPaymentsSuccess = createAction(
  ActionTypes.GetPaymentsSuccess,
  props<{ response: ResponseSuccess<GetPaymentMethodsResponse | null> }>(),
);

export const GetPaymentsFailure = createAction(
  ActionTypes.GetPaymentsFailure,
  props<{ response: ResponseError }>(),
);

// ConfirmSetupIntent

export const ConfirmSetupIntent = createAction(
  ActionTypes.ConfirmSetupIntent,
  props<{ setupIntentID: string }>(),
);

export const ConfirmSetupIntentSuccess = createAction(
  ActionTypes.ConfirmSetupIntentSuccess,
  props<{ response: SetupIntentResponseSuccess }>(),
);

export const ConfirmSetupIntentFailure = createAction(
  ActionTypes.ConfirmSetupIntentFailure,
  props<{ response: IErrorResponse }>(),
);

// ConfirmSetupIntent

export const ConfirmEmail = createAction(
  ActionTypes.ConfirmEmail,
  props<{ email: string }>(),
);

export const ConfirmSuccess = createAction(
  ActionTypes.ConfirmSuccess,
  props<{ response: ResponseSuccess<any> }>(),
);

export const ConfirmFailure = createAction(
  ActionTypes.ConfirmFailure,
  props<{ response: IErrorResponse }>(),
);

export const ConfirmEmailByToken = createAction(
  ActionTypes.ConfirmEmailByToken,
  props<{ token: string }>(),
);

export const ConfirmEmailSuccess = createAction(
  ActionTypes.ConfirmEmailSuccess,
  props<{ response: SetupIntentResponseSuccess }>(),
);

export const ConfirmEmailFailure = createAction(
  ActionTypes.ConfirmEmailFailure,
  props<{ response: IErrorResponse }>(),
);

export const ConfirmEmailByCode = createAction(
  ActionTypes.ConfirmEmailByCode,
  props<{ code: string }>(),
);

// MagicLink

export const SendMagicLink = createAction(
  ActionTypes.SendMagicLink,
  props<{ email: string }>(),
);

export const SendMagicLinkSuccess = createAction(
  ActionTypes.SendMagicLinkSuccess,
  props<{ response: ResponseSuccess<any> }>(),
);

export const SendMagicLinkFailure = createAction(
  ActionTypes.SendMagicLinkFailure,
  props<{ response: IErrorResponse }>(),
);

export const AuthenticateWithMagicLinkByToken = createAction(
  ActionTypes.AuthenticateWithMagicLinkByToken,
  props<{ token: string }>(),
);

export const AuthenticateWithMagicLinkByCode = createAction(
  ActionTypes.AuthenticateWithMagicLinkByCode,
  props<{ email: string; code: string }>(),
);

export const AuthenticateWithMagicLinkSuccess = createAction(
  ActionTypes.AuthenticateWithMagicLinkSuccess,
  props<{ response: ResponseSuccess<any> }>(),
);

export const AuthenticateWithMagicLinkFailure = createAction(
  ActionTypes.AuthenticateWithMagicLinkFailure,
  props<{ response: IErrorResponse }>(),
);

export const LoginUserByToken = createAction(
  ActionTypes.LoginUserByToken,
  props<{ token: string }>(),
);

export const LoginUserByTokenSuccess = createAction(
  ActionTypes.LoginUserByTokenSuccess,
  props<{ response: SetupIntentResponseSuccess }>(),
);

export const LoginUserByTokenFailure = createAction(
  ActionTypes.LoginUserByTokenFailure,
  props<{ response: IErrorResponse }>(),
);

export const SetTicketsBy = createAction(
  ActionTypes.SetTicketsBy,
  props<{ tickets: any }>(),
);

// Follows

export const GetProfileFollowers = createAction(
  ActionTypes.GetProfileFollowers,
  props<{
    limit: string;
    page: string;
    profileID: string;
    accountType: string;
  }>(),
);

export const GetProfileFollowersSuccess = createAction(
  ActionTypes.GetProfileFollowersSuccess,
  props<{ response: UserFollowByAccountTypesResponseSuccess }>(),
);

export const GetProfileFollowersFailure = createAction(
  ActionTypes.GetProfileFollowersFailure,
  props<{ response: ResponseError }>(),
);

export const GetProfileFollowing = createAction(
  ActionTypes.GetProfileFollowing,
  props<{
    limit: string;
    page: string;
    profileID: string;
    accountType: string;
  }>(),
);

export const GetProfileFollowingSuccess = createAction(
  ActionTypes.GetProfileFollowingSuccess,
  props<{ response: UserFollowByAccountTypesResponseSuccess }>(),
);

export const GetProfileFollowingFailure = createAction(
  ActionTypes.GetProfileFollowingFailure,
  props<{ response: ResponseError }>(),
);

export const CheckIsProfileFollowing = createAction(
  ActionTypes.CheckIsProfileFollowing,
  props<{
    followerID: string;
    followedID: string;
  }>(),
);

export const CheckIsProfileFollowingSuccess = createAction(
  ActionTypes.CheckIsProfileFollowingSuccess,
  props<{ response: ResponseSuccess<any> }>(),
);

export const CheckIsProfileFollowingFailure = createAction(
  ActionTypes.CheckIsProfileFollowingFailure,
  props<{ response: ResponseError }>(),
);

export const CheckEmail = createAction(
  ActionTypes.CheckEmail,
  props<{
    email: string;
  }>(),
);

export const CheckEmailSuccess = createAction(
  ActionTypes.CheckEmailSuccess,
  props<{ response: ResponseSuccess<any> }>(),
);

export const CheckEmailFailure = createAction(
  ActionTypes.CheckEmailFailure,
  props<{ response: ResponseError }>(),
);
