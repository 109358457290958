import {
  GetDonorsByOrgAndSubId,
  ActionTypes,
  GetDonorsByOrgAndSubIdSuccess,
  GetDonorsByOrgAndSubIdFailure,
} from '@store/root/client/donors/actions';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DonorsService } from '@services/client/donors/donors.service';
import { ResponseError } from '@models/shared/response/response-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponseHelper } from '@base/helpers/error-response.helper';

@Injectable()
export class DonorsEffects {
  constructor(
    private actions$: Actions,
    private donorsService: DonorsService,
  ) {}

  GetDonorsByIds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetDonorsByOrgAndSubId>(ActionTypes.GetDonorsByOrgAndSubId),
      switchMap((action: GetDonorsByOrgAndSubId) =>
        this.donorsService.getDonors(action.payload.eventId).pipe(
          map((response) => new GetDonorsByOrgAndSubIdSuccess({ response })),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetDonorsByOrgAndSubIdFailure({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );
}
