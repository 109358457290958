import { DonorsResponse } from '@models/client/donors/donors-response.model';
import { ErrorResponse } from '@models/client/error/error.response.model';

export interface State {
  donors: DonorsResponse | null;
  loading: boolean;
  loaded: boolean;
  updated: boolean;
  error: ErrorResponse | null;
  success: boolean | null;
}

export const initialState: State = {
  donors: null,
  loading: false,
  loaded: false,
  updated: false,
  error: null,
  success: false,
};
