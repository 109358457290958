import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  AcceptArtistRequestToEvent,
  AcceptArtistRequestToEventFailure,
  AcceptArtistRequestToEventSuccess,
  AcceptVenueRequestToEvent,
  AcceptVenueRequestToEventFailure,
  AcceptVenueRequestToEventSuccess,
  ActionTypes,
  AddArtistsToEvent,
  AddArtistsToEventFailure,
  AddArtistsToEventSuccess,
  AddVenuesToEvent,
  AddVenuesToEventFailure,
  AddVenuesToEventSuccess,
  AllowedArtistRequestToEventFailure,
  AllowedArtistRequestToEventSuccess,
  ApproveArtistRequestToEvent,
  ApproveArtistRequestToEventFailure,
  ApproveArtistRequestToEventSuccess,
  ApproveVenueRequestToEvent,
  ApproveVenueRequestToEventFailure,
  ApproveVenueRequestToEventSuccess,
  ConfirmEvent,
  ConfirmEventFailure,
  ConfirmEventSuccess,
  CreateEvent,
  CreateEventFailure,
  CreateEventSuccess,
  DeclineArtistRequestToEvent,
  DeclineArtistRequestToEventFailure,
  DeclineArtistRequestToEventSuccess,
  DeclineVenueRequestToEvent,
  DeclineVenueRequestToEventFailure,
  DeclineVenueRequestToEventSuccess,
  GetArtistPrivateInfoOfEvent,
  GetArtistPrivateInfoOfEventFailure,
  GetArtistPrivateInfoOfEventSuccess,
  GetArtistsRequestToEvent,
  GetArtistsRequestToEventFailure,
  GetArtistsRequestToEventSuccess,
  GetArtistsToEvent,
  GetArtistsToEventFailure,
  GetArtistsToEventSuccess,
  GetEventAnalytics,
  GetEventAnalyticsFailure,
  GetEventAnalyticsSuccess,
  GetEventPhotos,
  GetEventPhotosFailure,
  GetEventPhotosSuccess,
  GetFanPastEvents,
  GetFanPastEventsFailure,
  GetFanPastEventsSuccess,
  GetFanUpcomingEvents,
  GetFanUpcomingEventsFailure,
  GetFanUpcomingEventsSuccess,
  GetFundingDetailsOfEvent,
  GetFundingDetailsOfEventFailure,
  GetFundingDetailsOfEventSuccess,
  GetTimeZone,
  GetTimeZoneFailure,
  GetTimeZoneSuccess,
  GetVenuePrivateInfoOfEvent,
  GetVenuePrivateInfoOfEventFailure,
  GetVenuePrivateInfoOfEventSuccess,
  GetVenuesRequestToEvent,
  GetVenuesRequestToEventFailure,
  GetVenuesRequestToEventSuccess,
  GetVenuesToEvent,
  GetVenuesToEventFailure,
  GetVenuesToEventSuccess,
  LaunchEvent,
  LaunchEventFailure,
  LaunchEventSuccess,
  LoadEventById,
  LoadEventByIdFailure,
  LoadEventByIdSuccess,
  LoadEvents,
  LoadEventsFailure,
  LoadEventsSuccess,
  PayForArtistOfEvent,
  PayForArtistOfEventFailure,
  PayForArtistOfEventSuccess,
  PayForVenueOfEvent,
  PayForVenueOfEventFailure,
  PayForVenueOfEventSuccess,
  PutEventToReview,
  PutEventToReviewFailure,
  PutEventToReviewSuccess,
  RemoveArtistFromEvent,
  RemoveArtistFromEventFailure,
  RemoveArtistFromEventSuccess,
  RemoveEventByID,
  RemoveEventByIDFailure,
  RemoveEventByIDSuccess,
  RemoveVenueFromEvent,
  RemoveVenueFromEventFailure,
  RemoveVenueFromEventSuccess,
  ResendRequestToArtistOfEvent,
  ResendRequestToArtistOfEventFailure,
  ResendRequestToArtistOfEventSuccess,
  ResendRequestToVenueOfEvent,
  ResendRequestToVenueOfEventFailure,
  ResendRequestToVenueOfEventSuccess,
  SendRequestToArtistOfEvent,
  SendRequestToArtistOfEventFailure,
  SendRequestToArtistOfEventSuccess,
  SendRequestToVenueOfEvent,
  SendRequestToVenueOfEventFailure,
  SendRequestToVenueOfEventSuccess,
  UpdateArtistRequestToEvent,
  UpdateArtistRequestToEventFailure,
  UpdateArtistRequestToEventSuccess,
  UpdateEvent,
  UpdateEventFailure,
  UpdateEventSuccess,
  UpdateFundingPartOfEvent,
  UpdateFundingPartOfEventFailure,
  UpdateFundingPartOfEventSuccess,
  UpdateTicketDisplayOrder,
  UpdateTicketDisplayOrderFailure,
  UpdateTicketDisplayOrderSuccess,
  UpdateVenueRequestToEvent,
  UpdateVenueRequestToEventFailure,
  UpdateVenueRequestToEventSuccess,
} from './actions';

import { EventsService } from '@app/services/client/events/events.service';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { EventArtistsResponse } from '@app/models/client/events/event-artists-response.model';
import { EventArtistResponse } from '@app/models/client/events/event-artist-response.model';
import { IEventVenuesResponse } from '@app/models/client/events/event-venues-response.model';
import { EventVenueResponse } from '@app/models/client/events/event-venue-response.model';
import { GetEventPhotosResponseSuccess } from '@app/models/client/events/event-photos-success.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { AppStoreActions } from '@app/store/root/app';
import { EventAnalyticsResponse } from '@app/models/client/events/analytics/event-analytics-response.model';
import { CreatePaymentResponse } from '@app/models/client/payment/create-payment-response.model';
import { EventResponse } from '@app/models/client/events/event-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { GetOrganizerEventByID } from '@store/root/client/organizer/actions';
import { GetOrganizerEventTickets } from '@store/root/client/tickets/actions';

@Injectable()
export class EventsEffects {
  loadEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadEvents>(ActionTypes.LoadEvents),
      switchMap((action) =>
        this.eventsService.getEvents(action.payload.params).pipe(
          map(
            (response: ResponseSuccess<GetEventsResponse>) =>
              new LoadEventsSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new LoadEventsFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  loadEventById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadEventById>(ActionTypes.LoadEventById),
      switchMap((action) =>
        this.eventsService.getEventById(action.payload.id).pipe(
          map(
            (response: EventResponse) => new LoadEventByIdSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new LoadEventByIdFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  confirmEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConfirmEvent>(ActionTypes.ConfirmEvent),
      switchMap((action) =>
        this.eventsService.approveCrowdfunding(action.payload).pipe(
          switchMap((response: ResponseSuccess<any>) => [
            new ConfirmEventSuccess({ response }),
            new GetOrganizerEventByID({ id: action.payload }),
          ]),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new ConfirmEventFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getTimeZone$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTimeZone>(ActionTypes.GetTimeZone),
      switchMap((action) =>
        this.eventsService
          .getTimeZone(action.payload.lat, action.payload.lng)
          .pipe(
            map(
              (response: ResponseSuccess<EventResponse>) =>
                new GetTimeZoneSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetTimeZoneFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  createEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateEvent>(ActionTypes.CreateEvent),
      exhaustMap((action) =>
        this.eventsService.createEvent(action.payload.model).pipe(
          map(
            (response: EventResponse) => new CreateEventSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new CreateEventFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getFanUpcomingEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFanUpcomingEvents>(ActionTypes.GetFanUpcomingEvents),
      exhaustMap((action) =>
        this.eventsService.getFanUpcomingEvents(action.payload.params).pipe(
          map(
            (response: ResponseSuccess<any>) =>
              new GetFanUpcomingEventsSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetFanUpcomingEventsFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEvent>(ActionTypes.UpdateEvent),
      exhaustMap((action) =>
        this.eventsService
          .updateEvent(action.payload.eventId, action.payload.model)
          .pipe(
            switchMap((response: ResponseSuccess<any>) => [
              new UpdateEventSuccess({ response }),
              new GetOrganizerEventByID({ id: action.payload.eventId }),
            ]),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new UpdateEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getFanPastEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFanPastEvents>(ActionTypes.GetFanPastEvents),
      exhaustMap((action) =>
        this.eventsService.getFanPastEvents(action.payload.params).pipe(
          map(
            (response: ResponseSuccess<any>) =>
              new GetFanPastEventsSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetFanPastEventsFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /*** Event Artist ***/

  getArtistsToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetArtistsToEvent>(ActionTypes.GetArtistsToEvent),
      exhaustMap((action) =>
        this.eventsService.getArtistsToEvent(action.payload.eventGUID).pipe(
          map(
            (response: ResponseSuccess<EventArtistsResponse>) =>
              new GetArtistsToEventSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetArtistsToEventFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  addArtistsToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddArtistsToEvent>(ActionTypes.AddArtistsToEvent),
      exhaustMap((action) =>
        this.eventsService
          .addArtistsToEvent(action.payload.eventGUID, action.payload.event)
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new AddArtistsToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new AddArtistsToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  removeArtistFromEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveArtistFromEvent>(ActionTypes.RemoveArtistFromEvent),
      exhaustMap((action) =>
        this.eventsService
          .removeArtistFromEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new RemoveArtistFromEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new RemoveArtistFromEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  acceptArtistRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AcceptArtistRequestToEvent>(
        ActionTypes.AcceptArtistRequestToEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .acceptArtistRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new AcceptArtistRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new AcceptArtistRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  approveArtistRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApproveArtistRequestToEvent>(
        ActionTypes.ApproveArtistRequestToEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .approveArtistRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new ApproveArtistRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new ApproveArtistRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  declineArtistRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeclineArtistRequestToEvent>(
        ActionTypes.DeclineArtistRequestToEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .declineArtistRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new DeclineArtistRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new DeclineArtistRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getArtistsRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetArtistsRequestToEvent>(ActionTypes.GetArtistsRequestToEvent),
      exhaustMap((action) =>
        this.eventsService
          .getArtistRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
          )
          .pipe(
            map(
              (response: EventArtistResponse) =>
                new GetArtistsRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetArtistsRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getAllowedArtistsRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetArtistsRequestToEvent>(ActionTypes.GetAllowedArtistsOfEvent),
      exhaustMap((action) =>
        this.eventsService.allowedArtistRequestToEvent().pipe(
          map(
            (response: ResponseSuccess<EventArtistResponse[]>) =>
              new AllowedArtistRequestToEventSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new AllowedArtistRequestToEventFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateArtistRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateArtistRequestToEvent>(
        ActionTypes.UpdateArtistRequestToEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .updateArtistRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new UpdateArtistRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new UpdateArtistRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  sendRequestToArtistOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendRequestToArtistOfEvent>(
        ActionTypes.SendRequestToArtistOfEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .sendArtistRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new SendRequestToArtistOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new SendRequestToArtistOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  resendRequestToArtistOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResendRequestToArtistOfEvent>(
        ActionTypes.ResendRequestToArtistOfEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .resendArtistRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new ResendRequestToArtistOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new ResendRequestToArtistOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  /*** Event Venue ***/

  getVenuesToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetVenuesToEvent>(ActionTypes.GetVenuesToEvent),
      exhaustMap((action) =>
        this.eventsService.getVenuesToEvent(action.payload.eventGUID).pipe(
          map(
            (response: ResponseSuccess<IEventVenuesResponse>) =>
              new GetVenuesToEventSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetVenuesToEventFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  addVenuesToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddVenuesToEvent>(ActionTypes.AddVenuesToEvent),
      exhaustMap((action) =>
        this.eventsService
          .addVenuesToEvent(action.payload.eventGUID, action.payload.query)
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new AddVenuesToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new AddVenuesToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  removeVenueFromEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveVenueFromEvent>(ActionTypes.RemoveVenueFromEvent),
      exhaustMap((action) =>
        this.eventsService
          .removeVenueFromEvent(
            action.payload.eventGUID,
            action.payload.venueGUID,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new RemoveVenueFromEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new RemoveVenueFromEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  acceptVenueRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AcceptVenueRequestToEvent>(ActionTypes.AcceptVenueRequestToEvent),
      exhaustMap((action) =>
        this.eventsService
          .acceptVenueRequestToEvent(
            action.payload.eventGUID,
            action.payload.venueGUID,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new AcceptVenueRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new AcceptVenueRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  approveVenueRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApproveVenueRequestToEvent>(
        ActionTypes.ApproveVenueRequestToEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .approveVenueRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new ApproveVenueRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new ApproveVenueRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  declineVenueRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeclineVenueRequestToEvent>(
        ActionTypes.DeclineVenueRequestToEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .declineVenueRequestToEvent(
            action.payload.eventGUID,
            action.payload.venueGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new DeclineVenueRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new DeclineVenueRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getVenuesRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetVenuesRequestToEvent>(ActionTypes.GetVenuesRequestToEvent),
      exhaustMap((action) =>
        this.eventsService
          .getVenuesRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
          )
          .pipe(
            map(
              (response: EventVenueResponse) =>
                new GetVenuesRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetVenuesRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  updateVenueRequestToEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateVenueRequestToEvent>(ActionTypes.UpdateVenueRequestToEvent),
      exhaustMap((action) =>
        this.eventsService
          .updateVenueRequestToEvent(
            action.payload.eventGUID,
            action.payload.venueGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new UpdateVenueRequestToEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new UpdateVenueRequestToEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  sendRequestToVenueOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendRequestToVenueOfEvent>(ActionTypes.SendRequestToVenueOfEvent),
      exhaustMap((action) =>
        this.eventsService
          .sendVenueRequestToEvent(
            action.payload.eventGUID,
            action.payload.venueGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new SendRequestToVenueOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new SendRequestToVenueOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  resendRequestToVenueOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResendRequestToVenueOfEvent>(
        ActionTypes.ResendRequestToVenueOfEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .resendVenueRequestToEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new ResendRequestToVenueOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new ResendRequestToVenueOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  // Funding

  payForArtistOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PayForArtistOfEvent>(ActionTypes.PayForArtistOfEvent),
      exhaustMap((action) =>
        this.eventsService
          .payForArtistOfEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<CreatePaymentResponse>) =>
                new PayForArtistOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new PayForArtistOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  payForVenueOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PayForVenueOfEvent>(ActionTypes.PayForVenueOfEvent),
      exhaustMap((action) =>
        this.eventsService
          .payForVenueOfEvent(
            action.payload.eventGUID,
            action.payload.venueGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<CreatePaymentResponse>) =>
                new PayForVenueOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new PayForVenueOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getFundingDetailsOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFundingDetailsOfEvent>(ActionTypes.GetFundingDetailsOfEvent),
      switchMap((action) =>
        this.eventsService
          .getFundingDetailsOfEvent(action.payload.eventGUID)
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new GetFundingDetailsOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetFundingDetailsOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  updateFundingPartOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateFundingPartOfEvent>(ActionTypes.UpdateFundingPartOfEvent),
      exhaustMap((action) =>
        this.eventsService
          .updateFundingPartOfEvent(
            action.payload.eventGUID,
            action.payload.query,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new UpdateFundingPartOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new UpdateFundingPartOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  /***** Analytics *****/

  getEventAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventAnalytics>(ActionTypes.GetEventAnalytics),
      exhaustMap((action) =>
        this.eventsService.getEventAnalytics(action.payload.id).pipe(
          map(
            (response: ResponseSuccess<EventAnalyticsResponse>) =>
              new GetEventAnalyticsSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetEventAnalyticsFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /***** Media *****/

  getEventPhotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventPhotos>(ActionTypes.GetEventPhotos),
      exhaustMap((action) =>
        this.eventsService.getEventPhotos(action.payload.eventGUID).pipe(
          map(
            (response: GetEventPhotosResponseSuccess) =>
              new GetEventPhotosSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetEventPhotosFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  removeEventByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveEventByID>(ActionTypes.RemoveEventByID),
      exhaustMap((action) =>
        this.eventsService.removeEvent(action.payload.id).pipe(
          map(
            (response: ResponseSuccess<any>) =>
              new RemoveEventByIDSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new RemoveEventByIDFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  putEventToReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PutEventToReview>(ActionTypes.PutEventToReview),
      exhaustMap((action) =>
        this.eventsService.putEventToReview(action.payload.id).pipe(
          switchMap((response: ResponseSuccess<any>) => [
            new PutEventToReviewSuccess({ response }),
            new GetOrganizerEventByID({ id: action.payload.id }),
          ]),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new PutEventToReviewFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  launchEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LaunchEvent>(ActionTypes.LaunchEvent),
      exhaustMap((action) =>
        this.eventsService.launchEvent(action.payload.id).pipe(
          switchMap((response: ResponseSuccess<any>) => [
            new LaunchEventSuccess({ response }),
            new GetOrganizerEventByID({ id: action.payload.id }),
          ]),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new LaunchEventFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getVenuePrivateInfoOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetVenuePrivateInfoOfEvent>(
        ActionTypes.GetVenuePrivateInfoOfEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .getVenuePrivateInfoOfEvent(
            action.payload.eventGUID,
            action.payload.venueGUID,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new GetVenuePrivateInfoOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetVenuePrivateInfoOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getArtistPrivateInfoOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetArtistPrivateInfoOfEvent>(
        ActionTypes.GetArtistPrivateInfoOfEvent,
      ),
      exhaustMap((action) =>
        this.eventsService
          .getArtistPrivateInfoOfEvent(
            action.payload.eventGUID,
            action.payload.artistGUID,
          )
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new GetArtistPrivateInfoOfEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetArtistPrivateInfoOfEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  updateEventTicketsDisplayOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTicketDisplayOrder>(ActionTypes.UpdateTicketsDisplayOrder),
      exhaustMap((action) =>
        this.eventsService
          .updateEventTicketsDisplayOrder(
            action.payload.eventUUID,
            action.payload.ticketDisplayOrders,
          )
          .pipe(
            switchMap((response: ResponseSuccess<any>) => [
              new UpdateTicketDisplayOrderSuccess({ response }),
              new GetOrganizerEventTickets({
                eventGUID: action.payload.eventUUID,
                limit: '',
                page: '',
              }),
            ]),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new UpdateTicketDisplayOrderFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private eventsService: EventsService,
  ) {}
}
