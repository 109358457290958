import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LoadGenres: {
      return {
        ...state,
      };
    }
    case ActionTypes.SetGenres: {
      return {
        ...state,
        entities: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
