import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { getUserTokenState } from '../user/selectors';

import {
  ActionTypes,
  CreateProfile,
  CreateProfileFailure,
  CreateProfileSuccess,
  FollowAccount,
  FollowAccountFailure,
  FollowAccountSuccess,
  GetFollowersCount,
  GetFollowersCountFailure,
  GetFollowersCountSuccess,
  GetFollowingCount,
  GetFollowingCountFailure,
  GetFollowingCountSuccess,
  GetOrganizerEventByID,
  GetOrganizerEventByIDFailure,
  GetOrganizerEventByIDSuccess,
  GetOrganizerEventsPast,
  GetOrganizerEventsPastFailure,
  GetOrganizerEventsPastSuccess,
  GetOrganizerPermission,
  GetOrganizerPermissionFailure,
  GetOrganizerPermissionSuccess,
  GetOrganizersEvents,
  GetOrganizersEventsFailure,
  GetOrganizersEventsSuccess,
  GetOrganizerUpcomingEvents,
  GetOrganizerUpcomingEventsFailure,
  GetOrganizerUpcomingEventsSuccess,
  GetProfileByID,
  GetProfileByIDFailure,
  GetProfileByIDSuccess,
  IsCreatedOrganizerEvent,
  IsCreatedOrganizerEventFailure,
  IsCreatedOrganizerEventSuccess,
  RemoveMediaFile,
  RemoveMediaFileFailure,
  RemovePhotoSuccess,
  RemoveVideoSuccess,
  SendOnReview,
  SendOnReviewFailure,
  SendOnReviewSuccess,
  SetProfilePhoto,
  SetProfilePhotoFailure,
  SetProfilePhotoSuccess,
  UnfollowAccount,
  UnfollowAccountFailure,
  UnfollowAccountSuccess,
  UpdateProfile,
  UpdateProfileFailure,
  UpdateProfileSuccess,
  UploadVideo,
  UploadVideoFailure,
  UploadVideoSuccess,
} from './actions';

import { OrganizerService } from '@app/services/client/organizer/organizer.service';
import { Response } from '@app/interfaces/response.interface';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import {
  GetProfileMessages,
  GetProfileMessagesFailure,
  GetProfileMessagesSuccess,
} from '@app/store/root/client/organizer/actions';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { MessagesOrganizerResponseSuccess } from '@app/models/client/organizer/messages-organizer-response-success.model';
import { FollowersCount } from '@app/models/client/followers-count.model';
import { AppStoreActions } from '@app/store/root/app';
import { RootStoreState } from '@app/store/root';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { AccountType } from '@app/models/admin/accounts/account-type.enum';
import { EventResponse } from '@models/client/events/event-response.model';
import { EventsService } from '@modules/api/services/events.service';
import { OrganizerAccountService } from '@modules/api/services/organizer-account.service';
import { GetOrganizerEventsResponse } from '@modules/api/models/get-organizer-events-response';
import { GetEventsResponse } from '@modules/api/models/get-events-response';
import { SearchService } from '@services/client/search/search.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ProfileStoreActions } from '@store/root/client/profile';

@Injectable()
export class OrganizerEffects {
  createProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProfile>(ActionTypes.CreateProfile),
      exhaustMap((action) =>
        this.organizerService.createOrganizerProfile(action.payload.model).pipe(
          tap(async () => {
            const user = await this.auth.user.toPromise();
            await user.getIdToken(true);
          }),
          map(() => new CreateProfileSuccess()),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new CreateProfileFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  sendOnReview: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SendOnReview>(ActionTypes.SendOnReview),
      exhaustMap(() =>
        this.organizerService.sendOnReview().pipe(
          switchMap((response: Response) => [
            new SendOnReviewSuccess({ response }),
            new ProfileStoreActions.GetProfiles(),
          ]),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new SendOnReviewFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetOrganizerPermission>(ActionTypes.GetOrganizerPermission),
      switchMap((action) =>
        this.organizerService.getEventPermission(action.payload.id).pipe(
          switchMap((response) => [
            new GetOrganizerPermissionSuccess({ response }),
          ]),
          catchError((error) =>
            of(new GetOrganizerPermissionFailure({ response: error })),
          ),
        ),
      ),
    ),
  );

  uploadVideo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadVideo>(ActionTypes.UploadVideo),
      switchMap((action) =>
        this.organizerService.uploadVideo(action.payload.video).pipe(
          tap(console.log),
          switchMap((response) => [
            new UploadVideoSuccess({ videos: response.videos }),
          ]),
          catchError((response: IErrorResponse) =>
            of(new UploadVideoFailure({ response })),
          ),
        ),
      ),
    ),
  );

  removeMediaFile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveMediaFile>(ActionTypes.RemoveMediaFile),
      switchMap((action) =>
        this.organizerService
          .deleteEventMedia(
            action.payload.eventID,
            action.payload.url,
            action.payload.type,
          )
          .pipe(
            tap(console.log),
            map((response) => {
              if (response?.photos) {
                return new RemovePhotoSuccess({ photos: response.photos });
              } else if (response?.videos) {
                return new RemoveVideoSuccess({ videos: response.videos });
              }
            }),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new RemoveMediaFileFailure({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getProfileByID$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfileByID>(ActionTypes.GetProfileByID),
      switchMap((action) =>
        this.organizerService.getProfileByID(action.payload.id).pipe(
          map(
            (response) => new GetProfileByIDSuccess({ profile: response.data }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetProfileByIDFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateProfile$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfile>(ActionTypes.UpdateProfile),
      switchMap((action) =>
        this.organizerService.updateProfile(action.payload.model).pipe(
          map((response: Response) => new UpdateProfileSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateProfileFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  setProfilePhoto$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<SetProfilePhoto>(ActionTypes.SetProfilePhoto),
      switchMap((action) =>
        this.organizerService.setProfilePhoto(action.payload.photo).pipe(
          switchMap((response: Response) => [
            new SetProfilePhotoSuccess({ response }),
            new ProfileStoreActions.GetProfiles(),
          ]),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new SetProfilePhotoFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getOrganizersEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetOrganizersEvents>(ActionTypes.GetOrganizersEvents),
      switchMap((action) =>
        this.eventsService
          .getOrganizerEvents({
            limit: action.payload.limit,
            page: action.payload.page,
            'filter[date_from]': action.payload.dateFrom,
            'filter[date_to]': action.payload.dateTo,
          })
          .pipe(
            map(
              (response: GetOrganizerEventsResponse) =>
                new GetOrganizersEventsSuccess({ response }),
            ),
            catchError((errResponse: ResponseError) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetOrganizersEventsFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  //
  // getOrganizersPastEvents$: Observable<Action> = createEffect(() => this.actions$.pipe(
  //   ofType<GetOrganizersPastEvents>(ActionTypes.GetOrganizersPastEvents),
  //   switchMap((action) => this.eventsService
  //     .getPastEvents({
  //       limit: action.payload.limit,
  //       page: action.payload.page,
  //       profileID: action.payload.profileID,
  //       accountType: action.payload.accountType,
  //     })
  //     .pipe(
  //       map((response: GetGoingFanEventResponseSuccess) =>
  //         new GetOrganizersPastEventsSuccess({response}),
  //       ),
  //       catchError((errResponse: ResponseError) =>
  //         ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
  //           switchMap((response: ResponseError) => [
  //             new GetOrganizersPastEventsFailure({response}),
  //             new AppStoreActions.AddErrorResponse({response}),
  //           ]),
  //         ),
  //       ),
  //     ),
  //   ),
  // );

  getOrganizerUpcomingEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetOrganizerUpcomingEvents>(
        ActionTypes.GetOrganizerUpcomingEvents,
      ),
      switchMap((action) =>
        this.searchService
          .searchEvent(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
          )
          .pipe(
            map(
              (response: ResponseSuccess<GetEventsResponse>) =>
                new GetOrganizerUpcomingEventsSuccess({ response }),
            ),
            catchError((errResponse: ResponseError) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetOrganizerUpcomingEventsFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getOrganizerEventsPast$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetOrganizerEventsPast>(ActionTypes.GetOrganizerEventsPast),
      switchMap((action) =>
        this.searchService
          .searchEvent(
            action.payload.query,
            action.payload.limit,
            action.payload.page,
            action.payload.filters,
          )
          .pipe(
            map(
              (response: ResponseSuccess<GetEventsResponse>) =>
                new GetOrganizerEventsPastSuccess({ response }),
            ),
            catchError((errResponse: ResponseError) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetOrganizerEventsPastFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  getOrganizerEventByID$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetOrganizerEventByID>(ActionTypes.GetOrganizerEventByID),
      switchMap((action) =>
        this.organizerService.getOrganizerEventByID(action.payload.id).pipe(
          map(
            (response: EventResponse) =>
              new GetOrganizerEventByIDSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetOrganizerEventByIDFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  isCreatedOrganizerEvent = createEffect(() =>
    this.actions$.pipe(
      ofType<IsCreatedOrganizerEvent>(ActionTypes.IsCreatedOrganizerEvent),
      switchMap((action) =>
        this.organizerService.getOrganizerEventByID(action.payload.id).pipe(
          map(
            (response: EventResponse) =>
              new IsCreatedOrganizerEventSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new IsCreatedOrganizerEventFailure({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles get followers count.
   *
   * @action - [Organizer] GetFollowersCount
   *
   * @return:
   *   Success: [Organizer] GetFollowersCountFailure
   *   Failure: [Organizer] GetFollowersCountFailure & [Errors] AddErrorResponse
   */

  getFollowersCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFollowersCount>(ActionTypes.GetFollowersCount),
      switchMap((action) =>
        this.organizerService.getFollowersCount(action.payload.id).pipe(
          map(
            (response: FollowersCount) =>
              new GetFollowersCountSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetFollowersCountFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles get following count.
   *
   * @action - [Organizer] GetFollowingCount
   *
   * @return:
   *   Success: [Organizer] GetFollowingCountFailure
   *   Failure: [Organizer] GetFollowingCountFailure & [Errors] AddErrorResponse
   */

  getFollowingCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFollowingCount>(ActionTypes.GetFollowingCount),
      switchMap((action) =>
        this.organizerService.getFollowingCount(action.payload.id).pipe(
          map(
            (response: FollowersCount) =>
              new GetFollowingCountSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetFollowingCountFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /***** Messages *****/

  getMessages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfileMessages>(ActionTypes.GetProfileMessages),
      withLatestFrom(this.store.select(getUserTokenState)),
      switchMap(() =>
        this.organizerService.getMessages().pipe(
          map(
            (response: MessagesOrganizerResponseSuccess) =>
              new GetProfileMessagesSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetProfileMessagesFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  followAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FollowAccount>(ActionTypes.FollowAccount),
      switchMap((action) => {
        switch (action.payload.accountType) {
          case AccountType.FAN:
            return this.organizerService
              .followUpFanAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.ARTIST:
            return this.organizerService
              .followUpArtistAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.VENUE:
            return this.organizerService
              .followUpVenueAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.ORGANIZER:
            return this.organizerService
              .followUpOrganizerAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
        }
      }),
    ),
  );

  unfollowAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UnfollowAccount>(ActionTypes.UnfollowAccount),
      switchMap((action) => {
        switch (action.payload.accountType) {
          case AccountType.FAN:
            return this.organizerService
              .unfollowUpFanAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.ARTIST:
            return this.organizerService
              .unfollowUpArtistAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.VENUE:
            return this.organizerService
              .unfollowUpVenueAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.ORGANIZER:
            return this.organizerService
              .unfollowUpOrganizerAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private organizerService: OrganizerService,
    private organizerAccountService: OrganizerAccountService,
    private eventsService: EventsService,
    private searchService: SearchService,
    private auth: AngularFireAuth,
  ) {}
}
