import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import {
  ActionTypes,
  CreatePayment,
  CreatePaymentFailure,
  CreatePaymentSuccess,
} from './actions';
import { PaymentService } from '@app/services/client/payment/payment.service';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { AppStoreActions } from '@app/store/root/app';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { RootStoreState } from '@app/store/root';
import { CreatePaymentResponse } from '@app/models/client/payment/create-payment-response.model';

@Injectable()
export class PaymentEffects {
  createPayment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreatePayment>(ActionTypes.CreatePayment),
      switchMap((action) =>
        this.paymentService.createRegularPayment(action.payload.request).pipe(
          map(
            (response: ResponseSuccess<CreatePaymentResponse>) =>
              new CreatePaymentSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new CreatePaymentFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private paymentService: PaymentService,
  ) {}
}
