import { Action } from '@ngrx/store';
import { GetFAQResponseFailure } from '@app/models/client/faq/GetFAQResponseFailure.model';
import { FAQQuestion } from '@app/models/client/faq/FAQQuestion.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';

export enum ActionTypes {
  LoadFAQ = '[Support] Load FAQ',
  LoadFAQSuccess = '[Support] Load FAQ Success',
  LoadFAQFailure = '[Support] Load FAQ Failure',
}

export class LoadFAQ implements Action {
  public readonly type = ActionTypes.LoadFAQ;
}

export class LoadFAQSuccess implements Action {
  public readonly type = ActionTypes.LoadFAQSuccess;

  constructor(public payload: { response: ResponseSuccess<FAQQuestion[]> }) {}
}

export class LoadFAQFailure implements Action {
  public readonly type = ActionTypes.LoadFAQFailure;

  constructor(public payload: { response: GetFAQResponseFailure }) {}
}

export type Actions = LoadFAQ | LoadFAQSuccess | LoadFAQFailure;
