import { MessagesFanResponse } from '@app/models/client/fan/messages-fan-response.model';
import { FanModel } from '@models/client/fan/fan.model';
import { GuestModel } from '@models/client/fan/guest.model';
import { Profile } from '@models/client/profiles/create-profile-response.model';

export interface State {
  profile: Profile;
  profileByID: FanModel;
  guest: GuestModel;
  loading: boolean;
  loaded: boolean;
  logined: boolean;
  created: boolean;
  updated: boolean;
  followingArtists: any;
  messagesResponse: MessagesFanResponse[];
  followersCount: number;
  followingCount: number;
}

export const initialState: State = {
  profile: null,
  profileByID: null,
  guest: null,
  loading: true,
  loaded: false,
  created: false,
  updated: false,
  logined: false,
  followingArtists: null,
  messagesResponse: [],
  followersCount: 0,
  followingCount: 0,
};
