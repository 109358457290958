import { Injectable } from '@angular/core';
import { UserToken } from '@app/models/shared/user-token.model';
import { UserCreate } from '@app/models/client/user/user-create.model';
import { SocialUser } from '@app/models/client/social/social-user.model';
import { CreateEventSteps } from '@app/models/client/events/create-event-steps.model';
import { CreateOrUpdateEventRequest } from '@app/models/client/events/create-or-update-event-request.model';
import { EventRequest } from '@models/client/events/event-request.model';
import { CreateUser } from '@models/client/user/create-user.model';
import { ITicketsOrderData } from '@interfaces/local-storage/client/checkout/tickets-order-data.interface';
import { IsPlatformService } from '@services/client/is-platform/is-platform.service';

const localStorageEntities = {
  UserToken: 'userToken',
  UserCreateModel: 'userCreateModel',
  UserProfileType: 'userProfileType',
  SocialUser: 'socialUser',
  isSocial: 'isSocial',
  createEvent: 'createEvent',
  resetPasswordEmail: 'resetPasswordEmail',
  createEventSteps: 'createEventSteps',
  confirmEmail: 'confirmEmail',
  organizerEmail: 'organizerEmail',
  routerLinkByConfirm: 'routerLinkByConfirm',
  showAdvanceSetting: 'showAdvanceSetting',
  draftEvent: 'draftEvent',
  ticketsOrderData: 'ticketsOrderData',
  CreateUserInfo: 'createUserInfo',
};

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private isPlatformService: IsPlatformService) {}

  setItem(name: string, data: any) {
    if (!this.isPlatformService.isBrowser()) return;
    return localStorage.setItem(name, data);
  }

  getItem(name: string) {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(localStorage.getItem(name));
  }

  removeItem(name: string) {
    if (!this.isPlatformService.isBrowser()) return;
    return localStorage.removeItem(name);
  }

  // USER Token

  setUserToken(userToken: UserToken): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(
      localStorageEntities.UserToken,
      JSON.stringify(userToken),
    );
  }

  getUserToken(): UserToken {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(localStorage.getItem(localStorageEntities.UserToken));
  }

  removeUserToken(): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.removeItem(localStorageEntities.UserToken);
  }

  setOrganizerEmail(email: string, idEvent: string): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(
      localStorageEntities.organizerEmail,
      JSON.stringify({ email: email, idEvent: idEvent }),
    );
  }

  getOrganizerEmail(): { email: string; idEvent: string } {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(
      localStorage.getItem(localStorageEntities.organizerEmail),
    );
  }

  removeOrganizerEmail(): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.removeItem(localStorageEntities.organizerEmail);
  }

  setConfirmEmail(email: string): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(
      localStorageEntities.confirmEmail,
      JSON.stringify(email),
    );
  }

  getConfirmEmail(): string {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(localStorage.getItem(localStorageEntities.confirmEmail));
  }

  removeConfirmEmail(): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.removeItem(localStorageEntities.confirmEmail);
  }

  setRouterLinkByConfirm(link: string): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(
      localStorageEntities.routerLinkByConfirm,
      JSON.stringify(link),
    );
  }

  getRouterLinkByConfirm(): string {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(
      localStorage.getItem(localStorageEntities.routerLinkByConfirm),
    );
  }

  removeRouterLinkByConfirm(): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.removeItem(localStorageEntities.routerLinkByConfirm);
  }

  setSelectTypeFundraising(id: string, selectTypeFundraising: string): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(id, JSON.stringify(selectTypeFundraising));
  }

  getSelectTypeFundraising(id: string): string {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(localStorage.getItem(id));
  }

  removeSelectTypeFundraising(id: string): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.removeItem(id);
  }

  setDraftEvent(event: EventRequest): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(
      localStorageEntities.draftEvent,
      JSON.stringify(event),
    );
  }

  getDraftEvent(): EventRequest {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(localStorage.getItem(localStorageEntities.draftEvent));
  }

  removeDraftEvent(): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.removeItem(localStorageEntities.draftEvent);
  }

  // USER Social

  setUserSocial(user: any): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(localStorageEntities.SocialUser, JSON.stringify(user));
  }

  getUserSocial(): SocialUser {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(localStorage.getItem(localStorageEntities.SocialUser));
  }

  removeUserSocial(): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.removeItem(localStorageEntities.SocialUser);
  }

  // Tickets Order Data

  setTicketsOrderData(ticketsOrderData: ITicketsOrderData): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(
      localStorageEntities.ticketsOrderData,
      JSON.stringify(ticketsOrderData),
    );
  }

  getTicketsOrderData(): ITicketsOrderData {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(
      localStorage.getItem(localStorageEntities.ticketsOrderData),
    );
  }

  removeTicketsOrderData(): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.removeItem(localStorageEntities.ticketsOrderData);
  }

  // USER Create Model

  setUserCreateModel(user: UserCreate): void {
    if (!this.isPlatformService.isBrowser()) return;
    localStorage.setItem(
      localStorageEntities.UserCreateModel,
      JSON.stringify(user),
    );
  }

  getUserCreateModel(): UserCreate {
    if (!this.isPlatformService.isBrowser()) return;
    return JSON.parse(
      localStorage.getItem(localStorageEntities.UserCreateModel),
    );
  }

  removeUserCreateModel(): void {
    if (!this.isPlatformService.isBrowser()) return;
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.removeItem(localStorageEntities.UserCreateModel);
  }

  // User create Type of artist-artist-profile-private-profiles (fan, artist, venue, organizer).

  setUserProfileType(user: string): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.setItem(
      localStorageEntities.UserProfileType,
      JSON.stringify(user),
    );
  }

  getUserProfileType(): string {
    if (!this.isPlatformService.isBrowser()) return;

    return JSON.parse(
      localStorage.getItem(localStorageEntities.UserProfileType),
    );
  }

  removeUserProfileType(): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.removeItem(localStorageEntities.UserProfileType);
  }

  // Create User Info

  setCreateUserInfo(user: CreateUser): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.setItem(
      localStorageEntities.CreateUserInfo,
      JSON.stringify(user),
    );
  }

  getCreateUserInfo(): CreateUser {
    if (!this.isPlatformService.isBrowser()) return;

    return JSON.parse(
      localStorage.getItem(localStorageEntities.CreateUserInfo),
    );
  }

  removeCreateUserInfo(): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.removeItem(localStorageEntities.CreateUserInfo);
  }

  // Is Social user

  setIsSocial(value: boolean): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.setItem(localStorageEntities.isSocial, JSON.stringify(value));
  }

  getIsSocial(): boolean {
    if (!this.isPlatformService.isBrowser()) return;

    return JSON.parse(localStorage.getItem(localStorageEntities.isSocial));
  }

  removeIsSocial(): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.removeItem(localStorageEntities.isSocial);
  }

  // Is Reset Password

  setResetPassword(value: string): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.setItem(
      localStorageEntities.resetPasswordEmail,
      JSON.stringify(value),
    );
  }

  getResetPassword(): string {
    if (!this.isPlatformService.isBrowser()) return;

    return JSON.parse(
      localStorage.getItem(localStorageEntities.resetPasswordEmail),
    );
  }

  removeResetPassword(): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.removeItem(localStorageEntities.resetPasswordEmail);
  }

  setShowAdvanceSetting(value: boolean): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.setItem(
      localStorageEntities.showAdvanceSetting,
      JSON.stringify(value),
    );
  }

  getShowAdvanceSetting(): boolean {
    if (!this.isPlatformService.isBrowser()) return;

    return JSON.parse(
      localStorage.getItem(localStorageEntities.showAdvanceSetting),
    );
  }

  removeShowAdvanceSetting(): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.removeItem(localStorageEntities.showAdvanceSetting);
  }

  // Create Event

  setCreateEvent(event: CreateOrUpdateEventRequest): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.setItem(
      localStorageEntities.createEvent,
      JSON.stringify(event),
    );
  }

  getCreateEvent(): CreateOrUpdateEventRequest {
    if (!this.isPlatformService.isBrowser()) return;

    return JSON.parse(localStorage.getItem(localStorageEntities.createEvent));
  }

  removeCreateEvent(): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.removeItem(localStorageEntities.createEvent);
  }

  // Create Event Steps

  setCreateEventSteps(event: CreateEventSteps): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.setItem(
      localStorageEntities.createEventSteps,
      JSON.stringify(event),
    );
  }

  getCreateEventSteps(): CreateEventSteps {
    if (!this.isPlatformService.isBrowser()) return;

    return JSON.parse(
      localStorage.getItem(localStorageEntities.createEventSteps),
    );
  }

  removeCreateEventSteps(): void {
    if (!this.isPlatformService.isBrowser()) return;

    localStorage.removeItem(localStorageEntities.createEventSteps);
  }

  removeIndexedDB(exceptions: string[] | null) {
    // localStorage.clear();
    if (!this.isPlatformService.isBrowser()) return;

    this.clearSessionStorageExcept(exceptions);
  }

  clearSessionStorageExcept(exceptions?: string[]): void {
    if (!this.isPlatformService.isBrowser()) return;

    if (exceptions) {
      const savedItems: { [key: string]: string | null } = {};
      exceptions.forEach((key) => {
        savedItems[key] = sessionStorage.getItem(key);
      });

      sessionStorage.clear();

      Object.keys(savedItems).forEach((key) => {
        if (savedItems[key] !== null) {
          sessionStorage.setItem(key, savedItems[key] as string);
        }
      });
    } else {
      sessionStorage.clear();
    }
  }

  hasClientAccount(accounts: any[], valueForCheck: string): boolean {
    if (!this.isPlatformService.isBrowser()) return;

    return accounts.some((account) => {
      return account && typeof account === 'object' && valueForCheck in account;
    });
  }

  // REMOVE ALL

  removeAllItems(exceptions?: string[]) {
    this.removeUserToken();
    this.removeTicketsOrderData();
    this.removeUserCreateModel();
    this.removeUserProfileType();
    this.removeIsSocial();
    this.removeUserSocial();
    this.removeCreateEvent();
    this.removeCreateEventSteps();
    this.removeConfirmEmail();
    this.removeRouterLinkByConfirm();
    this.removeIndexedDB(exceptions);
    this.removeShowAdvanceSetting();
  }
}
