import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Response } from '@app/interfaces/response.interface';

/**
 * Implementation of genres APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class GenresService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  /**
   * GET: `/genres`.
   *
   * Returns genre list.
   *
   *
   *
   * @return An `Observable` of the `Response`.
   */
  getGenres(): Observable<Response> {
    return this.http.get<Response>(`${this.baseUrl}/genres`);
  }
}
