import { SearchResponse } from '@app/models/client/search/search-response.model';
import { VenueAccountsResponse } from '@app/models/client/venue/venue-accounts-response.model';
import { FanAccountsResponse } from '@app/models/client/fan/fan-accounts-response.model';
import { ArtistAccountsResponse } from '@app/models/client/artist/artist-accounts-response.model';
import { TagsResponse } from '@app/models/client/tags/tags-response.model';
import { OrganizerAccountsResponse } from '@app/models/client/organizer/organizer-accounts-response.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { EventVenueAccountsResponse } from '@app/models/client/events/event-venue-accounts-response.model';
import { EventArtistAccountsResponse } from '@app/models/client/events/event-artist-accounts-response.model';

export interface State {
  searchQuery: string;
  loading: boolean;
  loaded: boolean;
  result: SearchResponse;
  resultFans: FanAccountsResponse;
  resultArtists: ArtistAccountsResponse;
  resultVenues: VenueAccountsResponse;
  resultOrganizers: OrganizerAccountsResponse;
  resultEvents: GetEventsResponse;
  tags: TagsResponse;
  resultSuggest: string[];
  resultSearchArtistsForEvent: EventArtistAccountsResponse;
  resultSearchVenuesForEvent: EventVenueAccountsResponse;
  ui: {
    status: boolean;
  };
}

export const initialState: State = {
  searchQuery: '',
  loading: false,
  loaded: false,
  result: {
    resultFans: {
      count: 0,
      fanAccounts: [],
    },
    resultArtists: {
      count: 0,
      artistAccounts: [],
    },
    resultVenues: {
      count: 0,
      venueAccounts: [],
    },
    resultOrganizers: {
      count: 0,
      organizerAccounts: [],
    },
    resultEvents: {
      count: 0,
      events: [],
    },
    tags: {
      tags: [],
      count: 0,
    },
    count: 0,
  },
  resultFans: {
    count: 0,
    fanAccounts: [],
  },
  resultArtists: {
    count: 0,
    artistAccounts: [],
  },
  resultVenues: {
    count: 0,
    venueAccounts: [],
  },
  resultOrganizers: {
    count: 0,
    organizerAccounts: [],
  },
  resultEvents: {
    count: 0,
    events: [],
  },
  tags: {
    tags: [],
    count: 0,
  },
  resultSuggest: [],
  resultSearchArtistsForEvent: null,
  resultSearchVenuesForEvent: null,
  ui: {
    status: false,
  },
};
