import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatePaymentRequest } from '@app/models/client/payment/create-payment-request.model';
import { APIService } from '@app/interfaces/api-service.interface';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { CreateCrowdfundingPaymentRequest } from '@app/models/client/payment/create-crowdfunding-payment-request.model';
import { UpdateCrowdfundingPaymentRequest } from '@app/models/client/payment/update-crowdfunding-payment-request.model';
import { CreatePaymentResponse } from '@app/models/client/payment/create-payment-response.model';
import { SaveCardToCustomerRequest } from '@modules/api/models/save-card-to-customer-request';
import { GetPaymentMethodsResponse } from '@modules/api/models/get-payment-methods-response';

/**
 * Implementation of payment APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class PaymentService implements APIService {
  host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * POST: `/api/v1/payments/regular`.
   * Create regular payment.
   */
  createRegularPayment(
    request: CreatePaymentRequest,
  ): Observable<ResponseSuccess<CreatePaymentResponse>> {
    return this.http.post<ResponseSuccess<CreatePaymentResponse>>(
      `${this.host}/api/v1/payments/regular`,
      request,
    );
  }

  /**
   * POST: `/api/v1/payments/crowdfunding`.
   * Create crowdfunding payment.
   */
  createCrowdfundingPayment(
    body: CreateCrowdfundingPaymentRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/payments/crowdfunding`,
      body,
    );
  }

  /**
   * PUT: `/api/v1/payments/{id}/crowdfunding`.
   * Update payment method to crowdfunding payment.
   */
  updateCrowdfundingPayment(
    id: string,
    body: UpdateCrowdfundingPaymentRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/payments/${id}/crowdfunding`,
      body,
    );
  }

  /**
   * POST: `/api/v1/user/payment_methods`.
   * Save Payment Method
   */
  savePaymentMethod(
    body: SaveCardToCustomerRequest,
  ): Observable<ResponseSuccess<null>> {
    return this.http.post<ResponseSuccess<null>>(
      `${this.host}/api/v1/user/payment_methods`,
      body,
    );
  }

  /**
   * DELETE: `/api/v1/user/payment_methods`.
   * Save Payment Method
   */
  removePaymentMethod(
    stripePaymentMethodID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/user/payment_methods/${stripePaymentMethodID}`,
    );
  }

  /**
   * GET: `/api/v1/user/payment_methods`.
   * Get Payment Methods
   */
  getPaymentMethods(): Observable<ResponseSuccess<GetPaymentMethodsResponse>> {
    return this.http.get<ResponseSuccess<GetPaymentMethodsResponse>>(
      `${this.host}/api/v1/user/payment_methods`,
    );
  }

  /**
   * GET: `/api/v1/user/payment_methods/{stripePaymentMethodID}`.
   * Get Payment Method
   */
  getPaymentMethod(
    stripePaymentMethodID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/user/payment_methods/${stripePaymentMethodID}`,
    );
  }

  scanTickets(eventId: string, qrCodeText: string): Observable<any> {
    const body = { qrcode: qrCodeText };
    return this.http.post<any>(
      `${this.host}/api/v1/organizer/events/${eventId}/qrcode_scanner`,
      body,
    );
  }

  getEventPayTicketDetails(eventId: string): Observable<any> {
    return this.http.get<any>(
      `${this.host}/api/v1/organizer/events/${eventId}/payment_tickets`,
    );
  }
}
