import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import {
  DonationRequest,
  EventRequest,
  TimeZoneResponse,
} from '@models/client/events/event-request.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  /**
   * POST: `/api/v1/events`
   * Create an event.
   *
   * @param event - Event create model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  createEvent(event: EventRequest): Observable<ResponseSuccess<EventRequest>> {
    return this.http.post<ResponseSuccess<EventRequest>>(
      `${this.host}/api/v1/events`,
      event,
    );
  }

  /**
   * PATCH: `/api/v1/event/{eventId}`
   * Update current value an event.
   *
   * @param eventId - Event id.
   * @param currentValue - current value.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  updateCurrentValue(
    eventId: string,
    checkChanges: EventRequest,
  ): Observable<ResponseSuccess<EventRequest>> {
    return this.http.patch<ResponseSuccess<EventRequest>>(
      `${this.host}/api/v1/events/${eventId}`,
      checkChanges,
    );
  }

  /**
   * GET: `/api/v1/events/${GUID}`
   * Get event by ID.
   *
   * @param id - Event ID.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  loadEventById(id: string): Observable<ResponseSuccess<EventRequest>> {
    return this.http.get<ResponseSuccess<EventRequest>>(
      `${this.host}/api/v1/events/${id}`,
    );
  }

  updateEvent(
    eventId: string,
    event: EventRequest,
  ): Observable<ResponseSuccess<EventRequest>> {
    return this.http.put<ResponseSuccess<EventRequest>>(
      `${this.host}/api/v1/events/${eventId}`,
      event,
    );
  }

  /**
   * DELETE: `/api/v1/events/{eventGUID}`
   * Removes an event.
   *
   *
   * @param id - Event id.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  removeEvent(id: string): Observable<ResponseSuccess<void>> {
    return this.http.delete<ResponseSuccess<void>>(
      `${this.host}/api/v2/events/${id}`,
    );
  }

  /**
   * GET: `https://maps.googleapis.com/maps/api/timezone/json?location={lat},{long}`
   * Get event by ID.
   *
   * @param lat - Latitude.
   * @param lng - Longitude.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  getTimeZone(lat: string, lng: string): Observable<TimeZoneResponse> {
    const mapApiKey = environment.mapApiKey;
    return this.http.get<TimeZoneResponse>(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1331766000&key=${mapApiKey}&language=en`,
    );
  }

  /**
   * GET: `/api/v1/events/{eventId}/donations`
   * Get donations for a specific event with filters and sorting.
   *
   * @param eventId - Event ID.
   * @param type - Type of donations to fetch (e.g., 'top_fundraising', 'top_donors', 'newest').
   * @param limit - Number of donations to fetch.
   * @param page - Page number for pagination.
   * @param sort - Field to sort donations by (e.g., 'amount', 'percent').
   *
   * @return An `Observable` of the `ResponseSuccess` with a list of donations.
   */
  getDonations(
    eventId: string,
    type: 'top_fundraising' | 'top_donors' | 'newest',
    limit: number = 10,
    page: number = 1,
    sort: 'amount' | 'percent' = 'amount',
  ): Observable<ResponseSuccess<DonationRequest[]>> {
    const params = new HttpParams()
      .set('type', type)
      .set('limit', limit.toString())
      .set('page', page.toString())
      .set('sort', sort);

    return this.http.get<ResponseSuccess<DonationRequest[]>>(
      `${this.host}/api/v1/events/${eventId}/donations`,
      { params },
    );
  }
}
