import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getEventsState = (state: RootStoreState.State) =>
  state.client.events;

export const getEventsDataResponseState = createSelector(
  getEventsState,
  (state: State) => state.eventsDataResponse,
);

export const getEventsDataResponseEventsState = createSelector(
  getEventsState,
  (state: State) =>
    state.eventsDataResponse ? state.eventsDataResponse.events : null,
);

export const getEventsDataResponseCountState = createSelector(
  getEventsState,
  (state: State) =>
    state.eventsDataResponse ? state.eventsDataResponse.count : null,
);

export const getEventByIdState = createSelector(
  getEventsState,
  (state: State) => state.event,
);

export const getEventsIsLoadingState = createSelector(
  getEventsState,
  (state: State) => state.loading,
);

export const getFanUpcomingEventsState = createSelector(
  getEventsState,
  (state: State) => state.fanUpcomingEvents,
);

export const getFanPastEventsState = createSelector(
  getEventsState,
  (state: State) => state.fanPastEvents,
);

export const getEventIDState = createSelector(
  getEventsState,
  (state: State) => state.eventID,
);

export const getPageStatus = createSelector(
  getEventsState,
  (state: State) => state.pageStatus,
);

export const getCreateEventStepsState = createSelector(
  getEventsState,
  (state: State) => state.createEventSteps,
);

export const getArtistsToEventRespState = createSelector(
  getEventsState,
  (state: State) => state.artistsToEventResp,
);

export const getVenuesToEventRespState = createSelector(
  getEventsState,
  (state: State) => state.venuesToEventResp,
);

export const getEventAnalyticsRespState = createSelector(
  getEventsState,
  (state: State) => state.eventAnalyticsResponse,
);

export const getAllowedArtistsResp = createSelector(
  getEventsState,
  (state: State) => state.allowedArtistsResp,
);

export const getLoadStatusState = createSelector(
  getEventsState,
  (state: State) => ({
    loading: state.loading,
    loaded: state.loaded,
  }),
);

export const getLoadedStatusState = createSelector(
  getEventsState,
  (state: State) => state.loaded,
);

export const getUpdatedStatusState = createSelector(
  getEventsState,
  (state: State) => state.updated,
);

export const getCreatedStatusState = createSelector(
  getEventsState,
  (state: State) => state.created,
);

export const getVenuePrivateInfoOfEvent = createSelector(
  getEventsState,
  (state: State) => state.venuePrivateInfoOfEvent,
);

export const getArtistPrivateInfoOfEvent = createSelector(
  getEventsState,
  (state: State) => state.artistPrivateInfoOfEvent,
);
