import { RootStoreState } from '@store/root';
import { createSelector } from '@ngrx/store';
import { State } from '@store/root/client/event/state';

export const getEventState = (state: RootStoreState.State) =>
  state.client.event;

export const getCreateEventState = createSelector(
  getEventState,
  (state: State) => state?.event,
);

export const getCreateEventError = createSelector(
  getEventState,
  (state: State) => state?.error,
);

export const getEventDataState = createSelector(
  getEventState,
  (state: State) => state?.event,
);

export const getEventIdState = createSelector(
  getEventState,
  (state: State) => state?.event?.id,
);

export const getParentEventIdState = createSelector(
  getEventState,
  (state: State) => state?.event?.parentEventId,
);

export const getTicketsDataState = createSelector(
  getEventState,
  (state: State) => state?.event?.tickets,
);

export const getTimeZoneIdState = createSelector(
  getEventState,
  (state: State) => state?.timeZoneId,
);

export const getEventErrorsState = createSelector(
  getEventState,
  (state: State) => state?.error,
);
