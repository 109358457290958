import { Action } from '@ngrx/store';
import { ResponseError } from '@models/shared/response/response-error.model';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import {
  DonationRequest,
  EventRequest,
  TimeZoneResponse,
} from '@models/client/events/event-request.model';

export enum ActionTypes {
  CreateEvent = '[Event] Create Event',
  CreateEventSuccess = '[Event] Create Event Success',
  CreateEventFailure = '[Event] Create Event Failure',

  UpdateCurrentValue = '[Event] Update Current By ID Event',
  UpdateCurrentValueSuccess = '[Event] Update Current Event By ID Success',
  UpdateCurrentValueFailure = '[Event] Update Current Event By ID Failure',

  UpdateEvents = '[Event] Update By ID Event',
  UpdateEventsSuccess = '[Event] Update Events By ID Success',
  UpdateEventsFailure = '[Event] Update Events By ID Failure',

  GetEventById = '[Events] Get Event By ID',
  GetEventByIdSuccess = '[Events] Get Event By ID Success',
  GetEventByIdFailure = '[Events] Get Event By ID Failure',

  RemoveEventByID = '[Events] Remove Event By ID',
  RemoveEventByIDSuccess = '[Events] Remove Event By ID Success',
  RemoveEventByIDFailure = '[Events] Remove Event By ID Failure',

  RemoveEventFromState = '[Events] Remove Event From State',

  GetDonationsByEventId = '[Events] Get Donations By Event ID',
  GetDonationsByEventIdSuccess = '[Events] Get Donations By Event ID Success',
  GetDonationsByEventIdFailure = '[Events] Get Donations By Event ID Failure',

  GetTimeZone = '[Event] Get Time Zone',
  GetTimeZoneSuccess = '[Event] Get Time Zone Success',
  GetTimeZoneFailure = '[Event] Get Time Zone Failure',
}

export class RemoveEventFromState implements Action {
  public readonly type = ActionTypes.RemoveEventFromState;
}

export class CreateEvent implements Action {
  readonly type = ActionTypes.CreateEvent;

  constructor(public payload: { event: EventRequest }) {}
}

export class CreateEventSuccess implements Action {
  readonly type = ActionTypes.CreateEventSuccess;

  constructor(public payload: { response: ResponseSuccess<EventRequest> }) {}
}

export class CreateEventFailure implements Action {
  readonly type = ActionTypes.CreateEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class UpdateCurrentValue implements Action {
  public readonly type = ActionTypes.UpdateCurrentValue;

  constructor(
    public payload: { id: string; checkChanges: { [p: string]: any } },
  ) {}
}

export class UpdateCurrentValueSuccess implements Action {
  public readonly type = ActionTypes.UpdateCurrentValueSuccess;

  constructor(public payload: { response: ResponseSuccess<EventRequest> }) {}
}

export class UpdateCurrentValueFailure implements Action {
  public readonly type = ActionTypes.UpdateCurrentValueFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class UpdateEvents implements Action {
  public readonly type = ActionTypes.UpdateEvents;

  constructor(public payload: { eventId: string; event: EventRequest }) {}
}

export class UpdateEventsSuccess implements Action {
  public readonly type = ActionTypes.UpdateEventsSuccess;

  constructor(public payload: { response: ResponseSuccess<EventRequest> }) {}
}

export class UpdateEventsFailure implements Action {
  public readonly type = ActionTypes.UpdateEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class GetEventById implements Action {
  public readonly type = ActionTypes.GetEventById;

  constructor(public payload: { id: string }) {}
}

export class GetEventByIdSuccess implements Action {
  public readonly type = ActionTypes.GetEventByIdSuccess;

  constructor(public payload: { response: ResponseSuccess<EventRequest> }) {}
}

export class GetEventByIdFailure implements Action {
  public readonly type = ActionTypes.GetEventByIdFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class RemoveEventByID implements Action {
  public readonly type = ActionTypes.RemoveEventByID;

  constructor(public payload: { id: string }) {}
}

export class RemoveEventByIDSuccess implements Action {
  public readonly type = ActionTypes.RemoveEventByIDSuccess;

  constructor(public payload: { response: ResponseSuccess<void> }) {}
}

export class RemoveEventByIDFailure implements Action {
  public readonly type = ActionTypes.RemoveEventByIDFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class GetTimeZone implements Action {
  public readonly type = ActionTypes.GetTimeZone;

  constructor(public payload: { lat: string; lng: string }) {}
}

export class GetTimeZoneSuccess implements Action {
  public readonly type = ActionTypes.GetTimeZoneSuccess;

  constructor(public payload: { response: TimeZoneResponse }) {}
}

export class GetTimeZoneFailure implements Action {
  public readonly type = ActionTypes.GetTimeZoneFailure;
}

export class GetDonationsByEventId implements Action {
  readonly type = ActionTypes.GetDonationsByEventId;

  constructor(
    public payload: {
      eventId: string;
      type: 'top_fundraising' | 'top_donors' | 'newest';
      limit?: number;
      page?: number;
      sort?: 'amount' | 'percent';
    },
  ) {}
}

export class GetDonationsByEventIdSuccess implements Action {
  readonly type = ActionTypes.GetDonationsByEventIdSuccess;

  constructor(
    public payload: { response: ResponseSuccess<DonationRequest[]> },
  ) {}
}

export class GetDonationsByEventIdFailure implements Action {
  readonly type = ActionTypes.GetDonationsByEventIdFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | CreateEvent
  | CreateEventSuccess
  | CreateEventFailure
  | UpdateCurrentValue
  | UpdateCurrentValueSuccess
  | UpdateCurrentValueFailure
  | UpdateEvents
  | UpdateEventsSuccess
  | UpdateEventsFailure
  | GetEventById
  | GetEventByIdSuccess
  | GetEventByIdFailure
  | GetDonationsByEventId
  | GetDonationsByEventIdSuccess
  | GetDonationsByEventIdFailure
  | RemoveEventByID
  | RemoveEventByIDSuccess
  | RemoveEventFromState
  | RemoveEventByIDFailure
  | GetTimeZone
  | GetTimeZoneSuccess
  | GetTimeZoneFailure;
