import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getSignUpState = (state: RootStoreState.State) =>
  state.client.signUp;

export const getSignUpTypeState = createSelector(
  getSignUpState,
  (state: State) => state.type,
);

export const getSignUpHaveCompanyState = createSelector(
  getSignUpState,
  (state: State) => state.haveCompany,
);

export const getSignUpFollowsState = createSelector(
  getSignUpState,
  (state: State) => state.follows,
);

export const getSignUpFormState = createSelector(
  getSignUpState,
  (state: State) => state.signUpForm,
);

export const getSignUpSocialFormState = createSelector(
  getSignUpState,
  (state: State) => state.signUpSocialForm,
);

export const getSignUpInfoFormState = createSelector(
  getSignUpState,
  (state: State) => state.signUpInfoForm,
);

export const getErrorsSignUpInfoFormState = createSelector(
  getSignUpState,
  (state: State) => state.errors,
);
