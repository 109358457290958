import { RootStoreState } from '@store/root';
import { createSelector } from '@ngrx/store';
import { State } from '@store/root/client/donors/state';

export const getDonorsState = (state: RootStoreState.State) =>
  state.client.donors;

export const getDonorsByIds = createSelector(
  getDonorsState,
  (state: State) => state?.donors,
);

export const getDonorsError = createSelector(
  getDonorsState,
  (state: State) => state?.error,
);
