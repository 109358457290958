import { Action } from '@ngrx/store';

export enum ActionTypes {
  LoadGenres = '[Genres] Load Genres',
  SetGenres = '[Genres] Set Genres',
}

export class LoadGenres implements Action {
  public readonly type = ActionTypes.LoadGenres;
}

export class SetGenres implements Action {
  public readonly type = ActionTypes.SetGenres;

  constructor(public payload: any) {}
}

export type Actions = LoadGenres | SetGenres;
