import { Action } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { VideoLink } from '@app/models/client/video/video-link.model';

export enum ActionTypes {
  UploadVideo = '[Client_Video] Upload Video',
  UploadVideoSuccess = '[Client_Video] Upload Video Success',
  UploadVideoFailure = '[Client_Video] Upload Video Failure',

  RemoveVideo = '[Client_Video] Remove Video',
  RemoveVideoSuccess = '[Client_Video] Remove Video Success',
  RemoveVideoFailure = '[Client_Video] Remove Video Failure',
}

// Upload Video

export class UploadVideo implements Action {
  public readonly type = ActionTypes.UploadVideo;

  constructor(public payload: { file: FormData; link: string }) {}
}

export class UploadVideoSuccess implements Action {
  public readonly type = ActionTypes.UploadVideoSuccess;

  constructor(public payload: { response: ResponseSuccess<VideoLink> }) {}
}

export class UploadVideoFailure implements Action {
  public readonly type = ActionTypes.UploadVideoFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Remove Video

export class RemoveVideo implements Action {
  public readonly type = ActionTypes.RemoveVideo;
  constructor(public payload: { id: string }) {}
}

export class RemoveVideoSuccess implements Action {
  public readonly type = ActionTypes.RemoveVideoSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class RemoveVideoFailure implements Action {
  public readonly type = ActionTypes.RemoveVideoFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | UploadVideo
  | UploadVideoSuccess
  | UploadVideoFailure
  | RemoveVideo
  | RemoveVideoSuccess
  | RemoveVideoFailure;
