import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getFanState = (state: RootStoreState.State) => state.client.fan;

export const getProfileState = createSelector(getFanState, (state: State) => {
  if (state.profile) {
    return state.profile;
  }
});

export const getProfileUpdatedState = createSelector(
  getFanState,
  (state: State) => {
    if (state) {
      return state.updated;
    }
  },
);

export const getProfileCreatedState = createSelector(
  getFanState,
  (state: State) => {
    if (state) {
      return state.created;
    }
  },
);

export const getProfileIDState = createSelector(getFanState, (state: State) => {
  if (state.profile && state.profile.id) {
    return state.profile.id;
  }
});

export const getProfileByIDState = createSelector(
  getFanState,
  (state: State) => state.profileByID,
);

export const getLoginedState = createSelector(
  getFanState,
  (state: State) => state.logined,
);

export const getLoadingState = createSelector(
  getFanState,
  (state: State) => state.loading,
);

export const getLoadedState = createSelector(
  getFanState,
  (state: State) => state.loaded,
);

export const getProfileAuthStatus = createSelector(
  getFanState,
  (state: State) => ({
    logined: state.logined,
    loading: state.loading,
    loaded: state.loaded,
  }),
);

export const getFollowingArtistsState = createSelector(
  getFanState,
  (state: State) => state.followingArtists,
);

export const getMessagesResponseState = createSelector(
  getFanState,
  (state: State) => state.messagesResponse,
);

export const getFollowersCountState = createSelector(
  getFanState,
  (state: State) => state.followersCount,
);

export const getFollowingCountState = createSelector(
  getFanState,
  (state: State) => state.followingCount,
);

export const getGuestCreateState = createSelector(
  getFanState,
  (state: State) => state.guest,
);
