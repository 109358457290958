import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  ActionTypes,
  CreateFan,
  CreateFanFailure,
  CreateFanSuccess,
  FollowAccount,
  FollowAccountFailure,
  FollowAccountSuccess,
  FollowArtist,
  FollowArtistFailure,
  FollowArtistSuccess,
  GetFollowingArtists,
  GetFollowingArtistsFailure,
  GetFollowingArtistsSuccess,
  GetProfileByID,
  GetProfileByIDFailure,
  GetProfileByIDSuccess,
  GetProfileMessages,
  GetProfileMessagesFailure,
  GetProfileMessagesSuccess,
  SetProfilePhoto,
  UnfollowAccount,
  UnfollowAccountFailure,
  UnfollowAccountSuccess,
  UnfollowArtist,
  UnfollowArtistFailure,
  UnfollowArtistSuccess,
  UpdateProfile,
  UpdateProfileFailure,
  UpdateProfileSuccess,
} from './actions';

import { FanService } from '@app/services/client/fan/fan.service';

import { getUserIDState, getUserTokenState } from '../user/selectors';
import { Response } from '@app/interfaces/response.interface';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { MessagesFanResponseSuccess } from '@app/models/client/fan/messages-fan-response-success.model';
import {
  GetFollowersCount,
  GetFollowersCountFailure,
  GetFollowersCountSuccess,
  GetFollowingCount,
  GetFollowingCountFailure,
  GetFollowingCountSuccess,
} from '@app/store/root/client/organizer/actions';
import { FollowersCount } from '@app/models/client/followers-count.model';
import { AppStoreActions } from '@app/store/root/app';
import { RootStoreState } from '@app/store/root';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { AccountType } from '@app/models/admin/accounts/account-type.enum';
import { FanModel } from '@models/client/fan/fan.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ProfileStoreActions } from '@store/root/client/profile';

@Injectable()
export class FanEffects {
  createFan$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateFan>(ActionTypes.CreateFan),
      switchMap((action) =>
        this.fanService.createFanProfile(action.payload.fan).pipe(
          tap(async () => {
            const user = await this.auth.user.toPromise();
            await user.getIdToken(true);
          }),
          map((response: Response) => new CreateFanSuccess()),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new CreateFanFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getProfileByID$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfileByID>(ActionTypes.GetProfileByID),
      switchMap((action) =>
        this.fanService.getProfileByID(action.payload.id).pipe(
          map(
            (response: ResponseSuccess<FanModel>) =>
              new GetProfileByIDSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetProfileByIDFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateFan$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfile>(ActionTypes.UpdateProfile),
      switchMap((action) =>
        this.fanService.updateFanProfile(action.payload.profile).pipe(
          map((response: Response) => new UpdateProfileSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateProfileFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateFanSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProfileSuccess>(ActionTypes.UpdateProfileSuccess),
      map(() => new ProfileStoreActions.GetProfiles()),
    ),
  );

  setProfilePhoto$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SetProfilePhoto>(ActionTypes.SetProfilePhoto),
      switchMap((action) =>
        this.fanService.setProfilePhoto(action.payload.image).pipe(
          map((response: Response) => new UpdateProfileSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UpdateProfileFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  followArtist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FollowArtist>(ActionTypes.FollowArtist),
      switchMap((action) =>
        this.fanService.followArtistProfile(action.payload.artistGUID).pipe(
          map((response: Response) => new FollowArtistSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new FollowArtistFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  unfollowArtist$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UnfollowArtist>(ActionTypes.UnfollowArtist),
      switchMap((action) =>
        this.fanService.unfollowArtistProfile(action.payload.artistGUID).pipe(
          map((response: Response) => new UnfollowArtistSuccess({ response })),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new UnfollowArtistFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getFollowingArtists$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFollowingArtists>(ActionTypes.GetFollowingArtists),
      switchMap((action) =>
        this.fanService
          .getFollowingArtists(
            action.payload.fanGUID,
            action.payload.limit,
            action.payload.page,
          )
          .pipe(
            map(
              (response: Response) =>
                new GetFollowingArtistsSuccess({ response }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetFollowingArtistsFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  /**
   * Handles get followers count.
   * @action - [Fan] GetFollowersCount
   * @return:
   *   Success: [Fan] GetFollowersCountFailure
   *   Failure: [Fan] GetFollowersCountFailure & [Errors] AddErrorResponse
   */
  getFollowersCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFollowersCount>(ActionTypes.GetFollowersCount),
      withLatestFrom(
        this.store.select(getUserTokenState),
        this.store.select(getUserIDState),
      ),
      switchMap(([_, token, id]) =>
        this.fanService.getFollowersCount(id).pipe(
          map(
            (response: FollowersCount) =>
              new GetFollowersCountSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetFollowersCountFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles get following count.
   * @action - [Fan] GetFollowingCount
   * @return:
   *   Success: [Fan] GetFollowingCountFailure
   *   Failure: [Fan] GetFollowingCountFailure & [Errors] AddErrorResponse
   */
  getFollowingCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFollowingCount>(ActionTypes.GetFollowingCount),
      withLatestFrom(
        this.store.select(getUserTokenState),
        this.store.select(getUserIDState),
      ),
      switchMap(([action, token, id]) =>
        this.fanService.getFollowingCount(id).pipe(
          map(
            (response: FollowersCount) =>
              new GetFollowingCountSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetFollowingCountFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /***** Messages *****/

  getMessages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProfileMessages>(ActionTypes.GetProfileMessages),
      withLatestFrom(this.store.select(getUserTokenState)),
      switchMap(() =>
        this.fanService.getMessages().pipe(
          map(
            (response: MessagesFanResponseSuccess) =>
              new GetProfileMessagesSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new GetProfileMessagesFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  followAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FollowAccount>(ActionTypes.FollowAccount),
      switchMap((action) => {
        switch (action.payload.accountType) {
          case AccountType.FAN:
            return this.fanService.followUpFanAccount(action.payload.id).pipe(
              map(
                (response: ResponseSuccess<any>) =>
                  new FollowAccountSuccess({ response }),
              ),
              catchError((errResponse: ResponseError) =>
                ErrorResponseHelper.getErrorResponseErrorObject(
                  errResponse,
                ).pipe(
                  switchMap((response: IErrorResponse) => [
                    new FollowAccountFailure({ response }),
                    new AppStoreActions.AddErrorResponse({ response }),
                  ]),
                ),
              ),
            );
          case AccountType.ARTIST:
            return this.fanService
              .followUpArtistAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.VENUE:
            return this.fanService.followUpVenueAccount(action.payload.id).pipe(
              map(
                (response: ResponseSuccess<any>) =>
                  new FollowAccountSuccess({ response }),
              ),
              catchError((errResponse: ResponseError) =>
                ErrorResponseHelper.getErrorResponseErrorObject(
                  errResponse,
                ).pipe(
                  switchMap((response: IErrorResponse) => [
                    new FollowAccountFailure({ response }),
                    new AppStoreActions.AddErrorResponse({ response }),
                  ]),
                ),
              ),
            );
          case AccountType.ORGANIZER:
            return this.fanService
              .followUpOrganizerAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new FollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new FollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
        }
      }),
    ),
  );

  unfollowAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UnfollowAccount>(ActionTypes.UnfollowAccount),
      switchMap((action) => {
        switch (action.payload.accountType) {
          case AccountType.FAN:
            return this.fanService.unfollowUpFanAccount(action.payload.id).pipe(
              map(
                (response: ResponseSuccess<any>) =>
                  new UnfollowAccountSuccess({ response }),
              ),
              catchError((errResponse: ResponseError) =>
                ErrorResponseHelper.getErrorResponseErrorObject(
                  errResponse,
                ).pipe(
                  switchMap((response: IErrorResponse) => [
                    new UnfollowAccountFailure({ response }),
                    new AppStoreActions.AddErrorResponse({ response }),
                  ]),
                ),
              ),
            );
          case AccountType.ARTIST:
            return this.fanService
              .unfollowUpArtistAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.VENUE:
            return this.fanService
              .unfollowUpVenueAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
          case AccountType.ORGANIZER:
            return this.fanService
              .unfollowUpOrganizerAccount(action.payload.id)
              .pipe(
                map(
                  (response: ResponseSuccess<any>) =>
                    new UnfollowAccountSuccess({ response }),
                ),
                catchError((errResponse: ResponseError) =>
                  ErrorResponseHelper.getErrorResponseErrorObject(
                    errResponse,
                  ).pipe(
                    switchMap((response: IErrorResponse) => [
                      new UnfollowAccountFailure({ response }),
                      new AppStoreActions.AddErrorResponse({ response }),
                    ]),
                  ),
                ),
              );
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private fanService: FanService,
    private auth: AngularFireAuth,
  ) {}
}
