import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  AcceptTicket,
  AcceptTicketFailure,
  AcceptTicketSuccess,
  ActionTypes,
  CreateTicket,
  CreateTicketFailure,
  CreateTicketSuccess,
  DeleteTicket,
  DeleteTicketFailure,
  DeleteTicketSuccess,
  GetFanBoughtTickets,
  GetFanBoughtTicketsFailure,
  GetFanBoughtTicketsSuccess,
  GetOrganizerEventTickets,
  GetOrganizerEventTicketsFailure,
  GetOrganizerEventTicketsSuccess,
  GiveTicketToGuest,
  GiveTicketToGuestFailure,
  GiveTicketToGuestSuccess,
  PrintTicketAsPDF,
  PrintTicketAsPDFFailure,
  PrintTicketAsPDFSuccess,
  RefuseTicket,
  RefuseTicketFailure,
  RefuseTicketSuccess,
  UpdateTicket,
  UpdateTicketFailure,
  UpdateTicketSuccess,
} from './actions';

import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { TicketsService } from '@app/services/client/tickets/tickets.service';
import { GetFanTicketsResponseSuccess } from '@app/models/client/tickets/get-fan-tickets-response-success.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { FileUtils } from '@app/base/utils/file.utils';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { AppStoreActions } from '@app/store/root/app';
import { EventTicketsResponse } from '@app/models/client/tickets/event-tickets-response.model';

@Injectable()
export class TicketsEffects {
  getOrganizerEventTickets$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetOrganizerEventTickets>(ActionTypes.GetOrganizerEventTickets),
      switchMap((action) =>
        this.ticketsService
          .getOrganizerEventTickets(action.payload.eventGUID)
          .pipe(
            map(
              (response: EventTicketsResponse) =>
                new GetOrganizerEventTicketsSuccess({ response }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new GetOrganizerEventTicketsFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  createTicket$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateTicket>(ActionTypes.CreateTicket),
      switchMap((action) =>
        this.ticketsService.createTicket(action.payload.query).pipe(
          map(
            (response: ResponseSuccess<EventTicketsResponse>) =>
              new CreateTicketSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new CreateTicketFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  updateTicket$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTicket>(ActionTypes.UpdateTicket),
      switchMap((action) =>
        this.ticketsService
          .updateTicket(action.payload.query, action.payload.ticketGUID)
          .pipe(
            map(
              (response: ResponseSuccess<EventTicketsResponse>) =>
                new UpdateTicketSuccess({ response }),
            ),
            catchError((errResponse: IErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: IErrorResponse) => [
                  new UpdateTicketFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  deleteTicket$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTicket>(ActionTypes.DeleteTicket),
      switchMap((action) =>
        this.ticketsService.deleteTicket(action.payload.ticketGUID).pipe(
          map(
            (response: ResponseSuccess<EventTicketsResponse>) =>
              new DeleteTicketSuccess({
                ticketGUID: action.payload.ticketGUID,
              }),
          ),
          catchError((errResponse: IErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                new DeleteTicketFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  printTicketAsPDF$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PrintTicketAsPDF>(ActionTypes.PrintTicketAsPDF),
      switchMap((action) =>
        this.ticketsService.printTicketAsPDF(action.payload.ticketGUID).pipe(
          map((response: ArrayBuffer) => {
            FileUtils.downloadFilePDF(response);
            return new PrintTicketAsPDFSuccess({ response });
          }),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => {
                return [
                  new PrintTicketAsPDFFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ];
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getFanBoughtTickets$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFanBoughtTickets>(ActionTypes.GetFanBoughtTickets),
      switchMap((action) =>
        this.ticketsService
          .getFanBoughtTickets(
            action.payload.eventId,
            action.payload.limit,
            action.payload.page,
          )
          .pipe(
            map(
              (response: GetFanTicketsResponseSuccess) =>
                new GetFanBoughtTicketsSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetFanBoughtTicketsFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  giveTicketToGuest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GiveTicketToGuest>(ActionTypes.GiveTicketToGuest),
      switchMap((action) =>
        this.ticketsService
          .giveTicketToGuest(action.payload.ticketId, action.payload.request)
          .pipe(
            map(
              (response: ResponseSuccess<any>) =>
                new GiveTicketToGuestSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GiveTicketToGuestFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  acceptTicket$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AcceptTicket>(ActionTypes.AcceptTicket),
      switchMap((action) =>
        this.ticketsService.acceptTicket(action.payload.ticketId).pipe(
          map(
            (response: ResponseSuccess<any>) =>
              new AcceptTicketSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new AcceptTicketFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  refuseTicket$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RefuseTicket>(ActionTypes.RefuseTicket),
      switchMap((action) =>
        this.ticketsService.refuseTicket(action.payload.ticketId).pipe(
          map(
            (response: ResponseSuccess<any>) =>
              new RefuseTicketSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new RefuseTicketFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private ticketsService: TicketsService,
  ) {}
}
