import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getSignInState = (state: RootStoreState.State) =>
  state.client.signIn;

export const getSignInSocialState = createSelector(
  getSignInState,
  (state: State) => state.social,
);

export const getSignInUUIDState = createSelector(
  getSignInState,
  (state: State) => state.uuid,
);

export const getSignInConfirmState = createSelector(
  getSignInState,
  (state: State) => state.confirm,
);

export const getSignInPasswordUpdatedState = createSelector(
  getSignInState,
  (state: State) => state.passwordUpdated,
);
