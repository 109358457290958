import { SignUpForm } from '@app/models/client/sign-up/sign-up-form.model';
import { SignUpInfoForm } from '@app/models/client/sign-up/sign-up-info-form.model';
import { SignUpSocialForm } from '@app/models/client/sign-up/sign-up-social-form.model';
import { ResponseError } from '@models/shared/response/response-error.model';

export interface State {
  signUpForm: {
    passed: boolean;
    form: SignUpForm;
  };
  signUpSocialForm: {
    passed: boolean;
    form: SignUpSocialForm;
  };
  signUpSocialFanInfoForm: any;
  signUpInfoForm: SignUpInfoForm;
  type: string;
  haveCompany: string;
  follows: any;
  errors: ResponseError | null;
}

export const initialState: State = {
  signUpForm: {
    passed: false,
    form: null,
  },
  signUpSocialForm: {
    passed: false,
    form: null,
  },
  signUpSocialFanInfoForm: null,
  signUpInfoForm: null,
  type: 'Organizer',
  haveCompany: '',
  follows: null,
  errors: null,
};
