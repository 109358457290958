import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { RootStoreState } from '@store/root';
import { MediaService } from '@services/client/media/media.service';

@Injectable()
export class MediaEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private mediaService: MediaService,
  ) {}
}
