import { Action } from '@ngrx/store';

import { CreateOrUpdateEventRequest } from '@app/models/client/events/create-or-update-event-request.model';
import { EventArtistResponse } from '@app/models/client/events/event-artist-response.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { DeclineArtistRequestOfEvent } from '@app/models/client/events/decline-artist-request-of-event.model';
import { RequestArtistOfEvent } from '@app/models/client/events/request-artist-of-event.class';
import { ResendArtistRequestOfEvent } from '@app/models/client/events/resend-artist-request-of-event.model';
import { EventArtistsResponse } from '@app/models/client/events/event-artists-response.model';
import { DeclineVenueRequestOfEvent } from '@app/models/client/events/decline-venue-request-of-event.model';
import { IEventVenuesResponse } from '@app/models/client/events/event-venues-response.model';
import { EventVenueResponse } from '@app/models/client/events/event-venue-response.model';
import { ResendVenueRequestOfEvent } from '@app/models/client/events/resend-venue-request-of-event.model';
import { AddOrUpdateVenuesToEvent } from '@app/models/client/events/add-or-update-venues-to-event.model';
import { RequestVenueOfEvent } from '@app/models/client/events/request-venue-of-event.class';
import { PayForArtistOfEventRequest } from '@app/models/client/events/funding/pay-for-artist-of-event-request.model';
import { CreateOrUpdateFundingRequest } from '@app/models/client/events/funding/create-or-update-funding-request.model';
import { PayForVenueOfEventRequest } from '@app/models/client/events/funding/pay-for-venue-of-event-request.model';
import { GetEventPhotosResponseSuccess } from '@app/models/client/events/event-photos-success.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { UpdateRequestVenueOfEvent } from '@app/models/client/events/update-request-venue-of-event.model';
import { UpdateRequestArtistOfEvent } from '@app/models/client/events/update-request-artist-of-event.model';
import { EventPageStatus } from '@app/enums/events/event-page-status.enum';
import { CreateEventSteps } from '@app/models/client/events/create-event-steps.model';
import { EventAnalyticsResponse } from '@app/models/client/events/analytics/event-analytics-response.model';
import { CreatePaymentResponse } from '@app/models/client/payment/create-payment-response.model';
import { EventResponse } from '@app/models/client/events/event-response.model';
import { GetEventsParams } from '@app/models/client/events/get-events-params.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { GetGoingFanEventResponse } from '@app/models/client/events/get-going-fan-event-response.model';
import { EventFundingResponse } from '@app/models/client/events/funding/event-funding-response.model';
import { ticketDisplayOrder } from '@models/client/events/update-ticket-display-order';

export enum ActionTypes {
  LoadEvents = '[Events] Load Events',
  ConfirmEvent = '[Events] Confirm Event',
  ConfirmEventSuccess = '[Events] Confirm Event Success',
  ConfirmEventFailure = '[Events] Confirm Event Failure',

  LoadEventsSuccess = '[Events] Load Events Success',
  LoadEventsFailure = '[Events] Load Events Failure',

  LoadEventById = '[Events] Load Event By ID',
  LoadEventByIdSuccess = '[Events] Load Event By ID Success',
  LoadEventByIdFailure = '[Events] Load Event By ID Failure',

  LaunchEvent = '[Events] Launch Event',
  LaunchEventSuccess = '[Events] Launch Event Success',
  LaunchEventFailure = '[Events] Launch Event Failure',

  RemoveEvent = '[Events] Remove Event',
  RemoveEvents = '[Events] Remove Events',

  LoadEventImage = '[Events] Load Event Image',

  CreateEvent = '[Events] Create Event',
  CreateEventSuccess = '[Events] Create Event Success',
  CreateEventFailure = '[Events] Create Event Failure',

  UpdateEvent = '[Events] Update Event',
  UpdateEventSuccess = '[Events] Update Event Success',
  UpdateEventFailure = '[Events] Update Event Failure',

  GetTimeZone = '[Events] Get Time Zone',
  GetTimeZoneSuccess = '[Events] Get Time Zone Success',
  GetTimeZoneFailure = '[Events] Get Time Zone Failure',

  GetFanUpcomingEvents = '[Events] Get Fan Upcoming Events',
  GetFanUpcomingEventsSuccess = '[Events] Get Fan Upcoming Events Success',
  GetFanUpcomingEventsFailure = '[Events] Get Fan Upcoming Events Failure',

  GetFanPastEvents = '[Events] Get Fan Past Events',
  GetFanPastEventsSuccess = '[Events] Get Fan Past Events Success',
  GetFanPastEventsFailure = '[Events] Get Fan Past Events Failure',

  // Event Artist
  GetArtistsToEvent = '[Events] Get Artists To Event',
  GetArtistsToEventSuccess = '[Events] Get Artists To Event Success',
  GetArtistsToEventFailure = '[Events] Get Artists To Event Failure',

  AddArtistsToEvent = '[Events] Add Artists To Event',
  AddArtistsToEventSuccess = '[Events] Add Artists To Event Success',
  AddArtistsToEventFailure = '[Events] Add Artists To Event Failure',

  RemoveArtistFromEvent = '[Events] Remove Artist From Event',
  RemoveArtistFromEventSuccess = '[Events] Remove Artist From Event Success',
  RemoveArtistFromEventFailure = '[Events] Remove Artist From Event Failure',

  AcceptArtistRequestToEvent = '[Events] Accept Artist Request To Event',
  AcceptArtistRequestToEventSuccess = '[Events] Accept Artist Request To Event Success',
  AcceptArtistRequestToEventFailure = '[Events] Accept Artist Request To Event Failure',

  ApproveArtistRequestToEvent = '[Events] Approve Artist Request To Event',
  ApproveArtistRequestToEventSuccess = '[Events] Approve Artist Request To Event Success',
  ApproveArtistRequestToEventFailure = '[Events] Approve Artist Request To Event Failure',

  DeclineArtistRequestToEvent = '[Events] Decline Artist Request To Event',
  DeclineArtistRequestToEventSuccess = '[Events] Decline Artist Request To Event Success',
  DeclineArtistRequestToEventFailure = '[Events] Decline Artist Request To Event Failure',

  GetArtistsRequestToEvent = '[Events] Get Artists Request To Event',
  GetArtistsRequestToEventSuccess = '[Events] Get Artists Request To Event Success',
  GetArtistsRequestToEventFailure = '[Events] Get Artists Request To Event Failure',

  UpdateArtistRequestToEvent = '[Events] Update Artist Request To Event',
  UpdateArtistRequestToEventSuccess = '[Events] Update Artist Request To Event Success',
  UpdateArtistRequestToEventFailure = '[Events] Update Artist Request To Event Failure',

  SendRequestToArtistOfEvent = '[Events] Send Request To Artist Of Event',
  SendRequestToArtistOfEventSuccess = '[Events] Send Request To Artist Of Event Success',
  SendRequestToArtistOfEventFailure = '[Events] Send Request To Artist Of Event Failure',

  ResendRequestToArtistOfEvent = '[Events] Resend Request To Artist Of Event',
  ResendRequestToArtistOfEventSuccess = '[Events] Resend Request To Artist Of Event Success',
  ResendRequestToArtistOfEventFailure = '[Events] Resend Request To Artist Of Event Failure',

  GetAllowedArtistsOfEvent = '[Events] Get Allowed Artist Of Event',
  GetAllowedArtistsOfEventSuccess = '[Events] Get Allowed Artist Of Event Success',
  GetAllowedArtistOfEventFailure = '[Events] Get Allowed Artist Of Event Failure',

  // Event Venue
  UploadEventPoster = '[Events] Upload Event Poster',

  GetVenuesToEvent = '[Events] Get Venues To Event',
  GetVenuesToEventSuccess = '[Events] Get Venues To Event Success',
  GetVenuesToEventFailure = '[Events] Get Venues To Event Failure',

  AddVenuesToEvent = '[Events] Add Venues To Event',
  AddVenuesToEventSuccess = '[Events] Add Venues To Event Success',
  AddVenuesToEventFailure = '[Events] Add Venues To Event Failure',

  RemoveVenueFromEvent = '[Events] Remove Venue From Event',
  RemoveVenueFromEventSuccess = '[Events] Remove Venue From Event Success',
  RemoveVenueFromEventFailure = '[Events] Remove Venue From Event Failure',

  AcceptVenueRequestToEvent = '[Events] Accept Venue Request To Event',
  AcceptVenueRequestToEventSuccess = '[Events] Accept Venue Request To Event Success',
  AcceptVenueRequestToEventFailure = '[Events] Accept Venue Request To Event Failure',

  ApproveVenueRequestToEvent = '[Events] Approve Venue Request To Event',
  ApproveVenueRequestToEventSuccess = '[Events] Approve Venue Request To Event Success',
  ApproveVenueRequestToEventFailure = '[Events] Approve Venue Request To Event Failure',

  DeclineVenueRequestToEvent = '[Events] Decline Venue Request To Event',
  DeclineVenueRequestToEventSuccess = '[Events] Decline Venue Request To Event Success',
  DeclineVenueRequestToEventFailure = '[Events] Decline Venue Request To Event Failure',

  GetVenuesRequestToEvent = '[Events] Get Venues Request To Event',
  GetVenuesRequestToEventSuccess = '[Events] Get Venues Request To Event Success',
  GetVenuesRequestToEventFailure = '[Events] Get Venues Request To Event Failure',

  UpdateVenueRequestToEvent = '[Events] Update Venue Request To Event',
  UpdateVenueRequestToEventSuccess = '[Events] Update Venue Request To Event Success',
  UpdateVenueRequestToEventFailure = '[Events] Update Venue Request To Event Failure',

  SendRequestToVenueOfEvent = '[Events] Send Request To Venue Of Event',
  SendRequestToVenueOfEventSuccess = '[Events] Send Request To Venue Of Event Success',
  SendRequestToVenueOfEventFailure = '[Events] Send Request To Venue Of Event Failure',

  ResendRequestToVenueOfEvent = '[Events] Resend Request To Venue Of Event',
  ResendRequestToVenueOfEventSuccess = '[Events] Resend Request To Venue Of Event Success',
  ResendRequestToVenueOfEventFailure = '[Events] Resend Request To Venue Of Event Failure',

  // Funding
  PayForArtistOfEvent = '[Events] Pay For Artist Of Event',
  PayForArtistOfEventSuccess = '[Events] Pay For Artist Of Event Success',
  PayForArtistOfEventFailure = '[Events] Pay For Artist Of Event Failure',

  PayForVenueOfEvent = '[Events] Pay For Venue Of Event',
  PayForVenueOfEventSuccess = '[Events] Pay For Venue Of Event Success',
  PayForVenueOfEventFailure = '[Events] Pay For Venue Of Event Failure',

  GetFundingDetailsOfEvent = '[Events] Get Funding Details Of Event',
  GetFundingDetailsOfEventSuccess = '[Events] Get Funding Details Of Event Success',
  GetFundingDetailsOfEventFailure = '[Events] Get Funding Details Of Event Failure',

  UpdateFundingPartOfEvent = '[Events] Update Funding Part Of Event',
  UpdateFundingPartOfEventSuccess = '[Events] Update Funding Part Of Event Success',
  UpdateFundingPartOfEventFailure = '[Events] Update Funding Part Of Event Failure',

  /***** Analytics *****/
  GetEventAnalytics = '[Events] Get Event Analytics',
  GetEventAnalyticsSuccess = '[Events] Get Event Analytics Success',
  GetEventAnalyticsFailure = '[Events] Get Event Analytics Failure',

  /***** Media *****/
  GetEventPhotos = '[Events] Get Event Photos',
  GetEventPhotosSuccess = '[Events] Get Event Photos Success',
  GetEventPhotosFailure = '[Events] Get Event Photos Failure',
  SetPhotoToMedia = '[Events] Set Photo To Media',

  SetEventID = '[Events] Set Event ID',

  // Set Page Status
  SetPageStatus = '[Events] Set Page Status',

  // Set Create Event Steps
  SetCreateEventSteps = '[Events] Set Create Event Steps',

  // Remove Event By ID
  RemoveEventByID = '[Events] Remove Event By ID',
  RemoveEventByIDSuccess = '[Events] Remove Event By ID Success',
  RemoveEventByIDFailure = '[Events] Remove Event By ID Failure',

  // Put Event To Review
  PutEventToReview = '[Events] Put Event To Review',
  PutEventToReviewSuccess = '[Events] Put Event To Review Success',
  PutEventToReviewFailure = '[Events] Put Event To Review Failure',

  // Get venue private info of event
  GetVenuePrivateInfoOfEvent = '[Events] Get venue private info of event',
  GetVenuePrivateInfoOfEventSuccess = '[Events] Get venue private info of event Success',
  GetVenuePrivateInfoOfEventFailure = '[Events] Get venue private info of event Failure',

  // Get artist private info of event
  GetArtistPrivateInfoOfEvent = '[Events] Get artist private info of event',
  GetArtistPrivateInfoOfEventSuccess = '[Events] Get artist private info of event Success',
  GetArtistPrivateInfoOfEventFailure = '[Events] Get artist private info of event Failure',

  SetUpdated = '[Events] Set Updated',
  SetCreated = '[Events] Set Created',
  UpdateTicketsDisplayOrder = '[Events] Update Tickets Display Order',
}

export class SetPhotoToMedia implements Action {
  public readonly type = ActionTypes.SetPhotoToMedia;

  constructor(
    public payload: { photo: { url: string; title: string; id: string } },
  ) {}
}

// Load Events

export class LoadEvents implements Action {
  public readonly type = ActionTypes.LoadEvents;

  constructor(public payload: { params: GetEventsParams }) {}
}

export class LoadEventsSuccess implements Action {
  public readonly type = ActionTypes.LoadEventsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class LoadEventsFailure implements Action {
  public readonly type = ActionTypes.LoadEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Load Event by ID

export class LoadEventById implements Action {
  public readonly type = ActionTypes.LoadEventById;

  constructor(public payload: { id: string }) {}
}

export class LoadEventByIdSuccess implements Action {
  public readonly type = ActionTypes.LoadEventByIdSuccess;

  constructor(public payload: { response: EventResponse }) {}
}

export class LoadEventByIdFailure implements Action {
  public readonly type = ActionTypes.LoadEventByIdFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Load Event Image

export class LoadEventImage implements Action {
  public readonly type = ActionTypes.LoadEventImage;

  constructor(public payload: any) {}
}

// Remove Events

export class RemoveEvents implements Action {
  public readonly type = ActionTypes.RemoveEvents;
}

// Confirm Event

export class ConfirmEvent implements Action {
  public readonly type = ActionTypes.ConfirmEvent;

  constructor(public payload: string) {}
}

export class ConfirmEventSuccess implements Action {
  public readonly type = ActionTypes.ConfirmEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class ConfirmEventFailure implements Action {
  public readonly type = ActionTypes.ConfirmEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Remove Event

export class RemoveEvent implements Action {
  public readonly type = ActionTypes.RemoveEvent;
}

// Create Event

export class CreateEvent implements Action {
  public readonly type = ActionTypes.CreateEvent;

  constructor(public payload: { model: CreateOrUpdateEventRequest }) {}
}

export class CreateEventSuccess implements Action {
  public readonly type = ActionTypes.CreateEventSuccess;

  constructor(public payload: { response: EventResponse }) {}
}

export class CreateEventFailure implements Action {
  public readonly type = ActionTypes.CreateEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Update Event

export class UpdateEvent implements Action {
  public readonly type = ActionTypes.UpdateEvent;

  constructor(
    public payload: { model: CreateOrUpdateEventRequest; eventId: string },
  ) {}
}

export class UpdateEventSuccess implements Action {
  public readonly type = ActionTypes.UpdateEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UpdateEventFailure implements Action {
  public readonly type = ActionTypes.UpdateEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// GetFanUpcomingEvents

export class GetFanUpcomingEvents implements Action {
  public readonly type = ActionTypes.GetFanUpcomingEvents;

  constructor(public payload: { params: GetEventsParams }) {}
}

export class GetFanUpcomingEventsSuccess implements Action {
  public readonly type = ActionTypes.GetFanUpcomingEventsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetGoingFanEventResponse> },
  ) {}
}

export class GetFanUpcomingEventsFailure implements Action {
  public readonly type = ActionTypes.GetFanUpcomingEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// GetFanPastEvents

export class GetFanPastEvents implements Action {
  public readonly type = ActionTypes.GetFanPastEvents;

  constructor(public payload: { params: GetEventsParams }) {}
}

export class GetFanPastEventsSuccess implements Action {
  public readonly type = ActionTypes.GetFanPastEventsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetGoingFanEventResponse> },
  ) {}
}

export class GetFanPastEventsFailure implements Action {
  public readonly type = ActionTypes.GetFanPastEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

/*** Event Artist ***/

// GetArtistsToEvent

export class GetArtistsToEvent implements Action {
  public readonly type = ActionTypes.GetArtistsToEvent;

  constructor(public payload: { eventGUID: string }) {}
}

export class GetArtistsToEventSuccess implements Action {
  public readonly type = ActionTypes.GetArtistsToEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<EventArtistsResponse> },
  ) {}
}

export class GetArtistsToEventFailure implements Action {
  public readonly type = ActionTypes.GetArtistsToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// AddArtistsToEvent

export class AddArtistsToEvent implements Action {
  public readonly type = ActionTypes.AddArtistsToEvent;

  constructor(
    public payload: {
      eventGUID: string;
      event: CreateOrUpdateEventRequest;
    },
  ) {}
}

export class AddArtistsToEventSuccess implements Action {
  public readonly type = ActionTypes.AddArtistsToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class AddArtistsToEventFailure implements Action {
  public readonly type = ActionTypes.AddArtistsToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// RemoveArtistFromEvent

export class RemoveArtistFromEvent implements Action {
  public readonly type = ActionTypes.RemoveArtistFromEvent;

  constructor(
    public payload: {
      eventGUID: string;
      artistGUID: string;
    },
  ) {}
}

export class RemoveArtistFromEventSuccess implements Action {
  public readonly type = ActionTypes.RemoveArtistFromEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class RemoveArtistFromEventFailure implements Action {
  public readonly type = ActionTypes.RemoveArtistFromEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// AcceptArtistRequestToEvent

export class AcceptArtistRequestToEvent implements Action {
  public readonly type = ActionTypes.AcceptArtistRequestToEvent;

  constructor(public payload: { eventGUID: string; artistGUID: string }) {}
}

export class AcceptArtistRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.AcceptArtistRequestToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class AcceptArtistRequestToEventFailure implements Action {
  public readonly type = ActionTypes.AcceptArtistRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// AllowedArtistRequestToEvent

export class AllowedArtistRequestToEvent implements Action {
  public readonly type = ActionTypes.GetAllowedArtistsOfEvent;
}

export class AllowedArtistRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.GetAllowedArtistsOfEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<EventArtistResponse[]> },
  ) {}
}

export class AllowedArtistRequestToEventFailure implements Action {
  public readonly type = ActionTypes.GetAllowedArtistOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// ApproveArtistRequestToEvent

export class ApproveArtistRequestToEvent implements Action {
  public readonly type = ActionTypes.ApproveArtistRequestToEvent;

  constructor(public payload: { eventGUID: string; artistGUID: string }) {}
}

export class ApproveArtistRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.ApproveArtistRequestToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class ApproveArtistRequestToEventFailure implements Action {
  public readonly type = ActionTypes.ApproveArtistRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// DeclineArtistRequestToEvent

export class DeclineArtistRequestToEvent implements Action {
  public readonly type = ActionTypes.DeclineArtistRequestToEvent;

  constructor(
    public payload: {
      eventGUID: string;
      artistGUID: string;
      query: DeclineArtistRequestOfEvent;
    },
  ) {}
}

export class DeclineArtistRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.DeclineArtistRequestToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class DeclineArtistRequestToEventFailure implements Action {
  public readonly type = ActionTypes.DeclineArtistRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// GetArtistsRequestToEvent

export class GetArtistsRequestToEvent implements Action {
  public readonly type = ActionTypes.GetArtistsRequestToEvent;

  constructor(public payload: { eventGUID: string; artistGUID: string }) {}
}

export class GetArtistsRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.GetArtistsRequestToEventSuccess;

  constructor(public payload: { response: EventArtistResponse }) {}
}

export class GetArtistsRequestToEventFailure implements Action {
  public readonly type = ActionTypes.GetArtistsRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// UpdateArtistRequestToEvent

export class UpdateArtistRequestToEvent implements Action {
  public readonly type = ActionTypes.UpdateArtistRequestToEvent;

  constructor(
    public payload: {
      eventGUID: string;
      artistGUID: string;
      query: UpdateRequestArtistOfEvent;
    },
  ) {}
}

export class UpdateArtistRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.UpdateArtistRequestToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UpdateArtistRequestToEventFailure implements Action {
  public readonly type = ActionTypes.UpdateArtistRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// SendRequestToArtistOfEvent

export class SendRequestToArtistOfEvent implements Action {
  public readonly type = ActionTypes.SendRequestToArtistOfEvent;

  constructor(
    public payload: {
      eventGUID: string;
      artistGUID: string;
      query: RequestArtistOfEvent;
    },
  ) {}
}

export class SendRequestToArtistOfEventSuccess implements Action {
  public readonly type = ActionTypes.SendRequestToArtistOfEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class SendRequestToArtistOfEventFailure implements Action {
  public readonly type = ActionTypes.SendRequestToArtistOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// ResendRequestToArtistOfEvent

export class ResendRequestToArtistOfEvent implements Action {
  public readonly type = ActionTypes.ResendRequestToArtistOfEvent;

  constructor(
    public payload: {
      eventGUID: string;
      artistGUID: string;
      query: ResendArtistRequestOfEvent;
    },
  ) {}
}

export class ResendRequestToArtistOfEventSuccess implements Action {
  public readonly type = ActionTypes.ResendRequestToArtistOfEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class ResendRequestToArtistOfEventFailure implements Action {
  public readonly type = ActionTypes.ResendRequestToArtistOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

/*** Event Venue ***/

// GetVenuesToEvent

export class GetVenuesToEvent implements Action {
  public readonly type = ActionTypes.GetVenuesToEvent;

  constructor(public payload: { eventGUID: string }) {}
}

export class GetVenuesToEventSuccess implements Action {
  public readonly type = ActionTypes.GetVenuesToEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<IEventVenuesResponse> },
  ) {}
}

export class GetVenuesToEventFailure implements Action {
  public readonly type = ActionTypes.GetVenuesToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// AddVenuesToEvent

export class AddVenuesToEvent implements Action {
  public readonly type = ActionTypes.AddVenuesToEvent;

  constructor(
    public payload: {
      eventGUID: string;
      query: AddOrUpdateVenuesToEvent;
    },
  ) {}
}

export class AddVenuesToEventSuccess implements Action {
  public readonly type = ActionTypes.AddVenuesToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class AddVenuesToEventFailure implements Action {
  public readonly type = ActionTypes.AddVenuesToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// RemoveVenueFromEvent

export class RemoveVenueFromEvent implements Action {
  public readonly type = ActionTypes.RemoveVenueFromEvent;

  constructor(
    public payload: {
      eventGUID: string;
      venueGUID: string;
    },
  ) {}
}

export class RemoveVenueFromEventSuccess implements Action {
  public readonly type = ActionTypes.RemoveVenueFromEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class RemoveVenueFromEventFailure implements Action {
  public readonly type = ActionTypes.RemoveVenueFromEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// AcceptVenueRequestToEvent

export class AcceptVenueRequestToEvent implements Action {
  public readonly type = ActionTypes.AcceptVenueRequestToEvent;

  constructor(
    public payload: {
      eventGUID: string;
      venueGUID: string;
    },
  ) {}
}

export class AcceptVenueRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.AcceptVenueRequestToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class AcceptVenueRequestToEventFailure implements Action {
  public readonly type = ActionTypes.AcceptVenueRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// ApproveVenueRequestToEvent

export class ApproveVenueRequestToEvent implements Action {
  public readonly type = ActionTypes.ApproveVenueRequestToEvent;

  constructor(public payload: { eventGUID: string; artistGUID: string }) {}
}

export class ApproveVenueRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.ApproveVenueRequestToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class ApproveVenueRequestToEventFailure implements Action {
  public readonly type = ActionTypes.ApproveVenueRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// DeclineVenueRequestToEvent

export class DeclineVenueRequestToEvent implements Action {
  public readonly type = ActionTypes.DeclineVenueRequestToEvent;

  constructor(
    public payload: {
      eventGUID: string;
      venueGUID: string;
      query: DeclineVenueRequestOfEvent;
    },
  ) {}
}

export class DeclineVenueRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.DeclineVenueRequestToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class DeclineVenueRequestToEventFailure implements Action {
  public readonly type = ActionTypes.DeclineVenueRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// GetVenuesRequestToEvent

export class GetVenuesRequestToEvent implements Action {
  public readonly type = ActionTypes.GetVenuesRequestToEvent;

  constructor(public payload: { eventGUID: string; artistGUID: string }) {}
}

export class GetVenuesRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.GetVenuesRequestToEventSuccess;

  constructor(public payload: { response: EventVenueResponse }) {}
}

export class GetVenuesRequestToEventFailure implements Action {
  public readonly type = ActionTypes.GetVenuesRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// UpdateVenueRequestToEvent

export class UpdateVenueRequestToEvent implements Action {
  public readonly type = ActionTypes.UpdateVenueRequestToEvent;

  constructor(
    public payload: {
      eventGUID: string;
      venueGUID: string;
      query: UpdateRequestVenueOfEvent;
    },
  ) {}
}

export class UpdateVenueRequestToEventSuccess implements Action {
  public readonly type = ActionTypes.UpdateVenueRequestToEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UpdateVenueRequestToEventFailure implements Action {
  public readonly type = ActionTypes.UpdateVenueRequestToEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// SendRequestToVenueOfEvent

export class SendRequestToVenueOfEvent implements Action {
  public readonly type = ActionTypes.SendRequestToVenueOfEvent;

  constructor(
    public payload: {
      eventGUID: string;
      venueGUID: string;
      query: RequestVenueOfEvent;
    },
  ) {}
}

export class SendRequestToVenueOfEventSuccess implements Action {
  public readonly type = ActionTypes.SendRequestToVenueOfEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class SendRequestToVenueOfEventFailure implements Action {
  public readonly type = ActionTypes.SendRequestToVenueOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// ResendRequestToVenueOfEvent

export class ResendRequestToVenueOfEvent implements Action {
  public readonly type = ActionTypes.ResendRequestToVenueOfEvent;

  constructor(
    public payload: {
      eventGUID: string;
      artistGUID: string;
      query: ResendVenueRequestOfEvent;
    },
  ) {}
}

export class ResendRequestToVenueOfEventSuccess implements Action {
  public readonly type = ActionTypes.ResendRequestToVenueOfEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class ResendRequestToVenueOfEventFailure implements Action {
  public readonly type = ActionTypes.ResendRequestToVenueOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Funding

export class PayForArtistOfEvent implements Action {
  public readonly type = ActionTypes.PayForArtistOfEvent;

  constructor(
    public payload: {
      eventGUID: string;
      artistGUID: string;
      query: PayForArtistOfEventRequest;
    },
  ) {}
}

export class PayForArtistOfEventSuccess implements Action {
  public readonly type = ActionTypes.PayForArtistOfEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<CreatePaymentResponse> },
  ) {}
}

export class PayForArtistOfEventFailure implements Action {
  public readonly type = ActionTypes.PayForArtistOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class PayForVenueOfEvent implements Action {
  public readonly type = ActionTypes.PayForVenueOfEvent;

  constructor(
    public payload: {
      eventGUID: string;
      venueGUID: string;
      query: PayForVenueOfEventRequest;
    },
  ) {}
}

export class PayForVenueOfEventSuccess implements Action {
  public readonly type = ActionTypes.PayForVenueOfEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<CreatePaymentResponse> },
  ) {}
}

export class PayForVenueOfEventFailure implements Action {
  public readonly type = ActionTypes.PayForVenueOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class GetFundingDetailsOfEvent implements Action {
  public readonly type = ActionTypes.GetFundingDetailsOfEvent;

  constructor(public payload: { eventGUID: string }) {}
}

export class GetFundingDetailsOfEventSuccess implements Action {
  public readonly type = ActionTypes.GetFundingDetailsOfEventSuccess;

  constructor(
    public payload: { response: ResponseSuccess<EventFundingResponse> },
  ) {}
}

export class GetFundingDetailsOfEventFailure implements Action {
  public readonly type = ActionTypes.GetFundingDetailsOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class UpdateFundingPartOfEvent implements Action {
  public readonly type = ActionTypes.UpdateFundingPartOfEvent;

  constructor(
    public payload: {
      eventGUID: string;
      query: CreateOrUpdateFundingRequest;
    },
  ) {}
}

export class UpdateFundingPartOfEventSuccess implements Action {
  public readonly type = ActionTypes.UpdateFundingPartOfEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UpdateFundingPartOfEventFailure implements Action {
  public readonly type = ActionTypes.UpdateFundingPartOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Analytics

export class GetEventAnalytics implements Action {
  public readonly type = ActionTypes.GetEventAnalytics;

  constructor(public payload: { id: string }) {}
}

export class GetEventAnalyticsSuccess implements Action {
  public readonly type = ActionTypes.GetEventAnalyticsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<EventAnalyticsResponse> },
  ) {}
}

export class GetEventAnalyticsFailure implements Action {
  public readonly type = ActionTypes.GetEventAnalyticsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Time zone

export class GetTimeZone implements Action {
  public readonly type = ActionTypes.GetTimeZone;

  constructor(public payload: { lat: string; lng: string }) {}
}

export class GetTimeZoneSuccess implements Action {
  public readonly type = ActionTypes.GetTimeZoneSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class GetTimeZoneFailure implements Action {
  public readonly type = ActionTypes.GetTimeZoneFailure;

  constructor(public payload: { response: ResponseError }) {}
}

/***** Media *****/

// Event Photos

export class GetEventPhotos implements Action {
  public readonly type = ActionTypes.GetEventPhotos;

  constructor(
    public payload: {
      eventGUID: string;
    },
  ) {}
}

export class GetEventPhotosSuccess implements Action {
  public readonly type = ActionTypes.GetEventPhotosSuccess;

  constructor(public payload: { response: GetEventPhotosResponseSuccess }) {}
}

export class GetEventPhotosFailure implements Action {
  public readonly type = ActionTypes.GetEventPhotosFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Set Event ID
export class SetEventID implements Action {
  public readonly type = ActionTypes.SetEventID;

  constructor(public payload: { id: string }) {}
}

// Set Page Status

export class SetPageStatus implements Action {
  public readonly type = ActionTypes.SetPageStatus;

  constructor(public payload: { status: EventPageStatus }) {}
}

// Set Create Event Steps

export class SetCreateEventSteps implements Action {
  public readonly type = ActionTypes.SetCreateEventSteps;

  constructor(public payload: { steps: CreateEventSteps }) {}
}

// RemoveEventByID

export class RemoveEventByID implements Action {
  public readonly type = ActionTypes.RemoveEventByID;

  constructor(public payload: { id: string }) {}
}

export class RemoveEventByIDSuccess implements Action {
  public readonly type = ActionTypes.RemoveEventByIDSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class RemoveEventByIDFailure implements Action {
  public readonly type = ActionTypes.RemoveEventByIDFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// PutEventToReview

export class PutEventToReview implements Action {
  public readonly type = ActionTypes.PutEventToReview;

  constructor(public payload: { id: string }) {}
}

export class PutEventToReviewSuccess implements Action {
  public readonly type = ActionTypes.PutEventToReviewSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class PutEventToReviewFailure implements Action {
  public readonly type = ActionTypes.PutEventToReviewFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// LaunchEvent

export class LaunchEvent implements Action {
  public readonly type = ActionTypes.LaunchEvent;

  constructor(public payload: { id: string }) {}
}

export class LaunchEventSuccess implements Action {
  public readonly type = ActionTypes.LaunchEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class LaunchEventFailure implements Action {
  public readonly type = ActionTypes.LaunchEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// GetVenuePrivateInfoOfEvent

export class GetVenuePrivateInfoOfEvent implements Action {
  public readonly type = ActionTypes.GetVenuePrivateInfoOfEvent;

  constructor(public payload: { eventGUID: string; venueGUID: string }) {}
}

export class GetVenuePrivateInfoOfEventSuccess implements Action {
  public readonly type = ActionTypes.GetVenuePrivateInfoOfEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class GetVenuePrivateInfoOfEventFailure implements Action {
  public readonly type = ActionTypes.GetVenuePrivateInfoOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// GetArtistPrivateInfoOfEvent -- artist

export class GetArtistPrivateInfoOfEvent implements Action {
  public readonly type = ActionTypes.GetArtistPrivateInfoOfEvent;

  constructor(public payload: { eventGUID: string; artistGUID: string }) {}
}

export class GetArtistPrivateInfoOfEventSuccess implements Action {
  public readonly type = ActionTypes.GetArtistPrivateInfoOfEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class GetArtistPrivateInfoOfEventFailure implements Action {
  public readonly type = ActionTypes.GetArtistPrivateInfoOfEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class SetUpdated implements Action {
  public readonly type = ActionTypes.SetUpdated;

  constructor(public payload: any) {}
}

export class SetCreated implements Action {
  public readonly type = ActionTypes.SetCreated;

  constructor(public payload: any) {}
}

export class UpdateTicketDisplayOrder implements Action {
  public readonly type = ActionTypes.UpdateTicketsDisplayOrder;

  constructor(
    public payload: {
      ticketDisplayOrders: ticketDisplayOrder[];
      eventUUID: string;
    },
  ) {}
}

export class UpdateTicketDisplayOrderSuccess implements Action {
  public readonly type = ActionTypes.LaunchEventSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UpdateTicketDisplayOrderFailure implements Action {
  public readonly type = ActionTypes.LaunchEventFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | LoadEvents
  | LoadEventsSuccess
  | LoadEventsFailure
  | SetUpdated
  | SetCreated
  | LoadEventById
  | LoadEventByIdSuccess
  | LoadEventByIdFailure
  | RemoveEvents
  | RemoveEvent
  | LoadEventImage
  | CreateEvent
  | CreateEventSuccess
  | CreateEventFailure
  | GetFanUpcomingEvents
  | GetFanUpcomingEventsSuccess
  | GetFanUpcomingEventsFailure
  | GetFanPastEvents
  | GetFanPastEventsSuccess
  | GetFanPastEventsFailure

  // Event Artist
  | GetArtistsToEvent
  | GetArtistsToEventSuccess
  | GetArtistsToEventFailure
  | AddArtistsToEvent
  | AddArtistsToEventSuccess
  | AddArtistsToEventFailure
  | RemoveArtistFromEvent
  | RemoveArtistFromEventSuccess
  | RemoveArtistFromEventFailure
  | AcceptArtistRequestToEvent
  | AcceptArtistRequestToEventSuccess
  | AcceptArtistRequestToEventFailure
  | ApproveArtistRequestToEvent
  | ApproveArtistRequestToEventSuccess
  | ApproveArtistRequestToEventFailure
  | DeclineArtistRequestToEvent
  | DeclineArtistRequestToEventSuccess
  | DeclineArtistRequestToEventFailure
  | GetArtistsRequestToEvent
  | GetArtistsRequestToEventSuccess
  | GetArtistsRequestToEventFailure
  | AllowedArtistRequestToEvent
  | AllowedArtistRequestToEventSuccess
  | AllowedArtistRequestToEventFailure
  | UpdateArtistRequestToEvent
  | UpdateArtistRequestToEventSuccess
  | UpdateArtistRequestToEventFailure
  | SendRequestToArtistOfEvent
  | SendRequestToArtistOfEventSuccess
  | SendRequestToArtistOfEventFailure
  | ResendRequestToArtistOfEvent
  | ResendRequestToArtistOfEventSuccess
  | ResendRequestToArtistOfEventFailure

  // Event Venue
  | GetVenuesToEvent
  | GetVenuesToEventSuccess
  | GetVenuesToEventFailure
  | AddVenuesToEvent
  | AddVenuesToEventSuccess
  | AddVenuesToEventFailure
  | RemoveVenueFromEvent
  | RemoveVenueFromEventSuccess
  | RemoveVenueFromEventFailure
  | AcceptVenueRequestToEvent
  | AcceptVenueRequestToEventSuccess
  | AcceptVenueRequestToEventFailure
  | ApproveVenueRequestToEvent
  | ApproveVenueRequestToEventSuccess
  | ApproveVenueRequestToEventFailure
  | DeclineVenueRequestToEvent
  | DeclineVenueRequestToEventSuccess
  | DeclineVenueRequestToEventFailure
  | GetVenuesRequestToEvent
  | GetVenuesRequestToEventSuccess
  | GetVenuesRequestToEventFailure
  | UpdateVenueRequestToEvent
  | UpdateVenueRequestToEventSuccess
  | UpdateVenueRequestToEventFailure
  | SendRequestToVenueOfEvent
  | SendRequestToVenueOfEventSuccess
  | SendRequestToVenueOfEventFailure
  | ResendRequestToVenueOfEvent
  | ResendRequestToVenueOfEventSuccess
  | ResendRequestToVenueOfEventFailure

  // Funding
  | PayForArtistOfEvent
  | PayForArtistOfEventSuccess
  | PayForArtistOfEventFailure
  | PayForVenueOfEvent
  | PayForVenueOfEventSuccess
  | PayForVenueOfEventFailure
  | GetFundingDetailsOfEvent
  | GetFundingDetailsOfEventSuccess
  | GetFundingDetailsOfEventFailure
  | UpdateFundingPartOfEvent
  | UpdateFundingPartOfEventSuccess
  | UpdateFundingPartOfEventFailure

  // Analytics
  | GetEventAnalytics
  | GetEventAnalyticsSuccess
  | GetEventAnalyticsFailure

  // Media
  | GetEventPhotos
  | GetEventPhotosSuccess
  | GetEventPhotosFailure
  | SetEventID
  | ConfirmEvent
  | ConfirmEventSuccess
  | ConfirmEventFailure
  | SetPageStatus
  | SetCreateEventSteps
  | RemoveEventByID
  | RemoveEventByIDSuccess
  | RemoveEventByIDFailure
  | PutEventToReview
  | PutEventToReviewSuccess
  | PutEventToReviewFailure
  | GetVenuePrivateInfoOfEvent
  | GetVenuePrivateInfoOfEventSuccess
  | GetVenuePrivateInfoOfEventFailure
  | GetTimeZone
  | GetTimeZoneSuccess
  | GetTimeZoneFailure
  | UpdateEvent
  | UpdateEventSuccess
  | UpdateEventFailure
  | LaunchEvent
  | LaunchEventSuccess
  | LaunchEventFailure
  | SetPhotoToMedia
  | GetArtistPrivateInfoOfEvent
  | GetArtistPrivateInfoOfEventSuccess
  | GetArtistPrivateInfoOfEventFailure
  | UpdateTicketDisplayOrder;
