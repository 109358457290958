import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SetIsUpdate: {
      return {
        ...state,
        updated: false,
      };
    }
    case ActionTypes.CreateFan: {
      return {
        ...state,
        loading: true,
        loaded: false,
        logined: false,
        created: false,
      };
    }
    case ActionTypes.CreateFanSuccess: {
      return {
        ...state,
        created: true,
        loading: false,
        loaded: true,
        logined: true,
      };
    }
    case ActionTypes.CreateFanFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        logined: false,
        created: false,
      };
    }
    case ActionTypes.SetProfile: {
      const profile = action.payload.profile;

      return {
        ...state,
        profile,
        loading: false,
        loaded: true,
        logined: true,
      };
    }
    case ActionTypes.GetProfileByID: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.GetProfileByIDSuccess: {
      const profileByID = action.payload.response.data;

      return {
        ...state,
        profileByID,
        logined: true,
        loading: false,
        loaded: true,
      };
    }

    case ActionTypes.GetProfileByIDFailure: {
      return {
        ...state,
        profileByID: null,
        logined: false,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.UpdateProfile: {
      return {
        ...state,
        updated: false,
      };
    }
    case ActionTypes.UpdateProfileSuccess: {
      return {
        ...state,
        updated: true,
      };
    }
    case ActionTypes.UpdateProfileFailure: {
      return {
        ...state,
        updated: false,
      };
    }
    case ActionTypes.RemoveFan: {
      return {
        ...state,
        profile: null,
        loading: false,
        loaded: false,
        logined: false,
      };
    }
    case ActionTypes.SetProfilePhoto: {
      return {
        ...state,
      };
    }

    // Follow Artist
    case ActionTypes.FollowArtist: {
      return {
        ...state,
      };
    }
    case ActionTypes.FollowArtistSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.FollowArtistFailure: {
      return {
        ...state,
      };
    }

    // Unfollow Artist
    case ActionTypes.UnfollowArtist: {
      return {
        ...state,
      };
    }
    case ActionTypes.UnfollowArtistSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.UnfollowArtistFailure: {
      return {
        ...state,
      };
    }

    // Get Following artists
    case ActionTypes.GetFollowingArtists: {
      return {
        ...state,
      };
    }
    case ActionTypes.GetFollowingArtistsSuccess: {
      return {
        ...state,
        followingArtists: action.payload.response.data,
      };
    }
    case ActionTypes.GetFollowingArtistsFailure: {
      return {
        ...state,
        followingArtists: null,
      };
    }

    case ActionTypes.GetFollowersCount: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetFollowersCountSuccess: {
      return {
        ...state,
        followersCount: action.payload.response.count,
      };
    }

    case ActionTypes.GetFollowersCountFailure: {
      return {
        ...state,
        followersCount: 0,
      };
    }

    case ActionTypes.GetFollowingCount: {
      return {
        ...state,
      };
    }

    case ActionTypes.GetFollowingCountSuccess: {
      return {
        ...state,
        followingCount: action.payload.response.count,
      };
    }

    case ActionTypes.GetFollowingCountFailure: {
      return {
        ...state,
        followingCount: 0,
      };
    }

    /***** Messages *****/

    case ActionTypes.GetProfileMessages: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case ActionTypes.GetProfileMessagesSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        messagesResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetProfileMessagesFailure: {
      return {
        ...state,
        loaded: false,
        loading: false,
        messagesResponse: null,
      };
    }

    case ActionTypes.FollowAccount: {
      return {
        ...state,
      };
    }
    case ActionTypes.FollowAccountSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.FollowAccountFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.UnfollowAccount: {
      return {
        ...state,
      };
    }
    case ActionTypes.UnfollowAccountSuccess: {
      return {
        ...state,
      };
    }
    case ActionTypes.UnfollowAccountFailure: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
