import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

import { SearchResponse } from '@app/models/client/search/search-response.model';
import { EventArtistAccountsResponse } from '@app/models/client/events/event-artist-accounts-response.model';
import { EventVenueAccountsResponse } from '@app/models/client/events/event-venue-accounts-response.model';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.CleanSearch: {
      return {
        ...state,
        result: null,
      };
    }
    case ActionTypes.CleanSearchTypes: {
      return {
        ...state,
        resultArtists: {
          count: 0,
          artistAccounts: [],
        },
        resultEvents: {
          count: 0,
          events: [],
        },
        resultOrganizers: {
          count: 0,
          organizerAccounts: [],
        },
        resultVenues: {
          count: 0,
          venueAccounts: [],
        },
        resultFans: {
          count: 0,
          fanAccounts: [],
        },
      };
    }
    case ActionTypes.GetSearch: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchSuccess: {
      const result = action.payload.response;

      return {
        ...state,
        loading: false,
        loaded: true,
        result,
      };
    }
    case ActionTypes.GetSearchFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchRange: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchRangeSuccess: {
      const result: SearchResponse = action.payload.response.data;

      return {
        ...state,
        loading: false,
        loaded: true,
        result,
      };
    }
    case ActionTypes.GetSearchRangeFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchFan: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchFanSuccess: {
      const resultFans = action.payload.response.data;

      return {
        ...state,
        loading: false,
        loaded: true,
        resultFans,
      };
    }
    case ActionTypes.GetSearchFanFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchArtist: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchArtistSuccess: {
      const resultArtists = { ...action.payload.response.data };

      let data = { ...state.resultArtists };
      if (
        state.resultArtists.artistAccounts.length === 0 ||
        state.resultSuggest.length > 0
      ) {
        data = resultArtists;
      } else {
        // Extends events array on next load.
        data.artistAccounts = [
          ...state.resultArtists.artistAccounts,
          ...resultArtists.artistAccounts,
        ];
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        resultArtists: data,
      };
    }
    case ActionTypes.GetSearchArtistFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchVenue: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchVenueSuccess: {
      const resultVenues = action.payload.response.data;

      let data = state.resultVenues;
      if (
        state.resultVenues.venueAccounts.length === 0 ||
        state.resultSuggest.length > 0
      ) {
        data = resultVenues;
      } else {
        // Extends events array on next load.
        data.venueAccounts = [
          ...state.resultVenues.venueAccounts,
          ...resultVenues.venueAccounts,
        ];
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        resultVenues: data,
      };
    }
    case ActionTypes.GetSearchVenueFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchOrganizer: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchOrganizerSuccess: {
      const resultOrganizers = { ...action.payload.response.data };

      let data = { ...state.resultOrganizers };
      if (
        state.resultOrganizers.organizerAccounts.length === 0 ||
        state.resultSuggest.length > 0
      ) {
        data = resultOrganizers;
      } else {
        // Extends events array on next load.
        data.organizerAccounts = [
          ...state.resultOrganizers.organizerAccounts,
          ...resultOrganizers.organizerAccounts,
        ];
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        resultOrganizers: data,
      };
    }
    case ActionTypes.RemoveSearchOrganizer: {
      return {
        ...state,
        resultOrganizers: {
          count: 0,
          organizerAccounts: [],
        },
      };
    }
    case ActionTypes.GetSearchOrganizerFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchEvent: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchEventSuccess: {
      const resultEvents = { ...action.payload.response.data };

      let data = { ...state.resultEvents };
      if (
        (state.resultEvents && state.resultEvents.events.length === 0) ||
        state.resultSuggest.length > 0
      ) {
        data = resultEvents;
      } else {
        // Extends events array on next load.
        data.events = [...state.resultEvents.events, ...resultEvents.events];
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        resultEvents: data,
      };
    }
    case ActionTypes.GetSearchEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchRangeEvent: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchRangeEventSuccess: {
      const resultEvents = { ...action.payload.response.data };

      let data = { ...state.resultEvents };
      if (
        (state.resultEvents && state.resultEvents.events.length === 0) ||
        state.resultSuggest.length > 0
      ) {
        data = resultEvents;
      } else {
        // Extends events array on next load.
        data.events = [...state.resultEvents.events, ...resultEvents.events];
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        resultEvents: data,
      };
    }
    case ActionTypes.GetSearchRangeEventFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchTags: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchTagsSuccess: {
      const resultTags = action.payload.response.data;

      return {
        ...state,
        loading: false,
        loaded: true,
        tags: resultTags,
      };
    }
    case ActionTypes.GetSearchTagsFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetSearchSuggest: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetSearchSuggestSuccess: {
      const suggests = action.payload.response.data;

      return {
        ...state,
        loading: false,
        loaded: true,
        resultSuggest: suggests,
      };
    }
    case ActionTypes.GetSearchSuggestFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        resultSuggest: [],
      };
    }
    case ActionTypes.SetSearchQuery: {
      const searchQuery = action.payload.searchQuery;

      return {
        ...state,
        searchQuery,
      };
    }

    // Search Artists For Event

    case ActionTypes.SearchArtistsForEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.SearchArtistsForEventSuccess: {
      const result: EventArtistAccountsResponse = action.payload.response.data;

      return {
        ...state,
        loading: false,
        loaded: true,
        resultSearchArtistsForEvent: result,
      };
    }
    case ActionTypes.SearchArtistsForEventFailure: {
      const result: EventArtistAccountsResponse = null;

      return {
        ...state,
        loading: false,
        loaded: false,
        resultSearchArtistsForEvent: result,
      };
    }

    // Search Venues For Event

    case ActionTypes.SearchVenuesForEvent: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.SearchVenuesForEventSuccess: {
      const result: EventVenueAccountsResponse = action.payload.response.data;

      return {
        ...state,
        loading: false,
        loaded: true,
        resultSearchVenuesForEvent: result,
      };
    }
    case ActionTypes.SearchVenuesForEventFailure: {
      const result: EventVenueAccountsResponse = null;

      return {
        ...state,
        loading: false,
        loaded: false,
        resultSearchVenuesForEvent: result,
      };
    }

    case ActionTypes.SetStatus: {
      return {
        ...state,
        ui: {
          ...state.ui,
          status: action.payload.status,
        },
      };
    }

    default: {
      return state;
    }
  }
}
