export interface State {
  entities: [];
  count: number;
  loading: boolean;
  loaded: boolean;
  paymentSuccess: boolean;
  clientSecret: string;
}

export const initialState: State = {
  entities: null,
  count: 0,
  loading: false,
  loaded: false,
  paymentSuccess: true,
  clientSecret: null,
};
