import { RootStoreState } from '@store/root';
import { createSelector } from '@ngrx/store';
import { State } from '@store/root/client/dashboard/state';

export const getEventState = (state: RootStoreState.State) =>
  state.client.dashboard;

export const getDashboardOverviewState = createSelector(
  getEventState,
  (state: State) => state?.dashboardOverview,
);
export const getRecentOrdersState = createSelector(
  getEventState,
  (state: State) => state?.recentOrders,
);
export const getDashboardOverviewByEventState = createSelector(
  getEventState,
  (state: State) => state?.dashboardOverviewByEvent,
);
export const getRecentOrdersByEventState = createSelector(
  getEventState,
  (state: State) => state?.recentOrdersByEvent,
);
