import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import {
  ActionTypes,
  RemoveVideo,
  RemoveVideoFailure,
  RemoveVideoSuccess,
  UploadVideo,
  UploadVideoFailure,
  UploadVideoSuccess,
} from './video.actions';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { AppStoreActions } from '@app/store/root/app';
import { RootStoreState } from '@app/store/root';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { VideoService } from '@app/services/client/video/video.service';
import { VideoLink } from '@app/models/client/video/video-link.model';

@Injectable()
export class VideoEffects {
  uploadVideo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadVideo>(ActionTypes.UploadVideo),
      exhaustMap((action) =>
        this.videoService
          .uploadVideo(action.payload.file, action.payload.link)
          .pipe(
            map((response: ResponseSuccess<any>) => {
              return new UploadVideoSuccess({ response });
            }),
            catchError((errResponse: ResponseError) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new UploadVideoFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  removeVideo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveVideo>(ActionTypes.RemoveVideo),
      switchMap((action) =>
        this.videoService.removeVideo(action.payload.id).pipe(
          map(
            (response: ResponseSuccess<VideoLink>) =>
              new RemoveVideoSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new RemoveVideoFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private videoService: VideoService,
  ) {}
}
