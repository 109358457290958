import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getCommentsState = (state: RootStoreState.State) =>
  state.client.comments;

export const getCommentsEntities = createSelector(
  getCommentsState,
  (state: State) => state.entities,
);

export const getCommentsCount = createSelector(
  getCommentsState,
  (state: State) => state.count,
);

export const getCommentsStatus = createSelector(
  getCommentsState,
  (state: State) => {
    return {
      loading: state.loading,
      loaded: state.loaded,
      send: state.send,
    };
  },
);
