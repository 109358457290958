import { createSelector } from '@ngrx/store';

import { State } from './state';

import { StateStatus } from '@app/models/client/state/state-status.model';
import { RootStoreState } from '@app/store/root';

export const getMessagesState = (state: RootStoreState.State) =>
  state.client.messages;

export const getActiveMessageIDState = createSelector(
  getMessagesState,
  (state: State) => state.activeMessageID,
);

export const getMessagesStateStatus = createSelector(
  getMessagesState,
  (state: State): StateStatus => {
    return {
      loading: state.loading,
      loaded: state.loaded,
    };
  },
);
