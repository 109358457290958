import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@src/environments/environment';

import { Observable } from 'rxjs';

import { ArtistProfileResponse } from '@app/models/client/artist/artist-profile-response.model';
import { Response } from '@app/interfaces/response.interface';
import { HttpUrlEncodingCodecWithSquareBrackets } from '@app/base/http/HttpUrlEncodingCodecWithSquareBrackets';
import { GetArtistAccountBookingSettingsResponseSuccess } from '@app/models/client/artist/booking/get-artist-account-booking-settings-response-success.model';
import { MessagesArtistResponseSuccess } from '@app/models/client/artist/messages-artist-response-success.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { APIService } from '@app/interfaces/api-service.interface';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { GetArtistMediaResponse } from '@app/models/client/artist/get-artist-media-response.model';
import { ArtistAccountBookingSettings } from '@app/models/client/artist/artist-booking-settings.model';
import { GetArtistAccountCalendarResponse } from '@app/models/client/artist/calendar/get-artist-account-calendar-response.model';
import { ArtistAccountCalendarItem } from '@app/models/client/artist/calendar/artist-account-calendar-item.model';
import { UpdateArtistAccountRequest } from '@app/models/client/artist/update-artist-account-request.model';
import { ArtistRiderInterface } from '@app/interfaces/artist/artist-rider.interface';

/**
 * Implementation of artist account APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class ArtistService implements APIService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  /***** Profile *****/

  /**
   * DELETE: `/api/v2/artist`.
   * Create artist account.
   *
   *
   * @param url - Media url.
   * @param type - Media type.
   */
  deleteEventMedia(url: string, type: string): Observable<Response> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {},
    };
    if (type === 'photo') {
      options.body = {
        url,
      };
    } else {
      options.body = {
        link: url,
      };
    }
    return this.http.delete<Response>(
      `${this.host}/api/v1/artist/media/${type}`,
      options,
    );
  }

  /**
   * PUT: `/api/v2/artist`.
   * Create artist account.
   *
   *
   * @param link - Media link.
   * @param title - Media title.
   */
  uploadVideo(link: string, title: string): Observable<Response> {
    return this.http.put<Response>(`${this.host}/api/v1/artist/media/video`, {
      link,
      title,
    });
  }

  /**
   * POST: `/api/v1/artist`.
   * Create artist account.
   *
   *
   * @param profile - Artist profile model.
   */
  createArtistProfile(profile: any): Observable<Response> {
    return this.http.post<Response>(`${this.host}/api/v1/artist`, profile);
  }

  /**
   * GET: `/api/v1/artist/profile`.
   * Returns info about artist profile.
   *
   *
   */
  getArtistProfile(): Observable<ResponseSuccess<ArtistProfileResponse>> {
    return this.http.get<ResponseSuccess<ArtistProfileResponse>>(
      `${this.host}/api/v1/artist/profile`,
    );
  }

  /**
   * GET: `/api/v1/artist/rider/backstage/pdf`.
   * Returns pdf file.
   *
   *
   */
  downloadRiderBackstage(): Observable<any> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/artist/rider/backstage/pdf`,
    );
  }

  /**
   * GET: `/api/v1/artist/rider/hospitality/pdf`.
   * Returns pdf file.
   *
   *
   */
  downloadRiderHospitality(): Observable<any> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/artist/rider/hospitality/pdf`,
    );
  }

  /**
   * GET: `/api/v1/artist/rider/stage/pdf`.
   * Returns pdf file.
   *
   *
   */
  downloadRiderStage(): Observable<any> {
    return this.http.get<ResponseSuccess<ArtistProfileResponse>>(
      `${this.host}/api/v1/artist/rider/stage/pdf`,
    );
  }

  /**
   * GET: `/api/v1/artist/rider/technical/pdf`.
   * Returns pdf file.
   *
   *
   */
  downloadRiderTechnical(): Observable<any> {
    return this.http.get<ResponseSuccess<ArtistProfileResponse>>(
      `${this.host}/api/v1/artist/rider/technical/pdf`,
    );
  }

  /**
   * GET: `/api/v1/artist/profile`.
   * Returns info about artist by ID.
   *
   *
   * @param id - Profile ID.
   */
  getProfileByID(
    id: string,
  ): Observable<ResponseSuccess<ArtistProfileResponse>> {
    return this.http.get<ResponseSuccess<ArtistProfileResponse>>(
      `${this.host}/api/v1/artist/get/${id}`,
    );
  }

  /**
   * GET: `pdf`.
   * Returns PDF.
   *
   * @param url - PDF url.
   */
  getPDF(url: string, fileName: string): Observable<any> {
    window.open(url, '_blank');
    return new Observable<any>();
  }

  /**
   * PUT: `/api/v1/artist/review`.
   * Send on review venue account.
   *
   *
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  sendOnReview(): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/artist/review`,
      {},
    );
  }

  /**
   * PUT: `/api/v1/artist`.
   * Update artist profile.
   *
   *
   * @param profile - Artist model.
   */
  updateArtistProfile(
    profile: UpdateArtistAccountRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/artist`,
      profile,
    );
  }

  /**
   * POST: `/api/v1/artist/profile_photo`.
   * Upload profile photo.
   *
   *
   * @param image - multipart/form-data image=file.
   */
  setProfilePhoto(image: FormData): Observable<Response> {
    return this.http.post<Response>(
      `${this.host}/api/v1/artist/profile_photo`,
      image,
    );
  }

  /**
   * DELETE: `/api/v1/artist/profile_photo`.
   * Remove profile photo.
   *
   *
   */
  removeProfilePhoto(): Observable<Response> {
    return this.http.delete<Response>(
      `${this.host}/api/v1/artist/profile_photo`,
    );
  }

  /***** Media *****/

  /**
   * POST: `/api/v1/artist/artist_photo`.
   * Upload artist photo to artist account. Only PNG and JPG, 10 MB max.
   * photo description isn’t required, but max length of it is 100 symbols.
   *
   *
   * @param image - multipart/form-data image=file.
   * @param description - Description.
   * @param title - Title
   */
  uploadArtistPhoto(
    image: FormData,
    description?: string,
    title?: string,
  ): Observable<Response> {
    if (description) {
      image.append('description', description);
    }
    if (title) {
      image.append('title', title);
    }
    return this.http.post<Response>(
      `${this.host}/api/v1/artist/artist_photo`,
      image,
    );
  }

  /**
   * DELETE: `/api/v1/artist/artist_photo`.
   * Remove artist photo.
   *
   *
   * @param photoId - Photo ID.
   */
  removeArtistPhoto(photoId: string): Observable<Response> {
    return this.http.delete<Response>(
      `${this.host}/api/v1/artist/artist_photo`,
      {
        params: new HttpParams().set('photoId', photoId),
      },
    );
  }

  /**
   * GET: `/api/v1/artists/{id}/media`.
   * Returns all events media for an artist by the ID.
   *
   * @param id - Profile ID.
   *
   */
  getMedia(id: string): Observable<ResponseSuccess<GetArtistMediaResponse>> {
    return this.http.get<ResponseSuccess<GetArtistMediaResponse>>(
      `${this.host}/api/v1/artists/${id}/media`,
    );
  }

  /***** Riders *****/

  getRiders(): Observable<Response> {
    return this.http.get<Response>(`${this.host}/api/v1/artist/rider`);
  }

  // Backstage

  /**
   * POST: `/api/v1/artist/rider/backstage/pdf`.
   * Upload backstage rider pdf.
   *
   *
   * @param query - multipart/form-data file=file.
   */
  uploadRiderBackstage(query: FormData): Observable<Response> {
    return this.http.post<Response>(
      `${this.host}/api/v1/artist/rider/backstage/pdf`,
      query,
    );
  }

  /**
   * DELETE: `/api/v1/artist/rider/backstage/pdf`.
   * Remove backstage rider pdf.
   *
   *
   */
  removeRiderBackstage(): Observable<Response> {
    return this.http.delete<Response>(
      `${this.host}/api/v1/artist/rider/backstage/pdf`,
    );
  }

  // Hospitality

  /**
   * POST: `/api/v1/artist/rider/hospitality/pdf`.
   * Upload hospitality rider.
   *
   *
   * @param file - multipart/form-data file=file.
   */
  uploadRiderHospitality(file: FormData): Observable<Response> {
    return this.http.post<Response>(
      `${this.host}/api/v1/artist/rider/hospitality/pdf`,
      file,
    );
  }

  /**
   * DELETE: `/api/v1/artist/rider/hospitality/pdf`.
   * Remove hospitality rider pdf file.
   *
   *
   */
  removeRiderHospitality(): Observable<Response> {
    return this.http.delete<Response>(
      `${this.host}/api/v1/artist/rider/hospitality/pdf`,
    );
  }

  // Stage

  /**
   * POST: `/api/v1/artist/rider/stage/pdf`.
   * Upload backstage rider pdf file.
   *
   *
   * @param file - multipart/form-data file=file.
   */
  uploadRiderStage(file: FormData): Observable<Response> {
    return this.http.post<Response>(
      `${this.host}/api/v1/artist/rider/stage/pdf`,
      file,
    );
  }

  /**
   * PUT: `/api/v1/artist/rider/stage`.
   * Updates stage rider.
   *
   *
   * @param rider - Rider description.
   */
  updateRiderInfo(rider: ArtistRiderInterface): Observable<Response> {
    return this.http.put<Response>(`${this.host}/api/v1/artist/rider`, rider);
  }

  /**
   * DELETE: `/api/v1/artist/rider/stage/pdf`.
   * Remove stage rider pdf.
   *
   *
   */
  removeRiderStage(): Observable<Response> {
    return this.http.delete<Response>(
      `${this.host}/api/v1/artist/rider/stage/pdf`,
    );
  }

  // Technical

  /**
   * POST: `/api/v1/artist/rider/technical/pdf`.
   * Upload a technical rider pdf file.
   *
   *
   * @param file - multipart/form-data file=file.
   */
  uploadRiderTechnical(file: FormData): Observable<Response> {
    return this.http.post<Response>(
      `${this.host}/api/v1/artist/rider/technical/pdf`,
      file,
    );
  }

  /**
   * DELETE: `/api/v1/artist/rider/technical/pdf`.
   * Remove a technical rider pdf file.
   *
   *
   */
  removeRiderTechnical(): Observable<Response> {
    return this.http.delete<Response>(
      `${this.host}/api/v1/artist/rider/technical/pdf`,
    );
  }

  /***** Calendar *****/

  /**
   * GET: `/api/v1/artists/{artistAccountGUID}/dates`.
   * Retrieves artist dates.
   *
   *
   * @param artistAccountGUID - Artist ID.
   * @param from - From date.
   * @param to - To date.
   */
  getCalendar(
    artistAccountGUID: string,
    from: string,
    to: string,
  ): Observable<ResponseSuccess<GetArtistAccountCalendarResponse>> {
    return this.http.get<ResponseSuccess<GetArtistAccountCalendarResponse>>(
      `${this.host}/api/v1/artists/${artistAccountGUID}/calendar`,
      {
        params: new HttpParams({
          encoder: new HttpUrlEncodingCodecWithSquareBrackets(),
        })
          .set('filter[from]'.toString(), from)
          .set('filter[to]'.toString(), to),
      },
    );
  }

  /**
   * POST: `/api/v1/artist/calendar_items.
   * Add dates item.
   *
   *
   * @param query - Date day,
   */
  addCalendarItem(query: {
    day: string;
  }): Observable<ResponseSuccess<ArtistAccountCalendarItem>> {
    return this.http.post<ResponseSuccess<ArtistAccountCalendarItem>>(
      `${this.host}/api/v1/artist/calendar_items`,
      query,
    );
  }

  /**
   * PUT: `/api/v1/artist/calendar_settings`.
   * Updates the dates settings.
   *
   *
   * @param query - Settings.
   */
  updateCalendarSettings(query: {
    flexible: boolean;
    minAdvanceNotice: number;
    minAdvanceNoticeDays: number;
    minAdvanceNoticeWeeks: number;
    minAdvanceNoticeMonths: number;
  }): Observable<Response> {
    return this.http.put<Response>(
      `${this.host}/api/v1/artist/calendar_settings`,
      query,
    );
  }

  /**
   * DELETE: `/api/v1/artist/calendar_items/{id}`.
   * Remove dates item.
   *
   *
   * @param id - Item ID.
   */
  removeCalendarItem(id: string): Observable<Response> {
    return this.http.delete<Response>(
      `${this.host}/api/v1/artist/calendar_items/${id}`,
    );
  }

  /***** Booking *****/

  /**
   * GET: `/api/v1/artist/booking_settings`.
   * Retrieves artist booking settings.
   *
   *
   */
  getBookingSettings(): Observable<GetArtistAccountBookingSettingsResponseSuccess> {
    return this.http.get<GetArtistAccountBookingSettingsResponseSuccess>(
      `${this.host}/api/v1/artist/booking_settings`,
    );
  }

  /**
   * PUT: `/api/v1/artist/booking_settings`.
   * Updates booking settings.
   *
   *
   * @param settings - Settings.
   */
  updateBookingSettings(
    settings: ArtistAccountBookingSettings,
  ): Observable<Response> {
    return this.http.put<Response>(
      `${this.host}/api/v1/artist/booking_settings`,
      settings,
    );
  }

  /***** Events *****/

  /**
   * GET: `/api/v1/artists/{id}/upcoming_events`
   * Returns all events for an artist by the id.
   *
   * @param id - token from /login endpoint.
   */
  getUpcomingEvents(
    id: string,
  ): Observable<ResponseSuccess<GetEventsResponse>> {
    return this.http.get<ResponseSuccess<GetEventsResponse>>(
      `${this.host}/api/v1/artists/${id}/upcoming_events`,
    );
  }

  /**
   * GET: `/api/v1/artists/{id}/past_events`
   * Returns all events for an artist by the id.
   *
   * @param id - token from /login endpoint.
   */
  getPastEvents(id: string): Observable<ResponseSuccess<GetEventsResponse>> {
    return this.http.get<ResponseSuccess<GetEventsResponse>>(
      `${this.host}/api/v1/artists/${id}/past_events`,
    );
  }

  /***** Messages *****/

  /**
   * GET: `/api/v1/artist/messages`.
   * Get artist messages.
   *
   *
   */
  getMessages(): Observable<MessagesArtistResponseSuccess> {
    return this.http.get<MessagesArtistResponseSuccess>(
      `${this.host}/api/v1/artist/messages`,
    );
  }

  /***** Followers&Following. *****/

  /**
   * GET: `/api/v1/artists/{artistAccountGUID}/followers`.
   * Retrieves list of followers(fans) for artist.
   *
   *
   * @param artistAccountGUID - Artist Account ID.
   * @param limit - limit.
   * @param page - page.
   */
  getFanFollowersForArtist(
    artistAccountGUID: string,
    limit: string,
    page: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/artists/${artistAccountGUID}/followers`,
      {
        params: new HttpParams().set('limit', limit).set('page', page),
      },
    );
  }

  /**
   * POST: `/api/v1/fan/follower/artist/artist/{artistAccountGUID}`.
   * Follow up artist account(if authenticated as an artist).
   *
   *
   * @param artistAccountGUID - Artist Account ID.
   */
  followUpArtistAccount(
    artistAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/artist/artist/${artistAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/artist/artist/{artistAccountGUID}`.
   * Unfollow up artist account(if authenticated as an artist).
   *
   *
   * @param artistAccountGUID - Artist Account ID.
   */
  unfollowUpArtistAccount(
    artistAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/artist/artist/${artistAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/artist/fan/{fanAccountGUID}`.
   * Follow up fan account(if authenticated as an artist).
   *
   *
   * @param fanAccountGUID - Fan Account ID.
   */
  followUpFanAccount(fanAccountGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/artist/fan/${fanAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/artist/fan/{fanAccountGUID}`.
   * Unfollow up fan account(if authenticated as an artist).
   *
   *
   * @param fanAccountGUID - Fan Account ID.
   */
  unfollowUpFanAccount(
    fanAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/artist/fan/${fanAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/artist/organizer/{organizerAccountGUID}`.
   * Follow up organizer account(if authenticated as an artist).
   *
   *
   * @param organizerAccountGUID - Organizer Account ID.
   */
  followUpOrganizerAccount(
    organizerAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/artist/organizer/${organizerAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/artist/organizer/{organizerAccountGUID}`.
   * Unfollow up organizer account(if authenticated as an artist).
   *
   *
   * @param organizerAccountGUID - Organizer Account ID.
   */
  unfollowUpOrganizerAccount(
    organizerAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/artist/organizer/${organizerAccountGUID}`,
    );
  }

  /**
   * POST: `/api/v1/fan/follower/artist/venue/{venueAccountGUID}`.
   * Follow up venue account(if authenticated as an artist).
   *
   *
   * @param venueAccountGUID - Venue Account ID.
   */
  followUpVenueAccount(
    venueAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/artist/venue/${venueAccountGUID}`,
      null,
    );
  }

  /**
   * DELETE: `/api/v1/fan/follower/artist/venue/{venueAccountGUID}`.
   * Unfollow up venue account(if authenticated as an artist).
   *
   *
   * @param venueAccountGUID - Venue Account ID.
   */
  unfollowUpVenueAccount(
    venueAccountGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/fan/follower/artist/venue/${venueAccountGUID}`,
    );
  }
}
