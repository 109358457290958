import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getTicketsState = (state: RootStoreState.State) =>
  state.client.tickets;

export const getTicketsEntitiesState = createSelector(
  getTicketsState,
  (state: State) => state.entities,
);

export const getTicketForUpdateState = createSelector(
  getTicketsState,
  (state: State) => state.ticketForUpdate,
);

export const getOrganizerEventTicketsResponseState = createSelector(
  getTicketsState,
  (state: State) => state.organizerEventTicketsResponse,
);

export const getFanBoughtTicketsResponseState = createSelector(
  getTicketsState,
  (state: State) => state.fanBoughtTicketsResponse,
);

export const getInviteSuccessState = createSelector(
  getTicketsState,
  (state: State) => state.inviteSuccess,
);

export const getLoadedState = createSelector(
  getTicketsState,
  (state: State) => state.loaded,
);
