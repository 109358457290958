import { Action } from '@ngrx/store';

export enum ActionTypes {
  SetActiveMessageID = '[Messages] Set Active Message ID',
}

// SetCurrentActiveMessageID

export class SetActiveMessageID implements Action {
  public readonly type = ActionTypes.SetActiveMessageID;

  constructor(
    public payload: {
      id: string;
    },
  ) {}
}

export type Actions = SetActiveMessageID;
