import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import {
  ActionTypes,
  LoadFAQ,
  LoadFAQFailure,
  LoadFAQSuccess,
} from './actions';
import { SupportService } from '@app/services/client/support/support.service';
import { Action, Store } from '@ngrx/store';
import { GetFAQResponseFailure } from '@app/models/client/faq/GetFAQResponseFailure.model';
import { RootStoreState } from '@app/store/root';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { FAQQuestion } from '@app/models/client/faq/FAQQuestion.model';

@Injectable()
export class SupportEffects {
  public loadFAQ$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadFAQ>(ActionTypes.LoadFAQ),
      switchMap(() => {
        return this.supportService.getFAQ().pipe(
          map(
            (response: ResponseSuccess<FAQQuestion[]>) =>
              new LoadFAQSuccess({ response }),
          ),
          catchError((response: GetFAQResponseFailure) =>
            of(new LoadFAQFailure({ response })),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private supportService: SupportService,
  ) {}
}
