import { CartItemModel } from '@models/client/cart/cart-item.model';

export interface State {
  currentTickets: CartItemModel[];
}

export const initialState: State = {
  currentTickets: [
    {
      eventId: '',
      eventInfo: null,
      donationAmount: 0,
      isDonationAnonymous: false,
      tickets: [
        {
          id: '',
          count: 0,
          ticketInfo: null,
        },
      ],
    },
  ],
};
