import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.CreatePayment: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.CreatePaymentSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        paymentSuccess: action.payload?.response?.success || false,
      };
    }
    case ActionTypes.CreatePaymentFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        paymentSuccess: action.payload.response.success,
      };
    }

    default: {
      return state;
    }
  }
}
