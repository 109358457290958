import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { ResponseError } from '@models/shared/response/response-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponseHelper } from '@base/helpers/error-response.helper';
import { AppStoreActions } from '@store/root/app';
import { EventService } from '@services/client/event/event.service';
import {
  ActionTypes,
  CreateEvent,
  CreateEventFailure,
  CreateEventSuccess,
  GetDonationsByEventId,
  GetDonationsByEventIdFailure,
  GetDonationsByEventIdSuccess,
  GetEventById,
  GetEventByIdFailure,
  GetEventByIdSuccess,
  GetTimeZone,
  GetTimeZoneFailure,
  GetTimeZoneSuccess,
  RemoveEventByID,
  RemoveEventByIDFailure,
  RemoveEventByIDSuccess,
  UpdateCurrentValue,
  UpdateCurrentValueFailure,
  UpdateCurrentValueSuccess,
  UpdateEvents,
  UpdateEventsFailure,
  UpdateEventsSuccess,
} from '@store/root/client/event/actions';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import { TimeZoneResponse } from '@models/client/events/event-request.model';

@Injectable()
export class EventEffects {
  /**
   * Handles creating a new event.
   * @action - [Event] CreateEvent
   * @return:
   *   Success: [Event] CreateEventSuccess
   *   Failure: [Event] CreateEventFailure && [Errors] AddErrorResponse
   */
  createEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateEvent>(ActionTypes.CreateEvent),
      exhaustMap((action) =>
        this.eventService.createEvent(action.payload.event).pipe(
          map((response) => new CreateEventSuccess({ response })),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new CreateEventFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );
  /**
   * Handles updating an event.
   * @action - [Event] UpdateEvents
   * @return:
   *   Success: [Event] UpdateEventsSuccess
   *   Failure: [Event] UpdateEventsFailure && [Errors] AddErrorResponse
   */
  updateEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEvents>(ActionTypes.UpdateEvents),
      exhaustMap((action) =>
        this.eventService
          .updateEvent(action.payload.eventId, action.payload.event)
          .pipe(
            map((response) => new UpdateEventsSuccess({ response })),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new UpdateEventsFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );
  /**
   * Handles updating the current value of an event.
   * @action - [Event] UpdateCurrentValue
   * @return:
   *   Success: [Event] UpdateCurrentValueSuccess
   *   Failure: [Event] UpdateCurrentValueFailure && [Errors] AddErrorResponse
   */
  updateCurrentValue$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCurrentValue>(ActionTypes.UpdateCurrentValue),
      exhaustMap((action) =>
        this.eventService
          .updateCurrentValue(action.payload.id, action.payload.checkChanges)
          .pipe(
            map((response) => new UpdateCurrentValueSuccess({ response })),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new UpdateCurrentValueFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );
  /**
   * Handles retrieving an event by ID.
   * @action - [Event] GetEventById
   * @return:
   *   Success: [Event] GetEventByIdSuccess
   *   Failure: [Event] GetEventByIdFailure && [Errors] AddErrorResponse
   */
  loadEventById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventById>(ActionTypes.GetEventById),
      switchMap((action) =>
        this.eventService.loadEventById(action.payload.id).pipe(
          map((response) => new GetEventByIdSuccess({ response })),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new GetEventByIdFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );
  /**
   * Handles retrieving donations by event ID.
   * @action - [Event] GetDonationsByEventId
   * @return:
   *   Success: [Event] GetDonationsByEventIdSuccess
   *   Failure: [Event] GetDonationsByEventIdFailure && [Errors] AddErrorResponse
   */
  getDonationsByEventId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetDonationsByEventId>(ActionTypes.GetDonationsByEventId),
      switchMap((action) =>
        this.eventService
          .getDonations(
            action.payload.eventId,
            action.payload.type,
            action.payload.limit,
            action.payload.page,
            action.payload.sort,
          )
          .pipe(
            map((response) => new GetDonationsByEventIdSuccess({ response })),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetDonationsByEventIdFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );
  /**
   * Handles removing an event by ID.
   * @action - [Event] RemoveEventByID
   * @return:
   *   Success: [Event] RemoveEventByIDSuccess
   *   Failure: [Event] RemoveEventByIDFailure && [Errors] AddErrorResponse
   */
  removeEventByID$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveEventByID>(ActionTypes.RemoveEventByID),
      exhaustMap((action) =>
        this.eventService.removeEvent(action.payload.id).pipe(
          map(
            (response: ResponseSuccess<void>) =>
              new RemoveEventByIDSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new RemoveEventByIDFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );
  /**
   * Handles get time zone by lat and lng.
   * @action - [Event] GetTimeZone
   * @return:
   *   Success: [Event] GetTimeZoneSuccess
   *   Failure: [Event] GetTimeZoneFailure && [Errors] AddErrorResponse
   */
  getTimeZone$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTimeZone>(ActionTypes.GetTimeZone),
      switchMap((action) =>
        this.eventService
          .getTimeZone(action.payload.lat, action.payload.lng)
          .pipe(
            map(
              (response: TimeZoneResponse) =>
                new GetTimeZoneSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new GetTimeZoneFailure(),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private eventService: EventService,
  ) {}
}
