import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';
import { VenueAccountResponse } from '@app/models/client/venue/venue-account-response.model';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.CreateProfile: {
      return {
        ...state,
      };
    }
    case ActionTypes.CreateProfileSuccess: {
      return {
        ...state,
        logined: true,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.SetIsUpdate: {
      return {
        ...state,
        updated: action.payload,
      };
    }
    case ActionTypes.CreateProfileFailure: {
      return {
        ...state,
        logined: false,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.GetProfile: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.GetProfileSuccess: {
      const profile: VenueAccountResponse = action.payload.response.data;

      return {
        ...state,
        profile,
        logined: true,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.GetProfileFailure: {
      return {
        ...state,
        logined: false,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.ReviewProfile: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.ReviewProfileSuccess: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.ReviewProfileFailure: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.GetProfileByID: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.GetProfileByIDSuccess: {
      const profileById = action.payload.response.data;

      return {
        ...state,
        profileById,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.GetProfileByIDFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    // Upcoming events

    case ActionTypes.GetUpcomingEvents: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.GetUpcomingEventsSuccess: {
      return {
        ...state,
        loaded: true,
        upcomingEventsResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetUpcomingEventsFailure: {
      return {
        ...state,
        loaded: true,
        upcomingEventsResponse: null,
      };
    }

    // Past events

    case ActionTypes.GetPastEvents: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.GetPastEventsSuccess: {
      return {
        ...state,
        loaded: true,
        pastEventsResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetPastEventsFailure: {
      return {
        ...state,
        loaded: true,
        pastEventsResponse: null,
      };
    }

    case ActionTypes.UpdateAbout: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.UpdateAboutSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.UpdateAboutFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.UpdateHours: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.UpdateHoursSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.UpdateHoursFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case ActionTypes.UpdateListingDetails: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.UpdateListingDetailsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.UpdateListingDetailsFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.GetMedia: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }
    case ActionTypes.GetMediaSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        mediaResponse: action.payload.response.data,
      };
    }
    case ActionTypes.GetMediaFailure: {
      return {
        ...state,
        loaded: false,
        loading: false,
        mediaResponse: null,
      };
    }

    case ActionTypes.UpdateMedia: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.UpdateMediaSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.UpdateMediaFailure: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    /*** Calendar ***/

    case ActionTypes.GetCalendar: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.GetCalendarSuccess: {
      return {
        ...state,
        loaded: true,
        calendarItems: action.payload.response.data,
        calendarSettings: action.payload.response.data.settings,
      };
    }

    case ActionTypes.GetCalendarFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.AddCalendarItem: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.AddCalendarItemSuccess: {
      state.calendarItems.push(action.payload.response.data);

      return {
        ...state,
        loaded: true,
        calendarItems: state.calendarItems,
      };
    }

    case ActionTypes.AddCalendarItemFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.RemoveCalendarItem: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.RemoveCalendarItemSuccess: {
      const index = state.calendarItems.indexOf(
        action.payload.response.data.id,
      );

      return {
        ...state,
        loaded: true,
        calendarItems:
          index > -1
            ? state.calendarItems.splice(index, 1)
            : state.calendarItems,
      };
    }

    case ActionTypes.RemoveCalendarItemFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UpdateCalendarSettings: {
      return {
        ...state,
        loaded: false,
      };
    }

    case ActionTypes.UpdateCalendarSettingsSuccess: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.UpdateCalendarSettingsFailure: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionTypes.SetProfilePhoto: {
      return {
        ...state,
      };
    }

    case ActionTypes.SetProfilePhotoSuccess: {
      return {
        ...state,
      };
    }

    case ActionTypes.SetProfilePhotoFailure: {
      return {
        ...state,
      };
    }

    case ActionTypes.RemoveProfile: {
      return {
        ...state,
        profile: null,
        loading: false,
        logined: false,
        loaded: false,
      };
    }

    case ActionTypes.SetPageStatus: {
      return {
        ...state,
        pageStatus: action.payload.status,
      };
    }

    case ActionTypes.SetProfileViewMode: {
      return {
        ...state,
        profileViewMode: action.payload.mode,
      };
    }

    case ActionTypes.SetCreateVenueSteps: {
      return {
        ...state,
        createVenueSteps: action.payload.steps,
      };
    }

    /***** Messages *****/

    case ActionTypes.GetProfileMessages: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case ActionTypes.GetProfileMessagesSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        messagesResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetProfileMessagesFailure: {
      return {
        ...state,
        loaded: false,
        loading: false,
        messagesResponse: null,
      };
    }

    default: {
      return state;
    }
  }
}
