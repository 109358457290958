import { Action } from '@ngrx/store';
import { Response } from '@app/interfaces/response.interface';

export enum ActionTypes {
  LoadComments = '[Comments] Load Comments',
  LoadCommentsSuccess = '[Comments] Load Comments Success',
  LoadCommentsFailure = '[Comments] Load Comments Failure',

  AddComment = '[Comments] Add Comment',
  AddCommentSuccess = '[Comments] Add Comment Success',
  AddCommentFailure = '[Comments] Add Comment Failure',
}

/* Load event-comments. */

export class LoadComments implements Action {
  public readonly type = ActionTypes.LoadComments;

  constructor(
    public payload: { limit: string; page: string; eventId: string },
  ) {}
}

export class LoadCommentsSuccess implements Action {
  public readonly type = ActionTypes.LoadCommentsSuccess;

  constructor(public payload: { data: any }) {}
}

export class LoadCommentsFailure implements Action {
  public readonly type = ActionTypes.LoadCommentsFailure;

  constructor(public payload: { response: Response }) {}
}

/* Add comment. */

export class AddComment implements Action {
  public readonly type = ActionTypes.AddComment;

  constructor(
    public payload: { usertype: string; comment: string; eventId: string },
  ) {}
}

export class AddCommentSuccess implements Action {
  public readonly type = ActionTypes.AddCommentSuccess;

  constructor(public payload: { response: any }) {}
}

export class AddCommentFailure implements Action {
  public readonly type = ActionTypes.AddCommentFailure;
}

export type Actions =
  | LoadComments
  | LoadCommentsSuccess
  | LoadCommentsFailure
  | AddComment
  | AddCommentSuccess
  | AddCommentFailure;
