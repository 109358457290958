import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { EmailAuthProvider } from '@angular/fire/auth';

import * as FanStoreActions from '../fan/actions';
import * as ArtistStoreActions from '../artist/artist.actions';
import * as OrganizerStoreActions from '../organizer/actions';

import {
  AuthenticateWithMagicLinkByCode,
  AuthenticateWithMagicLinkByToken,
  AuthenticateWithMagicLinkFailure,
  AuthenticateWithMagicLinkSuccess,
  CheckEmail,
  CheckEmailFailure,
  CheckEmailSuccess,
  CheckIsProfileFollowing,
  CheckIsProfileFollowingFailure,
  CheckIsProfileFollowingSuccess,
  ConfirmEmail,
  ConfirmEmailByCode,
  ConfirmEmailByToken,
  ConfirmEmailFailure,
  ConfirmEmailSuccess,
  ConfirmFailure,
  ConfirmSetupIntent,
  ConfirmSetupIntentFailure,
  ConfirmSetupIntentSuccess,
  ConfirmSuccess,
  CreateGuestAccount,
  CreateGuestAccountSuccess,
  CreatePayment,
  CreatePaymentFailure,
  CreatePaymentSuccess,
  CreateUser,
  CreateUserSuccess,
  GetApplicationSettings,
  GetApplicationSettingsFailure,
  GetApplicationSettingsSuccess,
  GetPayments,
  GetPaymentsFailure,
  GetPaymentsSuccess,
  GetProfileFollowers,
  GetProfileFollowersFailure,
  GetProfileFollowersSuccess,
  GetProfileFollowing,
  GetProfileFollowingFailure,
  GetProfileFollowingSuccess,
  GetUser,
  GetUserFailure,
  GetUserSuccess,
  LoadProfiles,
  LoadUser,
  LoadUserFailure,
  LoadUserSuccess,
  LoginUser,
  LoginUserByToken,
  LoginUserFailure,
  LoginUserSuccess,
  RefreshToken,
  RefreshTokenFailure,
  RefreshTokenSuccess,
  RemoveFromFirebase,
  RemoveFromFirebaseFailure,
  RemoveFromFirebaseSuccess,
  RemovePayment,
  RemovePaymentFailure,
  RemovePaymentSuccess,
  RemoveUser,
  RemoveUserFailure,
  RemoveUserSuccess,
  SavePayment,
  SavePaymentFailure,
  SavePaymentSuccess,
  SendMagicLink,
  SendMagicLinkFailure,
  SendMagicLinkSuccess,
  SetApplicationSettings,
  SetApplicationSettingsFailure,
  SetApplicationSettingsSuccess,
  SetToken,
  SetupIntent,
  SetupIntentFailure,
  SetupIntentSuccess,
  UpdateUser,
  UpdateUserFailure,
  UpdateUserSuccess,
} from './actions';
import { getUserTokenState } from './selectors';

import { UserService } from '@app/services/client/user/user.service';
import { UserToken } from '@app/models/shared/user-token.model';
import { UserCreate } from '@app/models/client/user/user-create.model';
import { CreateUserResponse } from '@app/models/client/user/create-user-response.model';
import { Response } from '@app/interfaces/response.interface';
import { PaymentService } from '@app/services/client/payment/payment.service';
import { GetUserInfoResponseSuccess } from '@app/models/client/user/get-user-info-response-success.model';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { ErrorResponse } from '@app/models/client/error/error.response.model';
import { ErrorResponseHelper } from '@app/base/helpers/error-response.helper';
import { CreatePaymentMethodResponseSuccess } from '@app/models/client/payment/create-payment-method-response-success.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { SetupIntentResponseSuccess } from '@app/models/client/payment/setup-intent-response-success.model';
import { UserFollowByAccountTypesResponseSuccess } from '@app/models/client/user/user-follow-by-account-types-response-success.model';
import { AppStoreActions } from '@app/store/root/app';
import { RootStoreState } from '@app/store/root';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { CreatePaymentResponse } from '@models/client/payment/create-payment-response.model';
import { GetPaymentMethodsResponse } from '@modules/api/models/get-payment-methods-response';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ProfileStoreActions } from '@store/root/client/profile';

/**
 * Provides User Store Effects.
 */
@Injectable()
export class UserEffects {
  /*** CREATE ***/

  /**
   * Action: `[User] Create User`
   *
   * Create User.
   *
   * Success => @return Actions: `[User] Set Token` and `[User] Create User Success`.
   * Failure => @return Action: `[User] Create User Failure`.
   */

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateUser),
      map((action) => action.user),
      switchMap((user: UserCreate) => {
        return this.userService.createUser(user).pipe(
          switchMap((response: CreateUserResponse) => {
            if (user?.password) {
              return [
                CreateUserSuccess({ user }),
                LoginUserByToken({ token: response.data.token }),
                SetToken({ userToken: response.data }),
              ];
            } else {
              return [
                CreateUserSuccess({ user }),
                LoginUserByToken({ token: response.data.token }),
                SetToken({ userToken: response.data }),
              ];
            }
          }),
          catchError((response: IErrorResponse) => {
            return of(new AppStoreActions.AddErrorResponse({ response }));
          }),
        );
      }),
    ),
  );

  createPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreatePayment),
      switchMap((action) =>
        this.paymentService.createRegularPayment(action.request).pipe(
          map((response: ResponseSuccess<CreatePaymentResponse>) =>
            CreatePaymentSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                CreatePaymentFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  savePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SavePayment),
      switchMap((action) =>
        this.paymentService.savePaymentMethod(action.request).pipe(
          map((response: ResponseSuccess<null>) =>
            SavePaymentSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                SavePaymentFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  removePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemovePayment),
      switchMap((action) =>
        this.paymentService.removePaymentMethod(action.id).pipe(
          map((response: ResponseSuccess<null>) =>
            RemovePaymentSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                RemovePaymentFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  getPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetPayments),
      switchMap((action) =>
        this.paymentService.getPaymentMethods().pipe(
          map((response: ResponseSuccess<GetPaymentMethodsResponse>) =>
            GetPaymentsSuccess({ response }),
          ),
          catchError((errResponse: ResponseError) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                GetPaymentsFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  createUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateUserSuccess),
      tap(async () => {
        const user = await this.afAuth.user.toPromise();
        await user.getIdToken(true);
      }),
      map((action) => {
        if (action.user.isCreateOrganizerAccount) {
          return new OrganizerStoreActions.CreateProfileSuccess();
        }
        if (action.user.isCreateArtistAccount) {
          return new ArtistStoreActions.CreateProfileSuccess();
        }
        if (action.user.isCreateFanAccount) {
          return new FanStoreActions.CreateFanSuccess();
        }
      }),
    ),
  );

  /*** GET ***/

  /**
   * Action: `[User] Get User`
   *
   * Get User.
   *
   * Success => @return FeedbacksActions: `[User] Get User Success`.
   * Failure => @return Action: `[User] Get User Failure`.
   */
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetUser),
      withLatestFrom(this.store.select(getUserTokenState)),
      exhaustMap(() =>
        this.userService.getUser().pipe(
          exhaustMap((response: GetUserInfoResponseSuccess) => [
            GetUserSuccess({ response }),
          ]),
          catchError((response: Response) => of(GetUserFailure({ response }))),
        ),
      ),
    ),
  );

  /*** GET ***/

  /**
   * Action: `[User] Remove User From Firebase`
   *
   * Remove User From Firebase.
   *
   * Success => @return FeedbacksActions: `[User] Remove User Success`.
   * Failure => @return Action: `[User] Remove User Failure`.
   */
  removeFromFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveFromFirebase),
      withLatestFrom(this.store.select(getUserTokenState)),
      exhaustMap(() =>
        this.userService.removeUserFromFirebase().pipe(
          exhaustMap((response: GetUserInfoResponseSuccess) => [
            RemoveFromFirebaseSuccess({ response }),
          ]),
          catchError((response: Response) =>
            of(RemoveFromFirebaseFailure({ response })),
          ),
        ),
      ),
    ),
  );

  /*** DELETE ***/

  /**
   * Action: `[User] Remove User`
   *
   * Remove User.
   *
   * Success => @return FeedbacksActions: `[User] Remove User Success`.
   * Failure => @return Action: `[User] Remove User Failure`.
   */
  removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveUser),
      withLatestFrom(this.store.select(getUserTokenState)),
      exhaustMap(() =>
        this.userService.removeUser().pipe(
          exhaustMap((response: GetUserInfoResponseSuccess) => [
            RemoveUserSuccess({ response }),
          ]),
          catchError((response: Response) =>
            of(RemoveUserFailure({ response })),
          ),
        ),
      ),
    ),
  );

  /*** UPDATE ***/

  /**
   * Action: `[User] Update User`
   *
   * Update User.
   *
   * @return An `Observable` of the `Response`.
   */
  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateUser),
      switchMap((user) =>
        this.userService.updateUser(user.user).pipe(
          map((response: Response) => UpdateUserSuccess()),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                UpdateUserFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );
  /*** LOAD: ***/

  /**
   * Action: `[User] Login User Success`
   *
   * Load User on Login Success.
   *
   * @return Action: `[User] Load User`.
   */
  loadUserOnLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginUserSuccess),
      map((_) => LoadUser()),
    ),
  );

  /**
   * Action: `[User] Load User`
   *
   * Load User's data.
   *
   * @return Action: `[User] Load User Success`.
   */
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadUser),
      switchMap((_) =>
        this.userService.getUser().pipe(
          map((response: GetUserInfoResponseSuccess) => {
            const userData = response.data;
            return LoadUserSuccess({ userData });
          }),
          catchError((error: IErrorResponse) =>
            of(error).pipe(
              switchMap((response: Response) => [
                LoadUserFailure(),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Action: `[User] Load Profiles`
   *
   * Load User's Profiles.
   *
   * @return FeedbacksActions:
   *   `[Fan] Get Profile`,
   *   `[Artist] Get Profile`,
   *   `[Venue] Get Profile`,
   *   `[Organizer] Get Profile`.
   */
  loadProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProfiles),
      switchMap(() => [new ProfileStoreActions.GetProfiles()]),
    ),
  );

  /*** LOGIN: ***/

  /**
   * Action: `[User] Login User`
   *
   * Login User.
   *
   * Success => @return FeedbacksActions: `[User] Login User Success` and `[User] Set Token`.
   * Failure => @return Action: `[User] Login User Failure`.
   */
  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginUser),
      concatMap((params) =>
        from(
          this.afAuth.signInWithCredential(
            EmailAuthProvider.credential(params.email, params.password),
          ),
        ).pipe(
          mergeMap(() => [
            LoginUserSuccess(),
            SetToken({ userToken: { token: 'toke', expire: '9999999999' } }),
            LoadProfiles(),
          ]),
          catchError((error: IErrorResponse) =>
            of(error).pipe(
              switchMap((response: Response) => [
                LoginUserFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  loginUserByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginUserByToken),
      concatMap((params) =>
        from(this.afAuth.signInWithCustomToken(params.token)).pipe(
          mergeMap(() => {
            return [LoginUserSuccess(), LoadProfiles()];
          }),
          catchError((error: IErrorResponse) => {
            return of(error).pipe(
              switchMap((response: Response) => [
                LoginUserFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            );
          }),
        ),
      ),
    ),
  );

  createGuest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateGuestAccount),
      map((action) => action.guest),
      switchMap((guest) => {
        return this.userService.createGuestAccount(guest).pipe(
          switchMap((response) => {
            return from(
              this.afAuth.signInWithCustomToken(response.data.token),
            ).pipe(
              switchMap((r: any) => {
                return from(r.user.getIdToken(true)).pipe(
                  map(() => {
                    return CreateGuestAccountSuccess({ response });
                  }),
                );
              }),
            );
          }),
          catchError((response: IErrorResponse) => {
            return of(new AppStoreActions.AddErrorResponse({ response }));
          }),
        );
      }),
    ),
  );
  /**
   * Action: `[User] Login User By Social Google`
   *
   * Login By Google.
   *
   * Success => @return FeedbacksActions: `[User] Login User Success` and `[User] Set Token`.
   * Failure => @return Action: `[User] Login User Failure`.
   */
  // loginUserBySocialGoogle$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LoginUserBySocialGoogle),
  //     map(action => action.user),
  //     switchMap((user: SocialUser) => {
  //       const socialToken = user.authToken;
  //
  //       return this.authService.signInByGoogle(socialToken).pipe(
  //         switchMap((response: CreateUserResponse) => {
  //
  //           const userToken: UserToken = response.data;
  //
  //           return [
  //             LoginUserSuccess({ response }),
  //             SetToken({ userToken }),
  //             LoadProfiles({ token: userToken.token }),
  //             SetSocialStatusUser(),
  //           ];
  //         }),
  //         catchError((response: Response) => of(LoginUserFailure({ response }))),
  //       );
  //     }),
  //   ),
  // );

  /**
   * Action: `[User] Login User By Social Facebook`
   *
   * Login By Facebook.
   *
   * Success => @return FeedbacksActions: `[User] Login User Success` and `[User] Set Token`.
   * Failure => @return Action: `[User] Login User Failure`.
   */
  // loginUserBySocialFacebook$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LoginUserBySocialFacebook),
  //     map(action => action.user),
  //     switchMap((user: SocialUser) => {
  //       const socialToken = user.authToken;
  //
  //       return this.authService.signInByFacebook(socialToken).pipe(
  //         switchMap((response: CreateUserResponse) => {
  //
  //           const userToken: UserToken = response.data;
  //
  //           return [
  //             LoginUserSuccess({ response }),
  //             SetToken({ userToken }),
  //             LoadProfiles({ token: userToken.token }),
  //             SetSocialStatusUser(),
  //           ];
  //         }),
  //         catchError((response: Response) => of(LoginUserFailure({ response }))),
  //       );
  //     }),
  //   ),
  // );

  /*** TOKEN: ***/

  getUserApplicationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetApplicationSettings),
      switchMap(() =>
        this.userService.getUserApplicationSettings().pipe(
          map((response: Response) =>
            GetApplicationSettingsSuccess({ response }),
          ),
          catchError((error: IErrorResponse) =>
            of(error).pipe(
              switchMap((e: IErrorResponse) => [
                GetApplicationSettingsFailure({ response: e }),
                new AppStoreActions.AddErrorResponse({ response: e }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  setUserApplicationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetApplicationSettings),
      switchMap((action) =>
        this.userService.setUserApplicationSettings(action.settings).pipe(
          map((response: Response) =>
            SetApplicationSettingsSuccess({ response }),
          ),
          catchError((error: IErrorResponse | HttpErrorResponse) => {
            return of(
              error instanceof ErrorResponse ? error : error.error,
            ).pipe(
              switchMap((response: IErrorResponse) => [
                SetApplicationSettingsFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            );
          }),
        ),
      ),
    ),
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshToken),
      switchMap(() =>
        this.userService.refreshToken().pipe(
          map((response: ResponseSuccess<UserToken>) =>
            RefreshTokenSuccess({ response }),
          ),
          catchError((errResponse: IErrorResponse | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: IErrorResponse) => [
                RefreshTokenFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  setupIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetupIntent),
      withLatestFrom(this.store.pipe(select(getUserTokenState))),
      switchMap((action) =>
        this.userService.setupIntent(action[0].data).pipe(
          map((response: CreatePaymentMethodResponseSuccess) =>
            SetupIntentSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                SetupIntentFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  confirmSetupIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfirmSetupIntent),
      switchMap((action) =>
        this.userService.confirmSetupIntent(action.setupIntentID).pipe(
          map((response: SetupIntentResponseSuccess) =>
            ConfirmSetupIntentSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                ConfirmSetupIntentFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles email confirmation.
   * @action - [User] ConfirmEmail
   * @return:
   *   Success: [User] ConfirmEmailSuccess
   *   Failure: [User] ConfirmEmailFailure & [Errors] AddErrorResponse
   */

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfirmEmail),
      switchMap((action) =>
        this.userService.confirmEmail(action.email).pipe(
          map((response: SetupIntentResponseSuccess) =>
            ConfirmSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                ConfirmFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  confirmEmailByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfirmEmailByToken),
      switchMap((action) =>
        this.userService.confirmEmailByToken(action.token).pipe(
          map((response: SetupIntentResponseSuccess) =>
            ConfirmEmailSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                ConfirmEmailFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  confirmEmailByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfirmEmailByCode),
      switchMap((action) =>
        this.userService.confirmEmailByСode(action.code).pipe(
          map((response: SetupIntentResponseSuccess) =>
            ConfirmEmailSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                ConfirmEmailFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles sending a magic link to the user's email.
   * @action - [User] SendMagicLink
   * @return:
   *   Success: [User] SendMagicLinkSuccess
   *   Failure: [User] SendMagicLinkFailure & [Errors] AddErrorResponse
   */

  sendMagicLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendMagicLink),
      switchMap((action) =>
        this.userService.sendMagicLink(action.email).pipe(
          map((response: SetupIntentResponseSuccess) =>
            SendMagicLinkSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                SendMagicLinkFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  authenticateWithMagicLinkByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticateWithMagicLinkByToken),
      switchMap((action) =>
        this.userService.authenticateWithMagicLinkByToken(action.token).pipe(
          map((response: SetupIntentResponseSuccess) =>
            AuthenticateWithMagicLinkSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                AuthenticateWithMagicLinkFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  authenticateWithMagicLinkByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticateWithMagicLinkByCode),
      switchMap((action) =>
        this.userService
          .authenticateWithMagicLinkByCode(action.email, action.code)
          .pipe(
            map((response: SetupIntentResponseSuccess) =>
              AuthenticateWithMagicLinkSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  AuthenticateWithMagicLinkFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  /**
   * Handles get followers.
   * @action - [User] GetProfileFollowers
   * @return:
   *   Success: [User] GetProfileFollowersFailure
   *   Failure: [User] GetProfileFollowersFailure & [Errors] AddErrorResponse
   */
  getProfileFollowers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetProfileFollowers),
      switchMap((action) =>
        this.userService
          .getProfileFollowers(
            action.profileID,
            action.limit,
            action.page,
            action.accountType,
          )
          .pipe(
            map((response: UserFollowByAccountTypesResponseSuccess) =>
              GetProfileFollowersSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  GetProfileFollowersFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  /**
   * Handles get following.
   * @action - [User] GetProfileFollowing
   * @return:
   *   Success: [User] GetProfileFollowingFailure
   *   Failure: [User] GetProfileFollowingFailure & [Errors] AddErrorResponse
   */
  getProfileFollowing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetProfileFollowing),
      switchMap((action) =>
        this.userService
          .getProfileFollowing(
            action.profileID,
            action.limit,
            action.page,
            action.accountType,
          )
          .pipe(
            map((response: UserFollowByAccountTypesResponseSuccess) =>
              GetProfileFollowingSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  GetProfileFollowingFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  checkIsFollowing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckIsProfileFollowing),
      switchMap((action) =>
        this.userService
          .checkIsProfileFollowing(action.followerID, action.followedID)
          .pipe(
            map((response: ResponseSuccess<any>) =>
              CheckIsProfileFollowingSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  CheckIsProfileFollowingFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  checkEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckEmail),
      switchMap((action) =>
        this.userService.checkEmail(action.email).pipe(
          map((response: SetupIntentResponseSuccess) =>
            CheckEmailSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                CheckEmailFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store<RootStoreState.State>,
    public afAuth: AngularFireAuth,
    public paymentService: PaymentService,
  ) {}
}
