import { createSelector } from '@ngrx/store';
import { State } from './state';
import { StateStatus } from '@app/models/client/state/state-status.model';
import { RootStoreState } from '@app/store/root';

export const getSearchState = (state: RootStoreState.State) =>
  state.client.search;

export const getSearchQueryState = createSelector(
  getSearchState,
  (state: State) => state.searchQuery,
);

export const getSearchResult = createSelector(
  getSearchState,
  (state: State) => state.result,
);

export const getSearchResultFans = createSelector(
  getSearchState,
  (state: State) => state.resultFans,
);

export const getSearchResultArtists = createSelector(
  getSearchState,
  (state: State) => state.resultArtists,
);

export const getSearchResultVenues = createSelector(
  getSearchState,
  (state: State) => state.resultVenues,
);

export const getSearchResultOrganizers = createSelector(
  getSearchState,
  (state: State) => state.resultOrganizers,
);

export const getSearchResultEvents = createSelector(
  getSearchState,
  (state: State) => state.resultEvents,
);

export const getSearchResultTags = createSelector(
  getSearchState,
  (state: State) => state.tags,
);

export const getSearchResultSuggests = createSelector(
  getSearchState,
  (state: State) => state.resultSuggest,
);

export const getResultSearchArtistsForEvent = createSelector(
  getSearchState,
  (state: State) => state.resultSearchArtistsForEvent,
);

export const getResultSearchVenuesForEvent = createSelector(
  getSearchState,
  (state: State) => state.resultSearchVenuesForEvent,
);

export const getSearchStateStatus = createSelector(
  getSearchState,
  (state: State): StateStatus => {
    return {
      loading: state.loading,
      loaded: state.loaded,
    };
  },
);

export const getUIStatus = createSelector(
  getSearchState,
  (state: State) => state.ui,
);
