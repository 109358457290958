import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@src/environments/environment';

import { Observable } from 'rxjs';

import { PayForArtistOfEventRequest } from '@app/models/client/events/funding/pay-for-artist-of-event-request.model';
import { PayForVenueOfEventRequest } from '@app/models/client/events/funding/pay-for-venue-of-event-request.model';
import { CreateOrUpdateFundingRequest } from '@app/models/client/events/funding/create-or-update-funding-request.model';
import { EventArtistsResponse } from '@app/models/client/events/event-artists-response.model';
import { AddOrUpdateArtistsToEvent } from '@app/models/client/events/add-or-update-artists-to-event.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { DeclineArtistRequestOfEvent } from '@app/models/client/events/decline-artist-request-of-event.model';
import { EventArtistResponse } from '@app/models/client/events/event-artist-response.model';
import { RequestArtistOfEvent } from '@app/models/client/events/request-artist-of-event.class';
import { ResendArtistRequestOfEvent } from '@app/models/client/events/resend-artist-request-of-event.model';
import { IEventVenuesResponse } from '@app/models/client/events/event-venues-response.model';
import { AddOrUpdateVenuesToEvent } from '@app/models/client/events/add-or-update-venues-to-event.model';
import { DeclineVenueRequestOfEvent } from '@app/models/client/events/decline-venue-request-of-event.model';
import { EventVenueResponse } from '@app/models/client/events/event-venue-response.model';
import { RequestVenueOfEvent } from '@app/models/client/events/request-venue-of-event.class';
import { ResendVenueRequestOfEvent } from '@app/models/client/events/resend-venue-request-of-event.model';
import { GetEventPhotosResponseSuccess } from '@app/models/client/events/event-photos-success.model';
import { UpdateRequestArtistOfEvent } from '@app/models/client/events/update-request-artist-of-event.model';
import { UpdateRequestVenueOfEvent } from '@app/models/client/events/update-request-venue-of-event.model';
import { APIService } from '@app/interfaces/api-service.interface';
import { EventAnalyticsResponse } from '@app/models/client/events/analytics/event-analytics-response.model';
import { CreatePaymentResponse } from '@app/models/client/payment/create-payment-response.model';
import { CreateOrUpdateEventRequest } from '@app/models/client/events/create-or-update-event-request.model';
import { EventResponse } from '@app/models/client/events/event-response.model';
import { GetEventsParams } from '@app/models/client/events/get-events-params.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { GetGoingFanEventResponse } from '@app/models/client/events/get-going-fan-event-response.model';
import { EventFundingResponse } from '@app/models/client/events/funding/event-funding-response.model';
import { Response } from '@interfaces/response.interface';
import { EventService } from '@services/client/events/event/event.service';

/**
 * Implementation of events APIs.
 * @see [Swagger] https://dev.mouseapp.io/swagger/index.html
 */
@Injectable({ providedIn: 'root' })
export class EventsService implements APIService {
  readonly host = environment.host;

  constructor(
    private http: HttpClient,
    private eventService: EventService,
  ) {}

  /**
   * Approve crowdfunding.
   *
   * Approve crowdfunding
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveCrowdfunding()` instead.
   *
   * This method doesn't expect any request body.
   */

  approveCrowdfunding(id: string): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/organizer/events/${id}/approve_crowdfunding`,
      null,
    );
  }

  /**
   * GET: `/api/v1/events`
   * Get events.
   *
   * @param eParams - Get event params.
   *
   * @return An `Observable` of the `IGetEventsResponseSuccess`.
   */
  getEvents(
    eParams: GetEventsParams,
  ): Observable<ResponseSuccess<GetEventsResponse>> {
    const params = this.eventService.createEventParams(eParams);

    return this.http.get<any>(`${this.host}/api/v1/events`, { params });
  }

  /**
   * POST: `/api/v2/events`
   * Create an event.
   *
   *
   * @param event - Event create/update model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  createEvent(event: CreateOrUpdateEventRequest): Observable<EventResponse> {
    return this.http.post<EventResponse>(`${this.host}/api/v2/events`, event);
  }

  /**
   * PUT: `/api/v2/events`
   * Create an event.
   *
   *
   * @param eventId - Event id.
   * @param event - Event create/update model.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  updateEvent(
    eventId: string,
    event: CreateOrUpdateEventRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v2/events/${eventId}`,
      event,
    );
  }

  /**
   * PUT: `/api/v2/events/{eventGUID}/ticket/display-order`.
   * @param eventId
   * @param tickets
   */
  updateEventTicketsDisplayOrder(
    eventId: string,
    tickets: any[],
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<any>(
      `${this.host}/api/v2/events/${eventId}/tickets/display-order`,
      tickets,
    );
  }

  /**
   * DELETE: `/api/v1/events/{eventGUID}`
   * Removes an event.
   *
   *
   * @param id - Event id.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  removeEvent(id: string): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v2/events/${id}`,
    );
  }

  /**
   * GET: `https://maps.googleapis.com/maps/api/timezone/json?location={lat},{long}`
   * Get event by ID.
   *
   * @param lat - Latitude.
   * @param lng - Longitude.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  getTimeZone(
    lat: string,
    lng: string,
  ): Observable<ResponseSuccess<EventResponse>> {
    const mapApiKey = environment.mapApiKey;
    return this.http.get<ResponseSuccess<EventResponse>>(
      // tslint:disable-next-line:max-line-length
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1331766000&key=${mapApiKey}&language=en`,
    );
  }

  /**
   * GET: `/api/v1/events/${GUID}`
   * Get event by ID.
   *
   * @param id - Event ID.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  getEventById(id: string): Observable<EventResponse> {
    return this.http.get<EventResponse>(`${this.host}/api/v2/events/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  /**
   * GET: `/api/v1/events/{ eventGUID }/event-comments`.
   * Retrieves list of event-comments for an event.
   *
   *
   * @param limit - Limit.
   * @param page - Page.
   * @param eventGUID - Event ID.
   *
   * @return An `Observable` of the `ResponseSuccess`.
   */
  getEventComments(
    limit: string,
    page: string,
    eventGUID: string,
    subCampaignId?: string,
  ): Observable<ResponseSuccess<any>> {
    const params: Record<string, string | undefined> = {
      limit,
      page,
    };

    if (subCampaignId) {
      params.subCampaignId = subCampaignId; // Adding subCampaignId to the parameters
    }

    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/comments`,
      {
        params,
      },
    );
  }

  /**
   * GET: `/api/v1/events/{ eventGUID }/going_fans`.
   * Retrieves list of going fans for an event.
   *
   *
   * @param limit - Limit.
   * @param page - Page.
   * @param eventGUID - Event ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`
   */
  getGoingFans(
    limit: string,
    page: string,
    eventGUID: string,
  ): Observable<ResponseSuccess<any>> {
    const params = {
      limit,
      page,
    };

    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/going_fans`,
      {
        params,
      },
    );
  }

  /***** Event Media *****/

  /**
   * DELETE: `/api/v1/events/eventGUID/poster_photo`.
   * Remove event poster photo.
   *
   *
   * @param eventGUID - Event ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`
   */
  removePosterPhoto(eventGUID: string): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/poster_photo`,
    );
  }

  /**
   * POST: `/api/v1/events/{eventGUID}/event_photo`
   * Upload event photo.
   *
   *
   * @param eventGUID - Event ID.
   * @param image - multipart/form-data image=file description=text.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`
   */
  uploadEventPhoto(
    eventGUID: string,
    image: FormData,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/event_photo`,
      image,
    );
  }

  /**
   * POST: `/api/v1/events/{eventGUID}/poster_photo`.
   * Upload poster photo.
   *
   *
   * @param eventGUID - Event ID.
   * @param image - multipart/form-data image=file description=text.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`
   */
  uploadPosterPhoto(
    eventGUID: string,
    image: FormData,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/poster_photo`,
      image,
    );
  }

  /**
   * GET: `/api/v1/events/{eventGUID}/photos`.
   * Get event photos.
   *
   *
   * @param eventGUID - Event ID.
   *
   * @return An `Observable` of the `EventPhotosSuccess`.
   */
  getEventPhotos(eventGUID: string): Observable<GetEventPhotosResponseSuccess> {
    return this.http.get<GetEventPhotosResponseSuccess>(
      `${this.host}/api/v1/events/${eventGUID}/photos`,
    );
  }

  /**
   * POST: `/api/v1/events/{eventGUID}/photos`.
   * Upload event photos.
   *
   *
   * @param eventGUID - Event ID.
   * @param image - image
   * @param title - title
   * @param description - description
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  // tslint:disable-next-line:max-line-length
  uploadEventPhotos(
    eventGUID: string,
    image: FormData,
    title: string,
    description: string,
  ): Observable<Response> {
    if (description) {
      image.append('description', description);
    }
    if (title) {
      image.append('title', title);
    }
    return this.http.post<Response>(
      `${this.host}/api/v1/events/${eventGUID}/photos`,
      image,
    );
  }

  /**
   * DELETE: `/api/v1/events/{eventGUID}/photos/{photoGUID}`.
   * Remove photo of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param photoGUID - Photo ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  removeEventPhotos(
    eventGUID: string,
    photoGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/photos/${photoGUID}`,
    );
  }

  /***** Funding *****/

  /**
   * POST: `/api/v1/events/{eventGUID}/artists/{artistGUID}/pay`
   * Pay for the artist of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ICreatePaymentResponseSuccess<any>`.
   */
  payForArtistOfEvent(
    eventGUID: string,
    artistGUID: string,
    query: PayForArtistOfEventRequest,
  ): Observable<ResponseSuccess<CreatePaymentResponse>> {
    return this.http.post<ResponseSuccess<CreatePaymentResponse>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}/pay`,
      query,
    );
  }

  /**
   * POST: `/api/v1/events/{eventGUID}/venues/{venueGUID}/pay`
   * Pay for the venue of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   * @param query - Request model.
   */
  payForVenueOfEvent(
    eventGUID: string,
    venueGUID: string,
    query: PayForVenueOfEventRequest,
  ): Observable<ResponseSuccess<CreatePaymentResponse>> {
    return this.http.post<ResponseSuccess<CreatePaymentResponse>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}/pay`,
      query,
    );
  }

  /**
   * GET: `/api/v1/events/{eventGUID}/funding`
   * Get funding details of event.
   *
   *
   * @param eventGUID - Event ID.
   */
  getFundingDetailsOfEvent(
    eventGUID: string,
  ): Observable<ResponseSuccess<EventFundingResponse>> {
    return this.http.get<ResponseSuccess<EventFundingResponse>>(
      `${this.host}/api/v1/events/${eventGUID}/funding`,
    );
  }

  /**
   * PUT: `/api/v1/events/{eventGUID}/funding`
   * Create / update funding part of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  updateFundingPartOfEvent(
    eventGUID: string,
    query: CreateOrUpdateFundingRequest,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/funding`,
      query,
    );
  }

  /***** Fan *****/

  /**
   * GET: `/api/v1/upcoming_events`.
   * Information about the fan upcoming events.
   *
   *
   * @param eventParams - Event get params.
   *
   * @return An `Observable` of the `IGetGoingFanEventResponseSuccess`.
   */
  getFanUpcomingEvents(
    eventParams: GetEventsParams,
  ): Observable<ResponseSuccess<GetGoingFanEventResponse>> {
    const params = this.eventService.createEventParams(eventParams);

    return this.http.get<ResponseSuccess<GetGoingFanEventResponse>>(
      `${this.host}/api/v1/upcoming_events`,
      {
        params,
      },
    );
  }

  /**
   * GET: `/api/v1/past_events`.
   * Information about the fan past events.
   *
   *
   * @param eventParams - Event get params.
   *
   * @return An `Observable` of the `IGetGoingFanEventResponseSuccess`.
   */
  getFanPastEvents(
    eventParams: GetEventsParams,
  ): Observable<ResponseSuccess<GetGoingFanEventResponse>> {
    const params = this.eventService.createEventParams(eventParams);

    return this.http.get<ResponseSuccess<GetGoingFanEventResponse>>(
      `${this.host}/api/v1/past_events`,
      {
        params,
      },
    );
  }

  /***** Artist *****/

  /**
   * GET: `/api/v1/events/eventGUID/artists`.
   * Get artists to event.
   *
   *
   * @param eventGUID - Event ID.
   *
   * @return An `Observable` of the `IEventArtistsResponse`.
   */
  getArtistsToEvent(
    eventGUID: string,
  ): Observable<ResponseSuccess<EventArtistsResponse>> {
    return this.http.get<ResponseSuccess<EventArtistsResponse>>(
      `${this.host}/api/v2/events/${eventGUID}/artists`,
    );
  }

  /**
   * POST: `/api/v1/events/eventGUID/artists`.
   * Add artists to event.
   *
   *
   * @param eventGUID - Event ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  addArtistsToEvent(
    eventGUID: string,
    query: AddOrUpdateArtistsToEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v2/events/${eventGUID}/artists`,
      query,
    );
  }

  /**
   * DELETE: `/api/v1/events/eventGUID/artists/venueGUID`.
   * Remove artist from the event.
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  removeArtistFromEvent(
    eventGUID: string,
    artistGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}`,
    );
  }

  /**
   * PUT: `/api/v1/events/eventGUID/artists/artistGUID/accept`.
   * Accept artist request to event.
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  acceptArtistRequestToEvent(
    eventGUID: string,
    artistGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}/accept`,
      null,
    );
  }

  /**
   * PUT: `/api/v1/events/eventGUID/artists/artistGUID/approve`.
   * Approve artist request to event (From pending status).
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  approveArtistRequestToEvent(
    eventGUID: string,
    artistGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}/approve`,
      null,
    );
  }

  /**
   * PUT: `/api/v1/events/eventGUID/artists/artistGUID/decline`.
   * Decline artist request to event.
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  declineArtistRequestToEvent(
    eventGUID: string,
    artistGUID: string,
    query: DeclineArtistRequestOfEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}/decline`,
      query,
    );
  }

  /**
   * GET: `/api/v1/events/eventGUID/artists/artistGUID/request`.
   * Get artists request to event.
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   *
   * @return An `Observable` of the `IEventArtistResponse`.
   */
  getArtistRequestToEvent(
    eventGUID: string,
    artistGUID: string,
  ): Observable<EventArtistResponse> {
    return this.http.get<EventArtistResponse>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}/request`,
    );
  }

  /**
   * [GET]: `/api/v1/search/artist/event`.
   * Returns list of artist all-accounts with private info for an event.
   */
  allowedArtistRequestToEvent(): Observable<
    ResponseSuccess<EventArtistResponse[]>
  > {
    return this.http.get<ResponseSuccess<EventArtistResponse[]>>(
      `${this.host}/api/v1/search/artist?accountstatus=approved,pending`,
    );
  }

  /**
   * PUT: `/api/v1/events/eventGUID/artists/artistGUID/request`.
   * Confirm and update request from the artist of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  updateArtistRequestToEvent(
    eventGUID: string,
    artistGUID: string,
    query: UpdateRequestArtistOfEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}/request`,
      query,
    );
  }

  /**
   * POST: `/api/v1/events/eventGUID/artists/artistGUID/request`.
   * Send request to artist of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  sendArtistRequestToEvent(
    eventGUID: string,
    artistGUID: string,
    query: RequestArtistOfEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}/request`,
      query,
    );
  }

  /**
   * POST: `/api/v1/events/eventGUID/artists/artistGUID/resend`.
   * Resend request to artist of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param artistGUID - Artist ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  resendArtistRequestToEvent(
    eventGUID: string,
    artistGUID: string,
    query: ResendArtistRequestOfEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}/resend`,
      query,
    );
  }

  /***** Venue *****/

  /**
   * GET: `/api/v1/events/eventGUID/venues`.
   * Get venues to event.
   *
   *
   * @param eventGUID - Event ID.
   *
   * @return An `Observable` of the `IEventVenuesResponse`.
   */
  getVenuesToEvent(
    eventGUID: string,
  ): Observable<ResponseSuccess<IEventVenuesResponse>> {
    return this.http.get<ResponseSuccess<IEventVenuesResponse>>(
      `${this.host}/api/v1/events/${eventGUID}/venues`,
    );
  }

  /**
   * POST: `/api/v1/events/eventGUID/venues`.
   * Add venues to event.
   *
   *
   * @param eventGUID - Event ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  addVenuesToEvent(
    eventGUID: string,
    query: AddOrUpdateVenuesToEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues`,
      query,
    );
  }

  /**
   * DELETE: `/api/v1/events/eventGUID/venues/venueGUID`.
   * Remove venue from the event.
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  removeVenueFromEvent(
    eventGUID: string,
    venueGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.delete<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}`,
    );
  }

  /**
   * PUT: `/api/v1/events/eventGUID/venues/venueGUID/accept`.
   * Accept venue request to event.
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  acceptVenueRequestToEvent(
    eventGUID: string,
    venueGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}/accept`,
      null,
    );
  }

  /**
   * PUT: `/api/v1/events/eventGUID/venues/venueGUID/approve`.
   * Approve venue request to event (From pending status).
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  approveVenueRequestToEvent(
    eventGUID: string,
    venueGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}/approve`,
      null,
    );
  }

  /**
   * PUT: `/api/v1/events/eventGUID/venues/venueGUID/decline`.
   * Decline venue request to event.
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  declineVenueRequestToEvent(
    eventGUID: string,
    venueGUID: string,
    query: DeclineVenueRequestOfEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}/decline`,
      query,
    );
  }

  /**
   * GET: `/api/v1/events/eventGUID/venues/venueGUID/request`.
   * Get venues request to event.
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   *
   * @return An `Observable` of the `IEventVenueResponse`.
   */
  getVenuesRequestToEvent(
    eventGUID: string,
    venueGUID: string,
  ): Observable<EventVenueResponse> {
    return this.http.get<EventVenueResponse>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}/request`,
    );
  }

  /**
   * PUT: `/api/v1/events/eventGUID/venues/venueGUID/request`.
   * Confirm and update request from the venue of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  updateVenueRequestToEvent(
    eventGUID: string,
    venueGUID: string,
    query: UpdateRequestVenueOfEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}/request`,
      query,
    );
  }

  /**
   * POST: `/api/v1/events/eventGUID/venues/venueGUID/request`.
   * Send request to venue of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  sendVenueRequestToEvent(
    eventGUID: string,
    venueGUID: string,
    query: RequestVenueOfEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}/request`,
      query,
    );
  }

  /**
   * POST: `/api/v1/events/eventGUID/venues/venueGUID/resend`.
   * Resend request to venue of event.
   *
   *
   * @param eventGUID - Event ID.
   * @param venueGUID - Venue ID.
   * @param query - Request model.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  resendVenueRequestToEvent(
    eventGUID: string,
    venueGUID: string,
    query: ResendVenueRequestOfEvent,
  ): Observable<ResponseSuccess<any>> {
    return this.http.post<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}/resend`,
      query,
    );
  }

  /**
   * GET: `/api/v1/events/{eventGUID}/analytics`.
   * Get analytics of the event.
   *
   *
   * @param id - Event ID.
   *
   * @return An `Observable` of the `ResponseSuccess<EventAnalyticsResponse>`.
   */
  getEventAnalytics(
    id: string,
  ): Observable<ResponseSuccess<EventAnalyticsResponse>> {
    return this.http.get<ResponseSuccess<EventAnalyticsResponse>>(
      `${this.host}/api/v1/events/${id}/analytics`,
    );
  }

  /**
   * PUT: `/api/v1/events/{eventGUID}/to_review`.
   * Put the event to review process.
   *
   *
   * @param id - Event ID.
   *
   * @return An `Observable` of the `ResponseSuccess<any>`.
   */
  putEventToReview(id: string): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v2/events/${id}/to_review`,
      null,
    );
  }

  launchEvent(id: string): Observable<ResponseSuccess<any>> {
    return this.http.put<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${id}/launch`,
      null,
    );
  }

  // Get artist private info of event
  getArtistPrivateInfoOfEvent(
    eventGUID: string,
    artistGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/artists/${artistGUID}`,
    );
  }

  // Get venue private info of event
  getVenuePrivateInfoOfEvent(
    eventGUID: string,
    venueGUID: string,
  ): Observable<ResponseSuccess<any>> {
    return this.http.get<ResponseSuccess<any>>(
      `${this.host}/api/v1/events/${eventGUID}/venues/${venueGUID}`,
    );
  }
}
