import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LoadGoingFans: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.LoadGoingFansSuccess: {
      return {
        ...state,
        entities: action.payload.data.fanAccounts,
        count: action.payload.data.count,
        loading: false,
      };
    }
    case ActionTypes.LoadGoingFansFailure: {
      return {
        ...state,
        entities: [],
        count: 0,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
