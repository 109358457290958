import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import { DonorsResponse } from '@models/client/donors/donors-response.model';

@Injectable({
  providedIn: 'root',
})
export class DonorsService {
  readonly host = environment.host;

  constructor(private http: HttpClient) {}

  getDonors(eventId: string): Observable<ResponseSuccess<DonorsResponse>> {
    return this.http.get<ResponseSuccess<DonorsResponse>>(
      `${this.host}/api/v1/events/${eventId}`,
    );
  }
}
