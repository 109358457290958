import { createSelector } from '@ngrx/store';

import { State } from './artist.state';

import { RootStoreState } from '@app/store/root';

export const getArtistState = (state: RootStoreState.State) => {
  if (state && state.client && state.client.artist) {
    return state.client.artist;
  }
};

export const getProfileUpdatedState = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return state.updated;
    }
  },
);

export const getProfileState = createSelector(
  getArtistState,
  (state: State) => {
    if (state && state.profile) {
      return state.profile;
    }
  },
);

export const getProfileIDState = createSelector(
  getArtistState,
  (state: State) => {
    if (state && state.profile && state.profile.id) {
      return state.profile.id;
    }
  },
);

export const getProfileByIDState = createSelector(
  getArtistState,
  (state: State) => {
    if (state && state.profileByID) {
      return state.profileByID;
    }
  },
);

export const getLoginedState = createSelector(
  getArtistState,
  (state: State) => {
    if (state && state.logined) {
      return state.logined;
    }
  },
);

export const getOnReviewState = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return state.onReview;
    }
  },
);

export const getProfileAuthStatus = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return {
        logined: state.logined,
        loading: state.loading,
        loaded: state.loaded,
        updated: state.updated,
      };
    }
  },
);

export const getPageStatus = createSelector(getArtistState, (state: State) => {
  if (state && state.pageStatus) {
    return state.pageStatus;
  }
});

export const getCreateArtistStepsState = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return state.createArtistSteps;
    }
  },
);

export const getMediaResponseState = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return state.mediaResponse;
    }
  },
);

export const getRemovingMediaState = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return state.removingMedia;
    }
  },
);

export const getUploadingVideoState = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return state.uploadingVideo;
    }
  },
);

export const getCalendarItems = createSelector(
  getArtistState,
  (state: State) => state.calendarItems,
);

export const getCalendarSettings = createSelector(
  getArtistState,
  (state: State) => state.calendarSettings,
);

export const getBookingSettings = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return state.bookingSettings;
    }
  },
);

export const getMessagesResponseState = createSelector(
  getArtistState,
  (state: State) => state.messagesResponse,
);

export const getUpcomingEventsResponseState = createSelector(
  getArtistState,
  (state: State) => state.upcomingEventsResponse,
);

export const getPastEventsResponseState = createSelector(
  getArtistState,
  (state: State) => state.pastEventsResponse,
);

export const getProfileUpdateResultDataState = createSelector(
  getArtistState,
  (state: State) => {
    if (state) {
      return state.profileUpdateResultData;
    }
  },
);
