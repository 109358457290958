import { StoreData } from '@app/models/shared/store-data.model';
import { VideoLink } from '@app/models/client/video/video-link.model';

export interface State {
  videoResponse: StoreData<VideoLink>;
}

export const initialState: State = {
  videoResponse: new StoreData<VideoLink>(),
};
