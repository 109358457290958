import { Action } from '@ngrx/store';

import { Response } from '@app/interfaces/response.interface';
import { EVenuePageStatus } from '@app/enums/venue/venue-page-status.enum';
import { IErrorResponse } from '@app/interfaces/error/error-response.interface';
import { UpdateVenueAccountRequest } from '@app/models/client/venue/update-venue-account-request.model';
import { UpdateVenueAccountHoursRequest } from '@app/models/client/venue/update-venue-account-hours-request.model';
// tslint:disable-next-line:max-line-length
// tslint:disable-next-line:max-line-length
import { EVenueProfileViewMode } from '@app/enums/venue/venue-profile-view-mode.enum';
import { VenueProfileResponse } from '@app/models/client/venue/venue-profile-response.model';
import { ResponseError } from '@app/models/shared/response/response-error.model';
import { MessagesVenueResponseSuccess } from '@app/models/client/venue/messages-venue-response-success.model';
import { ResponseSuccess } from '@app/models/shared/response/response-success.model';
import { GetArtistMediaResponse } from '@app/models/client/artist/get-artist-media-response.model';
import { CreateVenueAccountRequest } from '@app/models/client/venue/create-venue-account-request.model';
import { GetVenueAccountInfoResponseSuccess } from '@app/models/client/venue/get-venue-account-info-response-success.model';
import { CreateVenueSteps } from '@app/models/client/venue/create-venue-steps.model';
import { UpdateVenueAccountListingDetailsRequest } from '@app/models/client/venue/update-venue-account-listing-details-request.model';
import { UpdateVenueAccountMediaRequest } from '@app/models/client/venue/update-venue-account-media-request.model';
import { VenueAccountCalendarItem } from '@app/models/client/venue/calendar/venue-account-calendar-item.model';
import { GetEventsResponse } from '@app/models/client/events/get-events-response.model';
import { Photo } from '@app/models/client/photo/photo.model';
import { VenueAccountDatesSettings } from '@app/models/client/venue/venue-account-dates-settings.model';
import { AccountType } from '@app/models/admin/accounts/account-type.enum';

export enum ActionTypes {
  CreateProfile = '[Venue] Create Profile',
  SetIsUpdate = '[Venue] Set IsUpdate',
  CreateProfileSuccess = '[Venue] Create Profile Success',
  CreateProfileFailure = '[Venue] Create Profile Failure',

  ReviewProfile = '[Venue] Review Profile',
  ReviewProfileSuccess = '[Venue] Review Profile Success',
  ReviewProfileFailure = '[Venue] Review Profile Failure',

  GetProfile = '[Venue] Get Profile',
  GetProfileSuccess = '[Venue] Get Profile Success',
  GetProfileFailure = '[Venue] Get Profile Failure',

  GetProfileByID = '[Venue] Get Profile By Id',
  GetProfileByIDSuccess = '[Venue] Get Profile By Id Success',
  GetProfileByIDFailure = '[Venue] Get Profile By Id Failure',

  // Upcoming events

  GetUpcomingEvents = '[Venue] Get Upcoming Events',
  GetUpcomingEventsSuccess = '[Venue] Get Upcoming Events Success',
  GetUpcomingEventsFailure = '[Venue] Get Upcoming Events Failure',

  // Past events

  GetPastEvents = '[Venue] Get Past Events',
  GetPastEventsSuccess = '[Venue] Get Past Events Success',
  GetPastEventsFailure = '[Venue] Get Past Events Failure',

  UpdateAbout = '[Venue] Update About',
  UpdateAboutSuccess = '[Venue] Update About Success',
  UpdateAboutFailure = '[Venue] Update About Failure',

  UpdateHours = '[Venue] Update Hours',
  UpdateHoursSuccess = '[Venue] Update Hours Success',
  UpdateHoursFailure = '[Venue] Update Hours Failure',

  UpdateListingDetails = '[Venue] Update ListingDetails',
  UpdateListingDetailsSuccess = '[Venue] Update ListingDetails Success',
  UpdateListingDetailsFailure = '[Venue] Update ListingDetails Failure',

  GetMedia = '[Venue] Get Media',
  GetMediaSuccess = '[Venue] Get Media Success',
  GetMediaFailure = '[Venue] Get Media Failure',

  UpdateMedia = '[Venue] Update Media',
  UpdateMediaSuccess = '[Venue] Update Media Success',
  UpdateMediaFailure = '[Venue] Update Media Failure',

  UploadPhoto = '[Venue] Upload Photo',
  UploadPhotoSuccess = '[Venue] Upload Photo Success',
  UploadPhotoFailure = '[Venue] Upload Photo Failure',

  DeletePhoto = '[Venue] Delete Photo',
  DeletePhotoSuccess = '[Venue] Delete Photo Success',
  DeletePhotoFailure = '[Venue] Delete Photo Failure',

  GetCalendar = '[Venue] Get Calendar',
  GetCalendarSuccess = '[Venue] Get Calendar Success',
  GetCalendarFailure = '[Venue] Get Calendar Failure',

  AddCalendarItem = '[Venue] Add Calendar Item',
  AddCalendarItemSuccess = '[Venue] Add Calendar Item Success',
  AddCalendarItemFailure = '[Venue] Add Calendar Item Failure',

  RemoveCalendarItem = '[Venue] Remove Calendar Item',
  RemoveCalendarItemSuccess = '[Venue] Remove Calendar Item Success',
  RemoveCalendarItemFailure = '[Venue] Remove Calendar Item Failure',

  UpdateCalendarSettings = '[Venue] Update Calendar Settings',
  UpdateCalendarSettingsSuccess = '[Venue] Update Calendar Settings Success',
  UpdateCalendarSettingsFailure = '[Venue] Update Calendar Settings Failure',

  SetProfilePhoto = '[Venue] Set Profile Photo',
  SetProfilePhotoSuccess = '[Venue] Set Profile Photo Success',
  SetProfilePhotoFailure = '[Venue] Set Profile Photo Failure',

  RemoveProfile = '[Venue] Remove Profile',

  // Set Page Status
  SetPageStatus = '[Venue] Set Page Status',

  // Profile view mode
  SetProfileViewMode = '[Venue] Set Profile View Mode',

  // Set Create Venue Steps

  SetCreateVenueSteps = '[Venue] Set Create Venue Steps',

  /***** Messages *****/

  GetProfileMessages = '[Artist] Get Profile Messages',
  GetProfileMessagesSuccess = '[Artist] Get Profile Messages Success',
  GetProfileMessagesFailure = '[Artist] Get Profile Messages Failure',

  /***** Followers&Following *****/

  FollowAccount = '[Venue] Follow Account',
  FollowAccountSuccess = '[Venue] Follow Account Success',
  FollowAccountFailure = '[Venue] Follow Account Failure',

  UnfollowAccount = '[Venue] Unfollow Account',
  UnfollowAccountSuccess = '[Venue] Unfollow Account Success',
  UnfollowAccountFailure = '[Venue] Unfollow Account Failure',
}

// Create Profile

export class CreateProfile implements Action {
  public readonly type = ActionTypes.CreateProfile;

  constructor(public payload: { model: CreateVenueAccountRequest }) {}
}

export class CreateProfileSuccess implements Action {
  public readonly type = ActionTypes.CreateProfileSuccess;
}

export class CreateProfileFailure implements Action {
  public readonly type = ActionTypes.CreateProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Review Venue Account

export class ReviewProfile implements Action {
  public readonly type = ActionTypes.ReviewProfile;

  constructor(public payload: { profile: any }) {}
}

export class ReviewProfileSuccess implements Action {
  public readonly type = ActionTypes.ReviewProfileSuccess;

  constructor(public payload: { response: Response }) {}
}

export class ReviewProfileFailure implements Action {
  public readonly type = ActionTypes.ReviewProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Profile

export class GetProfile implements Action {
  public readonly type = ActionTypes.GetProfile;
}

export class GetProfileSuccess implements Action {
  public readonly type = ActionTypes.GetProfileSuccess;

  constructor(
    public payload: { response: GetVenueAccountInfoResponseSuccess },
  ) {}
}

export class GetProfileFailure implements Action {
  public readonly type = ActionTypes.GetProfileFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Profile By ID

export class GetProfileByID implements Action {
  public readonly type = ActionTypes.GetProfileByID;

  constructor(public payload: { id: string }) {}
}

export class GetProfileByIDSuccess implements Action {
  public readonly type = ActionTypes.GetProfileByIDSuccess;

  constructor(
    public payload: { response: ResponseSuccess<VenueProfileResponse> },
  ) {}
}

export class GetProfileByIDFailure implements Action {
  public readonly type = ActionTypes.GetProfileByIDFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Upcoming Events

export class GetUpcomingEvents implements Action {
  public readonly type = ActionTypes.GetUpcomingEvents;

  constructor(public payload?: { id: string }) {}
}

export class GetUpcomingEventsSuccess implements Action {
  public readonly type = ActionTypes.GetUpcomingEventsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class GetUpcomingEventsFailure implements Action {
  public readonly type = ActionTypes.GetUpcomingEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class GetPastEvents implements Action {
  public readonly type = ActionTypes.GetPastEvents;

  constructor(public payload?: { id: string }) {}
}

export class GetPastEventsSuccess implements Action {
  public readonly type = ActionTypes.GetPastEventsSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetEventsResponse> },
  ) {}
}

export class GetPastEventsFailure implements Action {
  public readonly type = ActionTypes.GetPastEventsFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Update About

export class UpdateAbout implements Action {
  public readonly type = ActionTypes.UpdateAbout;

  constructor(public payload: { model: UpdateVenueAccountRequest }) {}
}

export class SetIsUpdate implements Action {
  public readonly type = ActionTypes.SetIsUpdate;

  constructor(public payload: boolean) {}
}

export class UpdateAboutSuccess implements Action {
  public readonly type = ActionTypes.UpdateAboutSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateAboutFailure implements Action {
  public readonly type = ActionTypes.UpdateAboutFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Update Hours

export class UpdateHours implements Action {
  public readonly type = ActionTypes.UpdateHours;

  constructor(public payload: { model: UpdateVenueAccountHoursRequest }) {}
}

export class UpdateHoursSuccess implements Action {
  public readonly type = ActionTypes.UpdateHoursSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateHoursFailure implements Action {
  public readonly type = ActionTypes.UpdateHoursFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Update Listing Details

export class UpdateListingDetails implements Action {
  public readonly type = ActionTypes.UpdateListingDetails;

  constructor(
    public payload: { model: UpdateVenueAccountListingDetailsRequest },
  ) {}
}

export class UpdateListingDetailsSuccess implements Action {
  public readonly type = ActionTypes.UpdateListingDetailsSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateListingDetailsFailure implements Action {
  public readonly type = ActionTypes.UpdateListingDetailsFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Media

export class GetMedia implements Action {
  public readonly type = ActionTypes.GetMedia;

  constructor(public payload?: { venueID: string }) {}
}

export class GetMediaSuccess implements Action {
  public readonly type = ActionTypes.GetMediaSuccess;

  constructor(
    public payload: { response: ResponseSuccess<GetArtistMediaResponse> },
  ) {}
}

export class GetMediaFailure implements Action {
  public readonly type = ActionTypes.GetMediaFailure;

  constructor(public payload: { response: ResponseError }) {}
}

// Update Media

export class UpdateMedia implements Action {
  public readonly type = ActionTypes.UpdateMedia;

  constructor(public payload: { model: UpdateVenueAccountMediaRequest }) {}
}

export class UpdateMediaSuccess implements Action {
  public readonly type = ActionTypes.UpdateMediaSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateMediaFailure implements Action {
  public readonly type = ActionTypes.UpdateMediaFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Photo

export class UploadPhoto implements Action {
  public readonly type = ActionTypes.UploadPhoto;

  constructor(public payload: { model: Photo }) {}
}

export class UploadPhotoSuccess implements Action {
  public readonly type = ActionTypes.UploadPhotoSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UploadPhotoFailure implements Action {
  public readonly type = ActionTypes.UploadPhotoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

export class DeletePhoto implements Action {
  public readonly type = ActionTypes.DeletePhoto;

  constructor(public payload: { id: string }) {}
}

export class DeletePhotoSuccess implements Action {
  public readonly type = ActionTypes.DeletePhotoSuccess;

  constructor(public payload: { response: Response }) {}
}

export class DeletePhotoFailure implements Action {
  public readonly type = ActionTypes.DeletePhotoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Get Calendar

export class GetCalendar implements Action {
  public readonly type = ActionTypes.GetCalendar;

  constructor(public payload: { from: string; to: string }) {}
}

export class GetCalendarSuccess implements Action {
  public readonly type = ActionTypes.GetCalendarSuccess;

  /*constructor(public payload: { response: ResponseSuccess<GetVenueAccountCalendarResponse> }) {
  }*/
  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class GetCalendarFailure implements Action {
  public readonly type = ActionTypes.GetCalendarFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Add Calendar Item

export class AddCalendarItem implements Action {
  public readonly type = ActionTypes.AddCalendarItem;

  constructor(public payload: { day: string }) {}
}

export class AddCalendarItemSuccess implements Action {
  public readonly type = ActionTypes.AddCalendarItemSuccess;

  constructor(
    public payload: { response: ResponseSuccess<VenueAccountCalendarItem> },
  ) {}
}

export class AddCalendarItemFailure implements Action {
  public readonly type = ActionTypes.AddCalendarItemFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Remove Calendar Item

export class RemoveCalendarItem implements Action {
  public readonly type = ActionTypes.RemoveCalendarItem;

  constructor(public payload: { id: any }) {}
}

export class RemoveCalendarItemSuccess implements Action {
  public readonly type = ActionTypes.RemoveCalendarItemSuccess;

  constructor(public payload: { response: Response }) {}
}

export class RemoveCalendarItemFailure implements Action {
  public readonly type = ActionTypes.RemoveCalendarItemFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Update Calendar Settings

export class UpdateCalendarSettings implements Action {
  public readonly type = ActionTypes.UpdateCalendarSettings;

  constructor(public payload: { model: VenueAccountDatesSettings }) {}
}

export class UpdateCalendarSettingsSuccess implements Action {
  public readonly type = ActionTypes.UpdateCalendarSettingsSuccess;

  constructor(public payload: { response: Response }) {}
}

export class UpdateCalendarSettingsFailure implements Action {
  public readonly type = ActionTypes.UpdateCalendarSettingsFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Set Profile Photo

export class SetProfilePhoto implements Action {
  public readonly type = ActionTypes.SetProfilePhoto;

  constructor(public payload: { image: FormData }) {}
}

export class SetProfilePhotoSuccess implements Action {
  public readonly type = ActionTypes.SetProfilePhotoSuccess;

  constructor(public payload: { response: Response }) {}
}

export class SetProfilePhotoFailure implements Action {
  public readonly type = ActionTypes.SetProfilePhotoFailure;

  constructor(public payload: { response: IErrorResponse }) {}
}

// Remove artist-artist-profile-private-profiles

export class RemoveProfile implements Action {
  public readonly type = ActionTypes.RemoveProfile;
}

// Set Page Status

export class SetPageStatus implements Action {
  public readonly type = ActionTypes.SetPageStatus;

  constructor(public payload: { status: EVenuePageStatus }) {}
}

// Set Profile View Mode

export class SetProfileViewMode implements Action {
  public readonly type = ActionTypes.SetProfileViewMode;

  constructor(public payload: { mode: EVenueProfileViewMode }) {}
}

// Set Create Venue Steps

export class SetCreateVenueSteps implements Action {
  public readonly type = ActionTypes.SetCreateVenueSteps;

  constructor(public payload: { steps: CreateVenueSteps }) {}
}

/***** Messages *****/

export class GetProfileMessages implements Action {
  public readonly type = ActionTypes.GetProfileMessages;
}

export class GetProfileMessagesSuccess implements Action {
  public readonly type = ActionTypes.GetProfileMessagesSuccess;

  constructor(public payload: { response: MessagesVenueResponseSuccess }) {}
}

export class GetProfileMessagesFailure implements Action {
  public readonly type = ActionTypes.GetProfileMessagesFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class FollowAccount implements Action {
  public readonly type = ActionTypes.FollowAccount;

  constructor(
    public payload: {
      id: string;
      accountType: AccountType;
    },
  ) {}
}

export class FollowAccountSuccess implements Action {
  public readonly type = ActionTypes.FollowAccountSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class FollowAccountFailure implements Action {
  public readonly type = ActionTypes.FollowAccountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class UnfollowAccount implements Action {
  public readonly type = ActionTypes.UnfollowAccount;

  constructor(
    public payload: {
      id: string;
      accountType: AccountType;
    },
  ) {}
}

export class UnfollowAccountSuccess implements Action {
  public readonly type = ActionTypes.UnfollowAccountSuccess;

  constructor(public payload: { response: ResponseSuccess<any> }) {}
}

export class UnfollowAccountFailure implements Action {
  public readonly type = ActionTypes.UnfollowAccountFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export type Actions =
  | CreateProfile
  | CreateProfileSuccess
  | CreateProfileFailure
  | GetProfile
  | GetProfileSuccess
  | GetProfileFailure
  | ReviewProfile
  | ReviewProfileSuccess
  | ReviewProfileFailure
  | GetProfileByID
  | GetProfileByIDSuccess
  | GetProfileByIDFailure

  // Upcoming events
  | GetUpcomingEvents
  | GetUpcomingEventsSuccess
  | GetUpcomingEventsFailure

  // Past events
  | GetPastEvents
  | GetPastEventsSuccess
  | GetPastEventsFailure
  | UpdateAbout
  | UpdateAboutSuccess
  | UpdateAboutFailure
  | UpdateListingDetails
  | UpdateListingDetailsSuccess
  | UpdateListingDetailsFailure
  | GetMedia
  | GetMediaSuccess
  | GetMediaFailure
  | UpdateHours
  | UpdateHoursSuccess
  | UpdateHoursFailure
  | UpdateMedia
  | UpdateMediaSuccess
  | UpdateMediaFailure
  | UploadPhoto
  | UploadPhotoSuccess
  | UploadPhotoFailure
  | DeletePhoto
  | DeletePhotoSuccess
  | DeletePhotoFailure
  | GetCalendar
  | GetCalendarSuccess
  | GetCalendarFailure
  | AddCalendarItem
  | AddCalendarItemSuccess
  | AddCalendarItemFailure
  | RemoveCalendarItem
  | RemoveCalendarItemSuccess
  | RemoveCalendarItemFailure
  | UpdateCalendarSettings
  | UpdateCalendarSettingsSuccess
  | UpdateCalendarSettingsFailure
  | SetProfilePhoto
  | SetProfilePhotoSuccess
  | SetProfilePhotoFailure
  | RemoveProfile
  | SetPageStatus
  | SetProfileViewMode
  | SetCreateVenueSteps
  | GetProfileMessages
  | GetProfileMessagesSuccess
  | GetProfileMessagesFailure
  | SetIsUpdate
  | FollowAccount
  | FollowAccountSuccess
  | FollowAccountFailure
  | UnfollowAccount
  | UnfollowAccountSuccess
  | UnfollowAccountFailure;
