import { UpdateProfileRequest } from '@models/client/profiles/create-profile-response.model';

export interface State {
  loading: boolean;
  loaded: boolean;
  profiles: UpdateProfileRequest;
  isUpdate: boolean;
  error?: any;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  profiles: null,
  isUpdate: false,
  error: null,
};
