import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.AddTickets: {
      const tickets = action.payload;

      const entities = tickets.reduce(
        // tslint:disable-next-line: no-shadowed-variable
        (entities: { [id: number]: any }, ticket: any, index: number) => {
          return {
            ...entities,
            [index]: {
              id: index,
              ticket,
              count: 0,
              left: ticket.numberOfAvailable,
            },
          };
        },
        {
          ...state.entities,
        },
      );

      return {
        ...state,
        entities,
      };
    }

    case ActionTypes.AddTicketCount: {
      const entities = {
        ...state.entities,
        [action.payload]: {
          id: action.payload,
          ticket: state.entities[action.payload].ticket,
          count: ++state.entities[action.payload].count,
          left:
            state.entities[action.payload].left > 0
              ? --state.entities[action.payload].left
              : 0,
        },
      };

      return {
        ...state,
        entities,
      };
    }

    case ActionTypes.RemoveTicketCount: {
      const entities = {
        ...state.entities,
        [action.payload]: {
          id: action.payload,
          ticket: state.entities[action.payload].ticket,
          count:
            state.entities[action.payload].count > 0
              ? --state.entities[action.payload].count
              : 0,
          left: ++state.entities[action.payload].left,
        },
      };

      return {
        ...state,
        entities,
      };
    }

    case ActionTypes.GetOrganizerEventTickets: {
      return {
        ...state,
      };
    }

    case ActionTypes.RemoveTickets: {
      return {
        ...state,
        organizerEventTicketsResponse: null,
      };
    }

    case ActionTypes.GetOrganizerEventTicketsSuccess: {
      return {
        ...state,
        organizerEventTicketsResponse: action.payload.response,
      };
    }

    case ActionTypes.GetOrganizerEventTicketsFailure: {
      return {
        ...state,
        organizerEventTicketsResponse: null,
      };
    }

    case ActionTypes.SetForUpdateTicket: {
      return {
        ...state,
        ticketForUpdate: action.payload,
      };
    }

    case ActionTypes.RemoveForUpdateTicket: {
      return {
        ...state,
        ticketForUpdate: null,
      };
    }

    case ActionTypes.CreateTicket: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.CreateTicketSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case ActionTypes.UpdateTicketSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case ActionTypes.DeleteTicket: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ActionTypes.DeleteTicketSuccess: {
      const entities = state.entities;
      Object.entries(state.entities).forEach(([id, value]) => {
        if (value.ticket.id === action.payload.ticketGUID) {
          delete (entities as Record<string, unknown>)[id];
        }
      });
      return {
        ...state,
        entities: entities,
        loading: false,
        loaded: true,
        organizerEventTicketsResponse: {
          ...state.organizerEventTicketsResponse,
          tickets: state.organizerEventTicketsResponse.tickets.filter(
            (ticket) => ticket.id !== action.payload.ticketGUID,
          ),
        },
      };
    }
    case ActionTypes.DeleteTicketFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case ActionTypes.UpdateTicket: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.CreateTicketFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.PrintTicketAsPDF: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.PrintTicketAsPDFSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case ActionTypes.PrintTicketAsPDFFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.GetFanBoughtTickets: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.GetFanBoughtTicketsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        fanBoughtTicketsResponse: action.payload.response.data,
      };
    }

    case ActionTypes.GetFanBoughtTicketsFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        fanBoughtTicketsResponse: null,
      };
    }

    case ActionTypes.RemoveFanBoughtTickets: {
      return {
        ...state,
        fanBoughtTicketsResponse: null,
      };
    }

    case ActionTypes.GiveTicketToGuest: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.GiveTicketToGuestSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        inviteSuccess: true,
      };
    }

    case ActionTypes.GiveTicketToGuestFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        inviteSuccess: false,
      };
    }

    case ActionTypes.AcceptTicket: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.AcceptTicketSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case ActionTypes.AcceptTicketFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.RefuseTicket: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case ActionTypes.RefuseTicketSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case ActionTypes.RefuseTicketFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case ActionTypes.SetOrganizerEventTickets: {
      return {
        ...state,
        organizerEventTicketsResponse: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
