import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.Toggle: {
      const open = !state.open;

      return {
        ...state,
        open,
      };
    }

    default: {
      return state;
    }
  }
}
