import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  ActionTypes,
  DashboardOverview,
  DashboardOverviewByEvent,
  DashboardOverviewByEventFailure,
  DashboardOverviewByEventSuccess,
  DashboardOverviewFailure,
  DashboardOverviewSuccess,
  RecentOrder,
  RecentOrderByEvent,
  RecentOrderByEventFailure,
  RecentOrderByEventSuccess,
  RecentOrderFailure,
  RecentOrderSuccess,
} from '@store/root/client/dashboard/actions';
import { Observable } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { ResponseError } from '@models/shared/response/response-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponseHelper } from '@base/helpers/error-response.helper';
import { AppStoreActions } from '@store/root/app';
import { DashboardService } from '@services/client/dashboard/dashboard.service';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import {
  DashboardOverviewInterface,
  RecentOrdersResponse,
} from '@models/client/dashboard/dashboard.model';

@Injectable()
export class DashboardEffects {
  /**
   * Handles Dashboard Overview
   * @action - [Dashboard] Overview
   * @return:
   *   Success: [Dashboard] Overview Success
   *   Failure: [Dashboard] Overview Failure && [Errors] AddErrorResponse
   */
  dashboardOverview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DashboardOverview>(ActionTypes.DashboardOverview),
      exhaustMap(() =>
        this.dashboardService.dashboardOverview().pipe(
          map(
            (response: ResponseSuccess<DashboardOverviewInterface>) =>
              new DashboardOverviewSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new DashboardOverviewFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles Dashboard Overview By Event
   * @action - [Dashboard] Overview By Event
   * @return:
   *   Success: [Dashboard] Overview Success By Event
   *   Failure: [Dashboard] Overview By Event Failure && [Errors] AddErrorResponse
   */
  dashboardOverviewByEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DashboardOverviewByEvent>(ActionTypes.DashboardOverviewByEvent),
      exhaustMap((action) =>
        this.dashboardService
          .dashboardOverviewByEvent(action.payload.eventId)
          .pipe(
            map(
              (response: ResponseSuccess<DashboardOverviewInterface>) =>
                new DashboardOverviewByEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new DashboardOverviewByEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  /**
   * Handles Recent Order
   * @action - [Dashboard] Recent Order
   * @return:
   *   Success: [Dashboard] Recent Order Success
   *   Failure: [Dashboard] Recent Order Failure && [Errors] AddErrorResponse
   */
  recentOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RecentOrder>(ActionTypes.RecentOrder),
      exhaustMap(() =>
        this.dashboardService.getRecentOrders().pipe(
          map(
            (response: ResponseSuccess<RecentOrdersResponse>) =>
              new RecentOrderSuccess({ response }),
          ),
          catchError((errResponse: ResponseError | HttpErrorResponse) =>
            ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
              switchMap((response: ResponseError) => [
                new RecentOrderFailure({ response }),
                new AppStoreActions.AddErrorResponse({ response }),
              ]),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Handles Recent Order By Event
   * @action - [Dashboard] Recent Order By Event
   * @return:
   *   Success: [Dashboard] Recent Order By Event Success
   *   Failure: [Dashboard] Recent Order By Event Failure && [Errors] AddErrorResponse
   */
  recentOrderByEvent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RecentOrderByEvent>(ActionTypes.RecentOrderByEvent),
      exhaustMap((action) =>
        this.dashboardService
          .getRecentOrdersByEvent(action.payload.eventId)
          .pipe(
            map(
              (response: ResponseSuccess<RecentOrdersResponse>) =>
                new RecentOrderByEventSuccess({ response }),
            ),
            catchError((errResponse: ResponseError | HttpErrorResponse) =>
              ErrorResponseHelper.getErrorResponseErrorObject(errResponse).pipe(
                switchMap((response: ResponseError) => [
                  new RecentOrderByEventFailure({ response }),
                  new AppStoreActions.AddErrorResponse({ response }),
                ]),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
