import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { ActionTypes, LoadGoingFans, LoadGoingFansSuccess } from './actions';
import { EventsService } from '@app/services/client/events/events.service';
import { RootStoreState } from '@app/store/root';

@Injectable()
export class GoingFansEffects {
  public loadGoingFans$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadGoingFans>(ActionTypes.LoadGoingFans),
      switchMap((action) => {
        const limit = action.payload.limit;
        const page = action.payload.page;
        const eventId = action.payload.eventId;

        return this.eventsService.getGoingFans(limit, page, eventId).pipe(
          map(
            (response: any) =>
              new LoadGoingFansSuccess({ data: response.data }),
          ),
          catchError((response) => of(new LoadGoingFansSuccess(response.data))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private eventsService: EventsService,
  ) {}
}
