import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetProfiles: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    }
    case ActionTypes.GetProfilesSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        profiles: action.payload?.profiles?.data,
      };
    }
    case ActionTypes.GetProfilesFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        profiles: null,
        error: action.payload?.response,
      };
    }
    case ActionTypes.UpdateProfile: {
      return {
        ...state,
        loading: true,
        loaded: false,
        isUpdate: false,
        error: null,
      };
    }
    case ActionTypes.UpdateProfileSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        isUpdate: true,
        profiles: action.payload?.response?.data,
        error: null,
      };
    }
    case ActionTypes.UpdateProfileFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        isUpdate: false,
        profiles: null,
        error: action.payload?.response,
      };
    }
    case ActionTypes.AddFanProfile: {
      return {
        ...state,
        loading: true,
        loaded: false,
        isUpdate: false,
        error: null,
      };
    }
    case ActionTypes.AddFanProfileSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        isUpdate: true,
        profiles: action.payload?.response?.data,
        error: null,
      };
    }
    case ActionTypes.AddFanProfileFailure: {
      return {
        ...state,
        loading: false,
        loaded: false,
        isUpdate: false,
        profiles: null,
        error: action.payload?.response,
      };
    }
    case ActionTypes.RemoveActiveProfile: {
      return {
        ...state,
        loading: false,
        loaded: false,
        isUpdate: false,
        profiles: null,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
}
