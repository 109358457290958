import { createSelector } from '@ngrx/store';

import { RootStoreState } from '@app/store/root';
import { State } from '@store/root/client/cart/state';

export const getCartState = (state: RootStoreState.State) => state.client.cart;

export const getState = createSelector(getCartState, (state: State) => state);

export const getCurrentTicketsState = createSelector(
  getCartState,
  (state: State) => state.currentTickets,
);
