import { Action } from '@ngrx/store';
import { ResponseSuccess } from '@models/shared/response/response-success.model';
import { DonorsResponse } from '@models/client/donors/donors-response.model';
import { ResponseError } from '@models/shared/response/response-error.model';

export enum ActionTypes {
  GetDonorsByOrgAndSubId = '[Donors] Get Donors By Donors And SubCampaign ID',
  GetDonorsByOrgAndSubIdSuccess = '[Donors] Get Donors By Event And SubCampaign ID Success',
  GetDonorsByOrgAndSubIdFailure = '[Donors] Get  Donors By Event And SubCampaign Failure',
  RemoveDonorsState = '[Donors] Remove Donors State',
}

export class GetDonorsByOrgAndSubId implements Action {
  public readonly type = ActionTypes.GetDonorsByOrgAndSubId;

  constructor(public payload: { eventId: string }) {}
}

export class GetDonorsByOrgAndSubIdSuccess implements Action {
  public readonly type = ActionTypes.GetDonorsByOrgAndSubIdSuccess;

  constructor(public payload: { response: ResponseSuccess<DonorsResponse> }) {}
}

export class GetDonorsByOrgAndSubIdFailure implements Action {
  public readonly type = ActionTypes.GetDonorsByOrgAndSubIdFailure;

  constructor(public payload: { response: ResponseError }) {}
}

export class RemoveDonorsState implements Action {
  public readonly type = ActionTypes.RemoveDonorsState;
}

export type Actions =
  | GetDonorsByOrgAndSubId
  | GetDonorsByOrgAndSubIdSuccess
  | GetDonorsByOrgAndSubIdFailure
  | RemoveDonorsState;
