import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getPaymentState = (state: RootStoreState.State) =>
  state.client.payment;

export const getClientSecretState = createSelector(
  getPaymentState,
  (state: State) => state.clientSecret,
);

export const getPaymentSuccessState = createSelector(
  getPaymentState,
  (state: State) => state.paymentSuccess,
);

export const getPaymentStatusState = createSelector(
  getPaymentState,
  (state: State) => ({
    loading: state.loading,
    loaded: state.loaded,
  }),
);
