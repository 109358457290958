import { createSelector } from '@ngrx/store';

import { State } from './state';

import { RootStoreState } from '@app/store/root';

export const getOrganizerState = (state: RootStoreState.State) =>
  state.client.organizer;

export const getProfileState = createSelector(
  getOrganizerState,
  (state: State) => state.profile,
);

export const getProfileIDState = createSelector(
  getOrganizerState,
  (state: State) => {
    if (state.profile && state.profile.id) {
      return state.profile.id;
    }
  },
);

export const getProfileByIDState = createSelector(
  getOrganizerState,
  (state: State) => state.profileByID,
);

export const getUploadingVideoState = createSelector(
  getOrganizerState,
  (state: State) => state.uploadingVideo,
);

export const removeMediaState = createSelector(
  getOrganizerState,
  (state: State) => state.removeMedia,
);

export const getSendOnReviewState = createSelector(
  getOrganizerState,
  (state: State) => state.sendOnReview,
);

export const getProfileUpdateState = createSelector(
  getOrganizerState,
  (state: State) => state.updated,
);

export const getLoginedState = createSelector(
  getOrganizerState,
  (state: State) => state.logined,
);

export const getLoadedState = createSelector(
  getOrganizerState,
  (state: State) => state.loaded,
);

export const getProfileAuthStatus = createSelector(
  getOrganizerState,
  (state: State) => ({
    logined: state.logined,
    loading: state.loading,
    loaded: state.loaded,
  }),
);

export const getOrganizersEventsResponseState = createSelector(
  getOrganizerState,
  (state: State) => state.organizersEventsResponse,
);

export const getOrganizersEventsErrorsState = createSelector(
  getOrganizerState,
  (state: State) => state.error,
);

export const getOrganizersEventsResponsePastState = createSelector(
  getOrganizerState,
  (state: State) => state.organizersEventsResponsePast,
);

export const getOrganizerEventsResponseState = createSelector(
  getOrganizerState,
  (state: State) => state.organizerEventsResponse,
);

export const getOrganizerEventsResponsePastState = createSelector(
  getOrganizerState,
  (state: State) => state.organizerEventsResponsePast,
);

export const getOrganizerEventByIDResponseState = createSelector(
  getOrganizerState,
  (state: State) => state.organizerEventByIDResponse,
);

export const getOrganizerEventByIDAndUpdateResponseState = createSelector(
  getOrganizerState,
  (state: State) => {
    return {
      event: state.organizerEventByIDResponse,
      updateEvent: state.updateEvent,
    };
  },
);

export const getOrganizerPermissionResponseState = createSelector(
  getOrganizerState,
  (state: State) => state.organizerPermission,
);

export const getOrganizerEventIsCreatedState = createSelector(
  getOrganizerState,
  (state: State) => state.organizerEventIsCreated,
);

export const getMessagesResponseState = createSelector(
  getOrganizerState,
  (state: State) => state.messagesResponse,
);

export const getFollowersCountState = createSelector(
  getOrganizerState,
  (state: State) => state.followersCount,
);

export const getFollowingCountState = createSelector(
  getOrganizerState,
  (state: State) => state.followingCount,
);

export const putTicketDisplayOrderState = createSelector(
  getOrganizerState,
  (state: State) => state.organizerEventTicketsDisplayOrderUpdated,
);
