import { createSelector } from '@ngrx/store';
import { State } from './state';
import { RootStoreState } from '@app/store/root';

export const getVenueState = (state: RootStoreState.State) => {
  if (state.client) {
    return state.client.venue;
  }
};

export const getProfileState = createSelector(getVenueState, (state: State) => {
  if (state) {
    return state.profile;
  }
});

export const getProfileUpdateState = createSelector(
  getVenueState,
  (state: State) => {
    if (state) {
      return state.updated;
    }
  },
);

export const getProfileIDState = createSelector(
  getVenueState,
  (state: State) => {
    if (state.profile && state.profile.id) {
      return state.profile.id;
    }
  },
);

export const getLoginedState = createSelector(
  getVenueState,
  (state: State) => state.logined,
);

export const getProfileAuthStatus = createSelector(
  getVenueState,
  (state: State) => ({
    logined: state.logined,
    loading: state.loading,
    loaded: state.loaded,
  }),
);

export const getPageStatus = createSelector(
  getVenueState,
  (state: State) => state.pageStatus,
);

export const getCalendarItems = createSelector(
  getVenueState,
  (state: State) => state.calendarItems,
);

export const getCalendarSettings = createSelector(
  getVenueState,
  (state: State) => state.calendarSettings,
);

export const getProfileViewMode = createSelector(
  getVenueState,
  (state: State) => state.profileViewMode,
);

export const getCreateVenueStepsState = createSelector(
  getVenueState,
  (state: State) => state.createVenueSteps,
);

export const getMessagesResponseState = createSelector(
  getVenueState,
  (state: State) => state.messagesResponse,
);

export const getMediaResponseState = createSelector(
  getVenueState,
  (state: State) => state.mediaResponse,
);

export const getProfileByIDState = createSelector(
  getVenueState,
  (state: State) => state.profileById,
);

export const getUpcomingEventsResponseState = createSelector(
  getVenueState,
  (state: State) => state.upcomingEventsResponse,
);

export const getPastEventsResponseState = createSelector(
  getVenueState,
  (state: State) => state.pastEventsResponse,
);
