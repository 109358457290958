import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { environment } from '@src/environments/environment';

import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as tus from 'tus-js-client';

import { APIService } from '@app/interfaces/api-service.interface';
import { MediaStoreActions } from '@store/root/client';
import { RootStoreState } from '@store/root';

@Injectable({
  providedIn: 'root',
})
export class MediaService implements APIService {
  readonly host = environment.host;
  token: string;
  upload: any = null;

  constructor(
    private store: Store<RootStoreState.State>,
    public afAuth: AngularFireAuth,
  ) {
    this.afAuth.idToken.pipe(filter((token) => !!token)).subscribe((token) => {
      if (token) {
        this.token = token;
      }
    });
  }

  uploadPhoto(file: any, data: any) {
    this.store.dispatch(new MediaStoreActions.InitUploadPhoto());

    this.upload = new tus.Upload(file, {
      endpoint: environment.mediaHost + '/uploads/',
      headers: {
        authorization: 'Bearer ' + this.token,
        'X-Forwarded-Proto': 'https',
      },
      retryDelays: [1000, 3000],
      metadata: {
        filename: file.name,
        filetype: file.type,
        type: data?.type,
        title: data?.title,
        eventId: data?.eventId,
      },
      onError: (error) => {
        this.upload = null;
        this.store.dispatch(
          new MediaStoreActions.UploadPhotoFailure({ response: 'error' }),
        );
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        this.store.dispatch(
          new MediaStoreActions.UploadPhotoInProgress({ percentage }),
        );
      },
      onSuccess: () => {
        this.store.dispatch(
          new MediaStoreActions.UploadPhotoSuccess({
            response: {
              url: this.upload.url,
              status: 'success',
              type: data?.type,
            },
          }),
        );
      },
    });
    this.upload.start();

    return of(null);
  }
}
