import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { LocalStorageService } from '../local-storage/local-storage.service';

import { UserToken } from '@app/models/shared/user-token.model';
import * as UserStoreActions from '@app/store/root/client/user/actions';
import * as UserStoreSelectors from '@app/store/root/client/user/selectors';
import { RootStoreState } from '@app/store/root';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SignInStoreActions } from '@store/root/client';
import { SocialAuthService } from '@services/client/social-auth/social-auth.service';

@Injectable({ providedIn: 'root' })
export class UserTokenService {
  tokenResponse$ = this.store.select(
    UserStoreSelectors.getUserTokenResponseState,
  );

  constructor(
    private store: Store<RootStoreState.State>,
    private localStorageService: LocalStorageService,
    private afAuth: AngularFireAuth,
    private socialAuth: SocialAuthService,
  ) {
    this.tokenResponse$.subscribe((token: UserToken) => {
      if (token) {
        if (!this.isExpiredToken(this.getToken())) {
          this.saveToken(token);
          this.localStorageService.removeUserToken();
        }
      }
    });
  }

  getToken(): UserToken {
    return this.localStorageService.getUserToken();
  }

  checkTokenExpireDate(token: UserToken): boolean {
    let tokenDate;
    let currentDate;
    if (token) {
      tokenDate = Date.parse(token.expire).valueOf() / 1000;
      currentDate = Date.now().valueOf() / 1000;
    }

    return tokenDate < currentDate;
  }

  isExpiredToken(token: UserToken): boolean {
    return this.checkTokenExpireDate(token);
  }

  getProfiles(): void {
    this.store.dispatch(UserStoreActions.LoadProfiles());
  }

  removeTokenState(): void {
    this.store.dispatch(UserStoreActions.RemoveToken());
  }

  saveToken(token: UserToken): void {
    this.localStorageService.setUserToken(token);
  }

  refreshToken(): void {
    this.store.dispatch(UserStoreActions.RefreshToken());
  }

  updateToken(): void {
    this.afAuth.currentUser
      .then((user) => {
        if (user) {
          user.getIdTokenResult(/* forceRefresh */ true).then();
        }
      })
      .catch((error) => {
        console.error('Error updating token:', error);
      });
  }

  removeTokenAndSignOutAccount(): void {
    this.afAuth.signOut().then();
    this.socialAuth.signOut();
    this.store.dispatch(new SignInStoreActions.SignOut());
    this.localStorageService.removeAllItems();
    this.store.dispatch(UserStoreActions.RemoveToken());
  }
}
